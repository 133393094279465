import React, { Component } from "react";
import { NavLink, Link } from "react-router-dom";
import classNames from "classnames";

import firebase from "firebase/app";
import dbFireStore from "../firebase";

import Dropzone from "react-dropzone";
import Loader from "react-loader-spinner";

import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";

import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import StarIcon from "@material-ui/icons/Star";
import VerifiedIcon from "@material-ui/icons/CheckCircle";

import UserImage from "../images/user-placeholder.jpg";
import UserAvatarImg from "../images/missing-avatar.svg";
import BecomeHostImage from "../images/host-your-place.png";

import "../styles/dashboard.scss";

export default class DashboardNav extends Component {
  state = {
    uploading: false,
    acceptedFile: [],
    rejectedFile: [],
    userAvatarRef: firebase
      .database()
      .ref("users/" + firebase.auth().currentUser.uid + "/profile/photos/avatar"),
    photoURL: null,
    modalOpen: false
  };

  componentDidMount() {
    if (this.state.userAvatarRef) {
      this.state.userAvatarRef.on('value', snapshot => {
        if (snapshot.val() != null) {
          snapshot.forEach(childSnapshot => {
            var childData = childSnapshot.val();
            var childUrl = childData.url;
            this.setState({ photoURL: childUrl })
          });
        };
      });
    }
  }

  componentWillUnmount() {
    if(this.state.userAvatarRef) {
      this.state.userAvatarRef.off();
    }
  }

  onDrop = (acceptedFile, rejectedFile) => {
    this.setState({ 
      uploading: true, 
      acceptedFile, 
      rejectedFile 
    });

    const file = acceptedFile[0];
    const timestamp = firebase.database.ServerValue.TIMESTAMP;
    const storageRef = firebase.storage().ref();

    if (this.state.userAvatarRef) {
      const userPhotosRef = firebase
        .database()
        .ref("users/" + firebase.auth().currentUser.uid + "/profile/photos");
      userPhotosRef.child("avatar").remove();
    }

    const userAvatarPhotoRef = this.state.userAvatarRef.push();
    const avatarKey = userAvatarPhotoRef.key;

    const storageAvatarRef = storageRef.child(`${avatarKey}/photo.jpg`);

    storageAvatarRef.put(file).then(() => {
      this.setState({ uploading: false });

      // updates the users public profile
      const userPrivatePublicRef = firebase
        .database()
        .ref("users/" + firebase.auth().currentUser.uid + "/profile/public_profile_key");

      userPrivatePublicRef.once("value", snapshot => {
        if (snapshot.val() != null) {
          //User has public profile
          const userPublicRef = dbFireStore
            .firestore()
            .collection("users")
            .doc(snapshot.val());

          userPublicRef.update({
            user_photo_key: `${avatarKey}/photo.jpg`,
            lastUpdated: firebase.firestore.FieldValue.serverTimestamp()
          });
        } else {
          // no public profile, set profile
          // createPublicProfile();
        }
      });

      //If the user has garages we need to update the image key to those garages
      const userGaragesRef = firebase
        .database()
        .ref("users/" + firebase.auth().currentUser.uid + "/garages");

      userGaragesRef.once("value", snapshot => {
        if (snapshot.val() != null) {
          snapshot.forEach(garage => {
            const garageData = garage.val();
            const isFinished = garageData.isFinished;
            const garageKey = garageData.garage_key;

            if (isFinished && garageKey) {
              // user has a public garage that needs the avatar image updated
              const publicGarageRef = dbFireStore
                .firestore()
                .collection("garages")
                .doc(garageKey);

              publicGarageRef.update({
                owner_photo_id: avatarKey,
                lastUpdated: firebase.firestore.FieldValue.serverTimestamp()
              });
            }
          });
        } else {
          // user has no garaes, don't worry about it
        }
      });

      storageAvatarRef.getDownloadURL().then(url => {
        userAvatarPhotoRef.set({
          url: url,
          timestamp: timestamp
        });
        this.setState({ photoURL: url });
      });
    });
  };

  handleOpen = () => {
    this.setState({ modalOpen: true });
  };

  handleClose = () => {
    this.setState({ modalOpen: false });
  };

  render() {
    return (
      <div>
        <Dialog onClose={this.handleClose} open={this.state.modalOpen}>
          <h4 className="drop-photo-title">
            {this.state.uploading ? (
              <span>Uploading...</span>
            ) : (
              <span>Drop photo below to upload</span>
            )}
          </h4>
          {this.state.uploading ? (
            <div className="uploading-from-dropzone">
              <div className="user-button-icon uploading-started">
                <div className="user-icon missing-avatar uploading-started">
                  <div className="photo-upload-started">
                    <Loader type="TailSpin" color="#90ffc8" />
                  </div>
                </div>
              </div>
              <Typography
                variant="caption"
                className="upload-photo-info uploading-drop"
              >
                Almost ready!
              </Typography>
            </div>
          ) : (
            <Dropzone onDrop={this.onDrop}>
              {({ getRootProps, getInputProps, isDragActive }) => {
                return (
                  <div
                    {...getRootProps()}
                    className={classNames("dropzone", {
                      "dropzone--isActive": isDragActive
                    })}
                  >
                    <input {...getInputProps()} />
                    {isDragActive ? (
                      <div>
                        <Button className="user-button-icon uploading-drop">
                          <div
                            className="user-icon missing-avatar uploading-drop"
                            style={{
                              backgroundImage: "url(" + UserImage + ")"
                            }}
                          >
                            <CloudUploadIcon className="drop-upload-icon" />
                          </div>
                        </Button>
                        <Typography
                          variant="caption"
                          className="upload-photo-info dropit"
                        >
                          Drop your photo to start upload!
                        </Typography>
                      </div>
                    ) : (
                      <div>
                        <Button className="user-button-icon">
                          {this.state.photoURL ? (
                            <div
                              className="user-icon has-avatar"
                              style={{
                                backgroundImage:
                                  "url(" + this.state.photoURL + ")"
                              }}
                            ></div>
                          ) : (
                            <div
                              className="user-icon missing-avatar"
                              style={{
                                backgroundImage: "url(" + UserImage + ")"
                              }}
                            >
                              <img src={UserAvatarImg} alt="User Avatar" />
                            </div>
                          )}
                        </Button>
                        <Typography
                          variant="caption"
                          className="upload-photo-info"
                        >
                          Drop your image in the circle above, or click to
                          select a photo.
                        </Typography>
                      </div>
                    )}
                  </div>
                );
              }}
            </Dropzone>
          )}
        </Dialog>
        <div>
          <Paper className="dashboard-navigation">
            <div className="user-photo">
              <Button
                className="click-to-upload-btn"
                onClick={this.handleOpen}
              >
                Upload Photo
              </Button>
              {this.state.photoURL ? (
                <div
                  className="user-image"
                  style={{
                    backgroundImage: "url(" + this.state.photoURL + ")"
                  }}
                ></div>
              ) : (
                <div className="user-image no-image-found"></div>
              )}
              <div className="user-image"></div>
              <div className="dashboard-reviews">
                <div className="star-rating">

                  <span className="ratings-holder">
                    <p className="user-rating-avg">{this.props.starRating}</p>
                    <StarIcon className="star-rating"/>
                  </span>

                </div>
                <p className="review-count">{this.props.reviewCount && this.props.reviewCount == 1? `${this.props.reviewCount} Review`: `${this.props.reviewCount} Reviews`}</p>
              </div>
              <div style={{ display: "none" }}>
                <Link to="/trust-meter">
                  <Tooltip
                    disableTouchListener
                    placement="top"
                    title="Trust Level"
                  >
                    <div className="trust-meter">
                      <div
                        className={
                          "meter-bar " + "level-" + this.props.trustLevel
                        }
                      ></div>
                      <div className="meter-background"></div>
                    </div>
                  </Tooltip>
                </Link>
              </div>
            </div>
            <NavLink to="/dashboard">
              <Button className={window.location.pathname === "/dashboard" || window.location.pathname === "/" || window.location.pathname === "/login" || window.location.pathname === "/signup"? "active dash-btn": "dash-btn"}>Dashboard</Button>
            </NavLink>
            <NavLink to="/reservations">
              <Button className={window.location.pathname === "/reservations"? "active dash-btn": "dash-btn"}>Reservations</Button>
            </NavLink>
            <NavLink to="/edit-profile">
              <Button className={window.location.pathname === "/edit-profile"? "active dash-btn": "dash-btn"}>Edit Profile</Button>
            </NavLink>
            <NavLink to="/billing">
              <Button className={window.location.pathname === "/billing"? "active dash-btn": "dash-btn"}>Billing</Button>
            </NavLink>
            <NavLink to="/get-verified" className="hide-element">
              <Button className="dash-btn">
                <VerifiedIcon className="verified-user-check" />
                Get Verified
              </Button>
            </NavLink>
          </Paper>
          <div className="list-your-space-dashblock">
            <img
              src={BecomeHostImage}
              className="host-your-space-image"
              alt="Host your space"
            />
            <Typography className="list-your-space-title">
              Host your space,
              <br /> earn some cash.<span>*</span>
            </Typography>
            <Typography variant="caption" className="subcap">
              * for free!
            </Typography>
            <Link to="/garages/new">
              <Button className="list-your-space-btn">List your space</Button>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}
