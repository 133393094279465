import React from 'react';
import {CardElement, injectStripe} from 'react-stripe-elements';
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import firebase from 'firebase/app';
import LockIcon from '@material-ui/icons/Lock';
import { ReactComponent as StripeLogo } from '../images/stripe-logo.svg';

import axios from 'axios';

class AddPaymentForm extends React.Component {
  state = {
    savingCard: false,
  };

  saveCardInfo = async e => {
    // safe to assume that stripe has been loaded via previous checks to load the form in
    e.preventDefault();
    this.setState({
      savingCard: true,
    });
    const {error, token} = await this.props.stripe.createToken();
    if (error) {
      this.props.handleError({
        message: error.message
      });
      this.setState({
        savingCard: false,
      });
    } else {
      // token created, update users default payment info
      const customerId = this.props.customerId;
      const isDevMode = process.env.NODE_ENV === "development" && process.env.NODE_ENV !== "production"? true: false;

      axios({
        method: 'post',
        url: isDevMode? 'https://1tun0tk9rg.execute-api.us-east-1.amazonaws.com/default/DEV_saveCard': 'https://npf382mkeb.execute-api.us-east-1.amazonaws.com/default/saveCard',
        headers: {
          'x-api-key': isDevMode? 'hiqt5Vw8ZJUBAnyQfAe23SQzdyZtOXx2so1Qzozh': '8uIXSjSIYZ6usRQjiWZVm1UbwK6wuPA11zJCNgD3'
        },
        data: {
          customerId: customerId,
          cardToken: token.id,
        }
      }).then(response => {
        const customerData = response.data.customer;
        const success = response.data.success;
        if(success) {
          // set to state
          this.setState({
            savingCard: false,
          });
          this._element.clear();
          this.props.updateCustomerInfo(customerData);
          this.props.createModeToggleOff();
        } else {
          // there was a error
          this.setState({
            savingCard: false,
          });
          console.log('Error saving customer card info');
        }
      }).catch(error => {
        this.setState({
          savingCard: false,
        });
        console.log('Error saving customer card');
      });
    }
  };

  render() {
    return (
      <div>
        {this.props.createMode?
          <div className="payment-form-wrap">
          <Typography className="new-payment-title">Add a new payment method</Typography>
          <div className="stripe-add-card-form">
            <CardElement style={{base: {fontSize: '19px'}}} onReady={(c) => this._element = c}/>
          </div></div>: null
        }
        <div className="save-payment-information">
          {!this.props.loading && this.props.createMode?
            <Button size="small" disabled={this.state.savingCard} className={this.state.savingCard? 'about-info-save-btn loading': 'about-info-save-btn'} onClick={(e) => this.saveCardInfo(e)}>
              {this.state.savingCard?
                <div className="saving-btn-dash">
                  <CircularProgress size={13} thickness={6} className="progress-saving"/>
                  <span>
                    Saving...
                  </span>
                </div>:
                <div>Save Default Payment</div>
              }
            </Button>: null
          }
          <div className="protected-by-row">
            <LockIcon className="lock-icon-billing"/> 
            <Typography variant="caption">
              Secured by
            </Typography>
            <StripeLogo className="stripe-secure" />
          </div>
        </div>
      </div>
    );
  }
}

export default injectStripe(AddPaymentForm);