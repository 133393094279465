import React from "react";
import { Link } from "react-router-dom";

import Typography from '@material-ui/core/Typography';

import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';

import CircularProgress from "@material-ui/core/CircularProgress";

import GarageGridItem from "../../components/GarageGridItem";
import GarageEditForm from "../../components/GarageEditForm";
import ConnectStripe from '../../components/ConnectStripe';
import PayoutTable from "../../components/PayoutTable";
import PrintableTable from "../../components/PrintableTable";
import StateSelect from '../../components/StateSelect';
import CheckIcon from '@material-ui/icons/Check';

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import HelpIcon from '@material-ui/icons/HelpOutline';
import Tooltip from '@material-ui/core/Tooltip';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { withStyles } from '@material-ui/core/styles';

import Footer from "../../components/Footer";
import HostReservationsList from "../../components/HostReservationsList";

import moment from 'moment';

import firebase from 'firebase/app';
import dbFireStore from '../../firebase';

import "../../styles/hosting_hub.scss";
import "../../styles/print_invoice.scss";

const isDevMode = process.env.NODE_ENV === "development" && process.env.NODE_ENV !== "production"? true: false;

const AWS_STRIPEDASHBOARDLINK_LAMBDA = isDevMode? 'JrLc8XGMwQjxarec47FN11ZuYEikd9y42I3kVF7a': 'r9CmepiXyX5XOqxCzRYIJ4Lulm0qytoX1ZrHAOKS';
const STRIPEDASHBOARDLINK_ENDPOINT = isDevMode? 'https://gwooweja9a.execute-api.us-east-1.amazonaws.com/default/DEV_hostGenerateDashboardLink': 'https://raature570.execute-api.us-east-1.amazonaws.com/default/hostGenerateDashboardLink';

const axios = require('axios');

const formatCurrencyUSD = (amount) => {
  const locale = 'en-US';
  const options = { style: 'currency', currency: 'USD'};
  return Intl.NumberFormat(locale, options).format(amount);
}


class HostPayoutRequest extends React.Component {

  state = {
    loading: true,
    hostStripeConnected: true,
    loadingStripeHost: false,
    paymentRequestList: [],
    reservations: [],
    paymentTableRows: [],
    errorRecord: false,
    askForIssueCheck: false,
    payoutInformation: [],
    payoutSubtotal: 0,
    payoutFees: 0,
    payoutTotal: 0,
    printRecords: [],
    confirmIssueCheck: false,
    confirmIssueCheckGuidelines: false,
    loadingCheckIssuing: false,
    support_issued: false,
    support_id: null,
    resetTableStatus: false,
  };

  componentDidMount() {
    this.getUserInfo();
    this.getReservations();
  };

  componentWillUnmount() {
  };

  getUserInfo = () => {
    var userInfoId = firebase.auth().currentUser.uid;
    firebase.database().ref('/users/' + userInfoId + '/profile').once('value').then((snapshot) => {
      var needsStripeHostConfirm = snapshot.val() && !snapshot.val().beenStripeHostWelcomed && snapshot.val().host_stripe_user_id? true: false;
      var hasStripeConnectError = snapshot.val() && snapshot.val().host_stripe_connect_error? true: false;
      if(!snapshot.val().host_stripe_user_id) {
        this.setState({
          hostStripeConnected: false,
        });
      } else {
        this.setState({
          stripeLink: snapshot.val().host_stripe_user_id,
        });
      }
      if(hasStripeConnectError) {
        this.setState({
          hostStripeError: true,
        });
      }
    });
  };

  getReservations = async () => {
    await dbFireStore.firestore()
      .collection("reservations")
      .where("host_id", "==", firebase.auth().currentUser.uid)
      .orderBy('created_at', "desc")
      .get()
      .then((querySnapshot) => {
        var reservationsList = [];
        var queryLength = querySnapshot.docs.length;
        var queryLooped = 0;
        if(queryLength === 0) {
          // no reservations
          this.setState({
            loading: false,
            reservations: [],
          });
        } else {
          // user has reservations
          querySnapshot.forEach((reservation,index) => {
            queryLooped++;
            var reservationData = reservation.data();
            var newReservationObj = {};
            newReservationObj.reservation_id = reservation.id; 
            newReservationObj.payment_amount = parseInt(reservationData.rental_subtotal);
            newReservationObj.request_type = 'Reservation';
            newReservationObj.paid = reservationData.paid? "Paid": "Awaiting Payment";
            newReservationObj.is_check_payout = reservationData.is_check_payout? true: false;
            newReservationObj.payout_status = reservationData.is_check_payout && reservationData.payout_status? reservationData.payout_status: reservationData.is_check_payout? 'Pending': 'Stripe Managed';
            newReservationObj.paid_on = reservationData.created_at.toDate();
            newReservationObj.reservation_uri = `reservations/trip/${reservation.id}`;
            newReservationObj.created_at = reservationData.created_at.toDate();
            newReservationObj.uid = `${reservation.id}_RESERVATION`;

            newReservationObj.eligible_for_payout =  newReservationObj.payout_status === 'Pending' &&
                                                     newReservationObj.paid === "Paid" &&
                                                     moment(newReservationObj.paid_on).add(1, 'months').isBefore(new Date());

            reservationsList.push(newReservationObj);
            if(queryLength === queryLooped) {
              // end of loop!
              this.setState({
                reservations: reservationsList,
              }, () => {
                this.getPaymentRequestInfo();
              });
            }
          });
        }
      }).catch(error => {
        // error duh!
        this.setState({
          loading: false,
          reservations: [],
        });
      });
  };

  getPaymentRequestInfo = async () => {
    this.setState({paymentRequestList: []});
    var paymentRequestPromises = [];
    this.state.reservations.forEach((reservation, index) => {
      this[`promise_${reservation.reservation_id}`] = dbFireStore.firestore()
        .collection("reservations")
        .doc(reservation.reservation_id)
        .collection("payment_requests")
        .where("host_id", '==', firebase.auth().currentUser.uid)
        .get()
        .then((querySnapshot) => {
          var invoiceList = [];
          var invoiceObj = {};
          var queryLength = querySnapshot.docs.length;
          var queryLooped = 0;
          if(queryLength === 0) {
            // don't update the list, nothing to update
          } else {
            querySnapshot.forEach((invoice) => {
              var invoiceInfo = invoice.data();
              // parse review data here
              queryLooped++;
              invoiceObj[`${invoice.id}__${reservation.reservation_id}`] = {};
              invoiceObj[`${invoice.id}__${reservation.reservation_id}`].paid = invoiceInfo.paid? "Paid": "Awaiting Payment";
              invoiceObj[`${invoice.id}__${reservation.reservation_id}`].request_type = invoiceInfo.request_type;
              invoiceObj[`${invoice.id}__${reservation.reservation_id}`].payment_amount = parseInt(invoiceInfo.payment_amount);
              invoiceObj[`${invoice.id}__${reservation.reservation_id}`].is_check_payout = invoiceInfo.is_check_payout? true: false;
              invoiceObj[`${invoice.id}__${reservation.reservation_id}`].payout_status = invoiceInfo.is_check_payout && invoiceInfo.payout_status? invoiceInfo.payout_status: invoiceInfo.is_check_payout? 'Pending': 'Stripe Managed';
              invoiceObj[`${invoice.id}__${reservation.reservation_id}`].paid_on = invoiceInfo.paid_on? invoiceInfo.paid_on.toDate(): '0';
              invoiceObj[`${invoice.id}__${reservation.reservation_id}`].reservation_uri = `reservations/trip/${reservation.reservation_id}`;
              invoiceObj[`${invoice.id}__${reservation.reservation_id}`].id = invoice.id;
              invoiceObj[`${invoice.id}__${reservation.reservation_id}`].reservation_id = reservation.reservation_id;
              invoiceObj[`${invoice.id}__${reservation.reservation_id}`].created_at = invoice.data().timestamp.toDate();
              invoiceObj[`${invoice.id}__${reservation.reservation_id}`].uid = `${invoice.id}__${reservation.reservation_id}`;

              invoiceObj[`${invoice.id}__${reservation.reservation_id}`].eligible_for_payout = invoiceObj[`${invoice.id}__${reservation.reservation_id}`].payout_status === 'Pending' &&
                                                                                               invoiceObj[`${invoice.id}__${reservation.reservation_id}`].paid === "Paid" &&
                                                                                               invoiceInfo.paid_on && 
                                                                                               moment(invoiceObj[`${invoice.id}__${reservation.reservation_id}`].paid_on).add(1, 'months').isBefore(new Date());

              invoiceList.push(invoiceObj[`${invoice.id}__${reservation.reservation_id}`]);
              if(queryLength == queryLooped) {
                // end of the list, push to state
                this.setState(prevState => (
                  {
                    paymentRequestList: [...prevState.paymentRequestList, ...invoiceList]
                  }
                ));
              }; 
            });
          } 
        });
        paymentRequestPromises.push(this[`promise_${reservation.reservation_id}`]);

    });

    Promise.all(paymentRequestPromises).then((values) => {
      this.createRows();
    });

  };

  createRows = () => {
    var tableRows = [...this.state.paymentRequestList.slice(), ...this.state.reservations.slice()].sort((a, b) => b.created_at.getTime() - a.created_at.getTime());
    this.setState({
      paymentTableRows: tableRows,
      loading: false,
    });
  }

  createStripeDashLink = (e) => {
    e.preventDefault();
    this.setState({
      loadingStripeHost: true,
    });
    var userInfo = {
      stripe_id: this.state.stripeLink,
    };

    axios({
      method: 'POST',
      url: STRIPEDASHBOARDLINK_ENDPOINT,
      headers: {
        "x-api-key": AWS_STRIPEDASHBOARDLINK_LAMBDA
      },
      data: userInfo,
      validateStatus: (status) => { // this is so we don't confuse the catch for error when error was returned from the server
        return true;
      }
    }).then(response => {
      if(response.status === 200) {
        var temp_link = response.data.link.url;
        window.location.href = temp_link;
      }
    }).catch(error => {
      console.log(error);
      this.setState({
        loadingStripeHost: false,
      });
    });

  };

  handleNameChange = (e) => {
    this.setState({
      checkName: e.target.value
    });
  };

  printOrDownload = (e, selectedRecords) => {
    e.preventDefault();
    const paymentTableArray = [...this.state.paymentTableRows];
    const intersection = paymentTableArray.filter(element => selectedRecords.includes(element.uid));
    this.setState({
      printRecords: intersection,
    }, () => {
      window.print();
    });
  };

  promptAskForIssueCheck = (e, selectedRecords) => {
    e.preventDefault();
    const paymentTableArray = [...this.state.paymentTableRows];
    const intersection = paymentTableArray.filter(element => selectedRecords.includes(element.uid));
    const subTotal = intersection.reduce((a, b) => {
      return a + b.payment_amount
    }, 0);
    const payoutStripeCost = subTotal * 0.025;
    const total = subTotal - 4.97 - payoutStripeCost;

    if(subTotal >= 10) {
      // check this.state.paymentTableRows;
      this.setState(prevState => ({
        askForIssueCheck: !prevState.askForIssueCheck,
        payoutInformation: intersection,
        payoutSubtotal: formatCurrencyUSD(subTotal),
        payoutFees: formatCurrencyUSD(payoutStripeCost),
        payoutTotal: formatCurrencyUSD(total)
      }));
    } else {
      this.props.handleError({
          message: "The minimum required for requesting a check payout is $10."
      });
    }
  };

  clearAskForCheck = () => {
    this.setState({
      askForIssueCheck: false,
    }, () => {
      // to prevent form from flashing states if we clear at once
      this.delayClear = setTimeout(() => {
        this.setState({
          payoutInformation: [],
          payoutSubtotal: 0,
          payoutFees: 0,
          payoutTotal: 0,
          support_issued: false,
          support_id: null,
          loadingCheckIssuing: false,
          state: '',
          street_one: '',
          street_two: '',
          zip: '',
          city: '',
          confirmIssueCheckGuidelines: false,
          confirmIssueCheck: false,
        }, () => {
          clearTimeout(this.delayClear);
        });
      }, 1500);
    });
  }

  prepSearchAddressText = name => event => {
    let newAddressText = event.target.value;
    if(name === 'zip') {
      if (/^\d+$/.test(newAddressText)) {

        this.setState({
          [name]: newAddressText
        });

      } else {
        this.setState({
          [name]: ''
        });
      }
    } else {
      this.setState({
        [name]: newAddressText
      });
    }
  };

  setSelectedState = abbr => {
    this.setState({
      state: abbr.label
    });
  };

  confirmIssueCheckGuidelines = () => {
    this.setState(prevState => ({
      confirmIssueCheckGuidelines: !prevState.confirmIssueCheckGuidelines
    }));
  };

  confirmIssueCheck = () => {
   this.setState(prevState => ({
      confirmIssueCheck: !prevState.confirmIssueCheck
    }));
  };

  requestPayout = (e) => {
    e.preventDefault();
    if(!this.state.confirmIssueCheckGuidelines) {
      this.props.handleError({
          message: "Please confirm you have read the check issuing guidelines."
      });
    } else if(!this.state.confirmIssueCheck) {
      this.props.handleError({
          message: "Please confirm your check issuing name and mailing address are corrrect."
      });
    } else {
      // issue check
      this.setState({loadingCheckIssuing: true});
      var user = firebase.auth().currentUser;

      var firstName = '';
      var lastName = '';
      if(user.displayName && user.displayName.split().length > 1) {
        firstName = `${user.displayName.split(' ')[0]}`;
        lastName = `${user.displayName.split(' ')[1]}`;
      } else {
        firstName = `${user.displayName.split(' ')[0]}`;
        lastName = '';
      }

      var payToOrderOf = `
        ——————————————————————————
        Check made payable to: ${this.state.checkName}
        ——————————————————————————
      `;
      var formatAddress = `
        ——————————————————————————
        Mail to:
        ——————————————————————————
        ${this.state.street_one}
        ${this.state.street_two? `${this.state.street_two}`: ''}
        ${this.state.city}
        ${this.state.state}
        ${this.state.zip}
        ——————————————————————————
      `;
      var paymentStatusUpdate = [...this.state.payoutInformation];
      var formatPaymentRecords = this.state.payoutInformation.map(function(item, index) {
        return `Payout #${index + 1}
                ——————————————————————————
                Payment Id: ${item['id']? item['id']: '-'}
                Reservation Id: ${item['reservation_id']}
                Request Type: ${item['request_type']}
                Payout Status: ${item['payout_status']}
                Paid: ${item['paid']}
                Is Check Payout: ${item['is_check_payout']}
                Created At: ${moment(item['created_at']).format("MM/DD/YYYY @ h:mm a")}
                Guest Paid On: ${moment(item['paid_on']).format("MM/DD/YYYY @ h:mm a")}
                UID: ${item['uid']}
                Payment Amount: ${formatCurrencyUSD(item['payment_amount'])}
                ——————————————————————————`;
      });
      var formatPayoutInfo = `
        The host ${user.displayName} is requesting a check for ${this.state.payoutTotal}
        ——————————————————————————
        ${formatPaymentRecords.join("\n")}
        \n
        ================================================
        Subtotal: ${this.state.payoutSubtotal}
        Check issued fee: $4.97
        Payout fee (0.25%): ${this.state.payoutFees}
        Total Check: ${this.state.payoutTotal}
        ${payToOrderOf}
        ${formatAddress}
      `;

      this.userProfileRef = firebase.database().ref('users/' + firebase.auth().currentUser.uid + '/profile/');

      this.userProfileRef.once("value").then(profile => {
        axios({
          method: 'post',
          url: "https://3jxsocakr0.execute-api.us-east-1.amazonaws.com/default/requestCheck",
          headers: {
            "x-api-key": "NjtQz9UuBr3uGaoJLdHSv6Di2xlrrUbv33iwyNAu"
          },
          data: {
            createdBy: user.uid,
            createdByEmail: user.email,
            createdByFirstName: firstName,
            createdByLastName: lastName,
            customerStripeId: 'N/A',
            hostStripeId: profile.val().host_stripe_user_id? profile.val().host_stripe_user_id: "No HostStripeID Found",
            payoutInfo: formatPayoutInfo,
            createdAt: new Date().toISOString().split('.')[0]+"Z"
          }
        })
        .then(response => {
          this.setPaymentStatus(paymentStatusUpdate, response.data.msg);
          this.setState({ support_issued: true, support_id: response.data.success? response.data.msg: null});
          this.timeUpdatingTable = setTimeout(() => {
            this.setState({loadingCheckIssuing: false}, () => {
              clearTimeout(this.timeUpdatingTable);
            });
          }, 3000);
        })
        .catch(error => {
          console.log(error);
          this.setState({ support_issued: false, support_id: null, loadingCheckIssuing: false});
        });
      }).catch(error => {
          console.log("Reporting error for user info");
      });

    }
  };

  setPaymentStatus = (payments, support_ticket_id) => {
    var lengthPayments = payments.length;
    var iterPayments = 0;
    var updatePromises = [];
    this.setState({resetTableStatus: true}, () => {
      // this will toggle the check list to clear
      this.setState({resetTableStatus: false});
    });
    payments.forEach((record) => {
      iterPayments++;
      if(record.request_type == 'Reservation') {
        // update the reservation record
        const reservationRef = dbFireStore.firestore().collection("reservations").doc(record.reservation_id);
        this[record.reservation_id] = reservationRef.update({
          payout_status: 'Requested',
          support_ticket_id: support_ticket_id,
          lastUpdated: firebase.firestore.FieldValue.serverTimestamp()
        }).catch((error) => {
          console.log('Error updating reservation');
        });
        updatePromises.push(this[record.reservation_id]);

      } else {
        // this is a payment transaction in a reservation
        const reservationTransactionRef = dbFireStore.firestore().collection("reservations").doc(record.reservation_id).collection("payment_requests").doc(record.id);
        this[record.id] = reservationTransactionRef.update({
          payout_status: 'Requested',
          support_ticket_id: support_ticket_id,
          lastUpdated: firebase.firestore.FieldValue.serverTimestamp()
        }).catch((error) => {
          console.log('Error updating transaction in reservation');
        });
        updatePromises.push(this[record.id]);
      }
      if(iterPayments === lengthPayments) {
        Promise.all(updatePromises).then((values) => {
          this.getReservations();
        });
      }
    });
  };

  render() {

    const HtmlTooltip = withStyles(theme => ({
      popper: {
        zIndex: 9999999,
      },
      tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
      },
    }))(Tooltip);

    return (
      <div className="with-navbar-wrap">
        <div className="print-table-wrap">
        <PrintableTable printRecords={this.state.printRecords} />
        </div>
        <Dialog
          open={this.state.askForIssueCheck}
          onClose={this.clearAskForCheck}
          disableEscapeKeyDown={this.state.loadingCheckIssuing || this.state.support_id? true: false}
          disableBackdropClick={this.state.loadingCheckIssuing || this.state.support_id? true: false}
          scroll="paper"
          className="request-check-form"
        >
          {this.state.support_issued?
            <React.Fragment>
            <DialogTitle className="request-check-title">
              📬 Check Payout Requested
            </DialogTitle>
            <DialogContent>
            <Typography variant="body2">
              Great! We got your check request and will review and reply with a confirmation via email within 24 - 48 hours. Please save this request number for checking the status of your check payment.            </Typography>
            <div className="support-id-info">
            <Typography variant="caption" className="request-id-titlex">Your Request ID</Typography>
            <Typography component="h2">#{this.state.support_id}</Typography>
            </div>
            <div className="requested-check-at">
              <Typography variant="caption">
                Requested on: {moment(new Date()).format("MM/DD/YYYY @ h:mm a")}
              </Typography>
            </div>
            </DialogContent>
            <DialogActions className="center-action-btns with-print">
            <Button
              variant="contained"
              size="large"
              color="secondary"
              className="download-print-btn"
              onClick={() => window.print()}
            >
              Download/Print
            </Button>
            <Button size="small" className="welcome-next-btn thanks" onClick={() => this.clearAskForCheck()}>
              Got it thanks!
              <CheckIcon />
            </Button>
            </DialogActions>
            </React.Fragment>:
            <React.Fragment>
              <DialogTitle className="request-check-title">
                Request Check Payout
              </DialogTitle>
              <DialogContent>
                <Typography variant="body2">
                  Please enter your check and mailing information below.
                </Typography>
                <form onSubmit={(e) => this.requestPayout(e)}>
                  <FormControl margin="normal" required fullWidth>
                    <TextField
                      required
                      id="issueName"
                      label="Check pay to the order of"
                      name="issuename"
                      type="issuename"
                      className="issuename-input"
                      autoFocus={true}
                      onChange={this.handleNameChange}
                      disabled={this.state.loadingCheckIssuing} 
                      margin="normal"
                    />

                    <div className="garage-location-editor">
                      <div className="full-width-input">
                        <TextField
                          required
                          id="search_address"
                          className="search-address-newgarage"
                          label="Address"
                          value={this.state.street_one}
                          disabled={this.state.loadingCheckIssuing} 
                          onChange={this.prepSearchAddressText('street_one')}
                        />
                      </div>
                      <div className="full-width-input">
                        <TextField
                          id="search_address_two"
                          className="search-address-newgarage"
                          label="Address Line 2"
                          value={this.state.street_two}
                          disabled={this.state.loadingCheckIssuing} 
                          onChange={this.prepSearchAddressText('street_two')}
                        />
                      </div>
                      <div className="full-width-input city-garage-build">
                        <div className="col-left">
                        <TextField
                          required
                          id="search_address_city"
                          className="search-address-newgarage"
                          label="City"
                          value={this.state.city}
                          disabled={this.state.loadingCheckIssuing} 
                          onChange={this.prepSearchAddressText('city')}
                        />
                        </div>
                        <div className="col-center">
                        <StateSelect
                          setSelectedState={this.setSelectedState}
                          disabled={this.state.loadingCheckIssuing} 
                          currentSelect={this.state.state}/>
                        </div>
                        <div className="col-right">
                        <TextField
                          required
                          id="search_zip"
                          className="search-address-newgarage"
                          label="Zip"
                          disabled={this.state.loadingCheckIssuing} 
                          value={this.state.zip}
                          onChange={this.prepSearchAddressText('zip')}
                        />
                        </div>
                      </div>
                    </div>
                    <div className="check-total-and-fees">
                      <div className="payment-info-breakdown">
                        <div className="total-table">

                          <div className="total-row">
                            <div className="info-col">
                              <Typography variant="caption">Payment #:</Typography>
                              <div className="tooltip-help-icon">
                                <HtmlTooltip
                                  placement="left"
                                  title={
                                    <React.Fragment>
                                      <Typography color="inherit" variant="body2">The total of completed transactions you would like to receive payment from.</Typography>
                                    </React.Fragment>
                                  }
                                >
                                  <HelpIcon />
                                </HtmlTooltip>
                              </div>

                            </div>
                            <div className="amount-col">
                              <Typography variant="caption">{this.state.payoutInformation.length}x</Typography>
                            </div>
                          </div>

                          <div className="total-row">
                            <div className="info-col">
                              <Typography variant="caption">Subtotal:</Typography>
                              <div className="tooltip-help-icon">
                                <HtmlTooltip
                                  placement="left"
                                  title={
                                    <React.Fragment>
                                      <Typography color="inherit" variant="body2">Total earned from selected payouts.</Typography>
                                    </React.Fragment>
                                  }
                                >
                                  <HelpIcon />
                                </HtmlTooltip>
                              </div>

                            </div>
                            <div className="amount-col">
                              <Typography variant="caption">{this.state.payoutSubtotal}</Typography>
                            </div>
                          </div>

                          <div className="total-row">
                            <div className="info-col">
                              <Typography variant="caption">Check Fee:</Typography>
                              <div className="tooltip-help-icon">
                                <HtmlTooltip
                                  placement="left"
                                  title={
                                    <React.Fragment>
                                      <Typography color="inherit" variant="body2">We charge a flat rate for postage and processing of checks.</Typography>
                                    </React.Fragment>
                                  }
                                >
                                  <HelpIcon />
                                </HtmlTooltip>
                              </div>
                            </div>
                            <div className="amount-col">
                              <Typography variant="caption">$4.97</Typography>
                            </div>
                          </div>

                          <div className="total-row">
                            <div className="info-col">
                              <Typography variant="caption">Payout Fee:</Typography>
                              <div className="tooltip-help-icon">
                                <HtmlTooltip
                                  placement="left"
                                  title={
                                    <React.Fragment>
                                      <Typography color="inherit" variant="body2">This is the fee charged by payment processors to distribute your earnings.</Typography>
                                    </React.Fragment>
                                  }
                                >
                                  <HelpIcon />
                                </HtmlTooltip>
                              </div>
                            </div>
                            <div className="amount-col">
                              <Typography variant="caption">{this.state.payoutFees}</Typography>
                            </div>
                          </div>

                          <div className="total-row final-amount">
                            <div className="info-col">
                              <Typography variant="caption">Total:</Typography>
                              <div className="tooltip-help-icon">
                                <HtmlTooltip
                                  placement="left"
                                  title={
                                    <React.Fragment>
                                      <Typography color="inherit" variant="body2">This is your subtotal minus our check issuing fee and payment processor fees.</Typography>
                                    </React.Fragment>
                                  }
                                >
                                  <HelpIcon />
                                </HtmlTooltip>
                              </div>
                            </div>
                            <div className="amount-col">
                              <Typography variant="caption">{this.state.payoutTotal}</Typography>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="confirm-check-send-actions">
                    <FormControlLabel
                      control={
                        <Checkbox checked={this.state.confirmIssueCheckGuidelines} disabled={this.state.loadingCheckIssuing} onChange={() => this.confirmIssueCheckGuidelines()} value="check_agreement" />
                      }
                      label={
                        <div className="inline-label-with-link">
                          <Typography>
                            I agree to and understand the
                          </Typography>
                          {/* <a href="https://help.motorroof.com/article/48-receiving-a-check-payout-instead-of-direct-deposit" target="_blank"> */}
                          <Typography className="garage-host-guidelines">
                            check issuing guidelines
                          </Typography>
                          {/* </a> */}
                        </div>
                      }
                    />
                    <FormControlLabel
                      control={
                        <Checkbox checked={this.state.confirmIssueCheck} disabled={this.state.loadingCheckIssuing} onChange={() => this.confirmIssueCheck()} value="check_info_correct_agreement" />
                      }
                      label="The above check name and mailing address is correct."
                    />
                  </div>
                  </FormControl>
                  <Typography variant="caption" className="confirm-check-info">We will confirm through email before sending your check</Typography>
                  <DialogActions className="center-action-btns">
                    <Button
                      variant="contained"
                      size="large"
                      color="secondary"
                      disabled={this.state.loadingCheckIssuing}
                      className={this.state.loadingCheckIssuing? "next-update-btn cancel requesting":"next-update-btn cancel"}
                      onClick={this.clearAskForCheck}
                    >
                    Cancel
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      size="large"
                      disabled={this.state.loadingCheckIssuing}
                      color="primary"
                      startIcon={this.state.loadingCheckIssuing? <CircularProgress style={{height: 15, width: 15, color: "#0bc794"}} className="loading-payment-request requesting-check" />:null}
                      className={this.state.loadingCheckIssuing? "next-update-btn requesting-check": "next-update-btn"}
                    >

                    {this.state.loadingCheckIssuing?
                      "Requesting Check":"Request Payout Check"
                    }
                    
                    </Button>
                  </DialogActions>
                </form>
              </DialogContent>
            </React.Fragment>
          }
        </Dialog>
        <React.Fragment>
          {this.state.loadingStripeHost || this.state.loading?
            <div className="app-loader-wrap dashboard-loader">
              <div className="loading-app">
                <CircularProgress className="main-loader"/>
              </div>
            </div>:null}
        </React.Fragment>
        <div className="host-container">
          <div className="col-left">
            <Typography variant="h5" component="h3">
              Payment Center
            </Typography>
            <Typography variant="caption" className="subtitle">
              Summary
            </Typography>

            {this.state.hostStripeConnected && this.state.stripeLink && !this.state.hostStripeError?
              <div className="manage-your-stripe-account">
              <Typography className="manage-info">🏦 You have MotorRoof direct deposits connected using Stripe.</Typography>
              <Button onClick={(e) => this.createStripeDashLink(e)}>
                  <Typography variant="body2">Manage Direct Deposits</Typography>
              </Button></div>:<div className="no-direct-deposits-but-can-issue-checks">
                <Typography className="check-issue-title">💸 You can receive payouts via a mailed check</Typography>
                <Typography className="check-issue-content">
                You have no direct deposit connected but can still receive payouts via check. {/*To learn more about check issuing fees, limits, and how to request a payout check view our 
                <a href="https://help.motorroof.com/article/48-receiving-a-check-payout-instead-of-direct-deposit" target="_blank"> help articles here.</a>*/}
                </Typography>
              </div>
            }

            <div className="table-wrapper-account-hosting">
              <PayoutTable resetTable={this.state.resetTableStatus} printOrDownload={this.printOrDownload} rows={this.state.paymentTableRows} loading={this.state.loading} errorRecord={this.state.errorRecord} promptAskForIssueCheck={this.promptAskForIssueCheck}/>
            </div>
          </div>
          <div className="col-right">
            <Typography variant="caption" className="host-nav-title">Host Tools</Typography>
            <div className="host-links">
              <Link to="/hosting-hub">
              <Button className={window.location.pathname === "/hosting-hub"? "active": ""}>
                <Typography variant="body2">Hosting Hub</Typography>
              </Button> 
              </Link>
              <Button className={window.location.pathname === "/payment-center"? "active": ""}>
                <Typography variant="body2">Payment Center</Typography>
              </Button>
              {!this.state.hostStripeConnected || this.state.hostStripeError?
                <ConnectStripe isButton={true} />:null
              }
              {/* 
              <a href="https://news.motorroof.com/starting-your-space-sharing-business-in-8-minutes/">
              <Button>
                <Typography variant="body2">Hosting Tips</Typography>
              </Button>
              </a>
              <a href="https://help.motorroof.com/">
                <Button>
                  <Typography variant="body2">Support</Typography>
                </Button>
              </a> */}
            </div>
            {!this.state.hostStripeConnected || this.state.hostStripeError?
              <ConnectStripe type={"sidebar"} />:null
            }
          </div>
        </div>
        <Footer />
      </div>
    );
  }
};

export default HostPayoutRequest;
