import React, { useState, useEffect } from 'react';
import {Link} from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Footer from "../../components/Footer";
import Dialog from '@material-ui/core/Dialog';
import firebase from "firebase/app";

import HostAgreeImage from "../../images/approved-guest.png";

import { ReactComponent as PrivateChat } from '../../images/private-chat.svg';
import { ReactComponent as AcceptPayments } from '../../images/accept-payments.svg';
import { ReactComponent as ApproveBookings } from '../../images/approve-booking.svg';
import { ReactComponent as CalendarTracking } from '../../images/calendar-tracking.svg';
import { ReactComponent as Reminders } from '../../images/reminders.svg';
import { ReactComponent as ReviewGuests } from '../../images/review-guests.svg';
import { ReactComponent as TwentyFour } from '../../images/24hr-color.svg';

import { ReactComponent as HostBackground } from '../../images/motorroof-host-bg.svg';
import { ReactComponent as PlayIcon } from '../../images/play.svg';
import { ReactComponent as OpenIcon } from '../../images/open.svg';
import { ReactComponent as WrenchIcon } from '../../images/wrenchscrew.svg';
import { ReactComponent as ScheduleIcon } from '../../images/schedule.svg';
import { ReactComponent as CameraHosticon } from '../../images/camera-host.svg';
import { ReactComponent as ShieldCheck } from '../../images/shield-check.svg';

import "../../styles/how_to_host.scss";


const HowToHost = (props) => {
  const [hostingInfoOpen, setHostingInfoOpen] = React.useState(false);
  const [hosting101Open, setHosting101Open] = React.useState(false);
  
  const setBeacon = setTimeout(() => {
    clearTimeout(setBeacon);
    window.Beacon('init', 'b6fb3931-6d73-4a27-a596-87191307622c');
  }, 200);

  useEffect(() => {
    return function cleanup() {
      window.Beacon('destroy');
    };
  });

  const ToggleBeacon = () => {
    window.Beacon('open');
  }

  const toggleHostingInfoDialog = () => {
    setHostingInfoOpen(!hostingInfoOpen);
  }

  const toggleHosting101Dialog = () => {
    setHosting101Open(!hosting101Open);
  }

  var userSignedIn = firebase.auth().currentUser;

  return (
    <div className="with-navbar-wrap">

      <Dialog
        open={hostingInfoOpen}
        onClose={toggleHostingInfoDialog}
      >
      <div className="about-stripe-dialog hosting-page-dialog">
        <Typography variant="h4">MotorRoof Hosting 101</Typography>
        <div className="embeded-iframe-youtube">
          <iframe src="https://www.youtube.com/embed/SoZMXXkfjJI?rel=0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <div className="stripe-info-actions-wrap">
          <Button className="close-connect-btn" onClick={toggleHostingInfoDialog}>Close</Button>
        </div>
      </div>
      </Dialog>

      <div className="host-info-hero">
        <div className="how-to-container">
          <HostBackground />
          <div className="intro-host-hero">
          </div>
          <div className="intro-host-content">
            <div className="mobile-host-hero-wrap">
            <h1>
              <span>Earn Money</span>
              Sharing Your Space
            </h1>
            </div>
            <div className="avg-income">
              <Typography>
              Extra parking space?
              <span>
              $500 monthly average
              </span>
              </Typography>
              <Typography>
              Two car garage space?
              <span>
              $900 monthly average
              </span>
              </Typography>
            </div>

            <div className="hero-action">
              <Link to={userSignedIn? '/garages/new':'/signup'}>
                <Button className="home-std-btn">List your space</Button>
              </Link>
              <Typography className="list-caption">it's free!</Typography>
            </div>

          </div>

        </div>
 
        <div className="what-is-motorroof-video-wrap">
          <div className="what-is-container">
            <div className="video-col">
              <div className="video-thumb" onClick={toggleHostingInfoDialog}>
                <PlayIcon />
              </div>
            </div>

              <div className="about-col">
              <strong>MotorRoof Hosting 101</strong>
              <Typography>Share your space on MotorRoof by signing up to be a host to our large community filled with users looking to rent out a space to store or work on their ride.</Typography>
            </div>
          </div>
        </div>


      </div>

      <div className="your-rules-wrap">
        <div className="text-content-container">
          <h2>Your Place, Your Rules</h2>
          <Typography>Each space is unique. You can specify detailed rules for potential guests. Think of MotorRoof like an Airbnb but more custom to hobbyists and storage needs.</Typography>
        </div>
        
        <div className="rule-list">
          <div className="rule-col open">
            <OpenIcon />
            <Typography>Set open hours</Typography>
          </div>
          <div className="rule-col schedule">
            <ScheduleIcon />
            <Typography>Set access days</Typography>
          </div>
          <div className="rule-col wrench">
            <WrenchIcon />
            <Typography>Add amenities</Typography>
          </div>
          <div className="rule-col shield">
            <ShieldCheck />
            <Typography>Screen guests</Typography>
          </div>
          <div className="rule-col camera">
            <CameraHosticon />
            <Typography>Upload Photos</Typography>
          </div>
        </div>

        <div className="customize-action-wrap">
          <div className="customize-action">
            <Link to={userSignedIn? '/garages/new':'/signup'}>
              <Button className="home-std-btn">Customize</Button>
            </Link>
          </div>
        </div>
    
      </div>

      <div className="what-can-you-list-wrap">
        <div className="what-can-you-list-container">
          <div className="what-content">
            <h2>What Can You List?</h2>
            <Typography>
              Almost any space, but here are some common spaces posted on MotorRoof to get your wheels turning.
            </Typography>
            <div className="list-of-spaces">
              <div className="left-col">
                <ul>
                  <li><Typography variant="caption">Attached/detached home garages</Typography></li>
                  <li><Typography variant="caption">White-glove service facilities</Typography></li>
                  <li><Typography variant="caption" variant="caption">Shared garages</Typography></li>
                  <li><Typography variant="caption" variant="caption">Warehouses</Typography></li>
                  <li><Typography variant="caption" variant="caption">Race shops</Typography></li>
                  <li><Typography variant="caption" variant="caption">Storage units</Typography></li>
                </ul>
              </div>
              <div className="right-col">
                 <ul>
                  <li><Typography variant="caption">Commercial garages</Typography></li>
                  <li><Typography variant="caption">Covered parking</Typography></li>
                  <li><Typography variant="caption">Street parking</Typography></li>
                  <li><Typography variant="caption">Parking garages</Typography></li>
                  <li><Typography variant="caption">Lawn space</Typography></li>
                  <li><Typography variant="caption">More</Typography></li>
                </ul>
              </div>

            </div>

            <div className="list-spaces-action">
              <Link to={userSignedIn? '/garages/new':'/signup'}>
                <Button className="home-std-btn">List your space</Button>
              </Link>
            </div>

          </div>
        </div>
      </div>

      <div className="host-tool-tri-overflow">
        <div className="host-tools-list">

          <div className="tool-list-content-container">
            <img src={HostAgreeImage} className="hosting-tools-top-image" />
            <h2>Supercharged</h2><h2>Reservation Tools</h2>
            <Typography>MotorRoof's Hosting Hub gives you professional tools to manage all hosting operations.</Typography>
          </div>

          <div className="tool-item-list">
            <div className="tool-grid-col">
              <div className="grid-item private-chat">
                <PrivateChat />
                <Typography>Private Chat</Typography>
              </div>
            </div>
            <div className="tool-grid-col">
              <div className="grid-item accept-payments">
                <AcceptPayments />
                <Typography>Accept Payments</Typography>
              </div>
            </div>
            <div className="tool-grid-col">
              <div className="grid-item approve-booking">
                <ApproveBookings />
                <Typography>Approve Bookings</Typography>
              </div>
            </div>
            <div className="tool-grid-col">
              <div className="grid-item review-guests">
                <ReviewGuests />
                <Typography>Review Guests</Typography>
              </div>
            </div>
            <div className="tool-grid-col">
              <div className="grid-item calendar-tracking">
                <CalendarTracking />
                <Typography>Calendar Tracking</Typography>
              </div>
            </div>
            <div className="tool-grid-col">
              <div className="grid-item reminder-system">
                <Reminders />
                <Typography>Reminder System</Typography>
              </div>
            </div>
          </div>
          <Typography className="and-more-tools">...and more!</Typography>
          
          <div className="host-tools-action">
            <Link to={userSignedIn? '/garages/new':'/signup'}>
              <Button className="home-std-btn">Try it out</Button>
            </Link>
          </div>

          <div className="tool-bg-left"></div>
          <div className="tool-bg-right"></div>

        </div>
      </div>
      <div className="chart-bg"></div>

      <div className="payment-systems-wrap">
        <div className="stripe-connected-img"></div>
        <div className="payment-system-info-container">
          <div className="col-left">
            <h2>Advance Payment Systems</h2>
            <Typography>We use only the best in secure payment systems integrations. MotorRoof has partnered with Stripe, a trusted payment processor used by companies such as Amazon, Google, and Target.</Typography>
            <Link to={userSignedIn? '/garages/new':'/signup'}>
              <Button className="home-std-btn">Get Started</Button>
            </Link>
          </div>
          <div className="col-right">
            <strong>Partnership Advantages</strong>
            <ul>
              <li><Typography>Direct Deposit payouts on the first day of each month.</Typography></li>
              <li><Typography>Dynamic risk-based KYC/AML checks.</Typography></li>
              <li><Typography>OFAC and sanctions screening.</Typography></li>
              <li><Typography>IRS tax threshold tracking and 1099 support.</Typography></li>
              <li><Typography>Card industry blacklist filtering.</Typography></li>
              <li><Typography>Screening for bad actors using data from the Stripe network.</Typography></li>
            </ul>
          </div>
        </div>
      </div>

      <div className="host-get-started-wrap">
        <div className="host-get-started-content">
          <h2>Get Started</h2>
          <Typography>Posting your space is free and the first step is signing up. If you need help adding your space talk to a MotorRoof team member right now using our live chat below with 24-hour support.</Typography>
        </div>
        <div className="get-started-grid">
          <div className="left-col no-video-left-col">
            <TwentyFour />
            <Typography>1(855)212-3262</Typography>
            {/*
            <div className="video-thumb" onClick={toggleHosting101Dialog}>
              <PlayIcon />
            </div>
            <Typography variant="caption">MotorRoof Hosting 101</Typography>
            */}
            <a href={"tel:18552123262"}>
            <Button className="home-std-btn">Phone Support</Button>
            </a>
          </div>
          <div className="center-col">
            <div className="become-a-host-card">
              <strong>Become a Host</strong>
              <div className="hosting-start"></div>
              <Link to={userSignedIn? '/garages/new':'/signup'}>
                <Button className="home-std-btn">{userSignedIn? 'List Space':'Sign Up'}</Button>
              </Link>
              <Typography className="list-caption">it's free!</Typography>
            </div>
          </div>
          <div className="right-col">
            <div className="live-chat-team"></div>
            <div className="mobile-text-info-support">
              <Typography>Need help setting up your account?</Typography>
            </div>
            <Button className="home-std-btn" onClick={() => ToggleBeacon()}>Live Chat</Button>
          </div>
        </div>
      </div>

      <div className="business-support-wrap">
        <div className="business-support-content">
          <div className="business-hero"></div>
          <h2>Business Assistance</h2>
          <Typography>
            Have a storage facility or space with existing customers?  Ask us about our free data migration services and other ways we can accelerate your business on MotorRoof.
          </Typography>
          <Button className="home-std-btn" onClick={() => ToggleBeacon()}>Contact Us</Button>
        </div>
      </div>

      <div className="no-padding-footer">
        <Footer />
      </div>
    </div>
  );
};

export default HowToHost;
