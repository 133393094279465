import React, { Component } from "react";
import firebase from 'firebase/app';
import dbFireStore from '../../firebase';

import { withStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import Tooltip from '@material-ui/core/Tooltip';
import Button from "@material-ui/core/Button";
import StarIcon from "@material-ui/icons/Star";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

import GarageGridItem from "../../components/GarageGridItem";

import { ReactComponent as VerifyIcon } from '../../images/user-verification.svg';

import BlockIcon from '@material-ui/icons/Block';
import ReportUser from "../../components/ReportUser";

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

export default class PublicProfilePage extends Component {

  state = {
    user_name: null,
    user_photo_url: null,
    verified_phone: null,
    verified_email: null,
    verified_id: null,
    loading: false,
    propertiesList: null,
  };

  componentDidMount() {
    if(this.props.match.params.public_profile_key) {
      this.userInfo();
    }
  }

  userInfo = () => {
    this.setState({ loading: true });
    const publicProfileRef = dbFireStore.firestore().collection("users").doc(this.props.match.params.public_profile_key);
    publicProfileRef.get()
      .then(doc => {
        if(doc.exists) {
          const user = doc.data();
          this.setState({
            user_name: user.display_name? user.display_name: false,
            verified_phone: user.verified_phone? user.verified_phone: false,
            verified_email: user.verified_email? user.verified_email: false,
            verified_id: user.verified_id? user.verified_id: false,
            loading: false
          });
          this.getUserPhoto(user.user_photo_key);
          this.getUsersGarages(user.user_id);
        } else {
          this.setState({ loading: false });
        }
      }).catch(error => {
        console.log(error);
      });
  };

  getUserPhoto = userPhotoKey => {
    const storageRef = firebase.storage().ref();
    const storageUserPhotoRef = storageRef.child(`${userPhotoKey}`);

    storageUserPhotoRef.getDownloadURL().then(url => {
      this.setState({ user_photo_url: url });
    });
  };

  getGarageLeadPhoto = () => {
    const { propertiesList } = this.state;
    propertiesList.forEach((garage, index) => {
      const garageRecord = this.state.propertiesList[index];
      if(garage.photos) {
        const photosList = Object.entries(garage.photos);
        photosList.forEach((photo) => {
          if(photo[1].order === 0) {
            const leadId = photo[0];
            const leadImgLocationRef = firebase.storage().ref(`/garages/${garage.garage_key}/${leadId}/photo.jpg`);
            leadImgLocationRef.getDownloadURL()
              .then(url => {
                garageRecord.leadPhoto = url;
                this.setState(prevState => ({
                  propertiesList: [
                    ...prevState.propertiesList.slice(0,index),
                    garageRecord,
                    ...prevState.propertiesList.slice(index + 1)
                  ]
                }));              
              })
              .catch(error => {
                console.log("Photo not found: ", error);
              });
          }
        });
      }
    });
  };

  getUsersGarages = userID => {
    const userGaragesRef = dbFireStore.firestore().collection('garages');
    userGaragesRef.where('owner_id', '==', userID).get()
      .then(snapshot => {
        if (snapshot.empty) {
          // User does not have garages
          this.setState({
            propertiesList: null
          });
        } else {
          const propertiesList = [];
          const queryLength = snapshot.size;
          let queryLooped = 0;
          if(queryLength === 0) {
            this.setState({
              propertiesList: null
            });
          } else {
            snapshot.forEach(garage => {
              const garageData = garage.data();
              garageData.id = garage.key;
              garageData.index = queryLooped;
              queryLooped++;
              propertiesList.push(garageData);
              if(queryLength === queryLooped) {
                this.setState({
                  propertiesList,
                }, () => {
                  this.getGarageLeadPhoto();
                });
              }
            });
          }          
        }
      })
      .catch(err => {
        console.log('Error getting documents: ', err);
      });
  };

  reportUser = () => {
    const firestoreTimestamp = firebase.firestore.FieldValue.serverTimestamp();

    dbFireStore.firestore().collection("reported").add({
      reporting_user: firebase.auth().currentUser.uid,
      reported_user: this.state.user_id,
      report_description: this.props.valueReport,
      created_at: firestoreTimestamp
    }).then(ref => {
      this.setState({ reported: true });
      // Send email about report ?
    }).catch(error => {
      this.props.askToReport();
      // Log Error
    });
  };

  redirectLogin = () => this.props.history.push(`/login`);

  render() {
    const { 
      user_name, 
      user_photo_url,
      verified_phone, 
      verified_email, 
      verified_id,
      loading,
      propertiesList
    } = this.state;

    const userSignedIn = firebase.auth().currentUser;
    const offendingUserId = null;
    const offendingUserPublicId = this.props.match.params.public_profile_key;
    return (
      <div style={{
        position: "absolute",
        top: "10%",
      }}>
        <div
          style={{
            width: "450px",
            height: "100%",
            padding: "20px",
            position: "relative"
          }}
          role="presentation"
        > 
          {loading? null :
            <div>
              <ReportUser
                reportUser={this.props.reportUser}
                wantsToReport={this.props.wantsToReport}
                askToReport={this.props.askToReport}
                valueReport={this.props.valueReport}
                typeMessageReport={this.props.typeMessageReport}
                reported={this.props.reported}
                reportedId={this.props.reportedId}
                loadingReport={this.props.loadingReport}
                typeOfReport={"public_profile"}
                offendingUserId={offendingUserId}
                offendingUserPublicId={offendingUserPublicId}
              />
              {user_name?
                <div>
                  <div className="host-info-card">
                    {user_photo_url?
                      <div className="owner-photo" style={{backgroundImage: `url('${user_photo_url}')`}}></div>:
                      <div className="owner-photo missing-photo"></div>
                    }
                    <div className="owner-info">
                      <Typography variant="h4">
                        {user_name}
                      </Typography>
                      <div className="owner-rating">
                        <Typography variant="body1">5</Typography>
                        <StarIcon className="user-star" />
                      </div>
                      <div className="user-verify-row">
                        <HtmlTooltip
                          placement="left"
                          title={
                            <React.Fragment>
                              <Typography color="inherit" variant="body2">Verification checked</Typography>
                            </React.Fragment>
                          }
                        >
                          <div className="user-verify-title">
                            <VerifyIcon />
                          </div>
                        </HtmlTooltip>
                        {verified_email?
                          <div className="user-verify-item is-verified">
                            <CheckCircleIcon />
                            <Typography variant="caption">
                              Email
                            </Typography>
                          </div>:
                          <div className="user-verify-item pending">
                            <RadioButtonUncheckedIcon />
                            <Typography variant="caption">
                              Email
                            </Typography>
                          </div>
                        }
                        {verified_phone?
                          <div className="user-verify-item is-verified">
                            <CheckCircleIcon />
                            <Typography variant="caption">
                              Phone
                            </Typography>
                          </div>:
                          <div className="user-verify-item pending">
                            <RadioButtonUncheckedIcon />
                            <Typography variant="caption">
                              Phone
                            </Typography>
                          </div>
                        }
                        {verified_id?
                          <div className="user-verify-item is-verified">
                            <CheckCircleIcon />
                            <Typography variant="caption">
                              ID
                            </Typography>
                          </div>:
                          <div className="user-verify-item pending" >
                            <RadioButtonUncheckedIcon />
                            <Typography variant="caption">
                              ID
                            </Typography>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                  {propertiesList?
                  <div className="properties-list-hosthub">
                    {this.state.propertiesList.map((garage, i) => (
                      <div className="grid-list-block" key={`garageitem-${i}`}>
                          <div className="link-garage">
                            <div onClick={() => this.handleDialog(garage)}>
                            <GarageGridItem
                              garageTitle={garage.title}
                              isStorage={garage.isStorage}
                              isWorkshop={garage.isWorkshop}
                              avatarPhotoURL={this.state.user_photo_url}
                              displayName={this.props.hostName}
                              starRating={garage.userStars? garage.userStars: 5}
                              price={garage.price}
                              city={garage.city}
                              state={garage.state}
                              photo={garage.leadPhoto}
                            />
                            </div>
                          </div>                     
                      </div>
                    ))}
                  </div>
                  :null}
                  <div className="host-review-title">
                    <Typography variant="caption">
                      Recent Host Reviews
                    </Typography>
                  </div>
                  <div className="no-reviews-for-owner">
                    <Typography variant="body1">
                      This owner hasn't received a review yet.
                    </Typography>
                  </div>
                  <div className="report-public-user">
                    <Button startIcon={<BlockIcon />} onClick={userSignedIn? this.props.askToReport : this.redirectLogin} className="report-btn">Report User</Button>
                  </div>
                </div>:<div><h1>THIS USER DOES NOT EXIST</h1></div>
              }
            </div>
          }
        </div>
      </div>
    );
  }
}