import React from "react";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from '@material-ui/core/Button';
import moment from 'moment';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import {Link} from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';

import firebase from 'firebase/app';
import dbFireStore from '../firebase';

import ReservationListItem from "./ReservationListItem";

import "../styles/reservation_list.scss";

const toTitleCase = (str) => {
    return str.replace(/\w\S*/g, function(txt){
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
}

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      className="reservations-tab-panel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

const ReservationTabs = withStyles({
  root: {
    borderColor: '#e8e8e8',
  },
  indicator: {
    backgroundColor: '#1890ff',
  },
})(Tabs);

const ReservationTab = withStyles(theme => ({
  root: {
    textTransform: 'none',
    '&:hover': {
      color: '#40a9ff',
      opacity: 1,
    },
    '&$selected': {
      color: '#1890ff',
    },
    '&:focus': {
      color: '#40a9ff',
    },
  },
  selected: {
    color: "40a9ff",
  }
}))(props => <Tab {...props} />);

class HostReservationsList extends React.Component {
  state = {
    loading: true,
    garages: {},
    value: 0,
    upcomingList: [],
    pastList: [],
  }

  componentDidMount() {
    this.checkForReservations();
  }

  checkForReservations = async () => {
    await dbFireStore.firestore()
      .collection("reservations")
      .where("host_id", "==", firebase.auth().currentUser.uid)
      .orderBy('created_at', "desc")
      .get()
      .then((querySnapshot) => {
        var reservationsList = [];
        var queryLength = querySnapshot.docs.length;
        var queryLooped = 0;
        if(queryLength === 0) {
          // no reservations
          this.setState({
            loading: false,
            upcomingList: [],
            pastList: []
          });
        } else {
          // user has reservations
          querySnapshot.forEach((reservation) => {
            queryLooped++;
            var reservationData = reservation.data();
            reservationData.id = reservation.id;
            this.getGarageInfo(reservation.data().garage_id);
            this.getGuestInfo(reservation.id, reservation.data().guest_id)
            reservationsList.push(reservationData);
            if(queryLength === queryLooped) {
              // end of loop!
              // UPCOMING reservations
              const upcomingList = reservationsList.filter(element => moment(new Date()).diff(moment(element.start_date.toDate()), "days") <= 0 || moment(new Date()).diff(moment(element.end_date.toDate()), "days") <= 0 && !element.host_canceled && !element.guest_canceled && !element.completed_reservation);

              // PAST reservations
              const pastList = reservationsList.filter(element => moment(new Date()).diff(moment(element.start_date.toDate()), "days") > 0 && moment(new Date()).diff(moment(element.end_date.toDate()), "days") > 0);

              this.setState({
                upcomingList: upcomingList,
                pastList: pastList,
                loading: false,
              });
            }
          });
        }
      }).catch(error => {
        // error duh!
        this.setState({
          loading: false,
          upcomingList: [],
          pastList: []
        });
      });
  }

  getGarageInfo = (garage_id) => {
    dbFireStore.firestore().collection("garages").doc(garage_id).get().then((garage) => {
      // check if garage has photos, grab one if it does
      if(garage.data().photos && garage.data().garage_key) {
        Object.keys(garage.data().photos).forEach((photo) => {
          if(garage.data().photos[photo].order === 0) {
            var leadId = photo;
            var leadImgLocationRef = firebase.storage().ref(`/garages/${garage.data().garage_key}/${leadId}/photo.jpg`);
            leadImgLocationRef.getDownloadURL().then(url => {
              this.setState(prevState => ({
                garages: {
                  ...prevState.garages,
                  [garage_id]: {url: url, temp_address: `${toTitleCase(garage.data().city)}, ${garage.data().state}`}
                }
              }));
            });
          }
        });
      } else {
        // has no photos
        this.setState(prevState => ({
          garages: {
            ...prevState.garages,
            [garage_id]: {url: '', temp_address: `${toTitleCase(garage.data().city)}, ${garage.data().state}`}
          }
        }));
      }
    });
  };

  getGuestInfo = (reservation_id, guest_id) => {
    if(this.state.guestList && this.state.guestList[reservation_id]) {
      // already have their info
    } else {
      dbFireStore.firestore().collection("users")
        .where("user_id", "==", guest_id)
        .get().then((guest) => {
          var guestData = guest.docs[0].data();
          console.log(guest_id)
          if(guestData.user_photo_key) {
            this.getGuestPhoto(reservation_id, guestData.display_name, guestData.user_photo_key);
          } else {
            this.setState(prevState => ({
            guestList: {
              ...prevState.guestList,
              [reservation_id]: {name: guestData.display_name, photo: ''}
            }}));
          }
        }).catch(error => {
          console.log("error getting owner info");
        });
    }
  };

  getGuestPhoto = (reservation_id, name, guest_photo_key) => {
    const storageRef = firebase.storage().ref();
    const storageUserPhotoRef = storageRef.child(`${guest_photo_key}`);
    storageUserPhotoRef.getDownloadURL().then(url => {
      this.setState(prevState => ({
        guestList: {
          ...prevState.guestList,
          [reservation_id]: {name: name, photo: url}
        }}));
    }).catch(error => {
      this.setState(prevState => ({
        guestList: {
          ...prevState.guestList,
          [reservation_id]: {name: name, photo: ''}
        }}));
    });
  }; 

  handleChange = (event, newValue) => {
    this.setState({
      value: newValue
    });
  };


  render() {
    var garageList = this.state.garages;
    return (
      <React.Fragment>
      {this.state.loading?
        <div className="loading-host-nofitications">
          <CircularProgress className="main-loader"/>
        </div>:
        <React.Fragment>
        <div className="reservation-guest-list-info host-reservation-list">
        <Typography>
         💡Remember, Pending reservations must be accepted by you, the Host, within 48 hours to receive payment.
        </Typography>
        {/* <Typography>
          Learn more about <a href="https://help.motorroof.com/article/55-host-reservation-statuses" target="_blank">reservation statuses.</a>
        </Typography> */}
        </div>
        <AppBar position="static" color="default" className="flat-bar">
          <ReservationTabs
            value={this.state.value}
            onChange={this.handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            <ReservationTab label="Current Reservations" {...a11yProps(0)} />
            <ReservationTab label="Past" {...a11yProps(1)} />
          </ReservationTabs>
        </AppBar>
        <TabPanel value={this.state.value} index={0}>
        <div className="reservations-list-container hosting-reservation-list">
          {this.state.upcomingList.length < 1?
            <div className="no-garages-yet">
              <Typography variant="h4">🛎 You have no upcoming reservations</Typography>
              <Typography>Guest reservations that are pending your approval or active will show here.</Typography>
            </div>:
            <React.Fragment>
              {this.state.upcomingList.map((reservation, i) => (
                <ReservationListItem 
                  key={`${i}-upcomingList-${reservation.id}`}
                  i={i}
                  reservation={reservation}
                  isHost={true}
                  ownerInfo={this.state.guestList && this.state.guestList[reservation.id]? this.state.guestList[reservation.id]: null}
                  garageList={this.state.garages}
                />
              ))}
            </React.Fragment>
          }
        </div>
        </TabPanel>
        <TabPanel value={this.state.value} index={1}>
        <div className="reservations-list-container  hosting-reservation-list">
          {this.state.pastList.length < 1?
            <div className="no-garages-yet">
              <Typography variant="h4">🛎 You have no past reservations</Typography>
              <Typography>Guest reservations have completed or expired will show here.</Typography>
            </div>:
            <React.Fragment>
              {this.state.pastList.map((reservation, i) => (
                <ReservationListItem 
                  key={`${i}-pastList-${reservation.id}`}
                  i={i}
                  reservation={reservation}
                  isHost={true}
                  ownerInfo={this.state.guestList && this.state.guestList[reservation.id]? this.state.guestList[reservation.id]: null}
                  garageList={this.state.garages}
                />
              ))}
            </React.Fragment>
          }
        </div>
        </TabPanel>
        </React.Fragment>
      }
      </React.Fragment>
    );
  }
};

export default HostReservationsList;
