import React from 'react';
import firebase from 'firebase/app';
import dbFireStore from '../../firebase';
import { Link, Redirect } from "react-router-dom";
import GarageGallery from '../../components/GarageGallery';
import GarageStandards from '../../components/GarageStandards';
import OwnerInfo from '../../components/OwnerInfo';
import Avatar from "@material-ui/core/Avatar";
import UserAvatarImg from '../../images/missing-avatar.svg';
import StarIcon from "@material-ui/icons/Star";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import ExploreOutlinedIcon from '@material-ui/icons/ExploreOutlined';

import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import classNames from "classnames";
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import HelpIcon from '@material-ui/icons/HelpOutline';
import TodayIcon from '@material-ui/icons/Today';
import Loader from 'react-loader-spinner';
import Footer from "../../components/Footer";

import { withStyles } from '@material-ui/core/styles';

import GarageAreaMap from "../../components/GarageAreaMap";
import Confetti from 'react-dom-confetti';
import g from 'ngeohash';

import { KeyboardArrowRight, KeyboardArrowLeft } from '@material-ui/icons';

import MomentUtils from '@date-io/moment';
import { Calendar, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';

import { ReactComponent as AcIcon } from '../../images/ac.svg';
import { ReactComponent as HeatingIcon } from '../../images/heat.svg';
import { ReactComponent as PaintIcon } from '../../images/paint-gun.svg';
import { ReactComponent as LiftIcon } from '../../images/lift.svg';
import { ReactComponent as WeldIcon } from '../../images/weld.svg';
import { ReactComponent as WifiIcon } from '../../images/wifi.svg';
import BlockIcon from '@material-ui/icons/Block';

import { ReactComponent as ComingSoonIcon } from '../../images/wrench.svg';

import "../../styles/garage.scss";
import ReportUser from '../../components/ReportUser';

const formatCurrencyUSD = (amount) => {
  const locale = 'en-US';
  const options = { style: 'currency', currency: 'USD'};
  return Intl.NumberFormat(locale, options).format(amount);
}

const toTitleCase = (str) => {
    return str.replace(/\w\S*/g, function(txt){
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
}

const config_conf = {
  angle: "90",
  spread: "59",
  startVelocity: 35,
  elementCount: 50,
  dragFriction: 0.1,
  duration: 3000,
  stagger: 0,
  width: "10px",
  height: "10px",
  colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"]
};

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

class GaragePage extends React.PureComponent {

  state = {
    garage: null,
    garagePhotosList: null,
    loading: true,
    todaysDate: moment(new Date()),
    startDate: null,
    endDate: null,
    hasStartDate: false,
    hasEndDate: false,
    bigMonth: null,
    garageOwnerPhotoURL: null,
    ownerStarRating: null,
    availableDates: false,
    ownerInfoBox: false,
    renderReservationLink: null,
    usersRating: null,
    location: {
      longitude: null,
      latitude: null
    }
  };

  componentDidMount() {
    if(this.props.match.params && this.props.match.params.city && this.props.match.params.state && this.props.match.params.garageid) {
      var garageUrl = `/garage/${this.props.match.params.city}/${this.props.match.params.state}/${this.props.match.params.garageid}`;
      this.setState({
        bigMonth: this.daysInMonth(this.state.todaysDate),
      });
      this.getGarageInfo(garageUrl);
    } else {
      // redirect to search?
    }
  };

  daysInMonth = (d) => {
    var t = moment(d);
    const checkWeekDayStart = t.startOf('month').isoWeekday() === 5 || t.startOf('month').isoWeekday() === 6;
    if(t.daysInMonth() === 31 && checkWeekDayStart) {
      return true;
    } else if(t.daysInMonth() === 30 && t.startOf('month').isoWeekday() === 6) {
      return true;
    } else {
      return false;
    }
  };

  getGarageInfo = garageUrl => {
    dbFireStore.firestore().collection("garages").where("url", "==", garageUrl)
    .get()
    .then(querySnapshot => {
      var scheduleObj = {};
      if(querySnapshot.docs.length == 1) {
        // found unique record set state.
        var garageData = querySnapshot.docs[0].data();
        if(garageData.schedule.length > 1) {
          // translate schedule json to object
          var garageDataSchedule = JSON.parse(garageData.schedule);          
          garageDataSchedule.forEach((year) => {
            // grab year record
            var yearNum = year[0];
            var months = {};
            year[1].forEach((month) => {
              var monthNum = month[0];
              months[monthNum] = month[1];
            });
            scheduleObj[yearNum] = months;
          });
          garageData.schedule = scheduleObj;
        }
        var latlon = g.decode(garageData.location);
        this.setState({
          garage: garageData,
          reservationId: querySnapshot.docs[0].id,
          loading: false,
          location: {
            longitude: latlon.longitude,
            latitude: latlon.latitude
          }
        });
        if(garageData.photos && garageData.garage_key) {
          var imageLabel = `${garageData.city}, ${garageData.state} - Garage and Workshop for rent on MotorRoof.com`;
          this.getGaragePhotos(garageData.photos, garageData.garage_key, imageLabel);
        }
        this.getGarageOwnerInfo(garageData.owner_photo_id);
        this.getOwnerRating(garageData.owner_id);
      } else if (querySnapshot.docs.length == 0) {
        // couldn't find it
      } else {
        // throw error and redirect, there was more then one! url is not unique.
      }
    })
    .catch(error => {
      // redirect user.
      console.log("Error getting documents: ", error);
    });

  };

  getOwnerRating = (owner_id) => {
    dbFireStore.firestore().collection("reviews").where("review_of_user_id", "==", owner_id).get().then(querySnapshot => {
      var reviewsList = [];
      var queryLength = querySnapshot.docs.length;
      var queryLooped = 0;
      if(queryLength === 0) {
        // user has no reviews yet
        this.setState({
          usersRating: 5,
        });
      } else {
        querySnapshot.forEach((review) => {
          var reviewInfo = review.data();
          // parse review data here
          queryLooped++;
          reviewsList.push(reviewInfo.rating);
          if(queryLength == queryLooped) {
            // end of the list, push to state
            var sum = reviewsList.reduce((a, b) => a + b, 0);
            var avg = (sum/queryLength).toFixed(1);
            this.setState({
              usersRating: avg,
            });
          };
        });
      }
    }).catch((error) => {
      console.log(error);
    });
  };

  getGarageOwnerInfo = photoId => {
    if(photoId) {
      var userPhotoRef = firebase.storage().ref(`/${photoId}/photo.jpg`);
      userPhotoRef.getDownloadURL().then(url => {
        this.setState({
          garageOwnerPhotoURL: url
        });
      }).catch(error => {
        console.log('There was an error retreiving the users photo');
      });
    }
  };

  getGaragePhotos = (photos, garage_key, label) => {
    var photoListLength = Object.keys(photos).length;
    var garagePhotos = Array(photoListLength).fill('');
    var photoIndex = 0;
    if(photos && garage_key) {
      Object.keys(photos).forEach((photo) => {
        var leadImgLocationRef = firebase.storage().ref(`/garages/${garage_key}/${photo}/photo.jpg`);
        leadImgLocationRef.getDownloadURL().then(url => {
          photoIndex++;
          if(url) {
            garagePhotos[photos[photo].order] = {imgPath: url, label: label, order: photos[photo].order};
          }
          if(photoListLength == photoIndex) {
            this.setState({garagePhotosList: garagePhotos});
          }
        }).catch((error) => {
          console.log('There was an error retreiving the garages lead photo');
        });
      });
    }
  };

  updateEditMonth = (date) => {
    this.setState({
      editDate: date,
      bigMonth: this.daysInMonth(date),
    });
  };

  renderDay = (date, selectedDate, dayInCurrentMonth) => {
    var monthact = this.state.garage? this.state.garage.schedule: null;
    var dateKey = `${date.date()}-${date.month()}`;
    var dateHasActivity = false;
    var dateBooked = false;
    var inPast = date.isBefore(this.state.todaysDate) && !date.isSame(this.state.todaysDate, 'day');
    if (monthact !== null && dayInCurrentMonth && !inPast) {
      var monthHasKeys = monthact.hasOwnProperty(date.year()) && monthact[date.year()].hasOwnProperty(date.month());
      if(monthHasKeys) {
        var dateRecord = monthact[date.year()][date.month()][date.date() - 1];
        if(dateRecord === 1) {
        // Date is available
        var dateHasActivity = false;
        } else if(dateRecord === 0) {
          // Date has activity saved
          var dateHasActivity = true;
        } else if(dateRecord === 2) {
          // Date has been booked
          var dateBooked = true;
        }
      }
    }

    //Passing in day will check day, month, and year
    // date is what was clicked, the state gets set before with the new date on click
    var isStartDate = false;
    var isEndDate = false;
    var isBetweenStartAndEndDate = false;
    var bothDatesSelected = false;

    if(this.state.startDate && moment(date).isSame(this.state.startDate, 'day') ) {
      isStartDate = true;
    }

    if(this.state.endDate && moment(date).isSame(this.state.endDate, 'day') ) {
      isEndDate = true;
    }

    if(this.state.hasEndDate && this.state.hasStartDate) {
      // check if date is between start and end dates!
      bothDatesSelected = true;
      if(moment(date).isBetween(this.state.startDate, this.state.endDate, 'day')) {
        isBetweenStartAndEndDate = true;
      }
    }


    // rewrite this!!

    var dateClassName = dayInCurrentMonth && !inPast && !isStartDate && !isEndDate && !isBetweenStartAndEndDate? 'schedule-day-block':
                        inPast && !dayInCurrentMonth? 'schedule-day-block hidden-day':
                        inPast? 'schedule-day-block inpast-day':
                        
                        !dayInCurrentMonth && isStartDate && isEndDate? 'schedule-day-block one-day-checkin not-current-month-day':
                        !dayInCurrentMonth && isStartDate && !isEndDate && !bothDatesSelected? 'schedule-day-block start-date not-current-month-day':
                        !dayInCurrentMonth && isStartDate && !isEndDate && bothDatesSelected? 'schedule-day-block start-date selected-both not-current-month-day':
                        !dayInCurrentMonth && !isStartDate && isEndDate && !bothDatesSelected? 'schedule-day-block end-date not-current-month-day':
                        !dayInCurrentMonth && !isStartDate && isEndDate && bothDatesSelected? 'schedule-day-block end-date selected-both not-current-month-day':

                        isStartDate && isEndDate? 'schedule-day-block one-day-checkin':
                        isStartDate && !isEndDate && !bothDatesSelected? 'schedule-day-block start-date':
                        isStartDate && !isEndDate && bothDatesSelected? 'schedule-day-block start-date selected-both':
                        !isStartDate && isEndDate && !bothDatesSelected? 'schedule-day-block end-date':
                        !isStartDate && isEndDate && bothDatesSelected? 'schedule-day-block end-date selected-both':
                        !dayInCurrentMonth && isBetweenStartAndEndDate && bothDatesSelected? 'schedule-day-block between-start-and-end-date not-current-month-day':
                        isBetweenStartAndEndDate? 'schedule-day-block between-start-and-end-date':
                        'schedule-day-block hidden-day';

    return (
      <div>
      <div className={dateClassName} key={dateKey}>
        <IconButton className="day-object" disabled={!dayInCurrentMonth || inPast || dateHasActivity} >
          <span>{moment(date).format("D")}</span>
          {dateHasActivity? <div className="not-available"></div>: ''}
        </IconButton>
      </div>
      </div>
    )
  };

  setStartAndEndDate = (date) => {
    var startDateMatch = moment(date._d).isSame(moment(this.state.startDate), 'day');

    if(this.state.hasStartDate && this.state.hasEndDate && startDateMatch) {
      // reset both!
      this.setState({
        endDate: null,
        hasEndDate: false,
        startDate: null,
        hasStartDate: false,
        availableDates: false,
      });

    } else if(this.state.hasStartDate) {
      // check if date is before start date
      var beforeStartDate = moment(date._d).isBefore(moment(this.state.startDate), 'day');
      if(beforeStartDate) {
        // override start date with new date
        // clear end date?
        this.setState({
          startDate: date._d,
          hasStartDate: true,
          hasEndDate: false,
          endDate: null,
          availableDates: false,
        });
      } else {
        // this is where we check if there is a conflict inbetween dates
        var makeReservationForArray = Array(moment(date._d).diff(this.state.startDate, 'days') + 1).fill(1);
        // get the startDate and end Dates, then gather dates between,
        //if months between don't exist make array for them that is all available
        // maybe get how many months we need to get first?
        
        var monthsBetweenLength = moment(date._d).startOf('month').diff(moment(this.state.startDate).startOf('month'), 'months') + 1;
        // we can check here if the reservation length is too long

        var available = [];
        var isAvailableRange = 0;
        var wasRequestedRange = 0;
        if(!this.state.garage.schedule.length && Object.keys(this.state.garage.schedule).length > 0) {

          for(var i = 0; i < monthsBetweenLength; i++) {
            // get each month and create array, push spread to available array
            // pay attention to year!
            var gatherMonth = moment(this.state.startDate).add(i, 'months');
            var getYear = gatherMonth.year();
            var getMonth = gatherMonth.month();
            // check if start month or end month, then we need to splice the array
            var sameStartDate = gatherMonth.isSame(this.state.startDate, 'month');
            var sameEndDate = gatherMonth.isSame(date._d, 'month');

            var startDateIndex = this.state.startDate.getDate() - 1;
            var endDateIndex = date._d.getDate();

            if(this.state.garage.schedule[getYear] && this.state.garage.schedule[getYear][getMonth]) {
              // This checks if the garage has a record for this month and year, if not we just print a array of all 1s
              // since it is all available
              if(sameStartDate && sameEndDate) {
                // picked range in same month, this will only hit once
                available.push(...this.state.garage.schedule[getYear][getMonth].slice(startDateIndex, endDateIndex));
              } else if(sameStartDate) {
                // start date month, splice array starting form start date (index starts at 0)
                available.push(...this.state.garage.schedule[getYear][getMonth].slice(startDateIndex));
              } else if (sameEndDate) {
                // end date month, splice array to end date (index starts at 0)
                available.push(...this.state.garage.schedule[getYear][getMonth].slice(0,endDateIndex));
              } else {
                // inbetween month
                available.push(...this.state.garage.schedule[getYear][getMonth]);
              }
            } else {
              if(sameStartDate && sameEndDate) {
                // picked range in same month, this will only hit once
                // get days between start date and end date length
                var daysBetweenStartAndEnd = moment(date._d).diff(this.state.startDate, 'days') + 1;
                available.push(...Array(daysBetweenStartAndEnd).fill(1));
              } else if(sameStartDate) {
                // start date month, splice array starting form start date (index starts at 0)
                // subtract the total days in the month by the index to fill the month record
                var daysInStartDateMonth = Array(moment(this.state.startDate).daysInMonth()).fill(1).slice(startDateIndex);
                available.push(...daysInStartDateMonth);
              } else if (sameEndDate) {
                // end date month, splice array to end date (index starts at 0)
                var daysInEndDateMonth = Array(moment(date._d).daysInMonth()).fill(1).slice(0, endDateIndex);
                available.push(...daysInEndDateMonth);
              } else {
                // inbetween month
                available.push(...Array(gatherMonth.daysInMonth()).fill(1));
              }
            }
          };

          // Get the sum of the available range array
          isAvailableRange = available.reduce((a, b) => a + b);
          wasRequestedRange = makeReservationForArray.reduce((a, b) => a + b);

          if (isAvailableRange == wasRequestedRange) {
            // sum of arrays match, this range is available
            console.log("! AVAILABLE");
            this.setState({
              endDate: date._d,
              hasEndDate: true,
              availableDates: true,
            });
          } else {
            // not available for these dates, alert user to choose different dates
            console.log("DATES NOT AVAILABLE FOR RENTING");
            this.props.handleError({
              message: "Sorry, those dates are not available. Please choose another date.",
              type: 'not_available'
            });
            this.setState({
              startDate: null,
              endDate: null,
              hasEndDate: false,
              hasStartDate: false,
              availableDates: false,
            });
          }


        } else {
          // garage has no schedule restrictions
          this.setState({
            endDate: date._d,
            hasEndDate: true,
            availableDates: true,
          });
        }
      }
    } else {
      if(!this.state.garage.schedule.length && Object.keys(this.state.garage.schedule).length > 0) {
        // garage has a schedule, need to see if this date is okay to reserve
        var startDateYear = moment(date._d).year();
        var startDateMonth = moment(date._d).month();
        if(this.state.garage.schedule[startDateYear] && this.state.garage.schedule[startDateYear][startDateMonth]) {
          // there is a matching month schedule
          var dateCheck = moment(date._d).date() - 1;
          var monthRecord = this.state.garage.schedule[startDateYear][startDateMonth][dateCheck];
          if (monthRecord === 1) {
            // this date is available
            this.setState({
              startDate: date._d,
              hasStartDate: true,
            });
          } else if (monthRecord === 0) {
            // this date is not available for renting
            this.props.handleError({
              message: "Sorry, those dates are not available. Please choose another date.",
              type: 'not_available'
            });
            console.log('Date not available');
          } else {
            // date booked
          }     
        } else {
          // no matching schedule available
          this.setState({
            startDate: date._d,
            hasStartDate: true,
          });
        }
      } else {
        this.setState({
          startDate: date._d,
          hasStartDate: true,
        });
      }
    }

  };

  renderAmenitie = (item) => {
    switch(item) {
      case 'ac':
        return (<AcIcon />);
        break;
      case 'heating':
        return (<HeatingIcon />);
        break;
      case 'lift':
        return (<LiftIcon />);
        break;
      case 'paint':
        return (<PaintIcon />);
        break;
      case 'welding':
        return (<WeldIcon />);
        break;
      case 'wifi':
        return (<WifiIcon />);
        break;
      default:
        return('');
        break;
    }
  };

  startReservation = () => {
    const { reservationId, startDate, endDate } = this.state;

    if (reservationId && startDate && endDate) {
      var garage_id = reservationId;
      var start_date = startDate.getTime() / 1000;
      var end_date = endDate.getTime() / 1000;
      var redirectLinkReservation = `/reservations/create/${garage_id}/${start_date}/${end_date}`;
      this.setState({
        renderReservationLink: redirectLinkReservation,
      });
    }
  };

  handleOpenOwnerInfoBox = () => {
    this.setState(prevState => ({ownerInfoBox: !prevState.ownerInfoBox}));
  };

  redirectLogin = () => this.props.history.push(`/login`);

  render() {
    var garagePrice = this.state.garage? formatCurrencyUSD(this.state.garage.price).split(".")[0]: '';
    const monthWrapper = this.state.bigMonth? 'big-month':'small-month';
    const rentalDays = this.state.startDate && this.state.endDate? moment(this.state.endDate).diff(this.state.startDate, 'days') + 1: null;
    
    const rentalSubTotal = rentalDays? formatCurrencyUSD(rentalDays * this.state.garage.price): "Oops!";
    const rentalServiceFee = rentalDays? formatCurrencyUSD((rentalDays * this.state.garage.price) * .145): "Oops!";
    const rentalTotal = rentalDays? formatCurrencyUSD(
        (rentalDays * this.state.garage.price) +
        ((rentalDays * this.state.garage.price) * .145)
    ): "Oops!";

    const amenitiesList = this.state.garage && this.state.garage.amenities? Object.keys(this.state.garage.amenities).filter((item) => item !== 'cctv' && item !== 'locked' && item !== 'security'): null;
    const securityList = this.state.garage && this.state.garage.amenities? Object.keys(this.state.garage.amenities).filter((item) => item == 'cctv' || item == 'locked' || item == 'security'): null;
    const weekdayList = [
      'monday',
      'tuesday',
      'wednesday',
      'thursday',
      'friday',
      'saturday',
      'sunday',
    ];

    var garageOwnerView = this.state.garage && firebase.auth().currentUser && firebase.auth().currentUser.uid === this.state.garage.owner_id;
    var userSignedIn = firebase.auth().currentUser;
    var reservationStart = this.state.renderReservationLink? <Redirect to={this.state.renderReservationLink} />: null;
    const offendingUserId = this.state.garage? this.state.garage.owner_id : null;
    const offendingUserPublicId = null;

    return (
      <div className="with-navbar-wrap">
      <ReportUser
        reportUser={this.props.reportUser}
        wantsToReport={this.props.wantsToReport}
        askToReport={this.props.askToReport}
        valueReport={this.props.valueReport}
        typeMessageReport={this.props.typeMessageReport}
        reported={this.props.reported}
        reportedId={this.props.reportedId}
        loadingReport={this.props.loadingReport}
        typeOfReport={"garage_page"}
        offendingUserId={offendingUserId}
        offendingUserPublicId={offendingUserPublicId}
      />
      {reservationStart}
      {this.state.garage?
        <React.Fragment>
          {garageOwnerView?
            <Link to="/hosting-hub">
            <div className="garage-owner-banner">
            <Typography variant="body2">You own this property. Click here to edit.</Typography>
            </div></Link>:<React.Fragment></React.Fragment>
          }
          <OwnerInfo owner={this.state.garage.owner_id} ownerPhoto={this.state.garageOwnerPhotoURL} ownerInfoBox={this.state.ownerInfoBox} ownerRating={this.state.usersRating? this.state.usersRating: 5} handleOpenOwnerInfoBox={this.handleOpenOwnerInfoBox}/>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <div className="garage-header">
              <div className="garage-header-container">
                <div className="garage-header-container-wrap">
                  <div className="garage-info-col">
                    <GarageGallery garagePhotosList={this.state.garagePhotosList} />
                    <div className="garage-title-container">
                      <h1>{this.state.garage.title}</h1>
                      <h4>{toTitleCase(this.state.garage.city)}, {this.state.garage.state}</h4>
                      <div className="garage-owner" onClick={this.handleOpenOwnerInfoBox}>
                      {this.state.garageOwnerPhotoURL?
                        <Avatar className="garage-avatar" src={this.state.garageOwnerPhotoURL} />:<Avatar className='garage-avatar no-image-user' src={UserAvatarImg} />
                      }
                      <div className="garage-owner-with-rating">
                        <h5 className="name">{this.state.garage.owner_name}</h5>
                        <span className='user-rating'>
                        <p className="rating">{this.state.usersRating? this.state.usersRating: 5}</p>
                        <StarIcon className="user-star" />
                      </span>
                      </div>
                      </div>
                    </div>
                  </div>
                  <div className="garage-make-reservation-col">
                    <div className="garage-price-info">
                      <div className="price-container">
                        <span className="rent-me-for">
                        <Typography variant="body2">Rent me for</Typography></span>
                        <Typography variant="h3">{garagePrice}</Typography>
                        <Typography variant="caption">per day</Typography>
                      </div>
                    </div>
                    <div className="confetti-wrapper-garage">
                      <Confetti active={this.state.availableDates} config={ config_conf }/>
                    </div>
                    <div className="checkin-checkout-reservation">
                      <div className="reservation-state-title">
                        {this.state.hasStartDate?
                          <React.Fragment>
                          { this.state.hasEndDate?
                            <Typography variant="h4" className="dates-available">{rentalDays > 1? 'These days are':'This day is'} available!</Typography>:
                            <Typography variant="h4" className="needs-checkout-title">And checkout date?</Typography>
                          }
                          </React.Fragment>:
                          <Typography variant="h4" className="needs-checkin-title">When will you check in?</Typography>
                        }
                      </div>
                        <div className={`reservation-cal-container ${monthWrapper}`}>
                          <Calendar
                            disablePast
                            allowKeyboardControl={false}
                            date={this.state.todaysDate}
                            renderDay={this.renderDay}
                            onChange={this.setStartAndEndDate}
                            onMonthChange={this.updateEditMonth}
                          />
                        </div>

                      <div className="reservation-total">
                        <div className="total-table">

                          {this.state.hasStartDate && this.state.hasEndDate?
                            <React.Fragment>
                            <div className="total-row">
                              <div className="info-col">
                                <Typography variant="caption">Rental Days:</Typography>
                                <div className="tooltip-help-icon">
                                  <HtmlTooltip
                                    placement="left"
                                    title={
                                      <React.Fragment>
                                        <Typography color="inherit" variant="body2">Rental day info</Typography>
                                      </React.Fragment>
                                    }
                                  >
                                    <HelpIcon />
                                  </HtmlTooltip>
                                </div>
                                </div>
                              <div className="amount-col">
                                <Typography variant="caption">{rentalDays}x</Typography>
                              </div>
                            </div>

                            <div className="total-row">
                              <div className="info-col">
                                <Typography variant="caption">Subtotal:</Typography>
                                <div className="tooltip-help-icon">
                                  <HtmlTooltip
                                    placement="left"
                                    title={
                                      <React.Fragment>
                                        <Typography color="inherit" variant="body2">Rental days times the daily rate.</Typography>
                                      </React.Fragment>
                                    }
                                  >
                                    <HelpIcon />
                                  </HtmlTooltip>
                                </div>

                              </div>
                              <div className="amount-col">
                                <Typography variant="caption">{rentalSubTotal}</Typography>
                              </div>
                            </div>

                            <div className="total-row">
                              <div className="info-col">
                                <Typography variant="caption">Service Fee:</Typography>
                                <div className="tooltip-help-icon">
                                  <HtmlTooltip
                                    placement="left"
                                    title={
                                      <React.Fragment>
                                        <Typography color="inherit" variant="body2">This helps our platform provide you 24/7 support and a safe renting environment.</Typography>
                                      </React.Fragment>
                                    }
                                  >
                                    <HelpIcon />
                                  </HtmlTooltip>
                                </div>
                              </div>
                              <div className="amount-col">
                                <Typography variant="caption">{rentalServiceFee}</Typography>
                              </div>
                            </div>

                            <div className="total-row final-amount">
                              <div className="info-col">
                                <Typography variant="caption">Total:</Typography>
                                <div className="tooltip-help-icon">
                                  <HtmlTooltip
                                    placement="left"
                                    title={
                                      <React.Fragment>
                                        <Typography color="inherit" variant="body2">This is your subtotal plus our service fee.</Typography>
                                      </React.Fragment>
                                    }
                                  >
                                    <HelpIcon />
                                  </HtmlTooltip>
                                </div>
                              </div>
                              <div className="amount-col">
                                <Typography variant="caption">{rentalTotal}</Typography>
                              </div>
                            </div></React.Fragment>:<div></div>
                          }
                        </div>

                        {this.state.hasStartDate && this.state.hasEndDate && userSignedIn && !garageOwnerView && !this.state.loading?
                          <React.Fragment>
                          <Button
                            fullWidth
                            variant="contained"
                            size="large"
                            color="primary"
                            className="make-a-reservation-btn"
                            onClick={this.startReservation}
                          >
                          <TodayIcon />
                            Rent me!
                          </Button>
                          <div className="payment-notes">
                          <Typography variant="caption">You won't be charged yet!</Typography>
                          </div>
                          </React.Fragment>:
                          <React.Fragment>
                            {this.state.loading?
                              <Button
                              fullWidth
                              variant="contained"
                              size="large"
                              color="primary"
                              className="make-a-reservation-btn loading-btn"
                              >
                              <TodayIcon />
                                Rent me!
                              </Button>:
                              <React.Fragment>
                                {userSignedIn?
                                  <React.Fragment>
                                    <HtmlTooltip
                                      placement="top"
                                      title={
                                        <React.Fragment>
                                          <Typography color="inherit" variant="body2">
                                            {garageOwnerView?
                                              "You own this garage!": !this.state.hasStartDate? "Add a check in date to make a reservation.":
                                              !this.state.hasEndDate? "What day will you leave the space?": "Loading..."
                                            }
                                          </Typography>
                                        </React.Fragment>
                                      }
                                    >
                                    {garageOwnerView?
                                      <Button
                                        fullWidth
                                        variant="contained"
                                        size="large"
                                        color="primary"
                                        className="make-a-reservation-btn disabled-btn"
                                      >
                                      <TodayIcon />
                                        Rent me!
                                      </Button>:
                                      <Button
                                        fullWidth
                                        variant="contained"
                                        size="large"
                                        color="primary"
                                        className="make-a-reservation-btn disabled-btn"
                                        onClick={this.startReservation}
                                      >
                                      <TodayIcon />
                                        Rent me!
                                      </Button>
                                    }
                                  </HtmlTooltip>

                                </React.Fragment>:
                                <React.Fragment>
                                  <Link to="/login" target="_blank">
                                  <Button
                                    fullWidth
                                    variant="contained"
                                    size="large"
                                    color="primary"
                                    className="make-a-reservation-btn needs-login-first-btn"
                                  >
                                  <TodayIcon />
                                    Rent me!
                                  </Button>
                                  </Link>
                                </React.Fragment>
                              }
                            </React.Fragment>
                          }
                        </React.Fragment>
                      }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </MuiPickersUtilsProvider>
        {/* ------------ End head, start body -------------- */}
        <div className="garage-body-wrap">
          <div className="garage-body-container">
            <div className="garage-content-col">
            
              <div className="garage-amenities">
                {amenitiesList.map((item,index) => (
                  <div className={`amenitie-label ${item}-label has-amenitie-${this.state.garage.amenities[item]}`} key={`${item}-${index}`}>
                    {this.state.garage.amenities[item]?
                      <div className="check-amenitie">
                      <CheckCircleIcon />
                      </div>:<div className="check-amenitie-false">
                        <RadioButtonUncheckedIcon />
                      </div>}
                    <div className={`amenitie-icon icon-${item}`}>
                      {this.renderAmenitie(item)}
                    </div>
                    <Typography variant="body2">{`${item == 'ac'? "A/C": toTitleCase(item)}`}</Typography>
                  </div>
                ))}
              </div>
              {securityList && securityList.length > 0?

              <div className="garage-security-wrap">
                {securityList.map((item, index) => (
                  <div className={`security-item  has-security-${this.state.garage.amenities[item]}`}>
                    <div className={`${item}-security-tag`}>
                      <Typography variant="body2">
                      {item == 'cctv'? 'Security Cameras': item == 'locked'? 'Locked Access':item == 'security'? 'Alarm System': ''}
                      </Typography>
                    </div>
                  </div>
                ))}
              </div>:<div></div>
              }

              <div className="garage-description">
                <div className="mobile-text-header">
                  <Typography variant="subtitle1">
                    <InfoOutlinedIcon />
                    About this space
                  </Typography>
                </div>
                <Typography variant="body2">
                  {this.state.garage.description}
                </Typography>
              </div>
              
            </div>
            <div className="garage-info-bar-col">
              <div className="mobile-text-header">
                <Typography variant="subtitle1">
                  <ExploreOutlinedIcon />
                  Location
                </Typography>
              </div>
              <div className="garage-map" >
                <GarageAreaMap location={this.state.location} />
              </div>
              <div className="garage-access-hours">
                {this.state.garage.hours?
                  <div className="hours-wrap">
                    <div className={this.state.garage.hours && this.state.garage.hours.is24hours? "access-hours-title is-open-24hours": "access-hours-title"}>
                      <AccessTimeIcon className="time-icon" />
                      <Typography variant="h5">Access Hours</Typography>
                      <HtmlTooltip
                        placement="top"
                        title={
                          <React.Fragment>
                            <Typography color="inherit" variant="body2">These are set times by the owner of the garage for when you can visit or use the space.</Typography>
                          </React.Fragment>
                        }
                      >
                        <HelpIcon className="info-hover" />
                      </HtmlTooltip>
                    </div>
                    { this.state.garage.hours && this.state.garage.hours.is24hours?
                    <React.Fragment></React.Fragment>:
                    <React.Fragment>
                    {this.state.garage.hours.monday && weekdayList.map((weekdayName, index) => (
                      <div key={`weekday-list-${index}`}>
                        {this.state.garage.hours[weekdayName].map((item, index) => (
                          <div key={`open-day-${index}`}>
                            {this.state.garage.hours.isOpen[item.name]?
                              <div className="hours-information is-open">
                                <div className="day-of-week-title">
                                  <Typography variant="body2">
                                    {`${item.name.charAt(0).toUpperCase() + item.name.slice(1)}`}
                                  </Typography>
                                </div>

                                {item.startHours && item.endHours?
                                <React.Fragment>
                                <div className="hour-info-col">
                                  <Typography variant="h4">
                                    {item.startHours? item.startHours: ''}
                                  </Typography>
                                </div>
                                <div className='dash-hours'>
                                ―
                                </div>
                                <div className="closed-at-col">
                                  <Typography variant="h4">
                                    {item.endHours? item.endHours: ''}
                                  </Typography>
                                </div>
                                </React.Fragment>:<div className="no-hours-but-open">
                                <Typography variant="h4">
                                  Open
                                </Typography>
                                </div>
                                }
                              </div>:
                              <div className="hours-information closed">
                                 <Typography variant="h4">
                                  {`${item.name.charAt(0).toUpperCase() + item.name.slice(1)} closed`}
                                </Typography>
                              </div>
                            }
                          </div>
                        ))}
                      </div>
                    ))}
                    </React.Fragment>
                  }
                  </div>:
                  <React.Fragment>
                  <div className="access-hours-title no-hours">
                      <AccessTimeIcon className="time-icon" />
                      <Typography variant="h5">Access Hours</Typography>
                      <HtmlTooltip
                        placement="top"
                        title={
                          <React.Fragment>
                            <Typography color="inherit" variant="body2">These are set times by the owner of the garage for when you can visit or use the space.</Typography>
                          </React.Fragment>
                        }
                      >
                        <HelpIcon className="info-hover" />
                      </HtmlTooltip>
                    </div>
                  <div className="no-access-hours-provided">
                    <Typography variant="body2">No Hours Specified</Typography>
                    <HtmlTooltip
                        placement="top"
                        title={
                          <React.Fragment>
                            <Typography color="inherit" variant="body2">This owner does not require specific access times.</Typography>
                          </React.Fragment>
                        }
                      >
                        <HelpIcon className="info-hover" />
                      </HtmlTooltip>
                  </div>
                  </React.Fragment>
                }
                <div className={this.state.garage.hours && !this.state.garage.hours.monday || !this.state.garage.hours? "garage-access-extra-info no-hours-box": "garage-access-extra-info"}>
                  {this.state.garage.hours && this.state.garage.hours.appointmentOnly?
                    <div className="garage-is-appointment-only">
                      <Typography variant="body2">
                      By Appointment Only
                      <HtmlTooltip
                        placement="top"
                        title={
                          <React.Fragment>
                            <Typography color="inherit" variant="body2">Schedule appointments with one click using MotorRoof's private and secure messaging.</Typography>
                          </React.Fragment>
                        }
                      >
                        <HelpIcon className="info-hover" />
                      </HtmlTooltip>
                      </Typography>

                    </div>:
                    <div className="garage-is-not-appointment-only">
                      <Typography variant="body2">
                      No Appointment Needed
                      <HtmlTooltip
                        placement="top"
                        title={
                          <React.Fragment>
                            <Typography color="inherit" variant="body2">This owner specifies that you don't need to make an appointment to use the property.</Typography>
                          </React.Fragment>
                        }
                      >
                        <HelpIcon className="info-hover" />
                      </HtmlTooltip>
                      </Typography>
                    </div>
                  }
                  {this.state.garage.hours && this.state.garage.hours.is24hours?
                    <div className="garage-is-24hours">
                    <Typography variant="body2">24 Hour Access Available
                    <HtmlTooltip
                        placement="top"
                        title={
                          <React.Fragment>
                            <Typography color="inherit" variant="body2">This space has 24-hour access!</Typography>
                          </React.Fragment>
                        }
                      >
                        <HelpIcon className="info-hover" />
                      </HtmlTooltip>
                    </Typography></div>:<div></div>
                  }
                </div>
              </div>
              <div className="report-public-garage">
                <Button startIcon={<BlockIcon />} onClick={userSignedIn? this.props.askToReport : this.redirectLogin} className="report-btn">Report Garage</Button>
              </div>
            </div>
          </div>
        </div>
        <GarageStandards />
        </React.Fragment>:
        <div className="app-loader-wrap">
          <div className="loading-app">
            <CircularProgress className="main-loader"/>
          </div>
        </div>
      }
      <Footer />
      </div>
    );
  }
}
 
export default GaragePage;