import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import CheckCircleOutline from "@material-ui/icons/CheckCircleOutline";
import ErrorOutline from "@material-ui/icons/ErrorOutline";

import "../styles/alerts.scss";

const AlertSnackbar = ({ isType, isOpen, message, handleClick }) => {
  const icon =
    isType === "error" ||
    isType === "not_available" ||
    isType === "connection-error" ? (
      <ErrorOutline
        key="error"
        className={isType + "-icon-snackbar"}
      />
    ) : (
      <CheckCircleOutline
        key="success"
        className={isType + "-icon-snackbar"}
      />
    );

  return (
    <div>
      <Snackbar
        style={{ zIndex: 99991 }}
        className={
          isType + "-snackbar-alert-wrap snackbar-main-wrapper "
        }
        anchorOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
        open={isOpen}
        autoHideDuration={3000}
        onClose={isType === "connection-error"? null: handleClick}
        ContentProps={{
          "aria-describedby": "alert-message-content"
        }}
        message={
          <span
            id="alert-message-content"
            className={isType + "-alert-message"}
          >
            {message}
          </span>
        }
        action={[
          icon,
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            className={isType + "-snackbar-button"}
            onClick={handleClick}
          >
            <CloseIcon />
          </IconButton>
        ]}
      />
    </div>
  );
}

export default AlertSnackbar;
