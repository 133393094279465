import React from "react";
import { Link } from "react-router-dom";

import Typography from "@material-ui/core/Typography";
import NewReleasesOutlinedIcon from '@material-ui/icons/NewReleasesOutlined';

const NoPlaceFoundInfoBox = ({ disabled, searchParams, handleGetNotified }) => {
  return (
    <div className="no-place-found-info-box">
      {searchParams.name ? (
        <div>
          <div className="suggest-places-box">
            <Typography>
              We couldn't find a workshop or storage spot near{" "}
              {decodeURIComponent(searchParams.name)}.
            </Typography>
          </div>
          {disabled? null :
            <div className="get-notified-box">
              <Typography>
                <Link to="#" onClick={(e) => handleGetNotified(e)}>
                <NewReleasesOutlinedIcon />
                Click here</Link> <span>to be notified when new garages are available in {decodeURIComponent(searchParams.name)}. </span>
              </Typography>
            </div>
          }
        </div>
      ) : (
        <div>
          <Typography>Featured available places for rent</Typography>
        </div>
      )}
    </div>
  );
};

export default NoPlaceFoundInfoBox;
