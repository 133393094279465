import React from "react";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import UserAvatarImg from '../images/missing-avatar.svg';
import StarIcon from "@material-ui/icons/Star";
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import CircularProgress from "@material-ui/core/CircularProgress";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Tooltip from '@material-ui/core/Tooltip';
import ReviewList from './ReviewList';


import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';

import dbFireStore from '../firebase';
import { ReactComponent as VerifyIcon } from '../images/user-verification.svg';


const useStyles = makeStyles({
  list: {
    width: 450,
    height: "100%",
    padding: '20px',
    position: 'relative'
  },
  fullList: {
    width: 'auto',
  },
});

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

const OwnerInfo = props => {
  const classes = useStyles();
  const [user, setUser] = React.useState({
    user_name: null,
    verified_phone: null,
  });
  const [reviewList, setReviewList] = React.useState(null);
  const [loader, setLoader] = React.useState(true);

  const ownerInfo = () => {
    dbFireStore.firestore().collection("users").where("user_id", "==", props.owner).get().then(querySnapshot => {
      if(querySnapshot.docs.length > 0) {
        var user = querySnapshot.docs[0].data();
        setUser({
          user_name: user.display_name? user.display_name: false,
          verified_phone: user.verified_phone? user.verified_phone: false,
          verified_email: user.verified_email? user.verified_email: false,
          verified_id: user.verified_id? user.verified_id: false,
          owner: props.owner,
        });
        getOwnerReviews(props.owner);
      }
    }).catch((error) => {
      console.log(error);
    });
  }

  const getOwnerReviews = (owner) => {
    dbFireStore.firestore().collection("reviews").where("review_of_user_id", "==", owner).get().then(querySnapshot => {
      var reviewsList = [];
      var queryLength = querySnapshot.docs.length;
      var queryLooped = 0;
      if(queryLength === 0) {
        // user has no reviews as a garage owner yet!
        setReviewList(null);
        setLoader(false);
      } else {
        querySnapshot.forEach((review) => {
          var reviewInfo = review.data();
          // parse review data here
          queryLooped++;
          reviewsList.push(reviewInfo);
          if(queryLength == queryLooped) {
            // end of the list, push to state
            setReviewList(reviewsList);
            setLoader(false);
          };
        });
      }
    }).catch((error) => {
      console.log(error);
    });
  };

  if(props.owner && props.owner !== user.owner && props.ownerInfoBox && !user.user_name) {
    ownerInfo();
  }

  console.log('rendering owner info...');

  return (
    <div>
      <SwipeableDrawer
        open={props.ownerInfoBox}
        onClose={props.handleOpenOwnerInfoBox}
        onOpen={props.handleOpenOwnerInfoBox}
        className="sidbar-public-profile"
      >
      <div
        className={classes.list}
        role="presentation"
        onClick={props.handleOpenOwnerInfoBox}
        onKeyDown={props.handleOpenOwnerInfoBox}
      > 
        {loader?
        <div>
          <div className="owner-loader-wrap">
            <div className="loading-app">
              <CircularProgress className="main-loader"/>
            </div>
          </div>
        </div>:
        <div>
          {user.user_name?
            <div className="host-info-card">
              {props.ownerPhoto?
                <div className="owner-photo" style={{backgroundImage: `url('${props.ownerPhoto}')`}}></div>:
                <div className="owner-photo missing-photo"></div>
              }
              <div className="owner-info">
                <Typography variant="h4">
                  {user.user_name}
                </Typography>
                <div className="owner-rating">
                  <Typography variant="body1">{props.ownerRating}</Typography>
                  <StarIcon className="user-star" />
                </div>
                <div className="user-verify-row">
                  <HtmlTooltip
                    placement="left"
                    title={
                      <React.Fragment>
                        <Typography color="inherit" variant="body2">Verification checked</Typography>
                      </React.Fragment>
                    }
                  >
                    <div className="user-verify-title">
                      <VerifyIcon />
                    </div>
                  </HtmlTooltip>
                  {user.verified_email?
                    <div className="user-verify-item is-verified">
                      <CheckCircleIcon />
                      <Typography variant="caption">
                        Email
                      </Typography>
                    </div>:
                    <div className="user-verify-item pending">
                      <RadioButtonUncheckedIcon />
                      <Typography variant="caption">
                        Email
                      </Typography>
                    </div>
                  }
                  {user.verified_phone?
                    <div className="user-verify-item is-verified">
                      <CheckCircleIcon />
                      <Typography variant="caption">
                        Phone
                      </Typography>
                    </div>:
                    <div className="user-verify-item pending">
                      <RadioButtonUncheckedIcon />
                      <Typography variant="caption">
                        Phone
                      </Typography>
                    </div>
                  }
                  {user.verified_id?
                    <div className="user-verify-item is-verified">
                      <CheckCircleIcon />
                      <Typography variant="caption">
                        ID
                      </Typography>
                    </div>:
                    <div className="user-verify-item pending" >
                      <RadioButtonUncheckedIcon />
                      <Typography variant="caption">
                        ID
                      </Typography>
                    </div>
                  }
                </div>
              </div>
            </div>:<div></div>
          }
          <div className="host-review-title">
             <Typography variant="caption">
              Recent Host Reviews
             </Typography>
          </div>
          {reviewList?
            <div>
              <ReviewList reviews={reviewList} type={"user"} />
            </div>:
            <div className="no-reviews-for-owner">
              <Typography variant="body1">
                This owner hasn't received a review yet.
              </Typography>
            </div>
          }
        </div>
      }
      </div>
      </SwipeableDrawer>
    </div>
  );
}

export default OwnerInfo;
