import React from 'react';

import Confetti from 'react-dom-confetti';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import CheckIcon from '@material-ui/icons/Check';

import "../styles/verified_email.scss";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const config_conf = {
  angle: "90",
  spread: "119",
  startVelocity: 45,
  elementCount: 50,
  dragFriction: 0.1,
  duration: 3000,
  stagger: 0,
  width: "10px",
  height: "10px",
  colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"]
};

const VerifiedEmailNotice = ({ needsVerifiedEmailNotice }) => {
  const [open, setOpen] = React.useState(true);
  const [confettiOn, setConfettiOn] = React.useState(false)

  const handleClose = () => {
    needsVerifiedEmailNotice();
    setOpen(false);
  };

  const triggerYeyFactor = () => {
    const timer = setTimeout(() => {
      setConfettiOn(true);
      clearTimeout(timer);
    }, 200);
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      className="verify-email-block"
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
    {triggerYeyFactor()}
    <div className="confetti-wrapper">
      <Confetti active={ confettiOn } config={ config_conf }/>
    </div>
      <div className="verify-complete email-verify">
        <Typography variant="h4">Your Email is Verified</Typography>
        <img
          src={require("../images/email-check-motorroof-verified.jpg")}
          alt="verified email"
          className="verify-email-hero-complete"
        />
        <Typography variant="body2">
          Your email has been verified!
        </Typography>
        <Button className="verify-success-btn" onClick={() => handleClose()}>
          <CheckIcon />
          Continue
        </Button>
      </div>
    </Dialog>
  );
}

export default VerifiedEmailNotice;