import React, { Component } from "react";

import firebase from 'firebase/app';
import dbFireStore from "../firebase";

import Typography from "@material-ui/core/Typography";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import TextField from "@material-ui/core/TextField";

import CheckIcon from '@material-ui/icons/Check';

import verifyDescSuccess from '../images/verify-description-bg-success.jpg';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default class AddAboutInfo extends Component {

  state = {
    openEditInfo: false,
    aboutInfoText: "",
    loading: false,
    hasUserDescription: false,
    saving: false,
  };

  handleChange = e => this.setState({ aboutInfoText: e.target.value });

  handleOpen = () => {
    this.setState({ openEditInfo: true });
  };

  handleClose = () => {
    if(this.state.hasUserDescription) {
      this.props.hasUserDescription();
    }

    this.setState({ openEditInfo: false });
  };

  updateAboutInfo = () => {
    this.setState({ loading: true, saving: true});
    const userPrivatePublicRef = firebase
      .database()
      .ref("users/" + firebase.auth().currentUser.uid + "/profile/public_profile_key");

    userPrivatePublicRef.once("value", snapshot => {
      if (snapshot.val() != null) {
        //User has public profile
        const userPublicRef = dbFireStore
          .firestore()
          .collection("users")
          .doc(snapshot.val());

        userPublicRef.update({
          about_info: this.state.aboutInfoText,
          lastUpdated: firebase.firestore.FieldValue.serverTimestamp()
        }).then(() => {
          const userProfileRef = firebase
            .database()
            .ref("users/" + firebase.auth().currentUser.uid + "/profile/");

          userProfileRef.update({
            user_description: true
          });
          this.setState({ loading: false, saving: false, hasUserDescription: true });
        });
      }
    });
  };

  render() {
    const { openEditInfo, hasUserDescription } = this.state;
    return (
      <div>
        <div className="verify-dash-block">
          <div className="verify-title-block about">
          </div>
          <Button size="small" className="verify-dash-btn" onClick={() => this.handleOpen()}>
            <span>
              Add About Info
            </span>
          </Button>
        </div>
        <Dialog
            open={openEditInfo}
            TransitionComponent={Transition}
            keepMounted
            onClose={this.handleClose}
            className="edit-about-info-view"
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >
            <div className="edit-about-info-container">
              <div className="edit-about-info-title">
                <Typography>Add About Info</Typography>
              </div>
              {hasUserDescription?
                <div className="verify-desc-success-image">
                  <img
                    src={verifyDescSuccess}
                    alt="Verify Description Success"
                  />
                  <Typography>
                    Great! Your profile information was added and you are off to a great start!
                  </Typography>
                  <Button size="small" className="about-info-save-btn close-box-btn" onClick={() => this.handleClose()}>
                    Close
                  </Button>
                </div>
                :
                <div className="ask-about-info-wrap">
                  <div className="edit-about-info">
                    <TextField
                      id="outlined-multiline-static"
                      label="About Info"
                      ref={(input) => { this.aboutInfoInput = input; }} 
                      multiline
                      fullWidth
                      rows="4"
                      placeholder="Enter a short description of yourself..."
                      margin="normal"
                      variant="outlined"
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="button-holder-about-info">
                    {this.state.saving?
                       <Button size="small" className="about-info-save-btn loading" disabled={true}>
                        Saving
                      </Button>:
                       <Button size="small" className="about-info-save-btn" onClick={() => this.updateAboutInfo()}>
                        Save
                      </Button>
                    }
                  </div>
                </div>
              }
            </div>
          </Dialog>
      </div>
    );
  }
};