import React from "react";
import MapGL, {Marker, NavigationControl} from 'react-map-gl';
import LocationIcon from "@material-ui/icons/LocationOn";
import GaragePin from "../../components/GaragePin";
import Loader from 'react-loader-spinner';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const MAPBOX_TOKEN = 'pk.eyJ1IjoibW90b3Jyb29mIiwiYSI6ImNqeWJhdXJlbTA3ZXgzZHA1aTY1MHp1OXYifQ.gzAtdjOpRs5jdFvWgf93vw';
const centerUSA = {
  latitude: 37.0902,
  longitude: -95.7129
}
const navStyle = {
  position: 'absolute',
  top: 0,
  right: 0,
  padding: '10px'
};

class SearchMap extends React.PureComponent {
  state = {
    viewport: {
      latitude: centerUSA.latitude,
      longitude: centerUSA.longitude,
      zoom: 4,
      bearing: 0,
      pitch: 0,
      height: "100%",
      width: "100%",
    },
    searchOnMapMove: true,
  }

  componentDidUpdate(prevProps) {
    if (this.props.searchParams !== prevProps.searchParams) {
      if(this.props.searchParams.longitude && this.props.searchParams.latitude) {
        this.setState({
          viewport: {
            latitude: parseFloat(this.props.searchParams.latitude),
            longitude: parseFloat(this.props.searchParams.longitude),
            zoom: 9,
            bearing: 0,
            pitch: 0,
            height: "100%",
            width: "100%",
          }
        });
      }
    }
  }

  updateViewport = viewport => {
    this.props.handleOpenMapPin(null);
    if(this.state.searchOnMapMove) {
      // need to handle searching up the map, but also prevent updating for no reason
      // check if the long and lat match the current state, then don't do anything.
      if(this.state.viewport.latitude == viewport.latitude && this.state.viewport.longitude == viewport.longitude) {
        // do nothing, the map hasen't changed, the render is creating the map move
      } else {
        clearTimeout(this.mapMoveTimer);
        this.mapMoveTimer = setTimeout(() => {
          this.props.handleSearchUpdateOnMapMove(viewport);
        }, 500);
      }
    }
    this.setState({
      viewport: {
        ...this.state.viewport,
        ...viewport,
      }
    });
  };

  handleMapClick = event => {
    this.props.handleOpenMapPin(null);
  };

  handleSearchOnMapMove = () => {
    this.setState(prevState => ({
      searchOnMapMove: !prevState.searchOnMapMove,
    }));
  };

  createMarker = (garage, i) => {
    return (
      <Marker
        longitude={garage.location.longitude}
        latitude={garage.location.latitude}
        offsetTop={-20}
        offsetLeft={-10}
        key={`marker-${i}`}
      >
        <GaragePin
          garage={garage}
          handleOpenMapPin={this.props.handleOpenMapPin}
          activePinInfoBox={this.props.activePinInfoBox}
        />
      </Marker>
    )
  }

  render() {
    return (  
      <MapGL
        {...this.state.viewport}
        onViewportChange={this.updateViewport}
        mapboxApiAccessToken={MAPBOX_TOKEN}
        scrollZoom={false}
        onClick={this.handleMapClick}
        mapStyle="mapbox://styles/mapbox/streets-v11"
      > 
        <div className={this.props.isSearching? "search-on-map-scroll-container map-is-searching": "search-on-map-scroll-container"}>
          {this.props.isSearching?
          <div className="loader-wrap-map">
          <Loader type="ThreeDots" color="#4bbfff"/>
          </div>
          :<FormControlLabel
            control={
              <Checkbox checked={this.state.searchOnMapMove} onChange={() => this.handleSearchOnMapMove()} value="Search area as map moves" />
            }
            label="Search area as map moves"
          />}
        </div>
        {this.props.garages?
          <React.Fragment>
          {this.props.garages.map((garage, i) => (
            <div className={`marker-wrap is-open-${this.props.activePinInfoBox == garage.index && garage.markerDisplay}`} key={i}>
            {this.createMarker(garage, i)}
            </div>
          ))}
          </React.Fragment>:<div></div>}
        <div className="nav" style={navStyle}>
          <NavigationControl
            onViewportChange={this.updateViewport}
            showCompass={false}
          />
        </div>
      </MapGL>
    )
  }
}

export default SearchMap;
