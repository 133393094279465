import React from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { Link } from "react-router-dom";

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import FormControl from "@material-ui/core/FormControl";
import TextField from '@material-ui/core/TextField';
import FormGroup from "@material-ui/core/FormGroup";
import Switch from "@material-ui/core/Switch";
import FormLabel from "@material-ui/core/FormLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Input from "@material-ui/core/Input";
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from "@material-ui/core/CircularProgress";
import Confetti from 'react-dom-confetti';
import Dialog from '@material-ui/core/Dialog';
import Loader from 'react-loader-spinner';

import StateSelect from '../../components/StateSelect';

import GarageGridItem from '../../components/GarageGridItem';
import CheckIcon from '@material-ui/icons/Check';
import LocationIcon from "@material-ui/icons/LocationOn";
import { ReactComponent as AcIcon } from '../../images/ac.svg';
import { ReactComponent as HeatingIcon } from '../../images/heat.svg';
import { ReactComponent as PaintIcon } from '../../images/paint-gun.svg';
import { ReactComponent as LiftIcon } from '../../images/lift.svg';
import { ReactComponent as WeldIcon } from '../../images/weld.svg';
import { ReactComponent as WifiIcon } from '../../images/wifi.svg';

import { ReactComponent as AlarmIcon } from '../../images/alarm.svg';
import { ReactComponent as LockIcon } from '../../images/locked.svg';
import { ReactComponent as CameraIcon } from '../../images/camera.svg';

import MapGL, {Marker, NavigationControl} from 'react-map-gl';

import GaragePhotoUploader from '../../components/GaragePhotoUploader';
import GarageAvailabilityScheduler from '../../components/GarageAvailabilityScheduler';
import GarageAccessHours from '../../components/GarageAccessHours';
import ConnectStripe from '../../components/ConnectStripe';

import VerifyPhone from '../../components/VerifyPhone';

import firebase from 'firebase/app';
import dbFireStore from '../../firebase';

import g from 'ngeohash';

const axios = require('axios');
const copyAction = require('clipboard-copy');

const config_conf = {
  angle: "90",
  spread: "119",
  startVelocity: 45,
  elementCount: 50,
  dragFriction: 0.1,
  duration: 3000,
  stagger: 0,
  width: "10px",
  height: "10px",
  colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"]
};

const navStyle = {
  position: 'absolute',
  top: 0,
  right: 0,
  padding: '10px'
};

const geolocateStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  margin: 10
};

const packardplant = {
  latitude: 42.379520,
  longitude: -83.028648
}

const MAPBOX_TOKEN = 'pk.eyJ1IjoibW90b3Jyb29mIiwiYSI6ImNqeWJhdXJlbTA3ZXgzZHA1aTY1MHp1OXYifQ.gzAtdjOpRs5jdFvWgf93vw';

class BuildGarageForm extends React.Component {

  state = {
    loading: true,
    hostGuidelinesAgreed: false,
    garage_post_success: false,
    loadingStepForm: false,
    garage_url: "",
    activeStep: 0,
    steps: [
      "What kind of rental are you hosting, it's availability, and access hours?",
      "Tell us about your rental such as price, rules, pets, etc...",
      "Amenities you provide?",
      "What is the address for this space?",
      "Add Photos",
      "Verify and Post!"],
    leadPhoto: null,
    noPhotosAsk: false,
    firstAsk: false,
    garage: {
      title: null,
      description: null,
      types: [{name: 'Workshop', isType: false},
              {name: 'Storage', isType: false}
              ],
      price: null,
      photos: null,
      address: {
        street_one: '',
        street_two: '',
        city: '',
        state: '',
        zip: ''
      },
      hours: {
        appointmentOnly: true,
        is24hours: false,
      },
      schedule: null,
      amenities: {
        heating: false,
        ac: false,
        wifi: false,
        lift: false,
        welding: false,
        paint: false,
        cctv: false,
        security: false,
        locked: false,
      }
    },
    viewport: {
      latitude: packardplant.latitude,
      longitude: packardplant.longitude,
      zoom: 5,
      bearing: 0,
      pitch: 0,
      height: 200,
      width: "100%"
    },
    marker: {
      latitude: packardplant.latitude,
      longitude: packardplant.longitude
    },  
    checkingAddress: false,
    userGaragesRef: firebase.database().ref('users/' + firebase.auth().currentUser.uid + '/garages'),
    editingGarageRef: null,
    hasVerfiedPhone: false,
  };

  handleUpdateVerification = () => {
    this.props.verificationRef.once('value').then((snapshot) => {
      if (snapshot.val() != null) {
        if(snapshot.val().phone) {
          this.setState({
            hasVerfiedPhone: true,
          });
        }
      }
    });
  }

  componentDidMount() {
    //pastGarageId in state should be set if we find a unfished garage, so we know what id to update to.
    //if not, This should remain null as we will need to check this each time on submit if it exists!
    //Check if user can post
    this.props.verificationRef.once('value').then((snapshot) => {
      if (snapshot.val() != null) {
        if(snapshot.val().phone) {
          this.setState({
            hasVerfiedPhone: true,
          });
        }
      }
    });


    //Check if user has garages
    this.state.userGaragesRef.once('value').then((snapshot) => {
      if (snapshot.val() != null) {
        //Something returned so check if there is any unfinished garages
        var garagesProcessed = 1;
        var snapshot_size = Object.keys(snapshot.val()).length;
        var foundNotFinished = false;
        snapshot.forEach((childSnapshot) => {
          // here is where we see if one of the garages is unfinished
          var garage = childSnapshot.val();
          var garageKey = childSnapshot.key;
          var isFinished = garage.isFinished;

          if(!isFinished || isFinished == null) {
            // <EDIT>
            // There should only be one that is unfinished at all time
            // this is where we set all the data points from this garage and update the state!
            foundNotFinished = true;
            this.resumeEditFrom(garage, garageKey)
          }
          if(garagesProcessed === snapshot_size && !foundNotFinished) {
            // <CREATE>
            // We have processed through all the garages and found none that were incomplete
            // we can safely create a new garage here
            this.createNewGarage();
          }
          garagesProcessed++;
        });
      } else {
        // <CREATE>
        //User has zero garages, lets create one now
        this.createNewGarage();
      }
    });
  }

  createNewGarage = () => {
    const timestamp = firebase.database.ServerValue.TIMESTAMP;
    let newGarage = this.state.userGaragesRef.push({
      timestamp: timestamp,
      activeStep: 0,
      isFinished: false,
      hours: {
        appointmentOnly: true,
        is24hours: false,
      },
    });

    this.setState({
      editingGarageRef: firebase.database().ref('users/' + firebase.auth().currentUser.uid + '/garages/' + newGarage.key),
      loading: false,
    });
  };

  getGarageLeadPhoto = (photos, garageKey) => {
    var photosList = Object.entries(photos);
    photosList.forEach((photo) => {
      if(photo[1].order === 0) {
        var leadId = photo[0];
        var leadImgLocationRef = firebase.storage().ref(`/garages/${garageKey}/${leadId}/photo.jpg`);
        leadImgLocationRef.getDownloadURL().then(url => {
          this.setState(prevState => ({
            leadPhoto: prevState.leadPhoto != url? url: null,
          }));
        });
      }
    });
  };

  handleNoLeadPhoto = () => {
    this.setState(prevState => ({
      leadPhoto: null,
    }));
  };

  handleUpdateLeadPhoto = (photo) => {
    this.setState({
      leadPhoto: photo
    });
  };

  resumeEditFrom = (garage, garageKey) => {
    // sets the ref of the editing garage to the garage we left off on and fill form
    if(garage.photos) {
      this.getGarageLeadPhoto(garage.photos, garageKey);
    }

    if(!garage.isFinished) {
      firebase.database().ref('users/' + firebase.auth().currentUser.uid + '/garages/' + garageKey).child('isFinished').set(false);
    }

    this.setState(prevState => ({
      editingGarageRef: firebase.database().ref('users/' + firebase.auth().currentUser.uid + '/garages/' + garageKey),
      hostGuidelinesAgreed: garage.hostAgreementSigned? garage.hostAgreementSigned.agreement_status: false,
      activeStep: garage.activeStep,
      garage: {
        key: garageKey,
        title: garage.title? garage.title: prevState.garage.title,
        description: garage.description? garage.description: prevState.garage.description,
        types: [{name: 'Workshop', isType: garage.isWorkshop? garage.isWorkshop: false},
                {name: 'Storage', isType: garage.isStorage? garage.isStorage: false}
                ],
        price: garage.price? garage.price: prevState.garage.price,
        photos: garage.photos? garage.photos: prevState.garage.photos,
        address: {
          street_one: garage.address && garage.address.street_one?garage.address.street_one: prevState.garage.address.street_one,
          street_two: garage.address && garage.address.street_two? garage.address.street_two: prevState.garage.street_two,
          city: garage.address && garage.address.city? garage.address.city: prevState.garage.address.city,
          state: garage.address && garage.address.state? garage.address.state: prevState.garage.address.state,
          zip: garage.address && garage.address.zip? garage.address.zip: prevState.garage.address.zip
        },
        hours: garage.hours? garage.hours: null,
        schedule: garage.schedule? garage.schedule: null,
        amenities: {
          heating: garage.amenities && garage.amenities.heating? garage.amenities.heating: false,
          ac:  garage.amenities && garage.amenities.ac? garage.amenities.ac: false,
          wifi: garage.amenities && garage.amenities.wifi? garage.amenities.wifi: false,
          lift: garage.amenities && garage.amenities.lift? garage.amenities.lift: false,
          welding: garage.amenities && garage.amenities.welding? garage.amenities.welding: false,
          paint: garage.amenities && garage.amenities.paint? garage.amenities.paint: false,
          cctv: garage.amenities && garage.amenities.cctv? garage.amenities.cctv: false,
          security: garage.amenities && garage.amenities.security? garage.amenities.security: false,
          locked: garage.amenities && garage.amenities.locked? garage.amenities.locked: false,
        },
        marker: {
          latitude: garage.location && garage.location.latitude? garage.location.latitude: prevState.marker.latitude,
          longitude: garage.location && garage.location.longitude? garage.location.longitude: prevState.marker.longitude
        },
        viewport: {
          latitude: garage.location && garage.location.latitude? garage.location.latitude: prevState.viewport.latitude,
          longitude: garage.location && garage.location.longitude? garage.location.longitude: prevState.viewport.longitude,
          zoom: garage.location? 15: 5,
          bearing: prevState.viewport.bearing,
          pitch: prevState.viewport.pitch,
          height: prevState.viewport.height,
          width: prevState.viewport.width
        },
      },
    }), () => {
      this.timerLoad = setTimeout(() => {
        this.setState({
          loading: false,
        });
        clearTimeout(this.timerLoad);
      }, 1000);
    });
  }

  reverseGeoSearch = (e) => {
    e.preventDefault();
    //TO-DO handle form check here

    var hasAddress = this.state.garage.address.street_one && this.state.garage.address.street_one.length > 0;
    var hasCity = this.state.garage.address.city && this.state.garage.address.city.length > 0;
    var hasState = this.state.garage.address.state && this.state.garage.address.state.length > 0;
    var hasZip = this.state.garage.address.zip && this.state.garage.address.zip.length >= 5;

    if(hasAddress && hasCity && hasState && hasZip) {

      var formatFirstStreet = this.state.garage.address.street_one.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
      var formatSecondStreet = this.state.garage.address.street_two? this.state.garage.address.street_two.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ''): "";
      var formatCity = this.state.garage.address.city.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');

      let searchQuery = encodeURI(`${formatFirstStreet} ${formatSecondStreet}, ${formatCity}, ${this.state.garage.address.state}, ${this.state.garage.address.zip}`);
      axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${searchQuery}.json?country=US&limit=1&access_token=${MAPBOX_TOKEN}`)
        .then((response) => {
          if(response.data.features.length > 0) {
            var long = response.data.features[0].geometry.coordinates[0];
            var lat = response.data.features[0].geometry.coordinates[1];
            this.setState(prevState => ({
              marker: {
                latitude: lat,
                longitude: long
              },
              viewport: {
                ...prevState.viewport,
                latitude: lat,
                longitude: long,
                zoom: 14,
              },
              checkingAddress: true
            }));

          }
        })
        .catch((error) => {
          this.props.handleError({
            message: "There was an error fetching your address, please try again."
          });
        });
    } else if(!hasAddress) {
      this.props.handleError({
        message: "Please enter a valid street address for your listing."
      });
    } else if(!hasCity) {
      this.props.handleError({
        message: "Please enter a valid city for your listing."
      });
    } else if(!hasState) {
      this.props.handleError({
        message: "Please enter a valid State for your listing."
      });
    } else if(!hasZip) {
      this.props.handleError({
        message: "Please enter a valid zip for your listing."
      });
    } else {
      this.props.handleError({
        message: "Please enter a valid address for your listing."
      });
    }
  }

  prepSearchAddressText = name => event => {
    let newAddressText = event.target.value;
    if(name === 'zip') {
      if (/^\d+$/.test(newAddressText)) {
        this.setState( prevState => ({
          garage: {
            ...prevState.garage,
            address: {
              ...prevState.garage.address,
              [name]: newAddressText.replace(/\s/g, '').replace(/\D/g,'')
            },
          }
        }))
      } else {
        this.setState( prevState => ({
          garage: {
            ...prevState.garage,
            address: {
              ...prevState.garage.address,
              [name]: ''
            },
          }
        }))
      }
    } else {
      this.setState( prevState => ({
        garage: {
          ...prevState.garage,
          address: {
            ...prevState.garage.address,
            [name]: newAddressText
          },
        }
      }))
    }
  };


  handleNext = prevActiveStep => {
   this.setActiveStep(prevActiveStep + 1);
  };

  handleBack = prevActiveStep => {
    this.setActiveStep(prevActiveStep - 1);
  };

  setActiveStep = newStep => {
    this.setState({activeStep: newStep});
  };

  handleTypeCheckboxChange = (name) => {
    switch (name) {
      case 'workshop':
        return (
          this.setState(prevState => ({
            garage: {
              ...prevState.garage,
              types: [
                {name: 'Workshop', isType: !prevState.garage.types[0].isType },
                prevState.garage.types[1]
                ]
            }
          }))
        )
      case 'storage':
        return (
          this.setState(prevState => ({
            garage: {
              ...prevState.garage,
              types: [
                prevState.garage.types[0],
                {name: 'Storage', isType: !prevState.garage.types[1].isType}
                ]
            }
          }))
        )
      default:
        return ''
    };
  };

  handleChangeTitle = name => event => {
    let newTitle = event.target.value;
    this.setState( prevState => ({
      garage: {
        ...prevState.garage,
        title: newTitle,
      }
    }))
  };

  handleChangePrice = price => event => {
    let newPrice = event.target.value.replace(/\s/g, '').replace(/\D/g,'');
    if (/^\d+$/.test(newPrice) && newPrice !== "0" && newPrice !== "") {
      this.setState( prevState => ({
        garage: {
          ...prevState.garage,
          price: newPrice.slice(0,5),
        }
      }))
    } else {
      this.setState(prevState => ({
        garage: {
          ...prevState.garage,
          price: newPrice,
        }
      }))
    }
  };

  handleChangeDescription = desc => event => {
    let newDesc = event.target.value;
    this.setState( prevState => ({
      garage: {
        ...prevState.garage,
        description: newDesc,
      }
    }))
  };

  addAmenitie = (amenitie, e) => {
    e.preventDefault();
    this.setState( prevState => ({
      garage: {
        ...prevState.garage,
        amenities: {...prevState.garage.amenities, [amenitie]: !prevState.garage.amenities[amenitie]},
      }
    }));
  };

  updateViewport = viewport => {
    this.setState({
      viewport: {
        ...this.state.viewport,
        ...viewport,
      }
    });
  };

  onMarkerDragEnd = event => {
    this.setState({
      marker: {
        longitude: event.lngLat[0],
        latitude: event.lngLat[1]
      }
    });
  };

  editAddressDialogChange = () => {
    this.setState({
      checkingAddress: false,
    });
  };

  setSelectedState = abbr => {
    this.setState(prevState => ({
      garage: {
        ...prevState.garage,
        address: {
          ...prevState.garage.address,
          state: abbr.label,
        }
      }
    }));
  };

  //======== START handle garage from section submission below

  //STEP ONE
  handleSetGarageTypeStepOne = () => {
    // require at least a workshop or storage selected 
    if(this.state.garage.types[0].isType || this.state.garage.types[1].isType) {
      this.state.editingGarageRef.child("isWorkshop").set(this.state.garage.types[0].isType);
      this.state.editingGarageRef.child("isStorage").set(this.state.garage.types[1].isType);
      this.state.editingGarageRef.child("activeStep").set(this.state.activeStep + 1);
      this.handleNext(this.state.activeStep);
    } else {
      // throw form error
      this.props.handleError({
        message: "Is your space a Workshop or Storage spot?"
      });
    }
  };

  handleUpdateSchedule = (schedule) => {
    this.state.editingGarageRef.child("schedule").set(schedule);
    this.state.editingGarageRef.child("activeStep").set(this.state.activeStep);
    this.setState(prevState => ({
      garage: {
        ...prevState.garage,
        schedule: schedule,
      }
    }));
  };

  handleUpdateHours = (hours) => {
    this.state.editingGarageRef.child("hours").set(hours);
    this.state.editingGarageRef.child("activeStep").set(this.state.activeStep);
    this.setState(prevState => ({
      garage: {
        ...prevState.garage,
        hours: hours,
      }
    }));
  };

  isNumeric(num){
    return !isNaN(num)
  };

  //STEP TWO
  handleSetGarageDescStepTwo = () => {

    var checkTitleContent = this.state.garage.title && this.state.garage.title.length < 80 && this.state.garage.title.length > 4? true:false;
    var checkDescContent = this.state.garage.description && this.state.garage.description.length < 2500 && this.state.garage.description.length > 1? true:false;
    var checkPrice = this.state.garage.price && this.isNumeric(this.state.garage.price);

    if(checkTitleContent && checkDescContent && checkPrice) {
      // check for bad language stuff
      var hasSwearWordTitle = this.state.garage.title.toLowerCase().includes("fuck") || this.state.garage.title.toLowerCase().includes("shit");
      var hasSwearWordDesc = this.state.garage.description.toLowerCase().includes("fuck") || this.state.garage.description.toLowerCase().includes("shit");

      if(hasSwearWordTitle) {
        // throw form error
        this.props.handleError({
          message: "Please no bad language in your title!"
        });
      } else if(hasSwearWordDesc) {
        this.props.handleError({
          message: "Please no bad language in your description!"
        });
      } else {
        // good to go
        this.state.editingGarageRef.child("title").set(this.state.garage.title);
        this.state.editingGarageRef.child("description").set(this.state.garage.description);
        this.state.editingGarageRef.child("price").set(this.state.garage.price);
        this.state.editingGarageRef.child("activeStep").set(this.state.activeStep + 1);
        this.handleNext(this.state.activeStep);
      }
    } else if(!checkPrice) {
      this.props.handleError({
        message: "Please enter your daily price."
      });
    } else if(!checkTitleContent) {
      // throw form error
      this.props.handleError({
        message: "Please enter a title for your listing. (Max 80 Characters)"
      });
    } else if(!checkDescContent) {
      this.props.handleError({
        message: "Please enter a description for your listing. (Max 1500 Characters)"
      });
    } else {
      this.props.handleError({
        message: "To post a listing we require your listing title, and a description."
      });
    }
  };

  //STEP THREE
  handleSetGarageAmenitiesStepThree = () => {
    this.state.editingGarageRef.child("amenities").set({
      heating: this.state.garage.amenities.heating,
      ac: this.state.garage.amenities.ac,
      wifi: this.state.garage.amenities.wifi,
      lift: this.state.garage.amenities.lift,
      welding: this.state.garage.amenities.welding,
      paint: this.state.garage.amenities.paint,
      cctv: this.state.garage.amenities.cctv,
      security: this.state.garage.amenities.security,
      locked: this.state.garage.amenities.locked,
    });
    this.state.editingGarageRef.child("activeStep").set(this.state.activeStep + 1);
    this.handleNext(this.state.activeStep);
  };

  //STEP FOUR
  confirmAndSubmitAddressStepFour = () => {
    this.state.editingGarageRef.child("location").set({
      latitude: this.state.marker.latitude,
      longitude: this.state.marker.longitude,
    });
    if(this.state.garage.address.street_two) {
      var addressBlock = {
        street_one: this.state.garage.address.street_one,
        street_two: this.state.garage.address.street_two,
        city: this.state.garage.address.city,
        state: this.state.garage.address.state,
        zip: this.state.garage.address.zip,
      };
    } else {
      var addressBlock = {
        street_one: this.state.garage.address.street_one,
        city: this.state.garage.address.city,
        state: this.state.garage.address.state,
        zip: this.state.garage.address.zip,
      };
    }
    this.state.editingGarageRef.child("address").set({
      ...addressBlock
    });
    this.state.editingGarageRef.child("activeStep").set(this.state.activeStep + 1);
    this.setState({
      checkingAddress: false,
      activeStep: 4
    });
  };

  //STEP FIVE
  handleSetPhotosStepFive = () => {
    if(this.state.garage.photos || this.state.leadPhoto) {
      this.state.editingGarageRef.child("activeStep").set(this.state.activeStep + 1);
      this.setState({
        activeStep: 5
      });
    } else {
      if(!this.state.firstAsk) {
        this.setState({
          noPhotosAsk: true,
          firstAsk: true,
        });
      } else {
        this.state.editingGarageRef.child("activeStep").set(this.state.activeStep + 1);
        this.setState({
          noPhotosAsk: false,
          activeStep: 5
        });
      }
    }
  };

  backToPhotosUploader = () => {
    this.setState({
      noPhotosAsk: false,
    });
  };

  confirmNoPhotos = () => {
    this.state.editingGarageRef.child("activeStep").set(this.state.activeStep + 1);
    this.setState({
      noPhotosAsk: false,
      activeStep: 5
    });
  };

  //FINAL STEP
  handleHostGuidelinesAgreed = () => {
    this.state.editingGarageRef.child("hostAgreementSigned").set({
      agreement_status: !this.state.hostGuidelinesAgreed,
      agreed_on: firebase.database.ServerValue.TIMESTAMP,
    }).then(() => {
      this.setState({
        hostGuidelinesAgreed: !this.state.hostGuidelinesAgreed,
      });
    });
  };

  handlePublish = () => {
    // Set form into next step which is loader
    var isVerified = this.state.hasVerfiedPhone;
    var agreedToHostGuidelines = this.state.hostGuidelinesAgreed;

    if(isVerified && agreedToHostGuidelines) {
      // since we will validate each submission, we need to pull the record from the database before we submit, to help prevent data tampering
      this.setState({
        activeStep: 6
      });
      this.state.editingGarageRef.once('value').then((snapshot) => {
        if (snapshot.val() !== null) {
        // set public data to public garage list
          var garageData = snapshot.val();
          var garageKey = snapshot.key;
          //firestore way, we keep the record for editing on the user side so it's private. The public posting data is sent to a garage collection as a document
          // 24 = 10km, 26 = 5km, 30 = 1km, 52 = full resolution (most accurate)
          var garageGeoHash = g.encode(garageData.location.latitude, garageData.location.longitude, 8);
          this.userProfileRef = firebase.database().ref('users/' + firebase.auth().currentUser.uid + '/profile');
          var garageURL = `/garage/${encodeURI(garageData.address.city.replace(/\s+/g, '-').toLowerCase())}/${encodeURI(garageData.address.state.replace(/\s+/g, '-').toLowerCase())}/${Math.floor(new Date().valueOf() * Math.random())}`;
          this.state.editingGarageRef.child("public_url").set(garageURL);
          this.userProfileRef.once('value').then((snapshot) => {
            //must have
            var garageStore = {
              garage_key: garageKey,
              city: garageData.address.city,
              state: garageData.address.state,
              amenities: garageData.amenities,
              description: garageData.description,
              isStorage: garageData.isStorage,
              isWorkshop: garageData.isWorkshop,
              location: garageGeoHash,
              price: garageData.price,
              title: garageData.title,
              url: garageURL,
              owner_name: this.props.displayName,
              owner_id: firebase.auth().currentUser.uid,
              timestamp: firebase.firestore.FieldValue.serverTimestamp()
            }

            //optionals
            if(snapshot.val() !== null && snapshot.val().photos && snapshot.val().photos.avatar) {
              // add user photo id
              garageStore.owner_photo_id = Object.keys(snapshot.val().photos.avatar)[0];
            }

            if(snapshot.val() !== null && snapshot.val().host_stripe_user_id) {
              garageStore.destination_charge = snapshot.val().host_stripe_user_id;
            }

            if(garageData.hours) {
              garageStore.hours = garageData.hours;
            }

            if(garageData.schedule) {
              var scheduleList = [];
              Object.keys(garageData.schedule).forEach((year) => {
                var yearRecord = garageData.schedule[year];
                var months = [];
                Object.keys(yearRecord).forEach((month) => {
                  months.push([`${month}`, yearRecord[month]]);
                });
                scheduleList.push([`${year}`, months]);
              });
              // apprently all of a sudden I can't store nested arrays,
              // so I had to do this to convert our object into a array
              garageStore.schedule = JSON.stringify(scheduleList);
            } else {
              garageStore.schedule = "";
            }

            if(garageData.photos) {
              garageStore.photos = garageData.photos;
            }
            this.createPublicGarage(garageStore);

          });
        }
      });
    } else if(!isVerified) {
       this.props.handleError({
        message: "Please verify your phone number before posting your listing."
      });
    } else if(!agreedToHostGuidelines) {
       this.props.handleError({
        message: "Please read and agree to our saftey rules and host guidelines."
      });
    } else {
      this.props.handleError({
        message: "Please verify your phone number and agree to our saftey rules and host guidelines."
      });
    }
  };

  createPublicGarage = (garageStore) => {
    console.log('setting with example');
    // [TO-DO] this makes no sense, this was working before but now i'm basically 
    // writing firebase.firestore().firestore().collection wtf...why does it need to be doubled to work?? somethings wrong.
    dbFireStore.firestore().collection("garages").add(garageStore).then((docRef) => {
      // post was succssful, we can get the post id from here!
      this.state.editingGarageRef.child("isFinished").set(true);
      this.state.editingGarageRef.child("publish_date").set(firebase.database.ServerValue.TIMESTAMP);
      this.state.editingGarageRef.child("garage_key").set(docRef.id);

      // check if user has stripe connected
      firebase.database().ref('/users/' + firebase.auth().currentUser.uid + '/profile').once('value').then((snapshot) => {
        const hasStripeConnectError = snapshot.val() && snapshot.val().host_stripe_connect_error? true: false;
        const hasStripeUserID = snapshot.val() && snapshot.val().host_stripe_user_id? true: false;

        if(hasStripeConnectError || hasStripeUserID === false) {
          this.setState({
            garage_post_success: true,
            garage_url: garageStore.url,
            needStripeConnect: true,
          });
        } else {
          this.setState({
            garage_post_success: true,
            garage_url: garageStore.url,
            needStripeConnect: false
          });
        }
      });
    }).catch((error) => {
      // throw error alert, and let the user try to post again
      console.log(error);
      this.props.handleError({
        message: "There was a problem posting your listing. Please try again or contact support if you continue to have issues."
      });
      this.setState({
        activeStep: 5
      });
    });

  }

  //========= END handle garage form section submission
  getStepContent = step => {
    switch (step) {
      case 0:
        return (
          <div>
            <FormControlLabel
              control={
                <Checkbox checked={this.state.garage.types[0].isType} onChange={() => this.handleTypeCheckboxChange('workshop')} value="Workshop" />
              }
              label="Workshop"
            />
            <FormControlLabel
              control={
                <Checkbox checked={this.state.garage.types[1].isType} onChange={() => this.handleTypeCheckboxChange('storage')} value="Storage" />
              }
              label="Storage"
            />
            <div className="hours-and-access-container">
              <GarageAvailabilityScheduler schedule={this.state.garage.schedule? this.state.garage.schedule: null} handleUpdateSchedule={this.handleUpdateSchedule}/>
              <GarageAccessHours hours={this.state.garage.hours? this.state.garage.hours: null} handleUpdateHours={this.handleUpdateHours} />
            </div>
            <div className="step-form-actions-container">
              <Button
                className="forward-btn"
                variant="contained"
                color="primary"
                onClick={() => this.handleSetGarageTypeStepOne()}
              >
              Next
              </Button>
            </div>
          </div>
          );
      case 1:
        return (
          <div>
            <div className="info-about-rate-container">
            <TextField
              id="listing-price"
              className="listing-price"
              label="Daily Price"
              value={this.state.garage.price}
              onChange={this.handleChangePrice()}
              variant="outlined"
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
              margin="normal"
            />
            <div className="rate-info">
              <Typography>Please enter a whole number with no special characters or letters, for example, enter 15 instead of 15.00.</Typography>
            </div>
            </div>
            <TextField
              id="listing-title"
              className="listing-title"
              label="Listing Title"
              value={this.state.garage.title}
              onChange={this.handleChangeTitle()}
              margin="normal"
              variant="outlined"
            />
            <TextField
              id="listing-description"
              className="listing-description"
              label="Describe your space"
              multiline
              rows="4"
              value={this.state.garage.description}
              onChange={this.handleChangeDescription()}
              margin="normal"
              variant="outlined"
            />
            <div className="step-form-actions-container">
              <Button
                className="back-btn"
                onClick={() => this.handleBack(this.state.activeStep)}
              >
                Back
              </Button>
              <Button
                className="forward-btn"
                variant="contained"
                color="primary"
                onClick={() => this.handleSetGarageDescStepTwo()}
              >
              Next
              </Button>
            </div>
          </div>
        );
      case 2:
        return (
          <div className="build-garage-amenities">

            <div className={this.state.garage.amenities.cctv? 'check-amenities selected-amenitie cctv': 'check-amenities cctv' } onClick={(e) => this.addAmenitie('cctv', e)}>
              {this.state.garage.amenities.cctv?
                <CheckIcon />:
                <CameraIcon />}
              <Typography>
              Security Cameras 
              </Typography>
            </div>

            <div className={this.state.garage.amenities.security? 'check-amenities selected-amenitie security': 'check-amenities security' } onClick={(e) => this.addAmenitie('security', e)}>
              {this.state.garage.amenities.security?
                <CheckIcon />:
                <AlarmIcon />}
              <Typography>
              Alarm System
              </Typography>
            </div>

            <div className={this.state.garage.amenities.locked? 'check-amenities selected-amenitie locked': 'check-amenities locked' } onClick={(e) => this.addAmenitie('locked', e)}>
              {this.state.garage.amenities.locked?
                <CheckIcon />:
                <LockIcon />}
              <Typography>
              Locked Access
              </Typography>
            </div>

            <div className={this.state.garage.amenities.heating? 'check-amenities selected-amenitie heating': 'check-amenities heating' } onClick={(e) => this.addAmenitie('heating', e)}>
              {this.state.garage.amenities.heating?
                <CheckIcon />:
                <HeatingIcon />
              }
              <Typography>
              Heating
              </Typography>
            </div>

            <div className={this.state.garage.amenities.ac? 'check-amenities selected-amenitie ac': 'check-amenities ac' } onClick={(e) => this.addAmenitie('ac', e)}>
              {this.state.garage.amenities.ac? 
                <CheckIcon />:
                <AcIcon />}
              <Typography>
              A/C
              </Typography>
            </div>

            <div className={this.state.garage.amenities.lift? 'check-amenities selected-amenitie lift': 'check-amenities lift' } onClick={(e) => this.addAmenitie('lift', e)}>
              {this.state.garage.amenities.lift?
                <CheckIcon />:
                <LiftIcon />}
              <Typography>
              Vehicle Lift
              </Typography>
            </div>

            <div className={this.state.garage.amenities.welding? 'check-amenities selected-amenitie welding': 'check-amenities welding' } onClick={(e) => this.addAmenitie('welding', e)}>
              {this.state.garage.amenities.welding?
                <CheckIcon />:
                <WeldIcon />}
              <Typography>
              Welding Equipment
              </Typography>
            </div>

            <div className={this.state.garage.amenities.paint? 'check-amenities selected-amenitie paint': 'check-amenities paint' } onClick={(e) => this.addAmenitie('paint', e)}>
              {this.state.garage.amenities.paint?
                <CheckIcon />:
                <PaintIcon />}
              <Typography>
              Paint Booth
              </Typography>
            </div>

            <div className={this.state.garage.amenities.wifi? 'check-amenities selected-amenitie wifi': 'check-amenities wifi' } onClick={(e) => this.addAmenitie('wifi', e)}>
              {this.state.garage.amenities.wifi?
                <CheckIcon />:
                <WifiIcon />}
              <Typography>
              Wifi
              </Typography>
            </div>

            <div className="step-form-actions-container">
              <Button
                className="back-btn"
                onClick={() => this.handleBack(this.state.activeStep)}
              >
                Back
              </Button>
              <Button
                className="forward-btn"
                variant="contained"
                color="primary"
                onClick={() => this.handleSetGarageAmenitiesStepThree()}
              >
              Next
              </Button>
            </div>

          </div>
        );
      case 3:
        return (
          <div>
            <div className="full-width-input">
              <TextField
                id="search_address"
                className="search-address-newgarage"
                label="Address"
                value={this.state.garage.address.street_one}
                onChange={this.prepSearchAddressText('street_one')}
              />
            </div>
            <div className="full-width-input">
              <TextField
                id="search_address_two"
                className="search-address-newgarage"
                label="Address Line 2"
                value={this.state.garage.address.street_two}
                onChange={this.prepSearchAddressText('street_two')}
              />
            </div>
            <div className="full-width-input city-garage-build">
              <div className="col-left">
              <TextField
                id="search_address_city"
                className="search-address-newgarage"
                label="City"
                value={this.state.garage.address.city}
                onChange={this.prepSearchAddressText('city')}
              />
              </div>
              <div className="col-center">
              <StateSelect
                setSelectedState={this.setSelectedState}
                currentSelect={this.state.garage.address.state}/>
              </div>
              <div className="col-right">
              <TextField
                id="search_zip"
                className="search-address-newgarage"
                label="Zip/Postal Code"
                value={this.state.garage.address.zip}
                onChange={this.prepSearchAddressText('zip')}
              />
              </div>
            </div>
            <div className="step-form-actions-container">
              <Button
                className="back-btn"
                onClick={() => this.handleBack(this.state.activeStep)}
              >
                Back
              </Button>
              <Button
                className="forward-btn"
                variant="contained"
                color="primary"
                onClick={(e) => this.reverseGeoSearch(e)}
              >
              Next
              </Button>
            </div>
          </div>
        );
      case 4:
        return (
          <div>
          <GaragePhotoUploader
            handleUpdateLeadPhoto={this.handleUpdateLeadPhoto}
            handleNoLeadPhoto={this.handleNoLeadPhoto}
            editingGarageRef={this.state.editingGarageRef? this.state.editingGarageRef: null} />
          <div className="step-form-actions-container">
              <Button
                className="back-btn"
                onClick={() => this.handleBack(this.state.activeStep)}
              >
                Back
              </Button>
              <Button
                className="forward-btn"
                variant="contained"
                color="primary"
                onClick={() => this.handleSetPhotosStepFive()}
              >
              Next
              </Button>
            </div>
            </div>
        );
      case 5:
        return (
          <div className="verify-and-post-step">
            <div className="required-to-post">
              <VerifyPhone isVerified={this.state.hasVerfiedPhone} handleError={this.props.handleError} handleUpdateVerification={this.handleUpdateVerification} />
            </div>
          <div className="step-form-actions-container">
            <div className="final-publish-requirements">
              <FormControlLabel
                control={
                  <Checkbox checked={this.state.hostGuidelinesAgreed} onChange={() => this.handleHostGuidelinesAgreed()} value="hosting_agreement" />
                }
                label="I agree to and have read the"
              />
                <a className="motorroof-terms-link" href="https://www.notion.so/mekasmith/MotorRoof-Terms-a7b3ff32f057471a9737fe4ebdf1aef5" target="_blank">
                    MotorRoof Terms of Use.
                </a>
            </div>
            <Button
              className="back-btn"
              onClick={() => this.handleBack(this.state.activeStep)}
            >
              Back
            </Button>
            <Button
              className="forward-btn"
              variant="contained"
              color="primary"
              onClick={() => this.handlePublish(this.state.activeStep)}
            >
            Publish Listing
            </Button>
          </div>
          </div>
        );
      default:
        return 'Whoops!';
    }
  };

  shareToggle = () => {
    console.log('share garage');
  };

  copyGarageURL = () => {
    this.setState({coping: true});
    this.copyTimer = setTimeout(() => {
      this.setState({coping: false});
      clearTimeout(this.copyTimer);
    }, 500);
    copyAction(`https://motorroof.com${this.state.garage_url}`);
  };

  render() {
    return (
      <React.Fragment>
      {this.state.loading?
          <div className="app-loader-wrap">
            <div className="loading-app">
              <CircularProgress className="main-loader"/>
            </div>
          </div>:<React.Fragment></React.Fragment>
        }
      <div className="list-space-form-container">

        <Dialog className="checking-photos-dialog" open={this.state.noPhotosAsk}>
          <div className="missing-garage-hero"></div>
          <Typography variant="h4" className="no-photos-title">
            No photos! Are you sure?
          </Typography>
          <Typography className="no-photos-content">
            Having even just one photo can help dramatically to increase your bookings. You can always add photos later.
          </Typography>
          <div className="confirm-photos-actions">
            <Button size="small" className="photo-back-btn" onClick={() => this.backToPhotosUploader()} >Upload a Photo</Button>
            <Button size="small" className="photo-next-btn" onClick={() => this.confirmNoPhotos()} >
              <CheckIcon />
              Continue
            </Button>
          </div>
        </Dialog>

        <Dialog className="checking-address-dialog" open={this.state.checkingAddress} aria-labelledby="form-dialog-check-address">
          <div className="check-address-notification">
            <Typography variant="h2" className="location-found-title">
              Is this the correct address?
            </Typography>
            <Typography className="location-found-content">
              You can drag and drop the pin to adjust your location or go back and change your address information.
              We share this address only to visiting guests who you confirm and never display publicly.
            </Typography>
          </div>
          <div className="mapbox-container">
            <MapGL
              {...this.state.viewport}
              className="mapbox-gl-wrap"
              onViewportChange={this.updateViewport}
              mapboxApiAccessToken={MAPBOX_TOKEN}
              scrollZoom={false}
              mapStyle="mapbox://styles/mapbox/streets-v11"
            >
              <Marker
                longitude={this.state.marker.longitude}
                latitude={this.state.marker.latitude}
                offsetTop={-20}
                offsetLeft={-10}
                draggable
                onDragEnd={this.onMarkerDragEnd}
              >
                <LocationIcon className="marker-pin-red" size={20} />
              </Marker>
              <div className="nav" style={navStyle}>
                <NavigationControl
                  onViewportChange={this.updateViewport}
                  showCompass={false}
                />
              </div>
            </MapGL>
            <div className="confirm-location-actions">
              <Button size="small" className="address-back-btn" onClick={() => this.editAddressDialogChange()} >Change Address Info</Button>
              <Button size="small" className="address-next-btn" onClick={() => this.confirmAndSubmitAddressStepFour()} >
                <CheckIcon />
                Confirm Address
              </Button>
            </div>
          </div>
        </Dialog>
        <div className="completed-steps-hideaway"></div>
        <div className="preview-garage-col">
          <p className="preview-label">Preview your listing</p>
          <GarageGridItem
            garageTitle={this.state.garage.title}
            garageTypes={this.state.garage.types}
            avatarPhotoURL={this.props.avatarPhotoURL}
            displayName={this.props.displayName}
            starRating={this.props.userStars}
            price={this.state.garage.price}
            city={this.state.garage.address.city}
            state={this.state.garage.address.state}
            photo={this.state.leadPhoto}
          />
        </div>
        <div className={"stepper-slide-up-container stepper-" + this.state.activeStep}>
        <Stepper activeStep={this.state.activeStep} orientation="vertical" >
          {this.state.steps.map((label, index) => (
            <Step key={label} className={ `${this.state.activeStep === index? 'active-step': ''} ${this.state.activeStep > index? 'completed-step': ''}`}>
              <StepLabel className={'step-' + this.state.activeStep}>{label}</StepLabel>
              <StepContent>
                {this.getStepContent(index)}
              </StepContent>
            </Step>
          ))}
        </Stepper>
        <div className={this.state.activeStep === this.state.steps.length? "creating-public-listing show-creating": "creating-public-listing"}>
          <div className={this.state.garage_post_success? "waiting-on-creation-content generated": "waiting-on-creation-content generate"}>
            {this.state.garage_post_success?
              <div className="garage-create-success">
                <div className="garage-creation-success-col">
                  <div className="motorroof-garage-finish-create"></div>
                  <div className="confetti-build-wrapper">
                    <Confetti active={this.state.garage_post_success} config={ config_conf }/>
                  </div>
                  <Typography variant="h2">Your space is <span>listed!</span></Typography>
                  <Typography variant="body2">Great work! You are setup to operate your new entrepreneuring experience!</Typography>
                </div>
                <div className="garage-created-info-col">
                <div className="garage-created-basic-info">
                  <Typography variant="h2">What's next?</Typography>
                  <Typography variant="body2">
                    You will now start receiving notifications for your listing when guests ask for a reservation. <strong>You will have 48 hours to respond to the guest's reservation request.</strong> Be sure to respond to each request quickly whether you deny or not as your response time counts to your search favorability. Read up on our Host Tips to get the most of your space also!
                  </Typography>
                </div>
                {this.state.needStripeConnect?
                  <ConnectStripe />:null
                }
                <div className="market-listing-info">
                  <Typography variant="caption">Use this link to market your listing (Click to copy)</Typography>
                  <div className="copy-wrap">
                  <TextField
                    variant="outlined"
                    value={`https://motorroof.com${this.state.garage_url}`}
                    onClick={this.copyGarageURL}
                  >
                  </TextField>
                  <div className={this.state.coping? 'coping':'not-copy'}>
                    <FileCopyIcon />
                    <Typography variant="body2">Copied!</Typography>
                  </div>
                  </div>
                </div>
                <div className="garage-created-options">
                  <Link to={this.state.garage_url} target="_blank">
                  <Button className="view-listing-btn">View Listing</Button>
                  </Link>
                  {/* disabled until built feature
                  <Button className="share-listing-btn" onClick={this.shareToggle}>Share Listing</Button>
                  */}
                  <Link to="/hosting-hub">
                    <Button className="hosting-dashboard-btn">Hosting Dashboard</Button>
                  </Link>
                </div>
                </div>
              </div>:
              <React.Fragment>
              <div className="motorroof-garage-creating"></div>
              <div className="loader-garage-creation">
                <Loader type="Triangle" color="#90ffc8"/>
              </div>
              <Typography>Please wait as we post your listing.</Typography>
              </React.Fragment>
            }
          </div>
        </div>
      </div>
      </div>
      </React.Fragment>
    );
  }
}

export default BuildGarageForm;
