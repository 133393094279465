import React from "react";
import { Link } from "react-router-dom";

import Typography from '@material-ui/core/Typography';
import "../../styles/hosting_hub.scss";
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';

import CircularProgress from "@material-ui/core/CircularProgress";

import GarageGridItem from "../../components/GarageGridItem";
import GarageEditForm from "../../components/GarageEditForm";
import ConnectStripe from '../../components/ConnectStripe';
import Footer from "../../components/Footer";
import HostReservationsList from "../../components/HostReservationsList";

import firebase from 'firebase/app';
import dbFireStore from '../../firebase';

const isDevMode = process.env.NODE_ENV === "development" && process.env.NODE_ENV !== "production"? true: false;

const AWS_STRIPEDASHBOARDLINK_LAMBDA = isDevMode? 'JrLc8XGMwQjxarec47FN11ZuYEikd9y42I3kVF7a': 'r9CmepiXyX5XOqxCzRYIJ4Lulm0qytoX1ZrHAOKS';
const STRIPEDASHBOARDLINK_ENDPOINT = isDevMode? 'https://gwooweja9a.execute-api.us-east-1.amazonaws.com/default/DEV_hostGenerateDashboardLink': 'https://raature570.execute-api.us-east-1.amazonaws.com/default/hostGenerateDashboardLink';

const axios = require('axios');


class HostingHub extends React.Component {

  state = {
    propertiesList: null,
    loadingProperties: true,
    hasUnfinished: null,
    editGarage: null,
    openEdit: false,
    hostStripeConnected: true,
    hostStripeError: false,
    loadingStripeHost: false,
    usersRating: null,
  };

  componentDidMount() {
    this.getUsersGarages();
    this.getUserInfo();
    this.getOwnerReviews();
  };

  componentWillUnmount() {
    if(this.userGaragesRef) {
      this.userGaragesRef.off();
    }
  };

  getOwnerReviews = (owner) => {
    dbFireStore.firestore().collection("reviews").where("review_of_user_id", "==", firebase.auth().currentUser.uid).get().then(querySnapshot => {
      var reviewsList = [];
      var queryLength = querySnapshot.docs.length;
      var queryLooped = 0;
      if(queryLength === 0) {
        // user has no reviews yet
      } else {
        querySnapshot.forEach((review) => {
          var reviewInfo = review.data();
          // parse review data here
          queryLooped++;
          reviewsList.push(reviewInfo.rating);
          if(queryLength == queryLooped) {
            // end of the list, push to state
            var sum = reviewsList.reduce((a, b) => a + b, 0);
            var avg = (sum/queryLength).toFixed(1);
            this.setState({
              usersRating: avg,
            });
          };
        });
      }
    }).catch((error) => {
      console.log(error);
    });
  };

  getUserInfo = () => {
    var userInfoId = firebase.auth().currentUser.uid;
    firebase.database().ref('/users/' + userInfoId + '/profile').once('value').then((snapshot) => {
      var needsStripeHostConfirm = snapshot.val() && !snapshot.val().beenStripeHostWelcomed && snapshot.val().host_stripe_user_id? true: false;
      var hasStripeConnectError = snapshot.val() && snapshot.val().host_stripe_connect_error? true: false;
      if(!snapshot.val().host_stripe_user_id) {
        this.setState({
          hostStripeConnected: false,
        });
      } else {
        this.setState({
          stripeLink: snapshot.val().host_stripe_user_id,
        });
      }
      if(hasStripeConnectError) {
        this.setState({
          hostStripeError: true,
        });
      }
    });
  };
  createStripeDashLink = (e) => {
    e.preventDefault();
    this.setState({
      loadingStripeHost: true,
    });
    var userInfo = {
      stripe_id: this.state.stripeLink,
    };

    axios({
      method: 'POST',
      url: STRIPEDASHBOARDLINK_ENDPOINT,
      headers: {
        "x-api-key": AWS_STRIPEDASHBOARDLINK_LAMBDA
      },
      data: userInfo,
      validateStatus: (status) => { // this is so we don't confuse the catch for error when error was returned from the server
        return true;
      }
    }).then(response => {
      if(response.status === 200) {
        var temp_link = response.data.link.url;
        window.location.href = temp_link;
      }
    }).catch(error => {
      console.log(error);
      this.setState({
        loadingStripeHost: false,
      });
    });

  }

  getUsersGarages = () => {
    this.userGaragesRef = firebase.database().ref('users/' + firebase.auth().currentUser.uid + '/garages');
    this.userGaragesRef.once('value', snapshot => {
      if (snapshot.val() != null) {
        var propertiesList = [];
        var hasUnfinished = false;
        var queryLength = Object.keys(snapshot.val()).length;
        var queryLooped = 0;
        if(queryLength === 0) {
          this.setState({
            propertiesList: null,
            loadingProperties: false,
          });
        } else {
          snapshot.forEach((garage) => {
            var garageData = garage.val();
            garageData.id = garage.key;
            garageData.index = queryLooped;
            if(!garageData.isFinished) {
              hasUnfinished = true;
            }
            queryLooped++;
            propertiesList.push(garageData);
            if(queryLength == queryLooped) {
              this.setState(prevState => ({
                propertiesList: propertiesList,
                hasUnfinished: hasUnfinished,
              }), () => {
                this.getPropertyImages();
              });
            }
          });
        }
      } else {
        this.setState({
          propertiesList: null,
          loadingProperties: false,
        });
      }
    });

  };

  getPropertyImages = () => {
    var propertyListLength = this.state.propertiesList.length;
    this.state.propertiesList.forEach((garage, index) => {
      var newPropertyImageRecord = this.state.propertiesList[index];
      if(garage.photos && garage.id) {
        Object.keys(garage.photos).forEach((photo) => {
          if(garage.photos[photo].order === 0) {
            var leadId = photo;
            var leadImgLocationRef = firebase.storage().ref(`/garages/${garage.id}/${leadId}/photo.jpg`);
            leadImgLocationRef.getDownloadURL().then(url => {    
              newPropertyImageRecord.leadPhoto = url;
              this.setState(prevState => ({
                propertiesList: [
                  ...prevState.propertiesList.slice(0,index),
                  newPropertyImageRecord,
                  ...prevState.propertiesList.slice(index + 1)
                ]
              }));
            }).catch((error) => {
              console.log('There was an error retreiving the garages lead photo');
            });
          }
        });
      }
      if(propertyListLength == index + 1) {
        this.setState({
          loadingProperties: false,
        })
      }
    });
  };

  handleDialog = (garage) => {
    this.setState(prevState => ({
      openEdit: !prevState.openEdit,
      editGarage: garage? garage: null,
    }));
  };

  deleteDraft = (garage) => {
    // delete the garage!
    const userDeleteGaragesRef = firebase.database().ref('users/' + firebase.auth().currentUser.uid + '/garages/' + garage.id);
    userDeleteGaragesRef.remove();
    this.setState(prevState => ({
      propertiesList: [...prevState.propertiesList.splice(0,prevState.propertiesList.length - 1)],
      hasUnfinished: false,
    }), () => {
      userDeleteGaragesRef.off();
    });
  };

  updateCardData = (garageData) => {
    var updateIndex = this.state.propertiesList.findIndex(x => x.id === garageData.id);
    var garagetest = this.state.propertiesList;
    this.setState(prevState => ({
      propertiesList: [
        ...prevState.propertiesList.slice(0,updateIndex),
        {
          ...prevState.propertiesList[updateIndex],
          ...garageData
        },
        ...prevState.propertiesList.slice(updateIndex + 1)
      ]
    }));
  };

  updateCardPhoto = (garageId, photo) => {
    var updateIndex = this.state.propertiesList.findIndex(x => x.id === garageId);
    this.setState(prevState => ({
      propertiesList: [
        ...prevState.propertiesList.slice(0,updateIndex),
        {
          ...prevState.propertiesList[updateIndex],
          leadPhoto: photo
        },
        ...prevState.propertiesList.slice(updateIndex + 1)
      ]
    }));
  }

  handleDestoryProperty = (garageId) => {
    var updateIndex = this.state.propertiesList.findIndex(x => x.id === garageId);
    const destoryGaragesRef = firebase.database().ref('users/' + firebase.auth().currentUser.uid + '/garages/' + garageId);
    this.setState(prevState => ({
      openEdit: false,
      propertiesList: [
        ...prevState.propertiesList.slice(0,updateIndex),
        ...prevState.propertiesList.slice(updateIndex + 1)
      ]
    }));
    destoryGaragesRef.once('value').then((snapshot) => {
      if (snapshot.val() !== null) {
        var garageData = snapshot.val();
        var garagePublicLocationKey = garageData.garage_key;
        var publicGarageRef = dbFireStore.firestore().collection("garages").doc(garagePublicLocationKey);
        publicGarageRef.delete().then(() => {
          const userDeleteGaragesRef = firebase.database().ref('users/' + firebase.auth().currentUser.uid + '/garages/' + garageId);
          userDeleteGaragesRef.remove();
          userDeleteGaragesRef.off();
        }).catch((error) => {
          console.error("Error removing document: ", error);
        });
      }
    });
  };

  render() {
    const propertiesManaged = this.state.propertiesList && this.state.propertiesList.length > 0;
    return (
      <div className="with-navbar-wrap">
        <React.Fragment>
          {this.state.loadingStripeHost?
            <div className="app-loader-wrap dashboard-loader">
              <div className="loading-app">
                <CircularProgress className="main-loader"/>
              </div>
            </div>:null}
        </React.Fragment>
        <GarageEditForm
          garage={this.state.editGarage}
          open={this.state.openEdit}
          handleClose={this.handleDialog}
          avatarPhotoURL={this.props.avatarPhotoURL}
          displayName={this.props.hostName}
          handleError={this.props.handleError}
          updateCardData={this.updateCardData}
          updateCardPhoto={this.updateCardPhoto}
          editGarageData={this.editGarageData}
          userStars={this.state.usersRating? this.state.usersRating: 5}
          handleDestoryProperty={this.handleDestoryProperty}
        />
        <div className="host-container">
          <div className="col-left">
            <Typography variant="h5" component="h3">
              Hosting Hub
            </Typography>
            <Typography variant="caption" className="subtitle">
              Reservation Manager
            </Typography>
            <HostReservationsList />
            <div className="garage-active-list-container">
              <div className="garage-active-title">
                <Typography variant="h5">
                  Place's you are renting
                </Typography>
                <Link to="/garages/new">
                  <Button className="new-garage-btn">
                  {this.state.hasUnfinished?
                    <span className="edit-icon"><EditIcon />Edit Draft</span>: <span className="add-icon"><AddIcon />Add New Space</span>
                  }
                  </Button>
                </Link>
              </div>
              {this.state.loadingProperties?
                <div><CircularProgress className="host-garage-loader"/></div>:
                <React.Fragment>
                {propertiesManaged?
                  <div className="properties-list-hosthub">
                  {this.state.propertiesList.map((garage, i) => (
                    <div className="grid-list-block" key={`garageitem-${i}`}>
                      {garage.isFinished?
                        <div className="link-garage">
                          <div className="garage-actions active">
                            <Typography variant="body2" className="garage-host-active">Active</Typography>
                            <Link to={garage.public_url} target="_blank" >
                              <Button>View Listing</Button>
                            </Link>
                          </div>
                          <div onClick={() => this.handleDialog(garage)} className="hover-edit-garage-container">
                          <div className="edit-garage-hover-btn">
                            <Typography className="edit-listing-btn-text">Edit Listing</Typography>
                          </div>
                          <GarageGridItem
                            garageTitle={garage.title}
                            isStorage={garage.isStorage}
                            isWorkshop={garage.isWorkshop}
                            avatarPhotoURL={this.props.avatarPhotoURL}
                            displayName={this.props.hostName}
                            starRating={this.state.usersRating? this.state.usersRating: 5}
                            price={garage.price}
                            city={garage.address.city}
                            state={garage.address.state}
                            photo={garage.leadPhoto? garage.leadPhoto: null}
                          />
                          </div>
                        </div>:
                        <div className="link-garage work-in-progress-garage" >
                          <div className="garage-actions draft">
                            <Typography variant="body2" className="garage-host-active">Draft</Typography>
                            <Button className="delete" onClick={() => this.deleteDraft(garage)}>Delete</Button>
                          </div>
                          <Link to="/garages/new" className="link-garage">
                          <GarageGridItem
                            garageTitle={garage.title}
                            isStorage={garage.isStorage}
                            isWorkshop={garage.isWorkshop}
                            avatarPhotoURL={this.props.avatarPhotoURL}
                            displayName={this.props.hostName}
                            starRating={this.state.usersRating? this.state.usersRating: 5}
                            price={garage.price}
                            city={garage.address ? garage.address.city: null}
                            state={garage.address ? garage.address.state: null}
                            photo={garage.leadPhoto? garage.leadPhoto: null}
                          />
                          </Link>
                        </div>
                      }
                      
                    </div>
                  ))}
                  </div>:<div className="no-garages-yet">
                  <Typography>You are not hosting any spaces. Click add to create a space to host!</Typography>
                  </div>}
                  </React.Fragment>
              }
            </div>

          </div>
          <div className="col-right">
            <Typography variant="caption" className="host-nav-title">Host Tools</Typography>
            <div className="host-links">
              <Button className={window.location.pathname === "/hosting-hub"? "active": ""}>
                <Typography variant="body2">Hosting Hub</Typography>
              </Button> 
              <Link to="/payment-center">
              <Button className={window.location.pathname === "/payment-center"? "active": ""}>
                <Typography variant="body2">Payment Center</Typography>
              </Button>
              </Link>
              {!this.state.hostStripeConnected || this.state.hostStripeError?
                <ConnectStripe isButton={true} />:null
              }
                   {/*
              <a href="https://news.motorroof.com/starting-your-space-sharing-business-in-8-minutes/">
              <Button>
                <Typography variant="body2">Hosting Tips</Typography>
              </Button>
              </a>
          <a href="https://help.motorroof.com/">
                <Button>
                  <Typography variant="body2">Support</Typography>
                </Button>
              </a> */}
            </div>

            {this.state.hostStripeConnected && this.state.stripeLink && !this.state.hostStripeError?
              <div className="manage-your-stripe-account sidebar-size">
              <Typography className="manage-info">🏦 You have MotorRoof direct deposits connected using Stripe.</Typography>
              <Button onClick={(e) => this.createStripeDashLink(e)}>
                  <Typography variant="body2">Manage Direct Deposits</Typography>
              </Button></div>:<div className="no-direct-deposits-but-can-issue-checks sidebar-size">
                <Typography className="check-issue-title">💸 Receiving payouts via mailed check</Typography>
                <Typography className="check-issue-content">
                You have no direct deposit connected but can still receive payouts via check. {/*To learn more about check issuing fees, limits, and how to request a payout check view our 
                <a href="https://help.motorroof.com/article/48-receiving-a-check-payout-instead-of-direct-deposit" target="_blank"> help articles here.</a>*/}
                </Typography>
              </div>
            }

          </div>
        </div>
        <Footer />
      </div>
    );
  }
};

export default HostingHub;
