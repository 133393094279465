import React from "react";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from '@material-ui/core/Button';
import DashboardNav from "../../components/DashboardNav";
import moment from 'moment';
import AppBar from '@material-ui/core/AppBar';
import { withStyles } from '@material-ui/core/styles';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import ConfirmationNumberOutlinedIcon from '@material-ui/icons/ConfirmationNumberOutlined';
import { Link, Redirect } from "react-router-dom";
import ThumbUpAltRoundedIcon from '@material-ui/icons/ThumbUpAltRounded';
import ThumbDownRoundedIcon from '@material-ui/icons/ThumbDownRounded';
import BlockIcon from '@material-ui/icons/Block';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ExploreOutlinedIcon from '@material-ui/icons/ExploreOutlined';
import FaceOutlinedIcon from '@material-ui/icons/FaceOutlined';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import AttachMoney from '@material-ui/icons/AttachMoney';
import WriteReview from '../../components/WriteReview';
import SendIcon from '@material-ui/icons/Send';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import HelpIcon from '@material-ui/icons/HelpOutline';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';

import {Elements} from 'react-stripe-elements';
import AddPaymentForm from '../../components/AddPaymentForm';

import GarageReservationAccessHours from '../../components/GarageReservationAccessHours';

import firebase from 'firebase/app';
import dbFireStore from '../../firebase';

import "../../styles/reservation_show.scss";
import ReportUser from "../../components/ReportUser";

import { ReactComponent as PaidInvoice } from "../../images/paid-invoice.svg";


const axios = require('axios');

const isDevMode = process.env.NODE_ENV === "development" && process.env.NODE_ENV !== "production"? true: false;

const toTitleCase = (str) => {
    return str.replace(/\w\S*/g, function(txt){
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
}

const formatCurrencyUSD = (amount) => {
  if(amount) {
    const locale = 'en-US';
    const options = { style: 'currency', currency: 'USD'};
    return Intl.NumberFormat(locale, options).format(amount);
  } else {
    return '';
  }
}

const a11yProps = index => {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

const EditTab = withStyles(theme => ({
  root: {
    textTransform: 'none',
    '&:hover': {
      color: '#40a9ff',
      opacity: 1,
    },
    '&$selected': {
      color: '#1890ff',
    },
    '&:focus': {
      color: '#40a9ff',
    },
  },
  selected: {
    color: "40a9ff",
  }
}))(props => <Tab {...props} />);

const EditTabs = withStyles({
  root: {
    borderColor: '#e8e8e8',
  },
  indicator: {
    backgroundColor: '#1890ff',
  },
})(Tabs);

const TabPanel = props => {
  const { children, value, index, ...other } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}


class Reservations extends React.Component {
  state = {
    loading: true,
    reservation: null,
    userType: null,
    garage: null,
    garagePhotoURL: null,
    hostData: null,
    hostPhoto: null,
    tabValue: 0,
    messages: null,
    value: '',
    valueDeny: '',
    valueCancel: '',
    loadingMessages: true,
    wantsToDeny: false,
    wantsToCancel: false,
    rating: null,
    requestMoneyDialogShow: false,
    valueRequestPayment: null,
    valueRequestPaymentAmount: '',
    requestType: "General Request",
    loadingChargeRequest: false,
    payRequestFormDialogShow: false,
    paymentInfo: null,
    paymentTotals: null,
    loadingPayment: true,
    createModeToggle: false,
    confirmDelete: false,
    deletingCard: false,
    processingPayment: false,
    paymentSuccessful: false,
    paymentRequestList: null,
    loadingPaymentRequests: true,
    paymentRequestsLoadingError: false,
    loadingCancelReservation: false,
    loadingDenyReservation: false,
  }

  componentDidMount() {
    if(this.props.match.params.reservation_id) {
      this.getReservation(this.props.match.params.reservation_id);
      this.checkCustomerInfo();
    } else {
      // redirect user?
    }
  }

  checkCustomerInfo = () => {
    this.setState({loadingPayment: true});
    this.userProfileRef = firebase.database().ref('users/' + firebase.auth().currentUser.uid + '/profile/');
    this.userProfileRef.once("value").then(profile => {
      if(profile.val().customerId) {
        // user has a stripe customer id -> get their info
        this.getCustomerInfo(profile.val().customerId);
      } else {
        // user needs to create a customer id in stripe and then base customer off of that created data
        this.createCustomer();
      }
    });
  };

  createCustomer = () => {
    const email = firebase.auth().currentUser.email;
    const fullname = firebase.auth().currentUser.displayName? firebase.auth().currentUser.displayName: '';
    const isDevMode = process.env.NODE_ENV === "development" && process.env.NODE_ENV !== "production"? true: false;
    axios({
      method: 'post',
      url: isDevMode? 'https://gl0rddpxh8.execute-api.us-east-1.amazonaws.com/default/DEV_createCustomer': 'https://3zej6teneb.execute-api.us-east-1.amazonaws.com/default/createCustomer',
      headers: {
        'x-api-key': isDevMode? 'imWuWDwIOg71r5I64SXli77aH4KSBoec5Nr9XzHI': 'nnJfyb4vgL9nWfHdDhRRt5qW2y7QJO5P699lIt2N'
      },
      data: {
        name: fullname,
        email: email
      }
    }).then(response => {
      const success = response.data.success;
      if(success) {
        this.userProfileRef.child("customerId").set(response.data.customer.id);
        this.setState({
          customerData: response.data.customer,
          loadingPayment: false,
        });
      } else {
        console.log("Stripe create customer account error");
        this.setState({
          customerData: null,
          loadingPayment: false,
        });
      }
    }).catch(error => {
      console.log('Stripe Payment API Error');
      this.setState({
        customerData: null,
        loadingPayment: false,
      });
    });
  };

  getCustomerInfo = customerId => {
    const isDevMode = process.env.NODE_ENV === "development" && process.env.NODE_ENV !== "production"? true: false;

    axios({
      method: 'post',
      url: isDevMode? 'https://vbjfkaqew8.execute-api.us-east-1.amazonaws.com/default/DEV_retrieveCustomer': 'https://hr36gm45mf.execute-api.us-east-1.amazonaws.com/default/retrieveCustomer',
      headers: {
        'x-api-key': isDevMode? 'gzbz5RTxuY1d3OElN5qff5URoEA8a0Zg75MVNlSZ': 'lRvcJr7SUyEe3JcGaK4B3FUTa3DOi5c1dzSz3190'
      },
      data: {
        customerId: customerId
      }
    }).then(response => {
      const customerData = response.data.customer;
      const success = response.data.success;
      if(success) {
        // set to state
        // need to get their past invoice list here and pass it to the table, format using the method below
        // createData(new Date('Sat Oct 26 2019 15:13:47 GMT+0900 (Japan Standard Time)'), 'ASDFASDFWEF2E', 'Reservation', "ASDFW234FD", 1234),
        this.setState({
          customerData: customerData,
          loadingPayment: false,
          pastInvoiceList: [],
        });
      } else {
        // there was a error
        this.setState({
          customerData: null,
          loadingPayment: false,
        });
        console.log('Error getting customer info');
      }
    }).catch(error => {
      this.setState({
        customerData: null,
        loadingPayment: false,
      });
      console.log('Error getting customer info');
    });
  };

  updateCustomerInfo = (newCustomerData) => {
    this.setState({
      customerData: newCustomerData,
    });
  }

  toggleUpdateCardInfo = () => {
    this.setState(prevState => ({
      createModeToggle: !prevState.createModeToggle,
    }));
  };

  createModeToggleOff = () => {
    this.setState({
      createModeToggle: false,
    });
  };

  deleteCardConfirmToggle = () => {
    this.setState(prevState => ({
      confirmDelete: !prevState.confirmDelete,
    }));
  };

  deletePaymentInfo = () => {
    this.setState({deletingCard: true, confirmDelete: false, createModeToggle: false});
    const customerId = this.state.customerData.id;
    const cardToDelete = this.state.customerData.default_source;
    const isDevMode = process.env.NODE_ENV === "development" && process.env.NODE_ENV !== "production"? true: false;

    axios({
      method: 'post',
      url: isDevMode? 'https://hdsbxx8h6d.execute-api.us-east-1.amazonaws.com/default/DEV_deleteCard': 'https://c4zr2auzgf.execute-api.us-east-1.amazonaws.com/default/deleteCard',
      headers: {
        'x-api-key': isDevMode? 'PS5XvnPOD61uFCZw6L7sZ93D6BXz6PMI6vsJmPtK': 'eiSuOGPrv86GKFFC16tjD4ENIqHUML3A8E06DAQG'
      },
      data: {
        customerId: customerId,
        cardToken: cardToDelete,
      }
    }).then(response => {
      const customerData = response.data.customer;
      const success = response.data.success;
      if(success) {
        // set to state
        var updateCustomerData = this.state.customerData;
        updateCustomerData.default_source = null;
        this.setState({
          deletingCard: false,
          customerData: updateCustomerData,
        });
      } else {
        // there was a error
        this.setState({
          deletingCard: false,
        });
        this.props.handleError({
          message: "There was an error deleting your payment information. Please try again."
        });
        console.log('Error deleting customer card info');
      }
    }).catch(error => {
      this.setState({
        deletingCard: false,
      });
      this.props.handleError({
        message: "There was an error deleting your payment information. Please try again."
      });
      console.log('Error deleting customer card');
    });
  };

  handleChange = (event, newValue) => {
    this.setState({tabValue: newValue});
    clearTimeout(this.timeScroll);
    this.timeScroll = setTimeout(()=> {
      clearTimeout(this.timeScroll);
      if(this.chatList && this.chatList.scrollHeight) {
        this.chatList.scrollTop = this.chatList.scrollHeight;
      }
    }, 100)
  };

  requestMoneyToggle = () => {
    this.setState(prevState => ({
      requestMoneyDialogShow: !prevState.requestMoneyDialogShow,
    }));
  };

  getReservation = async (reservation_id) => {
    await dbFireStore.firestore()
      .collection("reservations")
      .doc(reservation_id)
      .get()
      .then((querySnapshot) => {
        var chat_id = querySnapshot.data().conversation_id? querySnapshot.data().conversation_id: false;
        // we need to listen for updates if the reservation changes status!
        dbFireStore.firestore().collection("reservations").doc(reservation_id)
          .onSnapshot((snapshot) => {
            if(snapshot.data().host_canceled || snapshot.data().guest_canceled) {
              this.setState({
                reservation: snapshot.data(),
              });
            };
            if(this.state.reservation && this.state.reservation.host_approved === false) {
              if(snapshot.data().host_approved || snapshot.data().host_denied) {
                // we want to watch if the host approved or denied the request
                this.setState({
                  reservation: snapshot.data(),
                });
              } 
            }
          }, (error) => {
            console.log(error);
            console.log("There was a error getting the reservation changes.");
          });

        if(querySnapshot.data().host_id === firebase.auth().currentUser.uid ) {
          // user is host
          this.setState({
            userType: 'host',
            reservation_id: reservation_id,
            reservation: querySnapshot.data(),
          });
          // need to get the guests info
          this.getGuestInfo(querySnapshot.data().guest_id);
          this.getConversation(chat_id);
          this.getGarageInfo(querySnapshot.data().garage_id);
          this.gatherInvoices(reservation_id, 'host_id');

        } else {
          // user is guest
          this.setState({
            userType: 'guest',
            reservation_id: reservation_id,
            reservation: querySnapshot.data(),
          });
          this.getConversation(chat_id);
          this.getGarageInfo(querySnapshot.data().garage_id);
          this.gatherInvoices(reservation_id, 'guest_id');
        }
      }).catch(error => {
        // error duh!
        this.setState({
          reservation: null,
        });
      });
  }

  getConversation = (chat_id) => {
    if(chat_id) {
      // get the conversation
      this.chatRef = firebase.database().ref('chat/' + chat_id + '/messages/');

      // watch for messages
      this.chatRef.on('value', (snapshot) => {
        this.updateMessages(snapshot.val());
      });

      this.chatRef.once('value').then((snapshot) => {
        if(snapshot.val()) {
          this.updateMessages(snapshot.val());
        } else {
          // no messages have been sent then!
          this.setState({
            messages: null,
            loadingMessages: false
          });
        }
      });

    } else {
      // create a conversation, first create a ref in the realtime database, then update the reservation with the chat id
      var chatInfo = {
        guest_id: this.state.reservation.guest_id,
        host_id: this.state.reservation.host_id,
        reservation_id: this.state.reservation_id,
        created_at: firebase.database.ServerValue.TIMESTAMP,
      }

      // should check if there is a ref hear to this reservation already
      // so we don't overwrite on accident OR setting at the exact time so their messages are off (edge)
      var chatRef = firebase.database().ref('chat').push(chatInfo);

      // then set the ref to the top so we can watch for changes
      this.chatRef = firebase.database().ref('chat/' + chatRef.key + '/messages/');

      // check for messages
      this.chatRef.on('value', (snapshot) => {
        this.updateMessages(snapshot.val());
      });

      // then make sure we set the firestore ref that there is a key!
      const reservationRef = dbFireStore.firestore().collection("reservations").doc(this.state.reservation_id);
      reservationRef.update({
        conversation_id: chatRef.key,
        lastUpdated: firebase.firestore.FieldValue.serverTimestamp()
      }).catch((error) => {
        console.log('Error updating reservation');
      });

      this.setState({
        messages: null,
        loadingMessages: false
      });
    }
  };

  updateMessages = (message_list) => {
    if(message_list) {
      // there are messages
      var newMessageList = [];
      var messageList = Object.entries(message_list);
      var looped = 0;
      messageList.forEach((msg) => {
        looped++
        newMessageList.push({id: msg[0], ...msg[1]});
        if(looped === messageList.length) {
          this.setState({messages: messageList, loadingMessages: false});
          if(this.chatList && this.chatList.scrollHeight) {
            this.chatList.scrollTop = this.chatList.scrollHeight;
          } else {
            this.timeOutScroll =  setTimeout(() => {
              clearTimeout(this.timeOutScroll);
              if(this.chatList && this.chatList.scrollHeight) {
                this.chatList.scrollTop = this.chatList.scrollHeight;
              }
            }, 800);
          }
        }
      });
    } else {
      // no messages yet!
      this.setState({
        messages: null,
        loadingMessages: false
      });
    }
  };

  gatherInvoices = (reservation_id, type_of_user) => {

    this.setState({
      paymentRequestList: null,
      loadingPaymentRequests: true,
      paymentRequestsLoadingError: false,
    });

    dbFireStore.firestore()
      .collection("reservations")
      .doc(reservation_id)
      .collection("payment_requests")
      .where(type_of_user, '==', firebase.auth().currentUser.uid)
      .get()
      .then((querySnapshot) => {
        var invoiceObj = {};
        var queryLength = querySnapshot.docs.length;
        var queryLooped = 0;
        if(queryLength === 0) {
          // user has no reviews as a garage owner yet!
         this.setState({
            paymentRequestList: null,
            loadingPaymentRequests: false,
          });
        } else {
          querySnapshot.forEach((invoice) => {
            var invoiceInfo = invoice.data();
            // parse review data here
            queryLooped++;
            invoiceObj[invoice.id] = {};
            invoiceObj[invoice.id].paid = invoiceInfo.paid;
            invoiceObj[invoice.id].paid_on = invoiceInfo.paid_on? moment(invoiceInfo.paid_on.toDate()).format("MM/DD/YYYY @ h:mm a"): null;
            
            if(queryLength == queryLooped) {
              // end of the list, push to state
              this.setState({
                paymentRequestList: invoiceObj,
                loadingPaymentRequests: false,
              });
            };
          });
        }
      }).catch((e) => {
        console.log(e);
        console.log('Error gathering past invoices');
        this.setState({
          paymentRequestList: null,
          loadingPaymentRequests: false,
          paymentRequestsLoadingError: true,
        });
      });
  };

  getGuestInfo = (guest_id) => {
    dbFireStore.firestore().collection("users")
      .where("user_id", "==", guest_id)
      .get().then((guest) => {
        var guestData = guest.docs[0].data();
        if(guestData.user_photo_key) {
          this.setState({guestData: guestData});
          this.getGuestPhoto(guestData.user_photo_key);
        } else {
          this.setState({guestData: guestData, loading: false});
        }
      }).catch((error) => {
        console.log("Error Getting Guest Info");
      });
  };

  getGarageInfo = (garage_id) => {
    dbFireStore.firestore().collection("garages").doc(garage_id).get().then((garage) => {
      this.setState({
        garage: garage,
      });

      if(garage.data().photos && garage.data().garage_key) {
        Object.keys(garage.data().photos).forEach((photo) => {
          if(garage.data().photos[photo].order === 0) {
            var leadId = photo;
            var leadImgLocationRef = firebase.storage().ref(`/garages/${garage.data().garage_key}/${leadId}/photo.jpg`);
            leadImgLocationRef.getDownloadURL().then(url => {
              this.setState({
                garagePhotoURL: url,
              });
            });
          }
        });
      }
      this.getGarageOwnerInfo(garage.data().owner_id);
    }).catch((error) => {
      this.props.handleError({
        message: "There was an error retrieving your reservation info."
      });
    });
  };

  getGarageOwnerInfo = (owner_id) => {
    dbFireStore.firestore().collection("users")
      .where("user_id", "==", owner_id)
      .get().then((owner) => {
        var ownerData = owner.docs[0].data();
        if(ownerData.user_photo_key) {
          this.setState({hostData: ownerData});
          this.getOwnerPhoto(ownerData.user_photo_key);
        } else {
          this.setState({hostData: ownerData, loading: false});
        }
      });
  };

  getOwnerPhoto = (owner_photo_key) => {
    const storageRef = firebase.storage().ref();
    const storageUserPhotoRef = storageRef.child(`${owner_photo_key}`);
    storageUserPhotoRef.getDownloadURL().then(url => {
      this.setState({ hostPhoto: url, loading: false });
    });
  };

  getGuestPhoto = (guest_photo_key) => {
    const storageRef = firebase.storage().ref();
    const storageUserPhotoRef = storageRef.child(`${guest_photo_key}`);
    storageUserPhotoRef.getDownloadURL().then(url => {
      this.setState({ guestPhoto: url, loading: false });
    });
  };

  typeMessage = e => this.setState({ value: e.target.value });

  typeMessageDeny = e => this.setState({ valueDeny: e.target.value });

  typeMessageCancel = e => this.setState({ valueCancel: e.target.value });

  typeRequestPayment = e => this.setState({ valueRequestPayment: e.target.value });

  typeRequestPaymentAmount = price => event => {
    let newPrice = event.target.value;
    if (/^\d+$/.test(newPrice)) {
      this.setState( prevState => ({
        valueRequestPaymentAmount: newPrice.slice(0,5),
      }));
    } else {
      this.setState({
        valueRequestPaymentAmount: ''
      });
    }
  };

  postMessage = () => {
    if(this.state.value && this.state.value.length > 0) {
      const copyMessage = this.state.value;
      var textMessage = {
        content: this.state.value,
        user_id: firebase.auth().currentUser.uid,
        created_at: firebase.database.ServerValue.TIMESTAMP,
      }

      this.chatRef.push().set(textMessage).then(() => {
        this.setState({
          value: '',
        });
        if(this.chatList && this.chatList.scrollHeight) {
          this.chatList.scrollTop = this.chatList.scrollHeight;
        }
      });

      // send message to other person
      var sendToUID = firebase.auth().currentUser.uid === this.state.reservation.guest_id? this.state.reservation.host_id: this.state.reservation.guest_id;
      var public_name = '';
      var private_name = firebase.auth().currentUser.displayName;
      if(private_name.split().length > 1) {
        public_name = `${private_name.split(' ')[0]} ${private_name.split(' ')[1].charAt(0)}.`;
      } else {
        public_name = private_name.split(' ')[0];
      }

      const isDevMode = process.env.NODE_ENV === "development" && process.env.NODE_ENV !== "production"? true: false;

      axios({
          method: 'POST',
          url: isDevMode? "https://e8g5sdljpa.execute-api.us-east-1.amazonaws.com/default/DEV_notifyNewMessage": "https://iq8yj7gvc8.execute-api.us-east-1.amazonaws.com/default/notifyNewMessage",
          headers: {
            "x-api-key": isDevMode? "7Le38yEuy8a02f9yS7Hbp7nI3SVBqMABaG7WsABn": "2c5TIAhliL5iMcrOyVAUB1AX3Mr7Yn5s9D4G1gnY"
          },
          data: {
            sendToUID: sendToUID,
            reservation: this.state.reservation_id,
            message: copyMessage,
            username: public_name,
          }
        }).catch(error => {
          // Error occured accessing lambda function
        });
    }
  };

  approveReservation = () => {
    // get real address from snapshot
    firebase.database().ref('/users/' + firebase.auth().currentUser.uid + '/garages/' + this.state.garage.data().garage_key).once('value').then((snapshot) => {
      if(snapshot.val()) {
        // firestore -> need to update reservation with real address and update the reservation status
        var addressInfo = snapshot.val().address;
        const reservationRef = dbFireStore.firestore().collection("reservations").doc(this.state.reservation_id);
        reservationRef.update({
          host_approved: true,
          location: addressInfo,
          lastUpdated: firebase.firestore.FieldValue.serverTimestamp()
        }).then(() => {
          // realtime -> need to update the chat with the approval and the real address
          // create the message and add extra parameters
          var textMessage = {
            content: 'This reservation has been confirmed!',
            location: addressInfo,
            type: 'confirm',
            user_id: firebase.auth().currentUser.uid,
            created_at: firebase.database.ServerValue.TIMESTAMP,
          }
          this.chatRef.push().set(textMessage).then(() => {
            if(this.chatList && this.chatList.scrollHeight) {
              this.chatList.scrollTop = this.chatList.scrollHeight;
            }
          });
          
          const isDevMode = process.env.NODE_ENV === "development" && process.env.NODE_ENV !== "production"? true: false;

          axios({
            method: 'POST',
            url: isDevMode? "https://jm654u4e27.execute-api.us-east-1.amazonaws.com/default/DEV_notifyGuestOfApproval": "https://0fxcotfhyg.execute-api.us-east-1.amazonaws.com/default/notifyGuestOfApproval",
            headers: {
              "x-api-key": isDevMode? "6aM02AzO0Q8zbwoUDAX097r8im305H3N1ryLuImo": "3xGrU04nFQ7IEqywNWg0S3IQdPTZInVi8Wy4OAQ0"
            },
            data: {
              guestUID: this.state.reservation.guest_id,
              reservation: this.state.reservation_id,
              street_one: addressInfo.street_one,
              street_two: addressInfo.street_two? addressInfo.street_two: '',
              city: addressInfo.city,
              state: addressInfo.state,
              zip: addressInfo.zip,
              check_in: `${moment(this.state.reservation.start_date.toDate()).format('MMMM Do YYYY')}`,
              check_out: `${moment(this.state.reservation.end_date.toDate()).format('MMMM Do YYYY')}`,
            }
          })
          .catch(error => {
            // Error occured accessing lambda function
          });

        }).catch((error) => {
          // most likely because the reservation has expired!
          console.log('Expired reservation');
        });
      } else {
        // couldn't find the garage to update?
      }
    });
  };

  askForDeny = () => {
    this.setState(prevState => ({
      wantsToDeny: !prevState.wantsToDeny,
    }));
  };

  denyReservation = () => {
    if(this.state.valueDeny && this.state.valueDeny.length > 0) {
      // need to start issue refund for guest
      this.setState({loadingDenyReservation: true});
      var user = firebase.auth().currentUser;
      var firstName = '';
      var lastName = '';
      if(user.displayName && user.displayName.split().length > 1) {
        firstName = `${user.displayName.split(' ')[0]}`;
        lastName = `${user.displayName.split(' ')[1]}`;
      } else {
        firstName = `${user.displayName.split(' ')[0]}`;
        lastName = '';
      }
      var reservation = this.state.reservation;
      var reservation_id = this.state.reservation_id;

      var now = moment(new Date());
      var startDate = moment(reservation.start_date.toDate().setHours(0,0,0,0));
      var duration = moment.duration(startDate.diff(now));
      var untilStartTime = duration.asHours();
      var refundPercentageHostCancelled = untilStartTime > 0? "100%": untilStartTime < 0 && untilStartTime >= -24? "80%": "Requires Admin Approval";

      var refundTotalHostCancelled = refundPercentageHostCancelled === "100%"? formatCurrencyUSD(reservation.rental_subtotal): refundPercentageHostCancelled === "80%"? formatCurrencyUSD(reservation.rental_subtotal * 0.8):"$0.00";

      this.userProfileRef = firebase.database().ref('users/' + firebase.auth().currentUser.uid + '/profile/');
      const reservationRef = dbFireStore.firestore().collection("reservations").doc(this.state.reservation_id);

      const refundInfo = `
        Denied by: HOST
        Host Id: ${reservation.host_id}
        Guest Id: ${reservation.guest_id}

        ——————————————————————————
        Reservation Info:
        ——————————————————————————
        
        Id: ${reservation_id}
        Automated Denied?: ${reservation.automated_denied? reservation.automated_denied: false}
        Automated Denied On Date: ${reservation.automated_denied_at? moment(reservation.automated_denied_at).toDate().format("MM/DD/YYYY @ h:mm a"): '-'}
        Conversation Id: ${reservation.conversation_id? reservation.conversation_id: 'N/A'}
        Completed Reservation?: ${reservation.completed_reservation? reservation.completed_reservation: false}
        Garage Id: ${reservation.garage_id}
        Is Storage: ${reservation.is_storage}
        Is Workshop: ${reservation.is_workshop}
        Reservation Paid for?: ${reservation.paid}
        Rental Daily Rate: ${formatCurrencyUSD(reservation.daily_rate)}
        Rental Service Fee: ${formatCurrencyUSD(reservation.rental_servicefee/100)}
        Rental Subtotal: ${formatCurrencyUSD(reservation.rental_subtotal)}
        Rental Total: ${formatCurrencyUSD(reservation.rental_total)}

        Is this a check Payout Reservation?: ${reservation.is_check_payout? reservation.is_check_payout: false}
        If check payout, what's it payout status?: ${reservation.payout_status? reservation.payout_status: 'No Status Set'}
        Any Support Tickets Associated? ${reservation.support_ticket_id? reservation.support_ticket_id: 'N/A'}

        Host Approved?: ${reservation.host_approved? reservation.host_approved: false}
        Host Denied?: True
        Host Canceled?: False
        Guest Canceled?: False

        Start Date: ${moment(reservation.start_date.toDate()).format("MM/DD/YYYY")}
        End Date: ${moment(reservation.end_date.toDate()).format("MM/DD/YYYY")}

        Location:
        ${reservation.location && reservation.location.street_one? reservation.location.street_one: 'N/A'}
        ${reservation.location && reservation.location.street_two? reservation.location.street_two: ''}
        ${reservation.location && reservation.location.city? `${reservation.location.city},`: ''} ${reservation.location && reservation.location.state? reservation.location.state: ''}, ${reservation.location && reservation.location.zip? reservation.location.zip: ''}

        Last Updated at:
        ${moment(reservation.lastUpdated? reservation.lastUpdated.toDate(): new Date()).format("MM/DD/YYYY @ h:mm a")}

        ——————————————————————————
        Host denied guests rerservation before 48hour expiration, with reasoning:
        ${this.state.valueDeny}
        
        ——————————————————————————
        Hours until reservation start? ${untilStartTime}
        Percentage to refund (from subtotal): ${refundPercentageHostCancelled}
        Total Refunded: ${refundTotalHostCancelled}
      `;

      this.userProfileRef.once("value").then(profile => {
        axios({
          method: 'post',
          url: "https://gme7c3orka.execute-api.us-east-1.amazonaws.com/default/refundUser",
          headers: {
            "x-api-key": "TEr7ShjeeL4dFe1LHXXUd3uGN5LmHaJ35HGieRae"
          },
          data: {
            createdBy: user.uid,
            createdByEmail: user.email,
            createdByFirstName: firstName,
            createdByLastName: lastName,
            customerStripeId: profile.val().customerId? profile.val().customerId: "No CustomerStripeID Found",
            hostStripeId: profile.val().host_stripe_user_id? profile.val().host_stripe_user_id: "No HostStripeID Found",
            isCheckPayout: reservation.is_check_payout,
            typeOfRefund: 'denied_host',
            reservationId: reservation_id,
            refundInfo: refundInfo,
            createdAt: new Date().toISOString().split('.')[0]+"Z"
          }
        }).then(response => {
          reservationRef.update({
            host_denied: true,
            host_denied_support_id: response.data.msg,
            lastUpdated: firebase.firestore.FieldValue.serverTimestamp()
          }).then(() => {
            // realtime -> need to update the chat with the approval and the real address
            // create the message and add extra parameters
            var textMessage = {
              content: this.state.valueDeny,
              type: 'denied',
              user_id: firebase.auth().currentUser.uid,
              created_at: firebase.database.ServerValue.TIMESTAMP,
            };

            this.chatRef.push().set(textMessage).then(() => {
              if(this.chatList && this.chatList.scrollHeight) {
                this.chatList.scrollTop = this.chatList.scrollHeight;
              }
              this.setState({valueDeny: '', loadingDenyReservation: false});
              this.askForDeny();
            });
            // send email to guest that their reservation was denied

            const isDevMode = process.env.NODE_ENV === "development" && process.env.NODE_ENV !== "production"? true: false;

            axios({
              method: 'POST',
              url: isDevMode? "https://6wzxcc1h3i.execute-api.us-east-1.amazonaws.com/default/DEV_notifyGuestOfDenied": "https://0zkwlv63g8.execute-api.us-east-1.amazonaws.com/default/notifyGuestOfDenied",
              headers: {
                "x-api-key": isDevMode? "8Fxxsn61bWa2QFUrTHpVT1DGkMowaG8t63HOhasq": "AWPDv6RKqb5VDGv3PIoWu3Px42Yvi9JI2dwOOZIl"
              },
              data: {
                guestUID: this.state.reservation.guest_id,
                reservation: this.state.reservation_id,
              }
            })
            .catch(error => {
              // Error occured accessing lambda function
            });
          }).catch((error) => {
            // most likely because the reservation has expired!
            console.log('Expired reservation');
            this.setState({loadingDenyReservation: false});
          });
        }).catch(error => {
          this.setState({loadingDenyReservation: false});
        });
      }).catch(error => {
        this.setState({loadingDenyReservation: false});
      });

    } else {
      this.props.handleError({
        message: "Please enter a reason for denying the reservation."
      });
      this.setState({loadingDenyReservation: false});
    }
  };

  handleRequestChange = (event) => {
    this.setState({requestType: event.target.value});
  };

  cancelReservation = () => {
  
    const { 
      reservation, 
      reservation_id,
      valueCancel 
    } = this.state;

    this.setState({loadingCancelReservation: true});
    
    const reservationRef = dbFireStore.firestore().collection("reservations").doc(reservation_id);
    const firestoreTimestamp = firebase.firestore.FieldValue.serverTimestamp();
    const firebaseTimestamp = firebase.database.ServerValue.TIMESTAMP;

    var user = firebase.auth().currentUser;
    var firstName = '';
    var lastName = '';
    if(user.displayName && user.displayName.split().length > 1) {
      firstName = `${user.displayName.split(' ')[0]}`;
      lastName = `${user.displayName.split(' ')[1]}`;
    } else {
      firstName = `${user.displayName.split(' ')[0]}`;
      lastName = '';
    }

    var now = moment(new Date());
    var startDate = moment(reservation.start_date.toDate().setHours(0,0,0,0));
    var duration = moment.duration(startDate.diff(now));
    var untilStartTime = duration.asHours();

    var refundPercentageHostCancelled = untilStartTime > 0? "100%": untilStartTime < 0 && untilStartTime >= -24? "80%": "Requires Admin Approval";
    var refundPercentageGuestCancelled = untilStartTime >= 72? "100%": untilStartTime < 72 && untilStartTime >= 24? "80%": untilStartTime < 24 && untilStartTime >= 0? "70%": "Requires Admin Approval";

    var refundTotalHostCancelled = refundPercentageHostCancelled === "100%"? formatCurrencyUSD(reservation.rental_subtotal): refundPercentageHostCancelled === "80%"? formatCurrencyUSD(reservation.rental_subtotal * 0.8):"$0.00";
    var refundTotalGuestCancelled = refundPercentageGuestCancelled === "100%"? formatCurrencyUSD(reservation.rental_subtotal): refundPercentageGuestCancelled === "80%"? formatCurrencyUSD(reservation.rental_subtotal * 0.8): refundPercentageGuestCancelled === "70%"? formatCurrencyUSD(reservation.rental_subtotal * 0.7): "$0.00";

    if(firebase.auth().currentUser.uid === reservation.host_id) {

      // The host has canceled the Reservation, refund the guest
      this.userProfileRef = firebase.database().ref('users/' + firebase.auth().currentUser.uid + '/profile/');

      const refundInfo = `
        Canceled by: HOST
        Host Id: ${reservation.host_id}
        Guest Id: ${reservation.guest_id}

        ——————————————————————————
        Reservation Info:
        ——————————————————————————
        
        Id: ${reservation_id}
        Automated Denied?: ${reservation.automated_denied? reservation.automated_denied: false}
        Automated Denied On Date: ${reservation.automated_denied_at? moment(reservation.automated_denied_at).toDate().format("MM/DD/YYYY @ h:mm a"): '-'}
        Conversation Id: ${reservation.conversation_id? reservation.conversation_id: 'N/A'}
        Completed Reservation?: ${reservation.completed_reservation? reservation.completed_reservation: false}
        Garage Id: ${reservation.garage_id}
        Is Storage: ${reservation.is_storage}
        Is Workshop: ${reservation.is_workshop}
        Reservation Paid for?: ${reservation.paid}
        Rental Daily Rate: ${formatCurrencyUSD(reservation.daily_rate)}
        Rental Service Fee: ${formatCurrencyUSD(reservation.rental_servicefee/100)}
        Rental Subtotal: ${formatCurrencyUSD(reservation.rental_subtotal)}
        Rental Total: ${formatCurrencyUSD(reservation.rental_total)}

        Is this a check Payout Reservation?: ${reservation.is_check_payout? reservation.is_check_payout: false}
        If check payout, what's it payout status?: ${reservation.payout_status? reservation.payout_status: 'No Status Set'}
        Any Support Tickets Associated? ${reservation.support_ticket_id? reservation.support_ticket_id: 'N/A'}

        Host Approved?: ${reservation.host_approved? reservation.host_approved: false}
        Host Denied?: ${reservation.host_denied? reservation.host_denied: false}
        Host Canceled?: True
        Guest Canceled?: False

        Start Date: ${moment(reservation.start_date.toDate()).format("MM/DD/YYYY")}
        End Date: ${moment(reservation.end_date.toDate()).format("MM/DD/YYYY")}

        Location:
        ${reservation.location && reservation.location.street_one? reservation.location.street_one: 'N/A'}
        ${reservation.location && reservation.location.street_two? reservation.location.street_two: ''}
        ${reservation.location && reservation.location.city? `${reservation.location.city},`: ''} ${reservation.location && reservation.location.state? reservation.location.state: ''}, ${reservation.location && reservation.location.zip? reservation.location.zip: ''}

        Last Updated at:
        ${moment(reservation.lastUpdated? reservation.lastUpdated.toDate(): new Date()).format("MM/DD/YYYY @ h:mm a")}
        
        ——————————————————————————
        Reason for canceling:
        ${valueCancel}
        
        ——————————————————————————
        Hours until reservation start? ${untilStartTime}
        Percentage to refund (from subtotal): ${refundPercentageHostCancelled}
        Total Refunded: ${refundTotalHostCancelled}
      `;

      this.userProfileRef.once("value").then(profile => {
        axios({
          method: 'post',
          url: "https://gme7c3orka.execute-api.us-east-1.amazonaws.com/default/refundUser",
          headers: {
            "x-api-key": "TEr7ShjeeL4dFe1LHXXUd3uGN5LmHaJ35HGieRae"
          },
          data: {
            createdBy: user.uid,
            createdByEmail: user.email,
            createdByFirstName: firstName,
            createdByLastName: lastName,
            customerStripeId: profile.val().customerId? profile.val().customerId: "No CustomerStripeID Found",
            hostStripeId: profile.val().host_stripe_user_id? profile.val().host_stripe_user_id: "No HostStripeID Found",
            isCheckPayout: reservation.is_check_payout,
            typeOfRefund: 'canceled_host',
            reservationId: reservation_id,
            refundInfo: refundInfo,
            createdAt: new Date().toISOString().split('.')[0]+"Z"
          }
        }).then(response => {
          // update reservation status
          reservationRef.update({
            host_canceled: true,
            host_canceled_support_id: response.data.msg,
            lastUpdated: firestoreTimestamp
          }).then(() => {
            // update reservation chat log
            var textMessage = {
              content: valueCancel,
              type: 'canceled_host',
              user_id: firebase.auth().currentUser.uid,
              created_at: firebaseTimestamp,
            }
            this.chatRef.push().set(textMessage).then(() => {
              if(this.chatList && this.chatList.scrollHeight) {
                this.chatList.scrollTop = this.chatList.scrollHeight;
              }
              this.setState({valueCancel: '', loadingCancelReservation: false});
              this.askToCancel();
            });

            const isDevMode = process.env.NODE_ENV === "development" && process.env.NODE_ENV !== "production"? true: false;

            // send email to host and guest that their reservation was cancelled
            axios({
              method: 'POST',
              url: isDevMode? "https://607aaa30wi.execute-api.us-east-1.amazonaws.com/default/DEV_notifyGuestAndHostOfReservationCancelled": "https://gp5efzbqcf.execute-api.us-east-1.amazonaws.com/default/notifyGuestAndHostOfReservationCancelled",
              headers: {
                "x-api-key": isDevMode? "8q0ETCvKORaQms51Yjeny45xR7Ufu7hv1Kff51rB": "N2nJPuaV5x5PhwF7WDtT27rW2fsbUaWva4HizSpQ"
              },
              data: {
                guestUID: this.state.reservation.guest_id,
                hostUID: this.state.reservation.host_id,
                reservation: this.state.reservation_id,
              }
            }).catch(error => {
              // Error occured accessing lambda function
              console.log('Error has occured please try again');
              this.setState({loadingCancelReservation: false});
            });

          });

        }).catch(error => {
          console.log('Error has occured please try again');
          this.setState({loadingCancelReservation: false});
        });

      }).catch(error => {
        console.log('Error has occured please try again');
        this.setState({loadingCancelReservation: false});
      });

    } else if (firebase.auth().currentUser.uid === reservation.guest_id) {
      // The guest cancelled refund them
      this.userProfileRef = firebase.database().ref('users/' + firebase.auth().currentUser.uid + '/profile/');
      
      const refundInfo = `
        Canceled by: GUEST
        Host Id: ${reservation.host_id}
        Guest Id: ${reservation.guest_id}

        ——————————————————————————
        Reservation Info:
        ——————————————————————————
        
        Id: ${reservation_id}
        Automated Denied?: ${reservation.automated_denied? reservation.automated_denied: false}
        Automated Denied On Date: ${reservation.automated_denied_at? moment(reservation.automated_denied_at).toDate().format("MM/DD/YYYY @ h:mm a"): '-'}
        Conversation Id: ${reservation.conversation_id? reservation.conversation_id: 'N/A'}
        Completed Reservation?: ${reservation.completed_reservation? reservation.completed_reservation: false}
        Garage Id: ${reservation.garage_id}
        Is Storage: ${reservation.is_storage}
        Is Workshop: ${reservation.is_workshop}
        Reservation Paid for?: ${reservation.paid}
        Rental Daily Rate: ${formatCurrencyUSD(reservation.daily_rate)}
        Rental Service Fee: ${formatCurrencyUSD(reservation.rental_servicefee/100)}
        Rental Subtotal: ${formatCurrencyUSD(reservation.rental_subtotal)}
        Rental Total: ${formatCurrencyUSD(reservation.rental_total)}

        Is this a check Payout Reservation?: ${reservation.is_check_payout? reservation.is_check_payout: false}
        If check payout, what's it payout status?: ${reservation.payout_status? reservation.payout_status: 'No Status Set'}
        Any Support Tickets Associated? ${reservation.support_ticket_id? reservation.support_ticket_id: 'N/A'}

        Host Approved?: ${reservation.host_approved? reservation.host_approved: false}
        Host Denied?: ${reservation.host_denied? reservation.host_denied: false}
        Host Canceled?: False
        Guest Canceled?: True

        Start Date: ${moment(reservation.start_date.toDate()).format("MM/DD/YYYY")}
        End Date: ${moment(reservation.end_date.toDate()).format("MM/DD/YYYY")}

        Location:
        ${reservation.location && reservation.location.street_one? reservation.location.street_one: 'N/A'}
        ${reservation.location && reservation.location.street_two? reservation.location.street_two: ''}
        ${reservation.location && reservation.location.city? `${reservation.location.city},`: ''} ${reservation.location && reservation.location.state? reservation.location.state: ''}, ${reservation.location && reservation.location.zip? reservation.location.zip: ''}

        Last Updated at:
        ${moment(reservation.lastUpdated? reservation.lastUpdated.toDate(): new Date()).format("MM/DD/YYYY @ h:mm a")}


        ——————————————————————————
        Reason for canceling:
        ${valueCancel}
        
        ——————————————————————————
        Hours until reservation start? ${untilStartTime}
        Percentage to refund (from subtotal): ${refundPercentageGuestCancelled}
        Total Refunded: ${refundTotalGuestCancelled}
      `;

      this.userProfileRef.once("value").then(profile => {
        axios({
          method: 'post',
          url: "https://gme7c3orka.execute-api.us-east-1.amazonaws.com/default/refundUser",
          headers: {
            "x-api-key": "TEr7ShjeeL4dFe1LHXXUd3uGN5LmHaJ35HGieRae"
          },
          data: {
            createdBy: user.uid,
            createdByEmail: user.email,
            createdByFirstName: firstName,
            createdByLastName: lastName,
            customerStripeId: profile.val().customerId? profile.val().customerId: "No CustomerStripeID Found",
            hostStripeId: "N/A",
            isCheckPayout: reservation.is_check_payout,
            typeOfRefund: 'canceled_guest',
            reservationId: reservation_id,
            refundInfo: refundInfo,
            createdAt: new Date().toISOString().split('.')[0]+"Z"
          }
        }).then(response => {
          reservationRef.update({
            guest_canceled: true,
            guest_canceled_support_id: response.data.msg,
            lastUpdated: firestoreTimestamp
          }).then(() => {
            // update chat
            var textMessage = {
              content: valueCancel,
              type: 'canceled_guest',
              user_id: firebase.auth().currentUser.uid,
              created_at: firebaseTimestamp,
            }
            this.chatRef.push().set(textMessage).then(() => {
              if(this.chatList && this.chatList.scrollHeight) {
                this.chatList.scrollTop = this.chatList.scrollHeight;
              }
              this.setState({valueCancel: '', loadingCancelReservation: false});
              this.askToCancel();
            });

            // send email to host and guest that their reservation was cancelled
            const isDevMode = process.env.NODE_ENV === "development" && process.env.NODE_ENV !== "production"? true: false;
            axios({
              method: 'POST',
              url: isDevMode? "https://607aaa30wi.execute-api.us-east-1.amazonaws.com/default/DEV_notifyGuestAndHostOfReservationCancelled": "https://gp5efzbqcf.execute-api.us-east-1.amazonaws.com/default/notifyGuestAndHostOfReservationCancelled",
              headers: {
                "x-api-key": isDevMode? "8q0ETCvKORaQms51Yjeny45xR7Ufu7hv1Kff51rB": "N2nJPuaV5x5PhwF7WDtT27rW2fsbUaWva4HizSpQ"
              },
              data: {
                guestUID: this.state.reservation.guest_id,
                hostUID: this.state.reservation.host_id,
                reservation: this.state.reservation_id,
              }
            }).catch(error => {
              // Error occured accessing lambda function
              console.log('Error has occured please try again');
              this.setState({loadingCancelReservation: false});
            });

          });
        }).catch(error => {
          console.log('Error has occured please try again');
          this.setState({loadingCancelReservation: false});
        });

      }).catch(error => {
        console.log('Error has occured please try again');
        this.setState({loadingCancelReservation: false});
      });

    };
  };

  askToCancel = () => {
    this.setState(prevState => ({
      wantsToCancel: !prevState.wantsToCancel,
    }));
  };

  passRating = (rating) => {
    this.setState({rating: rating});
  };

  sendRequestPayment = () => {
    // This is on the host side
    //1. Write a record that there is a payment request to this reservation, this is a sub collection of payments for the reservation doc
    var garage = this.state.garage.data();
    this.setState({loadingChargeRequest: true});
    if(this.state.valueRequestPayment && this.state.valueRequestPaymentAmount && this.state.valueRequestPaymentAmount > 0 && this.state.requestType && garage) {
      const reservationRef = dbFireStore.firestore().collection("reservations").doc(this.state.reservation_id);
      const firestoreTimestamp = firebase.firestore.FieldValue.serverTimestamp();
      reservationRef.collection("payment_requests").add({
        timestamp: firestoreTimestamp,
        paid: false,
        request_by: 'host',
        host_id: firebase.auth().currentUser.uid,
        guest_id: this.state.reservation.guest_id ,
        pay_to_user_id: this.state.reservation.host_id,
        pay_by_user_id: this.state.reservation.guest_id,
        destination_charge: garage.destination_charge? garage.destination_charge: "",
        is_check_payout: this.state.reservation.is_check_payout? true: false,
        request_type: this.state.requestType,
        payment_amount: this.state.valueRequestPaymentAmount,
        comment: this.state.valueRequestPayment,
      }).then((docRef) => {
        //2. Add a chat message that there is a payment request
        if(this.state.tabValue !== 1) {
          this.setState({tabValue: 1});
          if(this.chatList && this.chatList.scrollHeight) {
            this.chatList.scrollTop = this.chatList.scrollHeight;
          }
        }
        var textMessage = {
          content: 'Payment Request',
          amount: this.state.valueRequestPaymentAmount,
          payment_comment: this.state.valueRequestPayment,
          request_type: this.state.requestType,
          type: 'payment_request',
          user_id: firebase.auth().currentUser.uid,
          created_at: firebase.database.ServerValue.TIMESTAMP,
          payment_request_id: docRef.id
        }

        this.chatRef.push().set(textMessage).then(() => {
          if(this.chatList && this.chatList.scrollHeight) {
            this.chatList.scrollTop = this.chatList.scrollHeight;
          }
        });

        //3. send email to guest that there is a payment request
        var sendToUID = this.state.reservation.guest_id;
        var public_name = '';
        var private_name = firebase.auth().currentUser.displayName;
        if(private_name.split().length > 1) {
          public_name = `${private_name.split(' ')[0]} ${private_name.split(' ')[1].charAt(0)}.`;
        } else {
          public_name = private_name.split(' ')[0];
        }

        var emailMessage = `[${this.state.requestType}] Payment Requested for $${this.numberWithCommas(this.state.valueRequestPaymentAmount)}. [Request Details] - ${this.state.valueRequestPayment}`;
        const isDevMode = process.env.NODE_ENV === "development" && process.env.NODE_ENV !== "production"? true: false;
        axios({
          method: 'POST',
          url: isDevMode? "https://e8g5sdljpa.execute-api.us-east-1.amazonaws.com/default/DEV_notifyNewMessage": "https://iq8yj7gvc8.execute-api.us-east-1.amazonaws.com/default/notifyNewMessage",
          headers: {
            "x-api-key": isDevMode? "7Le38yEuy8a02f9yS7Hbp7nI3SVBqMABaG7WsABn": "2c5TIAhliL5iMcrOyVAUB1AX3Mr7Yn5s9D4G1gnY"
          },
          data: {
            sendToUID: sendToUID,
            reservation: this.state.reservation_id,
            message: emailMessage,
            username: public_name,
          }
        }).catch(error => {
          // Error occured accessing lambda function
          this.setState({loadingChargeRequest: false});
          this.requestMoneyToggle();
        });

      });
    } else {
      this.setState({loadingChargeRequest: false});
      if(!this.state.valueRequestPaymentAmount) {
        this.props.handleError({
          message: "Please enter the amount requesting."
        });
      } else if(!this.state.valueRequestPayment) {
        this.props.handleError({
          message: "Please let the guest know the details of your payment request."
        });
      } else {
        this.props.handleError({
          message: "Sorry, there was an error, please check over your form before submitting."
        });
      }
    }
  };

  payRequestFormToggle = (payment_info) => {
    if(this.state.payRequestFormDialogShow) {
      // clear state
      this.setState({
        paymentInfo: null,
        payRequestFormDialogShow: false,
        paymentTotals: null,
        paymentSuccessful: false,
      });
    } else {
      var requestedAmount = Math.round(100 * parseFloat(payment_info.amount));
      var serviceFee = Math.round(100 * parseFloat(payment_info.amount) * .145);
      var finalAmount = requestedAmount + serviceFee;
      this.setState({
        paymentInfo: payment_info,
        payRequestFormDialogShow: true,
        paymentTotals: {
          requestedAmount: requestedAmount,
          serviceFee: serviceFee,
          finalAmount: finalAmount,
        },
      });
    }
  };

  submitPaymentForPaymentRequest = () => {
    this.setState({
      processingPayment: true,
    });

    // need to get the document before processing the payment
    dbFireStore.firestore()
      .collection("reservations")
      .doc(this.state.reservation_id)
      .collection("payment_requests")
      .doc(this.state.paymentInfo.payment_request_id)
      .get()
      .then((querySnapshot) => {
        if(querySnapshot.data()) {
          var payment_request = querySnapshot.data();
          var request_id = querySnapshot.id;
          var reservation_id = this.state.reservation_id;
          var is_check_payout = payment_request.is_check_payout? true: false;
          var destination_charge = is_check_payout? "":payment_request.destination_charge;
          var pay_by_user_id = payment_request.pay_by_user_id;
          var pay_to_user_id = payment_request.pay_to_user_id;
          var request_type = payment_request.request_type;
          var requested_amount = this.state.paymentTotals.requestedAmount;
          var service_fee = this.state.paymentTotals.serviceFee;
          var final_amount = this.state.paymentTotals.finalAmount;
          var default_payment = this.state.customerData.default_source;
          var customer_id = this.state.customerData.id;

          var dataInfo = {
            request_id: request_id,
            reservation_id: reservation_id,
            destination_charge: destination_charge,
            pay_by_user_id: pay_by_user_id,
            pay_to_user_id: pay_to_user_id,
            request_type: request_type,
            requested_amount: requested_amount,
            service_fee: service_fee,
            final_amount: final_amount,
            default_payment: default_payment,
            customer_id: customer_id,
            is_check_payout: payment_request.is_check_payout? "true":"false",
            payment_from: "guest"
          }

          axios({
            method: 'POST',
            url: isDevMode? 'https://r2bkcjfvrc.execute-api.us-east-1.amazonaws.com/default/DEV_chargeAndUpdatePaymentRequest': 'https://b66qv4bl04.execute-api.us-east-1.amazonaws.com/default/chargeAndUpdatePaymentRequest',
            headers: {
              "x-api-key": isDevMode? 'oNdRw8REEW3TxZHAYI4qW8UOrxlbr8da9LEIDMTY': "DrbgMwQfzu2H6soF5OabC2p1gB75riwS3lfQMKSZ"
            },
            data: dataInfo,
          }).then(response => {
            const success = response.data.success;
            if(success) {
              // update chat feed locally
              var newObject = {};
              newObject[request_id] = {};
              newObject[request_id].paid = true;
              newObject[request_id].paid_on = moment(new Date()).format("MM/DD/YYYY @ h:mm a");
              
              // tell user payment was succesful
              this.setState(prevState => ({
                processingPayment: false,
                paymentSuccessful: true,
                paymentRequestList: {...prevState.paymentRequestList, ...newObject}
              }));
              // email host of payment

              var sendToUID = this.state.reservation.host_id;
              var public_name = '';
              var private_name = firebase.auth().currentUser.displayName;
              if(private_name.split().length > 1) {
                public_name = `${private_name.split(' ')[0]} ${private_name.split(' ')[1].charAt(0)}.`;
              } else {
                public_name = private_name.split(' ')[0];
              }

              var emailMessage = `${public_name} has paid your request of ${formatCurrencyUSD(this.state.paymentTotals.requestedAmount/100)}.`;
              const isDevMode = process.env.NODE_ENV === "development" && process.env.NODE_ENV !== "production"? true: false;
              axios({
                method: 'POST',
                url: isDevMode? "https://e8g5sdljpa.execute-api.us-east-1.amazonaws.com/default/DEV_notifyNewMessage": "https://iq8yj7gvc8.execute-api.us-east-1.amazonaws.com/default/notifyNewMessage",
                headers: {
                  "x-api-key": isDevMode? "7Le38yEuy8a02f9yS7Hbp7nI3SVBqMABaG7WsABn": "2c5TIAhliL5iMcrOyVAUB1AX3Mr7Yn5s9D4G1gnY"
                },
                data: {
                  sendToUID: sendToUID,
                  reservation: this.state.reservation_id,
                  message: emailMessage,
                  username: public_name,
                }
              }).catch(error => {
                // Error occured accessing lambda function
                console.log("Notification Error");
              });


            } else {
              console.log("Stripe charge customer account error");
              this.props.handleError({
                message: "We were unable to complete the payment. Please try again."
              });
              this.setState({
                processingPayment: false,
              });
            }
          }).catch(error => {
            console.log(error);
            this.props.handleError({
              message: "We were unable to complete the payment. Please try again."
            });
            this.setState({
              processingPayment: false,
            });
          });
        }

      });
  };

  numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  render() {
    const { reservation } = this.state;
    const viewers_message_id = firebase.auth().currentUser.uid;
    const offendingUserId = 
      reservation && firebase.auth().currentUser.uid === reservation.guest_id? reservation.host_id : 
      reservation && firebase.auth().currentUser.uid === reservation.host_id? reservation.guest_id : 
      null;
    const offendingUserPublicId = null;
    var defaultSourceId = this.state.customerData? this.state.customerData.default_source: null;
    var savedCardInfo = defaultSourceId? this.state.customerData.sources.data.filter((card) => card.id === defaultSourceId)[0]: null;
    var createModeStatus = this.state.createModeToggle || !savedCardInfo;

    return (
      <div className={`with-navbar-wrap reservation-show ${this.state.userType && this.state.userType == 'host'? 'host-mode': 'guest'}`}>
        <div className="dashboard-container reservation-show">
          <Dialog
            open={this.state.confirmDelete}
            onClose={this.deleteCardConfirmToggle}
            className={"confirm-delete-dialog"}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle id="alert-dialog-title">Confirm Delete</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to delete your payment information?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.deleteCardConfirmToggle} color="primary">
                Cancel
              </Button>
              <Button className="delete-card-btn" onClick={this.deletePaymentInfo} color="primary" autoFocus>
                Delete Card
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={this.state.payRequestFormDialogShow}
            keepMounted={this.state.paymentSuccessful? false:true}
            disableEscapeKeyDown={this.state.paymentSuccessful? false:true}
            disableBackdropClick={this.state.paymentSuccessful? false:true}
            onClose={this.payRequestFormToggle}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle style={{textAlign: this.state.paymentSuccessful? "center": 'left' }}>
              {this.state.paymentSuccessful?
              `Payment Successful #${this.state.paymentInfo && this.state.paymentInfo.payment_request_id? this.state.paymentInfo.payment_request_id.slice(0,9).toUpperCase(): ''}`:
              `Pay Request ${this.state.paymentInfo && this.state.paymentInfo.payment_request_id? `#${this.state.paymentInfo.payment_request_id.slice(0,9).toUpperCase()}`: ''}`
              }
            </DialogTitle>
              {this.state.paymentSuccessful?
              <div className="payment-successful-info">
                <div className="paid-invoice-hero">
                  <PaidInvoice />
                </div>
                <div className="paid-invoice-actions">
                  <div className="paid-invoice-actions-container">
                  <Link to="/billing">
                    <Button className="payment-success-back-btn to-billing">View Billing</Button>
                  </Link>
                  <Button className="payment-success-back-btn" onClick={this.payRequestFormToggle}>Close</Button>
                  </div>
                </div>
              </div>:this.state.processingPayment?
                <div className="processing-payment-send">
                  <div className="payment-loader-wrap">
                  <div className="payment-loader-container processing">
                    <CircularProgress style={{width: 30, height: 30, color: "#06c55b"}}/>
                  </div>
                  <Typography className="processing-payment-text">Processing Payment</Typography>
                </div>
                </div>
             :<DialogContent className="payment-form-container" >
              {this.state.paymentInfo?
                <React.Fragment>
                <Typography className="request-info-label invoice-info">{`Request Info: ${this.state.paymentInfo.payment_comment}`}</Typography>
                <Typography className="request-info-label time-label with-form">{`Requested at: ${moment(this.state.paymentInfo.created_at).format("MM/DD/YYYY @ h:mm a")}`}</Typography>
                <div className="user-cards-data">
                  {this.state.customerData && !this.state.customerData.default_source?
                    <div className="no-garages-yet">
                    <Typography>💳 Add your payment info below to reserve this space!</Typography></div>: null
                  }
                  {savedCardInfo?
                    <div className="default-card-source">
                      {this.state.deletingCard?
                        <div className="deleting-card-loader">
                          <div className="loading-stripe-card-from">
                          <div className="loader-container-delete">
                          <CircularProgress className="stripe-form-loader"/>
                           <Typography variant="caption">Deleting Payment Info</Typography>
                          </div>
                          </div>
                        </div>:null
                      }
                      <Typography variant="h3" className="payment-info-title">
                      Pay using this payment information
                      </Typography>
                      <div className="saved-card-info">
                        <div className={`card-brand-logo ${savedCardInfo.brand}`}>
                        </div>
                        <div className="card-four">
                        <span>Last 4</span>
                        {savedCardInfo.last4}
                        </div>
                        <div className="card-four">
                          <span>exp</span>
                          {`${savedCardInfo.exp_month}/${savedCardInfo.exp_year.toString().slice(-2)}`}
                        </div>
                        <div className="saved-card-actions">
                          {!this.state.createModeToggle?
                            <Button className="update-card-btn" onClick={this.toggleUpdateCardInfo}>
                              Update
                            </Button>:<Button className="update-card-btn with-cancel" onClick={this.toggleUpdateCardInfo}>
                              Cancel Update
                            </Button>
                          }
                          <Button className="delete-card-btn" onClick={this.deleteCardConfirmToggle}>
                            Delete
                          </Button>
                        </div>
                      </div>
                    </div>: null
                  }
                </div>
                <div className="add-payment-info-form">
                  {this.props.stripeLoaded && !this.state.loadingPayment?
                    <Elements>
                      <AddPaymentForm
                        loading={this.state.loadingPayment}
                        handleError={this.props.handleError}
                        customerId={this.state.customerData? this.state.customerData.id: null}
                        updateCustomerInfo={this.updateCustomerInfo}
                        createMode={createModeStatus}
                        createModeToggleOff={this.createModeToggleOff}
                        paymentTitle={"Pay using this payment method"}
                      />
                    </Elements>:
                    <div className="loading-stripe-card-from">
                    <CircularProgress className="stripe-form-loader"/>
                     <Typography variant="caption">Loading Payment Information</Typography>
                    </div>
                  }
                </div>
                <div className="payment-request-total-container">
                  {this.state.paymentTotals?
                    <div className="payment-info-breakdown">
                      <div className="total-table">

                        <div className="total-row">
                          <div className="info-col">
                            <Typography variant="caption">Subtotal:</Typography>
                            <div className="tooltip-help-icon">
                              <HtmlTooltip
                                placement="left"
                                title={
                                  <React.Fragment>
                                    <Typography color="inherit" variant="body2">Requested amount from host</Typography>
                                  </React.Fragment>
                                }
                              >
                                <HelpIcon />
                              </HtmlTooltip>
                            </div>

                          </div>
                          <div className="amount-col">
                            <Typography variant="caption">{formatCurrencyUSD(this.state.paymentTotals.requestedAmount/100)}</Typography>
                          </div>
                        </div>

                        <div className="total-row">
                          <div className="info-col">
                            <Typography variant="caption">Service Fee:</Typography>
                            <div className="tooltip-help-icon">
                              <HtmlTooltip
                                placement="left"
                                title={
                                  <React.Fragment>
                                    <Typography color="inherit" variant="body2">This helps our platform provide you 24/7 support and a safe renting environment.</Typography>
                                  </React.Fragment>
                                }
                              >
                                <HelpIcon />
                              </HtmlTooltip>
                            </div>
                          </div>
                          <div className="amount-col">
                            <Typography variant="caption">{formatCurrencyUSD(this.state.paymentTotals.serviceFee/100)}</Typography>
                          </div>
                        </div>

                        <div className="total-row final-amount">
                          <div className="info-col">
                            <Typography variant="caption">Total:</Typography>
                            <div className="tooltip-help-icon">
                              <HtmlTooltip
                                placement="left"
                                title={
                                  <React.Fragment>
                                    <Typography color="inherit" variant="body2">This is your subtotal plus our service fee.</Typography>
                                  </React.Fragment>
                                }
                              >
                                <HelpIcon />
                              </HtmlTooltip>
                            </div>
                          </div>
                          <div className="amount-col">
                            <Typography variant="caption">{formatCurrencyUSD(this.state.paymentTotals.finalAmount/100)}</Typography>
                          </div>
                        </div>
                      </div>
                    </div>:null
                  }
                </div>

                </React.Fragment>:
                <div className="payment-loader-wrap">
                  <div className="payment-loader-container">
                    <CircularProgress style={{width: 30, height: 30, color: "#ddd"}}/>
                  </div>
                </div>
              }
              <div className="pay-request-actions">
                <Button className="cancel-payment-btn" onClick={this.payRequestFormToggle}>Cancel</Button>
                {this.state.paymentInfo && this.props.stripeLoaded && savedCardInfo && this.state.customerData?
                  <Button className="submit-payment-request-btn" onClick={this.submitPaymentForPaymentRequest} startIcon={<AttachMoney />}>Submit Payment</Button>:null}
              </div>
            </DialogContent>}
          </Dialog>
          <Dialog
            open={this.state.requestMoneyDialogShow}
            keepMounted
            disableEscapeKeyDown={this.state.loadingChargeRequest? true: false}
            disableBackdropClick={this.state.loadingChargeRequest? true: false}
            onClose={this.requestMoneyToggle}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle>
            Request Guest Payment
            </DialogTitle>
             <DialogContent>
              <DialogContentText>
                Please select the reason for your request. Add a message to inform your guest of the details of your payment request.
              </DialogContentText>
              <div className="payment-type-top-row">
                <div className="col-left">
                <InputLabel htmlFor="request-amount-input" className="amount-label">Amount</InputLabel> 
                <TextField
                  id="request-amount-input"
                  className="request-amount-text-input"
                  placeholder={"0"}
                  margin="normal"
                  disabled={this.state.loadingChargeRequest}
                  variant="outlined"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                  value={this.state.valueRequestPaymentAmount}
                  onChange={this.typeRequestPaymentAmount()}
                />
                </div>
                <div className="col-right">
                <InputLabel htmlFor="request-select-label" className="request-select-label">Type of Request</InputLabel> 
                <Select
                  labelId="request-select-label"
                  id="request-type-select"
                  disabled={this.state.loadingChargeRequest}
                  className="select-type-of-payment"
                  value={this.state.requestType}
                  onChange={this.handleRequestChange}
                >
                  <MenuItem value={"General Request"}>General Request</MenuItem>
                  <MenuItem value={"Labor Service"}>Labor Service</MenuItem>
                  <MenuItem value={"Tool Rental"}>Tool Rental</MenuItem>
                  <MenuItem value={"Material Usage"}>Material Usage</MenuItem>
                  <MenuItem value={"Disposal Service"}>Disposal Service</MenuItem>
                  <MenuItem value={"Property Damage"}>Property Damage</MenuItem>
                </Select>
                </div>
              </div>
              <TextField
                id="request-input"
                className={'request-texting-input'}
                placeholder={"Enter your request details here"}
                margin="normal"
                multiline
                disabled={this.state.loadingChargeRequest}
                variant="outlined"
                value={this.state.valueRequestPayment}
                onChange={this.typeRequestPayment}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={this.requestMoneyToggle} disabled={this.state.loadingChargeRequest} className="cancel-delete">
                Cancel
              </Button>
              <Button
                onClick={this.sendRequestPayment}
                disabled={this.state.loadingChargeRequest}
                startIcon={this.state.loadingChargeRequest? <CircularProgress style={{height: 15, width: 15, color: "#2cda61"}} className="loading-payment-request" />:<AttachMoney />}
                className={this.state.loadingChargeRequest? "send-payment-request-btn loading-request": "send-payment-request-btn"}>
                {this.state.loadingChargeRequest?
                  "Sending...":"Send Request Payment"
                }
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={this.state.wantsToCancel}
            keepMounted
            onClose={this.askToCancel}
            disableEscapeKeyDown={this.state.loadingCancelReservation? true: false}
            disableBackdropClick={this.state.loadingCancelReservation? true: false}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle>
            Cancel Reservation?
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure you want to cancel this reservation? This cannot be undone.
              </DialogContentText>
              <Typography className="deny-label">
                *Please enter your reason for canceling this reservation.
              </Typography>
              <TextField
                id="deny-input"
                className={'deny-texting-input'}
                placeholder={"Sorry there was a mistake!"}
                margin="normal"
                ref={(ref) => this.cancelInput = ref}
                multiline
                variant="outlined"
                value={this.state.valueCancel}
                disabled={this.state.loadingCancelReservation}
                onChange={this.typeMessageCancel}
              />
            </DialogContent>
            <DialogActions>
              <Button
                disabled={this.state.loadingCancelReservation}
                onClick={this.askToCancel}
                className="cancel-delete">
                Nevermind
              </Button>
              <Button
                disabled={this.state.loadingCancelReservation}
                onClick={this.cancelReservation}
                startIcon={this.state.loadingCancelReservation? <CircularProgress style={{height: 15, width: 15, color: "#ff008b"}} className="loading-payment-request" />:null}
                className={this.state.loadingCancelReservation? "delete-forever-btn loading-delete-request": "delete-forever-btn"}
                >
                {this.state.loadingCancelReservation?
                  "Canceling...": "Cancel Reservation"
                }
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={this.state.wantsToDeny}
            keepMounted
            onClose={this.askForDeny}
            disableEscapeKeyDown={this.state.loadingDenyReservation? true: false}
            disableBackdropClick={this.state.loadingDenyReservation? true: false}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle>
            Deny Reservation?
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure you want to deny this reservation? This cannot be undone and the guest will be refunded.
              </DialogContentText>
              <Typography className="deny-label">
                *Please enter your reason for denying this reservation.
              </Typography>
              <TextField
                id="deny-input"
                className={'deny-texting-input'}
                placeholder={"Sorry we are overbooked, please try these other dates!"}
                margin="normal"
                ref={(ref) => this.denyInput = ref}
                multiline
                variant="outlined"
                disabled={this.state.loadingDenyReservation}
                value={this.state.valueDeny}
                onChange={this.typeMessageDeny}
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={this.askForDeny}
                disabled={this.state.loadingDenyReservation}
                className="cancel-delete">
                Cancel
              </Button>
              <Button
                onClick={this.denyReservation}
                disabled={this.state.loadingDenyReservation}
                startIcon={this.state.loadingDenyReservation? <CircularProgress style={{height: 15, width: 15, color: "#ff008b"}} className="loading-payment-request" />:null}
                className={this.state.loadingDenyReservation? "delete-forever-btn loading-delete-request": "delete-forever-btn"}>
                {this.state.loadingDenyReservation?
                  "Declining...": "Deny Reservation"
                }
              </Button>
            </DialogActions>
          </Dialog>
          <ReportUser
            reportUser={this.props.reportUser}
            wantsToReport={this.props.wantsToReport}
            askToReport={this.props.askToReport}
            valueReport={this.props.valueReport}
            typeMessageReport={this.props.typeMessageReport}
            reported={this.props.reported}
            reportedId={this.props.reportedId}
            loadingReport={this.props.loadingReport}
            typeOfReport={this.state.userType && this.state.userType == 'host'? "reported_by_host": "reported_by_guest"}
            offendingUserId={offendingUserId}
            offendingUserPublicId={offendingUserPublicId}
          />
          {this.state.userType && this.state.userType == 'host'?
            <div className="hosting-mode-title"><Typography>
            <MenuBookIcon/>Hosting Mode</Typography></div>:null
          }
          <div className="reservation-card">
            <div className="col-left">
              <div className="reservation-card-info">
                <ConfirmationNumberOutlinedIcon className="ticket-info"/>
                <Typography variant="h5" component="h3">
                  {this.state.userType === 'host'?
                    'Reservation Info':
                    'Your Reservation Info'
                  }
                </Typography>
                <div className="garage-photo" style={this.state.garagePhotoURL? {"backgroundImage": `url(${this.state.garagePhotoURL})`}:null} >
                </div>
                {this.state.reservation?
                  <div className="reservation-actions-and-info">
                    <div className="status">
                      {this.state.reservation.host_canceled || this.state.reservation.guest_canceled?
                        <div className="reservation-status canceled">
                          <Typography>
                            {`${this.state.reservation.host_canceled? 'Host Canceled': "Guest Canceled"}`}
                          </Typography>
                        </div>:
                        <React.Fragment>
                         {this.state.reservation.completed_reservation?
                          <div className="reservation-status completed">
                            <Typography>
                            Completed
                            </Typography>
                          </div>
                      :<React.Fragment>
                      {this.state.reservation.host_approved?
                        <div className="reservation-status approved">
                          <Typography>
                          Approved
                          </Typography>
                        </div>:
                        <React.Fragment>
                          {this.state.reservation.host_denied?
                            <div className="reservation-status denied">
                              <Typography>
                                Host Denied Request
                              </Typography>
                            </div>:
                            <div className={`${this.state.userType == 'host'? "reservation-status pending": "reservation-status pending guest"}`}>
                              <Typography>
                              {`Pending ${this.state.userType == 'host'? 'Your': 'Host'} Approval`}
                              </Typography>
                            </div>
                          }
                        </React.Fragment>
                      }
                      </React.Fragment>
                    }
                     </React.Fragment>
                    }
                    </div>
                    <div className="checkin-checkout-row">
                      <div className="start-date">
                        <span><Typography>Check In</Typography></span>
                        <Typography>
                        {`${moment(this.state.reservation.start_date.toDate()).format('MMMM Do YYYY')}`}
                        </Typography>
                      </div>
                      <div className="end-date">
                        <span><Typography>Check Out</Typography></span>
                        <Typography>
                        {`${moment(this.state.reservation.end_date.toDate()).format('MMMM Do YYYY')}`}
                        </Typography>
                      </div>
                    </div>
                    <React.Fragment>
                      <div className={`type-of-rental-row`}>
                        <Typography>
                          <span>Reservation Type</span>
                        </Typography>
                      <div className='float-row'>
                      {this.state.reservation.is_workshop?
                        <div className={'Workshop type-label'} ><Typography>Workshop</Typography></div>:null
                      }
                      {this.state.reservation.is_storage?
                        <div className={'Storage type-label'} ><Typography>Storage</Typography></div>:null
                      }
                      </div>
                      </div>
                      {this.state.userType == 'host'?
                      <div className="reservation-price-row host">
                       <Typography>
                       <span>Guest Paid</span>
                       {formatCurrencyUSD(this.state.reservation.rental_total)}
                        </Typography>
                      </div>:null}
                      {this.state.userType == 'guest'?
                      <div className="reservation-price-row guest">
                         <Typography>
                         <span>You Paid</span>
                        {formatCurrencyUSD(this.state.reservation.rental_total)}
                         </Typography>
                      </div>:null}
                    </React.Fragment>
                    {this.state.reservation && this.state.reservation.host_denied || this.state.reservation && this.state.reservation.host_canceled || this.state.reservation && this.state.reservation.guest_canceled || this.state.reservation && this.state.reservation.completed_reservation?
                      null:
                      <div className="reservation-actions">
                        {this.state.userType == 'host'?
                          <React.Fragment>
                            {this.state.reservation.host_approved?
                              <React.Fragment>
                                <Button startIcon={<BlockIcon />} className="dash-btn cancel-btn" onClick={this.askToCancel }> Cancel Reservation</Button>
                                <Button onClick={this.props.askToReport} className="dash-btn cancel-btn report-btn">Report Guest</Button>
                              </React.Fragment>:
                              <React.Fragment>
                                <Button size="large" startIcon={<ThumbUpAltRoundedIcon />} className="dash-btn approve-btn" onClick={this.approveReservation}>Approve Reservation</Button>
                                <Button size="large" startIcon={<ThumbDownRoundedIcon />} className="dash-btn deny-btn" onClick={this.askForDeny}>Deny Reservation</Button>
                                <Button size="large" className="dash-btn cancel-btn report-btn" onClick={this.props.askToReport}>Report Guest</Button>
                              </React.Fragment>
                            }
                          </React.Fragment>:
                          <React.Fragment>
                            <Button startIcon={<BlockIcon />} onClick={this.askToCancel} className="dash-btn cancel-btn">Cancel Reservation</Button>
                            <Button onClick={this.props.askToReport} className="dash-btn cancel-btn report-btn">Report Host</Button>
                          </React.Fragment>
                        }
                      </div>
                    }
                  </div>:null
                }
              </div>
            </div>
            <div className={`col-right dashboard-notifications ${this.state.tabValue == 0? 'description-tab': 'chat-tab'}`}>
              <AppBar position="static" color="default" className="edit-tabs-app-bar">
                <EditTabs
                  value={this.state.tabValue}
                  onChange={this.handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs example"
                >
                  <EditTab label="Reservation Info" {...a11yProps(0)} />
                  <EditTab label="Messages" {...a11yProps(1)} />
                  {this.state.reservation && this.state.reservation.completed_reservation?
                    <EditTab label={`${this.state.userType == 'host'? "Review Guest": "Review Host"}`} {...a11yProps(2)} />: null
                  }
                </EditTabs>
              </AppBar>
              <TabPanel value={this.state.tabValue} index={0}>
                <div className="reservation-info-panel">

                  <div className="mobile-reservation-info">
                    <div className="col-left mobile-inline-reservation-basic-info-header">
                      <div className="reservation-card-info">
                        <div className="garage-photo" style={this.state.garagePhotoURL? {"backgroundImage": `url(${this.state.garagePhotoURL})`}:null} >
                        </div>
                        {this.state.reservation?
                          <div className="reservation-actions-and-info">
                            <div className="status">
                              {this.state.reservation.host_canceled || this.state.reservation.guest_canceled?
                                <div className="reservation-status canceled">
                                  <Typography>
                                    {`${this.state.reservation.host_canceled? 'Host Canceled': "Guest Canceled"}`}
                                  </Typography>
                                </div>:
                                <React.Fragment>
                               {this.state.reservation.completed_reservation?
                                <div className="reservation-status completed">
                                  <Typography>
                                  Completed
                                  </Typography>
                                </div>
                              :<React.Fragment>
                                {this.state.reservation.host_approved?
                                  <div className="reservation-status approved">
                                    <Typography>
                                    Approved
                                    </Typography>
                                  </div>:
                                  <React.Fragment>
                                    {this.state.reservation.host_denied?
                                      <div className="reservation-status denied">
                                        <Typography>
                                          Host Denied Request
                                        </Typography>
                                      </div>:
                                      <div className={`${this.state.userType == 'host'? "reservation-status pending": "reservation-status pending guest"}`}>
                                        <Typography>
                                        {`Pending ${this.state.userType == 'host'? 'Your': 'Host'} Approval`}
                                        </Typography>
                                      </div>
                                    }
                                  </React.Fragment>
                                }
                                </React.Fragment>
                              }
                              </React.Fragment>
                            }
                            </div>
                            <div className="checkin-checkout-row">
                              <div className="start-date">
                                <span><Typography>Check In</Typography></span>
                                <Typography>
                                {`${moment(this.state.reservation.start_date.toDate()).format('MMMM Do YYYY')}`}
                                </Typography>
                              </div>
                              <div className="end-date">
                                <span><Typography>Check Out</Typography></span>
                                <Typography>
                                {`${moment(this.state.reservation.end_date.toDate()).format('MMMM Do YYYY')}`}
                                </Typography>
                              </div>
                            </div>
                            <React.Fragment>
                              <div className={`type-of-rental-row`}>
                                <Typography>
                                  <span>Reservation Type</span>
                                </Typography>
                              <div className='float-row'>
                              {this.state.reservation.is_workshop?
                                <div className={'Workshop type-label'} ><Typography>Workshop</Typography></div>:null
                              }
                              {this.state.reservation.is_storage?
                                <div className={'Storage type-label'} ><Typography>Storage</Typography></div>:null
                              }
                              </div>
                              </div>
                            </React.Fragment>
                          </div>:null
                        }
                      </div>
                    </div>
                  </div>
                  <div className="mobile-price">
                    {this.state.userType == 'host'?
                      <div className="reservation-price-row host">
                       <Typography>
                       <span>Guest Paid</span>
                       {formatCurrencyUSD(this.state.reservation.rental_total)}
                        </Typography>
                      </div>:null}
                      {this.state.userType == 'guest'?
                      <div className="reservation-price-row guest">
                         <Typography>
                         <span>You Paid</span>
                        {formatCurrencyUSD(this.state.reservation.rental_total)}
                         </Typography>
                      </div>:null}
                  </div>

                  {this.state.userType === 'host' && this.state.reservation?
                    <div className='reservation-infobox host-info'>
                      {this.state.reservation.host_canceled || this.state.reservation.guest_canceled?
                        <React.Fragment>
                          {this.state.reservation.guest_canceled?
                            <React.Fragment>
                            <div className="pending-reservation-show-banner canceled"></div>
                              <Typography variant="h5">
                                The guest canceled this reservation
                              </Typography>
                              <Typography>
                               This guest will receive a refund based on the MotorRoof refund polices.
                              </Typography>
                              <Button className="reservation-action-info-btn" onClick={(e) => this.handleChange(e,1)}>View Past Messages with Guest</Button>
                              {this.state.guestData?
                                <div className="about-the-host">
                                  <Typography className="host-info-title">Guest Info</Typography>
                                  <div className="host-content">
                                  <div className="about-host-user-icon" style={this.state.guestPhoto? {"backgroundImage": `url(${this.state.guestPhoto})`}: null}></div>
                                  <Typography>
                                    {this.state.guestData.display_name? this.state.guestData.display_name: ''}
                                  </Typography>
                                  </div>
                                </div>:null
                              }
                             </React.Fragment>:
                             <React.Fragment>
                              <div className="pending-reservation-show-banner canceled"></div>
                                <Typography variant="h5">
                                  You canceled this reservation
                                </Typography>
                                <Typography>
                                 The guest will receive a refund based on the MotorRoof refund polices.
                                </Typography>
                                <Button className="reservation-action-info-btn" onClick={(e) => this.handleChange(e,1)}>View Past Messages with Guest</Button>
                                {this.state.guestData?
                                  <div className="about-the-host">
                                    <Typography className="host-info-title">Guest Info</Typography>
                                    <div className="host-content">
                                    <div className="about-host-user-icon"  style={this.state.guestPhoto? {"backgroundImage": `url(${this.state.guestPhoto})`}: null}></div>
                                    <Typography>
                                      {this.state.guestData.display_name? this.state.guestData.display_name: ''}
                                    </Typography>
                                    </div>
                                  </div>:null
                                }
                             </React.Fragment> 
                          }
                        </React.Fragment>:

                        <React.Fragment>
                        {this.state.reservation.completed_reservation?
                          <React.Fragment>
                          <div className="pending-reservation-show-banner approved"></div>
                          <Typography variant="h5">
                            📋 This reservation has ended. You can now publicly review your guest.
                          </Typography>
                          <Button className="reservation-action-info-btn" onClick={(e) => this.handleChange(e,2)}>Review Guest</Button>
                          </React.Fragment>
                        :<React.Fragment>

                          {this.state.reservation.host_approved?
                            <React.Fragment>
                              <div className="pending-reservation-show-banner approved"></div>
                              <Typography variant="h5">
                                🗓 Get your place ready! You have a guest coming!
                              </Typography>
                              <div className="res-approved-content">
                              <Typography>If you have additional instructions or would like to help your guest, please communicate here.
                              </Typography>
                              <Button className="reservation-action-info-btn" onClick={(e) => this.handleChange(e,1)}>Message Guest</Button>
                              </div>
                              <div className="reservation-info-approved">
                              <div className="reservation-show-where-col">
                              <Typography className="res-title" variant="h5">
                              <ExploreOutlinedIcon />
                                Your place
                              </Typography>
                              <div className="reservation-location-info">
                                <LocationOnIcon />
                                <Typography>
                                {this.state.reservation.location.street_one}
                                </Typography>
                                <Typography>
                                {this.state.reservation.street_two? this.state.reservation.street_two: ''}
                                </Typography>
                                <Typography>
                                {`${toTitleCase(this.state.reservation.location.city)} ${this.state.reservation.location.state}  ${this.state.reservation.location.zip}`}
                                </Typography>
                              </div>
                              <a target="_blank" href={`https://maps.google.com/?q=${this.state.reservation.location.street_one},${this.state.reservation.location.street_two? `${this.state.reservation.location.street_two},`: ''} ${toTitleCase(this.state.reservation.location.city)},${this.state.reservation.location.state},${this.state.reservation.location.zip}`} >
                                <Button className="res-show-location-btn">View in Google Maps</Button>
                              </a>
                              </div>
                              <div className="garage-access-hours-reservation">
                                <GarageReservationAccessHours garage={this.state.garage} />
                              </div>
                              <div className="res-host-info-col">
                                {this.state.guestData?
                                  <div className="about-the-host">
                                    <Typography className="res-title" variant="h5">
                                      <FaceOutlinedIcon />
                                      Your Guest
                                    </Typography>
                                    <div className="host-content">
                                    <div className="about-host-user-icon" style={this.state.guestPhoto? {"backgroundImage": `url(${this.state.guestPhoto})`}: null}></div>
                                    <Typography>
                                      {this.state.guestData.display_name? this.state.guestData.display_name: ''}
                                    </Typography>
                                    </div>
                                  </div>:null
                                }
                              </div>
                              </div>
                            </React.Fragment>:
                            <React.Fragment>
                              {this.state.reservation.host_denied?
                                <React.Fragment>
                                  <div className="pending-reservation-show-banner canceled"></div>
                                    <Typography variant="h5">
                                      🚫 You denied this reservation request
                                    </Typography>
                                    <Typography>
                                     This guest will receive a refund based on the MotorRoof refund polices.
                                    </Typography>
                                    <Button className="reservation-action-info-btn" onClick={(e) => this.handleChange(e,1)}>View Past Messages with Guest</Button>
                                    {this.state.guestData?
                                      <div className="about-the-host">
                                        <Typography className="host-info-title">Guest Info</Typography>
                                        <div className="host-content">
                                        <div className="about-host-user-icon" style={this.state.guestPhoto? {"backgroundImage": `url(${this.state.guestPhoto})`}: null}></div>
                                        <Typography>
                                          {this.state.guestData.display_name? this.state.guestData.display_name: ''}
                                        </Typography>
                                        </div>
                                      </div>:null
                                    }
                                </React.Fragment>:
                                <React.Fragment>
                                  <div className="pending-reservation-show-banner"></div>
                                  <Typography variant="h5">
                                    ⌛️ This reservation requires approval from you to start!
                                  </Typography>
                                  <Typography>
                                    <b>If you do not respond within the next 48 hours, this reservation will be automatically declined, and the guest will be refunded.</b> Once approved, the guest will receive the address of the reservation.
                                  </Typography>
                                  <Button className="reservation-action-info-btn" onClick={(e) => this.handleChange(e,1)}>Send Message to Guest</Button>
                                  {this.state.guestData?
                                    <div className="about-the-host">
                                      <Typography className="host-info-title">Your Guest</Typography>
                                      <div className="host-content">
                                      <div className="about-host-user-icon" style={this.state.guestPhoto? {"backgroundImage": `url(${this.state.guestPhoto})`}: null}></div>
                                      <Typography>
                                        {this.state.guestData.display_name? this.state.guestData.display_name: ''}
                                      </Typography>
                                      </div>
                                    </div>:null}
                              </React.Fragment>}
                            </React.Fragment>}
                          </React.Fragment>}
                        </React.Fragment>}
                    </div>:null
                  }
                  {this.state.userType === 'guest' && this.state.reservation?
                    <div className='reservation-infobox guest-info'>
                      {this.state.reservation.host_canceled || this.state.reservation.guest_canceled?
                        <React.Fragment>
                          {this.state.reservation.guest_canceled?
                            <React.Fragment>
                            <div className="pending-reservation-show-banner canceled"></div>
                              <Typography variant="h5">
                                You canceled this reservation
                              </Typography>
                              <Typography>
                               You will receive a refund based on the MotorRoof refund polices.
                              </Typography>
                              <Button className="reservation-action-info-btn" onClick={(e) => this.handleChange(e,1)}>View Past Messages with Host</Button>
                              {this.state.hostData?
                                <div className="about-the-host">
                                  <Typography className="host-info-title">Host Info</Typography>
                                  <div className="host-content">
                                  <div className="about-host-user-icon" style={this.state.hostPhoto? {"backgroundImage": `url(${this.state.hostPhoto})`}: null}></div>
                                  <Typography>
                                    {this.state.hostData.display_name? this.state.hostData.display_name: ''}
                                  </Typography>
                                  </div>
                                </div>:null
                              }
                             </React.Fragment>:
                             <React.Fragment>
                              <div className="pending-reservation-show-banner canceled"></div>
                                <Typography variant="h5">
                                  The host canceled this reservation
                                </Typography>
                                <Typography>
                                 You will receive a refund based on the MotorRoof refund polices.
                                </Typography>
                                <Button className="reservation-action-info-btn" onClick={(e) => this.handleChange(e,1)}>View Past Messages with Host</Button>
                                {this.state.hostData?
                                  <div className="about-the-host">
                                    <Typography className="host-info-title">Host Info</Typography>
                                    <div className="host-content">
                                    <div className="about-host-user-icon"  style={this.state.hostPhoto? {"backgroundImage": `url(${this.state.hostPhoto})`}: null}></div>
                                    <Typography>
                                      {this.state.hostData.display_name? this.state.hostData.display_name: ''}
                                    </Typography>
                                    </div>
                                  </div>:null
                                }
                             </React.Fragment> 
                          }
                        </React.Fragment>:
                        <React.Fragment>
                        {this.state.reservation.completed_reservation?
                          <React.Fragment>
                          <div className="pending-reservation-show-banner approved"></div>
                          <Typography variant="h5">
                            📋 This reservation has ended. You can now publicly review your host.
                          </Typography>
                          <Button className="reservation-action-info-btn" onClick={(e) => this.handleChange(e,2)}>Review Host</Button>
                          </React.Fragment>
                        :<React.Fragment>
                          {this.state.reservation.host_approved?
                            <React.Fragment>
                              <div className="pending-reservation-show-banner approved"></div>
                              <Typography variant="h5">
                                🚗 Grab your keys! The host has approved your reservation!
                              </Typography>
                              <div className="res-approved-content">
                              <Typography>If you need help with your reservation please message your host here
                              </Typography>
                              <Button className="reservation-action-info-btn" onClick={(e) => this.handleChange(e,1)}>Message Host</Button>
                              </div>
                              <div className="reservation-info-approved">
                              <div className="reservation-show-where-col">
                              <Typography className="res-title" variant="h5">
                              <ExploreOutlinedIcon />
                                Where?
                              </Typography>
                              <div className="reservation-location-info">
                                <LocationOnIcon />
                                <Typography>
                                {this.state.reservation.location.street_one}
                                </Typography>
                                <Typography>
                                {this.state.reservation.location.street_two? this.state.reservation.location.street_two: ''}
                                </Typography>
                                <Typography>
                                {`${toTitleCase(this.state.reservation.location.city)} ${this.state.reservation.location.state}  ${this.state.reservation.location.zip}`}
                                </Typography>
                              </div>
                              <a target="_blank" href={`https://maps.google.com/?q=${this.state.reservation.location.street_one},${this.state.reservation.location.street_two? `${this.state.reservation.location.street_two},`: ''} ${toTitleCase(this.state.reservation.location.city)},${this.state.reservation.location.state},${this.state.reservation.location.zip}`} >
                                <Button className="res-show-location-btn">View in Google Maps</Button>
                              </a>
                              </div>
                              <div className="garage-access-hours-reservation">
                                <GarageReservationAccessHours garage={this.state.garage} />
                              </div>
                              <div className="res-host-info-col">
                                {this.state.hostData?
                                  <div className="about-the-host">
                                    <Typography className="res-title" variant="h5">
                                      <FaceOutlinedIcon />
                                      Your Host
                                    </Typography>
                                    <div className="host-content">
                                    <div className="about-host-user-icon" style={this.state.hostPhoto? {"backgroundImage": `url(${this.state.hostPhoto})`}: null}></div>
                                    <Typography>
                                      {this.state.hostData.display_name? this.state.hostData.display_name: ''}
                                    </Typography>
                                    </div>
                                  </div>:null
                                }
                              </div>
                              </div>
                            </React.Fragment>:
                            <React.Fragment>
                              {this.state.reservation.host_denied?
                                <React.Fragment>
                                  <React.Fragment>
                                  <div className="pending-reservation-show-banner canceled"></div>
                                    <Typography variant="h5">
                                      🚫 The host declined this reservation request
                                    </Typography>
                                    <Typography>
                                     You will receive a refund based on the MotorRoof refund polices. Please check your user billing page to track your refund. Refunds may take up to 1 or 2 days to show up on your account.
                                    </Typography>
                                    <Button className="reservation-action-info-btn" onClick={(e) => this.handleChange(e,1)}>View Past Messages with Guest</Button>
                                    <Link to="/billing"><Button className="reservation-action-info-btn billing">Check on Refund</Button></Link>
                                    {this.state.guestData?
                                      <div className="about-the-host">
                                        <Typography className="host-info-title">Guest Info</Typography>
                                        <div className="host-content">
                                        <div className="about-host-user-icon" style={this.state.guestPhoto? {"backgroundImage": `url(${this.state.guestPhoto})`}: null}></div>
                                        <Typography>
                                          {this.state.guestData.display_name? this.state.guestData.display_name: ''}
                                        </Typography>
                                        </div>
                                      </div>:null
                                    }
                                </React.Fragment>
                                </React.Fragment>:
                                <React.Fragment>
                                  <div className="pending-reservation-show-banner"></div>
                                  <Typography variant="h5">
                                    🎉 Your reservation was created!
                                  </Typography>
                                  <Typography>
                                    <b>The host is reviewing your request and have 48 hours to respond or before the reservation check in date.</b> Once approved, you will receive the address of the reservation. To get started, introduce yourself, or a friendly hello works also!
                                  </Typography>
                                  <Button className="reservation-action-info-btn" onClick={(e) => this.handleChange(e,1)}>Send Message to Host</Button>
                                  {this.state.hostData?
                                    <div className="about-the-host">
                                      <Typography className="host-info-title">Your Host</Typography>
                                      <div className="host-content">
                                      <div className="about-host-user-icon" style={this.state.hostPhoto? {"backgroundImage": `url(${this.state.hostPhoto})`}: null}></div>
                                      <Typography>
                                        {this.state.hostData.display_name? this.state.hostData.display_name: ''}
                                      </Typography>
                                      </div>
                                    </div>:null
                                  }
                                </React.Fragment>
                              }
                            </React.Fragment>
                          }
                        </React.Fragment>
                      }
                      </React.Fragment>
                      }
                    </div>:null
                  }
                  <div className="reservation-mobile-footer">
                    {this.state.reservation && this.state.reservation.host_denied || this.state.reservation && this.state.reservation.host_canceled || this.state.reservation && this.state.reservation.guest_canceled?
                      null:
                      <div className="reservation-actions">
                        {this.state.userType == 'host'?
                          <React.Fragment>
                            {this.state.reservation.host_approved?
                              <Button startIcon={<BlockIcon />} className="dash-btn cancel-btn" onClick={this.askToCancel }> Cancel Reservation</Button>:
                              <div className="fixed-actions-container-mobile">
                                <Button size="large" startIcon={<ThumbUpAltRoundedIcon />} className="dash-btn approve-btn" onClick={this.approveReservation}>Approve Reservation</Button>
                                <Button size="large" startIcon={<ThumbDownRoundedIcon />} className="dash-btn deny-btn" onClick={this.askForDeny}>Deny Reservation</Button>
                              </div>
                            }
                          </React.Fragment>:
                          <React.Fragment>
                            <Button startIcon={<BlockIcon />} onClick={this.askToCancel} className="dash-btn cancel-btn">Cancel Reservation</Button>
                            <Button onClick={this.props.askToReport} className="dash-btn cancel-btn report-btn-mobile">Report Host</Button>
                          </React.Fragment>
                        }
                      </div>
                    }
                  </div>
                </div>
              </TabPanel>
              <TabPanel value={this.state.tabValue} index={1}>
                <div className="chat-container" ref={(ref) => this.chatList = ref}>
                  {this.state.reservation && this.state.messages?
                    <React.Fragment>
                      {this.state.loadingMessages?
                        <div>
                          <CircularProgress className="main-loader"/>
                        </div>:
                        <React.Fragment>
                          {this.state.messages.map((message, index) => (
                            <div key={`msg-box-${index}`}>
                            {message[1].type && message[1].type === 'canceled_host' || message[1].type && message[1].type === 'canceled_guest'?
                            <div className="chat-system-message-wrap">
                              <div className="system-message">
                                <div className="system-message-content">
                                  <div className="system-message-title">
                                    <Typography>
                                      {`This reservation has been canceled by the ${message[1].type === 'canceled_host'? 'host': 'guest'}.`}
                                    </Typography>
                                  </div>
                                  <div className="reason-for-deny">
                                    <Typography variant="caption">
                                      {`The ${message[1].type === 'canceled_host'? "host's": "guest's"} reasoning was:`}
                                    </Typography>
                                    <Typography>
                                      "{message[1].content}"
                                    </Typography>
                                  </div>
                                </div>
                              </div>
                            </div>:
                            <React.Fragment>
                            {message[1].type && message[1].type === 'denied'?
                              <React.Fragment>
                                <div className="chat-system-message-wrap">
                                  <div className="system-message">
                                    <div className="system-message-content">
                                      <div className="system-message-title">
                                        <Typography>
                                          This reservation has been denied by the host.
                                        </Typography>
                                      </div>
                                      <div className="reason-for-deny">
                                        <Typography variant="caption">
                                          The host's reasoning was:
                                        </Typography>
                                        <Typography>
                                          "{message[1].content}"
                                        </Typography>
                                        <div className="denied-author">
                                          <div className="chat-user-icon denied-message"  style={this.state.hostPhoto? {"backgroundImage": `url(${this.state.hostPhoto})`}:null}></div>
                                          <Typography>
                                            {`${this.state.hostData && this.state.hostData.display_name? this.state.hostData.display_name: ''}`}
                                          </Typography>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </React.Fragment>:
                               <React.Fragment>
                                {message[1].type && message[1].type === 'payment_request'?
                                <React.Fragment>
                                    <div className={`chat-system-message-wrap payment-request-system ${!this.state.loadingPaymentRequests && this.state.paymentRequestList && this.state.paymentRequestList[message[1].payment_request_id] && this.state.paymentRequestList[message[1].payment_request_id].paid? "paid-invoice-msg": ""}`}>
                                      <div className="system-message">
                                        <div className="system-message-content">
                                            <React.Fragment>
                                              <div className="payment-requested-title">
                                                <div className="who-is-requesting-payment">
                                                  <div className="chat-user-icon" style={this.state.hostPhoto? {"backgroundImage": `url(${this.state.hostPhoto})`}: null}></div>
                                                  <Typography>
                                                  {!this.state.loadingPaymentRequests && this.state.paymentRequestList && this.state.paymentRequestList[message[1].payment_request_id] && this.state.paymentRequestList[message[1].payment_request_id].paid?
                                                    <React.Fragment>
                                                      {this.state.userType === 'host'?
                                                      "You were paid this request":
                                                      `${this.state.hostData && this.state.hostData.display_name? this.state.hostData.display_name: "Your host"} was paid for this request`}
                                                    </React.Fragment>:
                                                    <React.Fragment>
                                                      {this.state.userType === 'host'?
                                                      "You have requested payment":
                                                      `${this.state.hostData && this.state.hostData.display_name? this.state.hostData.display_name: "Your host"} is requesting payment`}
                                                    </React.Fragment>
                                                  }
                                      
                                                  </Typography>
                                                </div>
                                              <Typography variant="h2">
                                                <span>$</span>{this.numberWithCommas(message[1].amount)}
                                              </Typography>
                                              </div>
                                              <div className="payment-request-details">
                                                <div className="type-of-request-row">
                                                  <Typography>
                                                    {message[1].request_type}
                                                  </Typography>
                                                </div>
                                                <div className="request-info-row">
                                                <Typography className="request-info-label">
                                                  Request Info:
                                                </Typography>
                                                <Typography>
                                                  {message[1].payment_comment}
                                                </Typography>
                                                  <Typography className="request-info-label time-label">{`Requested at: ${moment(message[1].created_at).format("MM/DD/YYYY @ h:mm a")}`}</Typography>
                                                  <Typography className="request-info-label invoice-label">{`Invoice #: ${message[1].payment_request_id.slice(0,9).toUpperCase()}`}</Typography>
                                                </div>
                                                <div className="guest-actions-for-payment">
                                                  {this.state.loadingPaymentRequests?
                                                    <div className="loading-payment-request-info">
                                                      <div className="loader-info-container">
                                                        {this.state.paymentRequestsLoadingError?
                                                          <span className="reload-your-browser-error-payment">Error: Please reload your browser</span>:
                                                          <CircularProgress style={{width: 30, height: 30}} />
                                                        }
                                                      </div>
                                                    </div>:
                                                    <React.Fragment>
                                                      {this.state.paymentRequestsLoadingError?
                                                        <span className="reload-your-browser-error-payment">Error: Please reload your browser</span>:
                                                        this.state.paymentRequestList && this.state.paymentRequestList[message[1].payment_request_id] && this.state.paymentRequestList[message[1].payment_request_id].paid?
                                                        <div className="user-has-paid-invoice">
                                                          <Typography>{this.state.paymentRequestList && this.state.paymentRequestList[message[1].payment_request_id].paid_on? `Paid on: ${this.state.paymentRequestList[message[1].payment_request_id].paid_on}`: ""}</Typography>
                                                          
                                                          {this.state.userType === 'guest'?
                                                            <div className="button-center has-paid">
                                                              <Link to="/billing">
                                                                <Button className="pay-request-btn has-paid">View Billing</Button>
                                                              </Link>
                                                            </div>:null}

                                                        </div>:
                                                        this.state.userType == 'guest'?
                                                        <div className="button-center">
                                                          <Button className="pay-request-btn" onClick={() => this.payRequestFormToggle(message[1])} startIcon={<AttachMoney />}>Pay Request</Button>
                                                        </div>:<div className="outstanding-invoice-label">
                                                          <Typography>This request has not been paid yet</Typography>
                                                        </div>
                                                    }
                                                    </React.Fragment>
                                                  }
                                                </div>
                                              </div>
                                            </React.Fragment>
                                        </div>
                                      </div>
                                    </div>
                                </React.Fragment>:
                              <React.Fragment>
                                {message[1].type && message[1].type === 'confirm'?
                                  <React.Fragment>
                                    <div className="chat-system-message-wrap">
                                      <div className="system-message">
                                        <div className="system-message-content">
                                          <div className="system-message-title">
                                            <Typography>
                                              🎉 Your reservation has been confirmed!
                                            </Typography>
                                          </div>
                                          <div className="chat-address-container">
                                            <LocationOnIcon />
                                            <Typography>
                                            {message[1].location.street_one}
                                            </Typography>
                                            <Typography>
                                            {message[1].location.street_two}
                                            </Typography>
                                            <Typography>
                                            {`${toTitleCase(message[1].location.city)} ${message[1].location.state}  ${message[1].location.zip}`}
                                            </Typography>
                                          </div>
                                          <div className="system-message-actions">
                                            <a target="_blank" href={`https://maps.google.com/?q=${message[1].location.street_one},${message[1].location.street_two? `${message[1].location.street_two},`: ''} ${toTitleCase(message[1].location.city)},${message[1].location.state},${message[1].location.zip}`} >
                                              <Button>View in Google Maps</Button>
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </React.Fragment>:
                                  <div className={viewers_message_id === message[1].user_id? 'viewers-message chat-message-row': 'chat-message-row'} key={message[0]}>
                                    <Typography>{message[1].content}</Typography>
                                    {viewers_message_id === message[1].user_id?
                                      <div className="chat-user-icon viewer-icon" style={this.props.avatarPhotoURL? {"backgroundImage": `url(${this.props.avatarPhotoURL})`}:null} ></div>:
                                      <React.Fragment>
                                        {this.state.userType === 'host'?
                                          <div className="chat-user-icon" style={this.state.guestPhoto? {"backgroundImage": `url(${this.state.guestPhoto})`}: null}></div>:
                                          <div className="chat-user-icon" style={this.state.hostPhoto? {"backgroundImage": `url(${this.state.hostPhoto})`}: null}></div>
                                        }
                                      </React.Fragment>
                                    }
                                    <Typography className="chat-caption">
                                      {viewers_message_id === message[1].user_id? `You said at ${moment(message[1].created_at).format("h:mm a on MM/DD/YYYY")}`:
                                      <React.Fragment>
                                        {this.state.userType === 'host'?
                                          `${this.state.guestData && this.state.guestData.display_name? this.state.guestData.display_name: "The guest " } said at ${moment(message[1].created_at).format("h:mm a on MM/DD/YYYY")}`:
                                          `${this.state.hostData && this.state.hostData.display_name? this.state.hostData.display_name: "Your host "} said at ${moment(message[1].created_at).format("h:mm a on MM/DD/YYYY")}`
                                        }
                                      </React.Fragment>
                                    }
                                    </Typography>
                                  </div>
                                }
                            </React.Fragment>}
                            </React.Fragment>}
                            </React.Fragment>}
                            </div>
                          ))}
                        </React.Fragment>
                      }
                    </React.Fragment>:
                    <div className="chat-no-messages-yet">
                      <Typography>This is the beginning of your conversation. Why don't you say hello! 👋</Typography>
                    </div>
                  }
                </div>
                {this.state.reservation && this.state.reservation.host_denied || this.state.reservation && this.state.reservation.host_canceled || this.state.reservation && this.state.reservation.guest_canceled?
                  <div className="chat-form-box disabled">
                  </div>:
                  <div className="chat-form-box">

                     <TextField
                        id="texting-input"
                        className={this.state.userType === 'host'? 'texting-input host-double-buttons': 'texting-input'}
                        placeholder={"Enter Message"}
                        margin="normal"
                        ref={(ref) => this.chatInput = ref}
                        multiline
                        variant="outlined"
                        value={this.state.value}
                        onChange={this.typeMessage}
                      />
                      { this.state.userType === 'host'?
                        <div className="host-message-buttons">
                          <Button onClick={this.postMessage} endIcon={<SendIcon />}>Send</Button>
                          <Button onClick={this.requestMoneyToggle} className="request-money-button large-screen-btn" startIcon={<AttachMoney />}>Request</Button>
                          <Button onClick={this.requestMoneyToggle} className="request-money-button mobile-request-btn" ><AttachMoney /></Button>
                        </div>:
                        <Button onClick={this.postMessage} className="single-action-guest" endIcon={<SendIcon />}>Send</Button>
                      }
                  </div>
                }
              </TabPanel>
              <TabPanel value={this.state.tabValue} index={2}>
                <div className="review-user-block">
                {this.state.reservation?
                  <React.Fragment>

                  <WriteReview userType={this.state.userType} passRating={this.passRating} reservationId={this.state.reservation_id} handleError={this.props.handleError} rating={this.state.rating} of={this.state.userType === "host"? this.state.reservation.guest_id: this.state.reservation.host_id} />

                  </React.Fragment>:null
                }
                </div>
              </TabPanel>
            </div>
          </div>
        </div>
        {this.state.loading?
          <div className="app-loader-wrap dashboard-loader">
            <div className="loading-app">
              <CircularProgress className="main-loader"/>
            </div>
          </div>:null}
      </div>
    );
  }
};

export default Reservations;
