import React, { Component } from 'react'
import Footer from "../../components/Footer";
import Typography from "@material-ui/core/Typography";
import ReactMarkdown from 'react-markdown';

import privacyPath from './content/privacy_policy.md';
import termsPath from './content/terms_of_use.md';
import disclaimerPath from './content/disclaimer.md';

class Page extends Component {
  state = {
    content: null,
  }

  componentWillMount() {
    this.fetchPage(this.props.pageName);
  }

  fetchPage = (page) => {
     switch(page) {
      case "privacy_policy":
        fetch(privacyPath).then((response) => response.text()).then((text) => {
          this.setState({ content: text })
        });
        break;
      case "terms_of_use":
        fetch(termsPath).then((response) => response.text()).then((text) => {
          this.setState({ content: text })
        });
        break;
      case "disclaimer":
        fetch(disclaimerPath).then((response) => response.text()).then((text) => {
          this.setState({ content: text })
        });
        break;
      default:
        break;
    }
  }

  render() {
    return (
      <div className="with-navbar-wrap">
        <div className="dashboard-container pages">
          <ReactMarkdown source={this.state.content} />
        </div>
        <Footer />
      </div>
    )
  }
}

export default Page