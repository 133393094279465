import React, { useState } from 'react';
import firebase from 'firebase/app';
import { NavLink, Link, Redirect } from "react-router-dom";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import InputBase from "@material-ui/core/InputBase"; 
import SearchIcon from "@material-ui/icons/Search";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import LinearProgress from '@material-ui/core/LinearProgress';
import PhoneIcon from '@material-ui/icons/Phone';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import Divider from '@material-ui/core/Divider';

import FaceOutlinedIcon from '@material-ui/icons/FaceOutlined';
import ConfirmationNumberOutlinedIcon from '@material-ui/icons/ConfirmationNumberOutlined';
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import AccountBalanceWalletOutlinedIcon from '@material-ui/icons/AccountBalanceWalletOutlined';
import BusinessCenterOutlinedIcon from '@material-ui/icons/BusinessCenterOutlined';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import VpnKeyOutlinedIcon from '@material-ui/icons/VpnKeyOutlined';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';

import EmojiPeopleOutlinedIcon from '@material-ui/icons/EmojiPeopleOutlined';
import ContactSupportOutlinedIcon from '@material-ui/icons/ContactSupportOutlined';

import { ReactComponent as LocationSearchPin } from '../images/location-search-pin.svg';
import { ReactComponent as MotorRoofMenuIcon } from '../images/motor-roof-logo-menu.svg';
import { ReactComponent as FacebookIcon } from '../images/facebook.svg';
import { ReactComponent as InstagramIcon } from '../images/instagram.svg';
import { ReactComponent as TwitterIcon } from '../images/twitter.svg';
import { ReactComponent as YoutubeIcon } from '../images/youtube.svg';

import DropdownMenu from "./DropdownMenu";

import { ReactComponent as Logo } from '../images/motorroof-logo.svg';
import "../styles/navbar.scss";

const axios = require('axios');

const MAPBOX_TOKEN = 'pk.eyJ1IjoibW90b3Jyb29mIiwiYSI6ImNqeWJhdXJlbTA3ZXgzZHA1aTY1MHp1OXYifQ.gzAtdjOpRs5jdFvWgf93vw';

const ListItemLink = (props) => {
  return <ListItem button component="a" {...props} />;
}

class Navbar extends React.Component {
  state = {
    search: '',
    autocompleteSearch: '',
    autocomplete: null,
    searching: false,
    keySelectIndex: null,
    mobileMenuOpen: false,
  };

  componentDidMount() {
    this.searchingPlace = null;
  };

  setAutocompleteSearch = e => {
    this.setState({
      autocompleteSearch: e,
    });
  };

  setSearching = b => {
    this.setState({
      searching: b,
    });
  };

  setSearchLink = s => {
    this.setState(prevState => ({
      search: s,
    }));
  };

  setAutocompleteSearchResults = results => {
    if(results) {
      this.setState({
        autocomplete: results,
      });
    } else {
      // being cleared need to reset our dropdown index selected
      this.setState({
        autocomplete: null,
        keySelectIndex: null,
      });
    }
  };

  handleChange = e => {
    // we set the time so we don't run a bunch of geoqueries if the user is in the middle of typing!
    // so this should run at the max rate of every 2s
    if(e.target.value && e.target.value.length > 0) {
      clearTimeout(this.searchingPlace);
      this.setAutocompleteSearch(e.target.value);
      this.setSearching(true);
      this.searchingPlace = setTimeout(() => {
        this.performGeoSearch();
      }, 1000);
      this.setSearchLink(encodeURIComponent(e.target.value));
    } else {
      this.setSearching(false);
      clearTimeout(this.searchingPlace);
      this.setSearchLink('');
      this.setAutocompleteSearch('');
      this.setAutocompleteSearchResults('');
      this.setState({keySelectIndex: null});
    }
  };

  performGeoSearch = () => {
    let searchQuery = encodeURI(`${this.state.autocompleteSearch}`);
    axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${searchQuery}.json?country=US&autocomplete=true&fuzzyMatch=true&types=place&access_token=${MAPBOX_TOKEN}`)
      .then((response) => {
        console.log(response.data.features);
        if(response.data.features.length > 0) {
          var locationRecords = [];
          response.data.features.forEach((place, index) => {
            var name = place.place_name.replace(', United States','');
            var long = place.geometry.coordinates[0];
            var lat = place.geometry.coordinates[1];
            locationRecords.push({index: index, name: name, longitude: long, latitude: lat});
            if(response.data.features.length == index + 1) {
              this.setAutocompleteSearchResults(locationRecords);
              this.setSearching(false);
            }
          });
        } else {
          // no results
          this.setAutocompleteSearchResults(locationRecords);
          this.setSearching(false);
        }
      })
      .catch((error) => {
        console.log('Could not find location');
        this.setSearching(false);
        this.setAutocompleteSearchResults(null);
      });
  }

  sendSearch = (event,type, i) => {
    if(type == 'auto') {
      event.preventDefault();
      if(this.state.keySelectIndex !== null) {
        // selected from dropdown
        this.setAutocompleteSearch(this.state.autocomplete[this.state.keySelectIndex].name);
        var searchLinkParams = `${encodeURIComponent(this.state.autocomplete[this.state.keySelectIndex].name)}?lat=${this.state.autocomplete[this.state.keySelectIndex].latitude}&long=${this.state.autocomplete[this.state.keySelectIndex].longitude}`;
      } else {
        // selected from topform
        var searchLinkParams = `${this.state.search}`;
      }
    } else {
      this.setAutocompleteSearch(this.state.autocomplete[i].name);
      var searchLinkParams = `${encodeURIComponent(this.state.autocomplete[i].name)}?lat=${this.state.autocomplete[i].latitude}&long=${this.state.autocomplete[i].longitude}`;
    }
    this.setAutocompleteSearchResults(null);
    this.setSearchLink(searchLinkParams);
    // then perform search direct
    clearTimeout(this.searchingPlace);
    this.setSearching(false);
    this.setAutocompleteSearchResults(null);
    this.closeMobileMenu();
    this.props.redirectToReferrerSearch(`/search/${searchLinkParams}`);
  }

  closeMobileMenu = () => {
    this.setState({
      mobileMenuOpen: false,
    });
  };

  onKeyPress = event => {
    if(this.state.autocomplete && this.state.autocomplete.length > 0 && !this.state.searching) {
      if(event.key == "ArrowDown") {
        event.preventDefault();
        if(this.state.keySelectIndex === null || this.state.keySelectIndex + 1 === this.state.autocomplete.length) {
          //first key down pressed OR at the end of list so go back to top
          this.setState({
            keySelectIndex: 0,
          });
        } else {
          // go down a record
          this.setState(prevState => ({
            keySelectIndex: prevState.keySelectIndex + 1,
          }));
        }
      } else if (event.key == "ArrowUp") {
        event.preventDefault();
        if(this.state.keySelectIndex === 0) {
          //first key down pressed OR at the end of list so go back to top
          this.setState(prevState => ({
            keySelectIndex: prevState.autocomplete.length - 1,
          }));
        } else if (this.state.keySelectIndex === null) {
          // don't do anything, the list displays down
        } else {
          // go up a record
          this.setState(prevState => ({
            keySelectIndex: prevState.keySelectIndex - 1,
          }));
        }
      }
    }
  };

  closeAutoSearch = () => {
    this.setSearching(false);
    clearTimeout(this.searchingPlace);
    this.setAutocompleteSearchResults(null);
    this.setState({keySelectIndex: null});
  };

  toggleMobileMenu = () => {
    this.setState(prevState => ({
      mobileMenuOpen: !prevState.mobileMenuOpen
    }));
  }

  closeAndLogout = () => {
    this.setState(prevState => ({
      mobileMenuOpen: !prevState.mobileMenuOpen
    }), () => {
      this.props.logout();
    });
  }

  render() {
    return (
      <div className="primary-navigation-wrapper">
          <AppBar className="app-bar-nav">
          <Toolbar>
            <div className="mobile-nav-menu-btn">
              <Logo className='motorroof-logo-navbar' onClick={this.toggleMobileMenu}/>
            </div>
            <Link to="/home" className='full-nav-logo'>
              <Logo className='motorroof-logo-navbar'/>
            </Link>
            {!this.props.loading && !this.props.signedIn?
              <div className="signup-login-top-mobile">
                <NavLink to="/login" className="log-in-nav-btn">
                  Login
                </NavLink>
                <Link to="/signup" className="sign-up-nav-btn">
                  <Button>
                    Sign Up
                  </Button>
                </Link>
              </div>:null
            }
            <div className={`mobile-menu-drop ${this.state.mobileMenuOpen? 'is-open': 'closed'}`}>
               {!this.props.loading?
                 <List component="nav" aria-label="main mailbox folders">
                  <Link to='/home' onClick={this.toggleMobileMenu}>
                  <ListItem button className="list-item-button-mobile">
                    <ListItemIcon>
                      <MotorRoofMenuIcon className='motorroof-nav-icon'/>
                    </ListItemIcon>
                    <ListItemText primary="What is MotorRoof?" />
                  </ListItem>
                  </Link>
                 {this.props.signedIn?
                    <React.Fragment>
                    <Link to='/edit-profile' onClick={this.toggleMobileMenu}>
                    <ListItem button className="list-item-button-mobile">
                      <ListItemIcon>
                        <FaceOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText primary="Edit Profile" />
                    </ListItem>
                    </Link>
                    <Link to='/reservations' onClick={this.toggleMobileMenu}>
                    <ListItem button className="list-item-button-mobile">
                      <ListItemIcon>
                        <ConfirmationNumberOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText primary="Your Reservations" />
                    </ListItem>
                    </Link>
                    <Link to='/dashboard' onClick={this.toggleMobileMenu}>
                    <ListItem button className="list-item-button-mobile">
                      <ListItemIcon>
                        <DashboardOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText primary="Dashboard" />
                    </ListItem>
                    </Link>
                    <Link to='/billing' onClick={this.toggleMobileMenu}>
                    <ListItem button className="list-item-button-mobile">
                      <ListItemIcon>
                        <AccountBalanceWalletOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText primary="Billing" />
                    </ListItem>
                    </Link>

                    {this.props.userHasGarages?
                      <Link to='/hosting-hub' onClick={this.toggleMobileMenu}>
                      <ListItem button className="list-item-button-mobile">
                        <ListItemIcon>
                          <BusinessCenterOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary="Hosting Hub" />
                      </ListItem></Link>:<Link to='/garages/new' onClick={this.toggleMobileMenu}>
                      <ListItem button className="list-item-button-mobile">
                        <ListItemIcon>
                          <HomeOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary="List your space" />
                      </ListItem>
                      </Link>
                    }

                    </React.Fragment>:
                    <React.Fragment>
                    <Link to='/signup' onClick={this.toggleMobileMenu}>
                    <ListItem button className="list-item-button-mobile">
                      <ListItemIcon>
                        <HomeOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText primary="List your space" />
                    </ListItem>
                    </Link>

                    </React.Fragment>
                  }

                  {/* <ListItemLink button href="https://help.motorroof.com/" className="list-item-button-mobile">
                    <ListItemIcon>
                      <ContactSupportOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary="Help & Support" />
                  </ListItemLink> */}

                  {this.props.signedIn?
                    <ListItem button className="list-item-button-mobile" onClick={this.closeAndLogout}>
                      <ListItemIcon>
                        <ExitToAppOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText primary="Sign out" />
                    </ListItem>:
                    <React.Fragment>
                      <Link to='/signup' onClick={this.toggleMobileMenu}>
                      <ListItem button className="list-item-button-mobile">
                        <ListItemIcon>
                          <EmojiPeopleOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary="Sign up" />
                      </ListItem>
                      </Link>
                      <Link to='/login' onClick={this.toggleMobileMenu}>
                      <ListItem button className="list-item-button-mobile">
                        <ListItemIcon>
                          <VpnKeyOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText primary="Log in" />
                      </ListItem>
                      </Link>
                    </React.Fragment>
                  }

                  <ListItem>
                    <div className="social-links-row mobile-nav">
                      <Typography variant={"caption"}>
                        Follow Us
                      </Typography>
                      <a href="https://www.facebook.com/motorroof" className="facebook-btn" target="_blank">
                        <FacebookIcon />
                      </a>
                      <a href="https://www.instagram.com/motorroof/" className="instagram-btn" target="_blank">
                        <InstagramIcon />
                      </a>
                      <a href="https://twitter.com/MotorRoofApp" className="twitter-btn" target="_blank">
                        <TwitterIcon />
                      </a>
                      <a href="https://www.youtube.com/channel/UCJyCoFTJkMRe_9JVwJ6uZ3g" className="youtube-btn" target="_blank">
                        <YoutubeIcon />
                      </a>
                    </div>
                  </ListItem>
                  <ListItem>
                   <div className="footer-sub-right-col support-number">
                    <Typography variant={"caption"}>
                    Need Help?
                    </Typography>
                    <Typography><PhoneIcon />(855)212-3262</Typography>
                  </div>
                  </ListItem>

                </List>:
                <div className="menu-loader">
                  <CircularProgress />
                </div>
              }
            </div>
            {this.state.searching || this.state.autocomplete && this.state.autocomplete.length > 0?
              <div className="outside-search" onClick={this.closeAutoSearch}></div>:<React.Fragment></React.Fragment>
            }
            <div className={this.state.searching || this.state.autocomplete && this.state.autocomplete.length > 0? "is-searching-input": ""}>
            <div className={`nav-search-container-form ${!this.props.loading && this.props.signedIn? 'full-width-mobile-search': ''}`}>
              <div className="nav-search-icon">
                {this.state.autocomplete && this.state.autocomplete.length > 0 && this.state.searching?
                  <CircularProgress
                    size={20}
                    thickness={6}
                    color={"#b9b9b9"}
                  />:
                  <SearchIcon />
                }
              </div>
              <form onSubmit={(e) => this.sendSearch(e,'auto')}>
                <InputBase
                  placeholder="Where do you want to rent?"
                  classes={{
                    root: "nav-search-input-root",
                    input: "nav-search-input-text"
                  }}
                  value={this.state.autocompleteSearch}
                  inputProps={{ "aria-label": "Search garages" }}
                  onChange={(e) => this.handleChange(e)}
                  onKeyDown={this.onKeyPress}
                />
                { this.state.searching?
                    <div className="searching-info-bar">
                    <LinearProgress variant="query" className="searching-navbar-progress" /></div>:
                    this.state.autocomplete && this.state.autocomplete.length > 0?
                      <div className="has-results-bar"></div>:<div></div>
                }
                {this.state.autocomplete && this.state.autocomplete.length > 0?
                  <div className="autocomplete-navbar-results">
                  { this.state.autocomplete.map((place, i) => (
                    <React.Fragment key={`search-parent-${i}`}>
                    {this.state.keySelectIndex == i?
                      <div className="search-result-row selected-by-key" key={`search-result-${i}`} onClick={(e) => this.sendSearch(e,'result',i)}>
                        <div className="location-search-pin">
                          <LocationSearchPin />
                        </div>
                        <Typography
                          component="span"
                          variant="body2"
                        >
                        {place.name}
                        </Typography>
                      </div>:
                      <div className="search-result-row" key={`search-result-${i}`} onClick={(e) => this.sendSearch(e,'result',i)}>
                        <div className="location-search-pin">
                          <LocationSearchPin />
                        </div>
                        <Typography
                          component="span"
                          variant="body2"
                        >
                        {place.name}
                        </Typography>
                      </div>
                    }
                    </React.Fragment>
                  ))}
                  </div>:<div>
                    {this.state.searching?
                      <div className="searching-locations">
                        <CircularProgress
                          size={20}
                          thickness={6}
                          color={"#b9b9b9"}
                        />
                        <Typography
                        component="span"
                        variant="body2"
                        >
                        Searching...
                        </Typography>
                      </div>:<div></div>
                    }
                  </div>
                }
              </form>
            </div>
            </div>
            {!this.props.loading?
              <div className="full-dash-navlist">
              {this.props.signedIn?
              <div className="navigation-list">
                <NavLink to="/dashboard">
                  Dashboard
                </NavLink>
                {this.props.userHasGarages?
                  <NavLink to="/hosting-hub">
                    Hosting Hub
                  </NavLink>:
                  <NavLink to="/garages/new">
                    Become a Host
                  </NavLink>
                }
                <DropdownMenu 
                  loading={this.props.loadingAvatar} 
                  avatarPhotoURL={this.props.avatarPhotoURL} 
                  logout={this.props.logout} 
                  displayName={this.props.displayName} 
                  publicProfileKey={this.props.publicProfileKey}
                />:
              </div>:
              <div className="navigation-list">
                <NavLink to="/home">
                  Home
                </NavLink>
                <NavLink to="/how-to-host">
                  Become a Host
                </NavLink>
                <NavLink to="/login" className="log-in-nav-btn">
                  Login
                </NavLink>
                <Link to="/signup" className="sign-up-nav-btn">
                  <Button>
                    Sign Up
                  </Button>
                </Link>
              </div>
            }
            </div>
          :null
         }
          </Toolbar>
          <div className="mobile-background-bar"></div>
        </AppBar>
      </div>
    );
  }
};

export default Navbar;
