import React from "react";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import UserAvatarImg from '../images/missing-avatar.svg';
import StarIcon from "@material-ui/icons/Star";
import LocationIcon from "@material-ui/icons/LocationOn";
import dbFireStore from '../firebase';

import "../styles/garage_thumb.scss";

const formatCurrencyUSD = (amount) => {
  const locale = 'en-US';
  const options = { style: 'currency', currency: 'USD'};
  return Intl.NumberFormat(locale, options).format(amount);
}


const GarageGridItem = props => {
  const [ratingStar, setRatingStar] = React.useState(5);

  // not ideal...
  const getStarRating = (owner) => {
    var rating_is = 5;
    var promiseList = [];
    const promise_check = dbFireStore.firestore().collection("reviews").where("review_of_user_id", "==", owner).get().then(querySnapshot => {
      var reviewsList = [];
      var queryLength = querySnapshot.docs.length;
      var queryLooped = 0;
      if(queryLength === 0) {
        // user has no reviews yet
      } else {
        const get_avg = querySnapshot.forEach((review) => {
          var reviewInfo = review.data();
          // parse review data here
          queryLooped++;
          reviewsList.push(reviewInfo.rating);
          if(queryLength == queryLooped) {
            // end of the list, push to state
            var sum = reviewsList.reduce((a, b) => a + b, 0);
            var avg = (sum/queryLength).toFixed(1);
            rating_is = avg;
          };
        });
      }
      promiseList.push(promise_check);
      Promise.all(promiseList).then(() => {
        setRatingStar(rating_is);
      });
    }).catch((error) => {
      console.log(error);
    });
  };
  return (
    <div className="garage-grid-item">
      <div className="garage-thumb-container">
      {props.photo?
        <div className="garage-thumb-image" style={{"backgroundImage": `url(${props.photo})`}}></div>:
        <div className="garage-thumb-image missing-image"></div>
      }
        <Avatar className={props.avatarPhotoURL? "user-nav-avatar has-avatar" : "user-nav-avatar no-avatar"} src={props.avatarPhotoURL? props.avatarPhotoURL: UserAvatarImg } />
        <div className="user-name-with-rating">
          {props.displayName?
            <span className="name">
              {props.displayName}
            </span>: ''}
          <span className={props.starRating? 'user-rating':'user-rating first-time-renter'}>
            {props.needsStar?
              <p className="rating">
              {getStarRating(props.needsStar)}
              {ratingStar}
              </p>:
              <p className="rating">{props.starRating? props.starRating: 5}</p>
            }
            <StarIcon className="user-star" />
          </span>
        </div>
        {props.price?
          <div className="garage-thumb-price">
            {formatCurrencyUSD(props.price).split(".")[0]}
          </div>: ''
        }
      </div>
      <div className="garage-thumb-title">
        <Typography variant="h2">{props.garageTitle}</Typography>
      </div>
      <div className="type-container">
        {props.isWorkshop?
          <div className={'Workshop type-label'} key={'type-workshop'}>Workshop</div>:''}
        {props.isStorage?
          <div className={'Storage type-label'} key={'type-storage'}>Storage</div>:''}
        {props.garageTypes && props.garageTypes.map((kind, i) => (
          kind.isType?
            <div className={kind.name + ' type-label'} key={`type-${i}`}>{kind.name}</div>:''
          ))}
      </div>
      {props.city && props.state?
        <div className="location-of-garage">
          <LocationIcon />
          <h1>{props.city.charAt(0).toUpperCase() + props.city.slice(1)}, {props.state}</h1>
        </div>:''
      }
    </div>
  );
};

export default GarageGridItem;
