import React from "react";
import Dropzone from 'react-dropzone';
import classNames from 'classnames';
import firebase from 'firebase/app';
import Loader from 'react-loader-spinner';
import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Typography from "@material-ui/core/Typography";

import ArrowIcon from "@material-ui/icons/SubdirectoryArrowLeft";
import CameraIcon from "@material-ui/icons/PhotoCamera";
import FavoriteIcon from "@material-ui/icons/Favorite";
import FavoriteOutlineIcon from "@material-ui/icons/FavoriteBorder";
import DeleteIcon from "@material-ui/icons/Delete";
import { ReactComponent as Stripe } from '../images/stripe.svg';

import "../styles/uploader.scss";


class GaragePhotoUploader extends React.Component {
  state = {
    uploading: false,
    acceptedFiles: null,
    rejectedFiles: null,
    garageHasImages: false,
    garagePhotos: [],
    garagePhotosRef: null,
  }

  onDrop = (acceptedFile, rejectedFile) => {
    var limitUpload = this.state.garagePhotos.length > 0? 6 - this.state.garagePhotos.length: 6;
    
    if(limitUpload == 0) {
      var files = [];
    } else {
      var files = acceptedFile.slice(0,limitUpload);
    }

    this.setState({ uploading: true, acceptedFiles: files, rejectedFiles: rejectedFile });

    const timestamp = firebase.database.ServerValue.TIMESTAMP;
    if(this.state.garagePhotosRef) {
      // place the garages photos into this folder
      const storageRef = firebase.storage().ref(`/garages/${this.props.editingGarageRef.key}/`);

      var uploadedAmount = files.length;

      if(this.state.garagePhotos.length > 0) {
        var uploaded = this.state.garagePhotos.length;
        var initUploaded = uploaded;
      } else {
        var initUploaded = 0;
        var uploaded = 0;
      }

      files.forEach(file => {
        let garagePhotosRef = this.state.garagePhotosRef.push();
        let gragePhotoKey = garagePhotosRef.key
        let garageStorageRef = storageRef.child(`${gragePhotoKey}/photo.jpg`);
        var order = uploaded;

        garageStorageRef.put(file).then(() => {
          var perOrder = order;
          var key = gragePhotoKey;
          garagePhotosRef.set({'timestamp': timestamp, 'order': perOrder});
          if(this.props.editorOnly) {
            this.props.needsPhotoUpdate();
          }
          garageStorageRef.getDownloadURL()
            .then(url => {
              if(perOrder == 0) {
                this.props.handleUpdateLeadPhoto(url);
              }
              this.setState(prevState => ({
                garageHasImages: true,
                acceptedFiles: prevState.acceptedFiles.length == 1? []: prevState.acceptedFiles.splice(prevState.acceptedFiles.indexOf(file), 1),
                uploading: perOrder == 0 && prevState.acceptedFiles.length == 1? false: perOrder + 1 == prevState.acceptedFiles.length? true: false,
                garagePhotos: [
                  ...prevState.garagePhotos,
                  {url: url, order: perOrder, id: key}
                ],
              }));
            });

        });
        uploaded++;
      });

    } else {
      // thow and catch error here
      console.log("garage wasn't found");
    }
  };

  componentDidMount() {
    this.setState((prevState) => ({
      garagePhotosRef: this.props.editingGarageRef.child('photos'),
    }),() => {
      this.loadPhotos();
    });
  }

  loadPhotos = () => {
    this.state.garagePhotosRef.once('value').then((snapshot) => {
      if(snapshot) {
        this.setState({
          garageHasImages: true,
        });
        snapshot.forEach(image => {
        var imgKey = image.key;
        var imgOrder = image.val().order;
        var imgLocationRef = firebase.storage().ref(`/garages/${this.props.editingGarageRef.key}/${imgKey}/photo.jpg`);
        imgLocationRef.getDownloadURL()
          .then(url => {
            if(imgOrder == 0) {
              this.props.handleUpdateLeadPhoto(url);
            }
            this.setState(prevState => ({ 
              garagePhotos: [
                ...prevState.garagePhotos,
                {url: url, order: imgOrder, id: imgKey }
              ]
            }));
          });
        });
      }
    });
  };

  deletePhoto = (image) => {
    var garagePhotosList = [...this.state.garagePhotos];
    var updatedOrderedPhotos = [];
    var imageOrder = image.order;
    // filter list to remove photo we need to delete
    // and update order
    garagePhotosList.forEach((photo) => {
      // subtract from ids higher then deleted image so order is preserved
      if(photo.order > imageOrder) {
        photo.order = photo.order - 1;
      }
      if(photo.id != image.id) {
        updatedOrderedPhotos.push(photo);
      }
    });

    // update firebase order
    updatedOrderedPhotos.forEach(photo => {
      if(photo.order == 0) {
        this.props.handleUpdateLeadPhoto(photo.url);
      }
      const timestamp = firebase.database.ServerValue.TIMESTAMP;
      this.state.garagePhotosRef.child(photo.id).set({'timestamp': timestamp, 'order': photo.order});
    });

    // then delete photo db record in garage
    this.state.garagePhotosRef.child(image.id).remove();

    //delete image storage
    // Create a reference to the file to delete
    var deletePhotoRef = firebase.storage().ref(`/garages/${this.props.editingGarageRef.key}/${image.id}/photo.jpg`);

    // Delete the file
    deletePhotoRef.delete().then((sucess) => {
      // File deleted successfully
    }).catch(function(error) {
      // Uh-oh, an error occurred!
      // catch delete error here
    });

    if(updatedOrderedPhotos.length == 0) {
      // there are no more images, update state of lead photo url
      this.props.handleNoLeadPhoto();
      this.setState({
        garageHasImages: false,
        garagePhotos: [],
      });
    } else {
      //update state

      this.setState((prevState) => ({
        garageHasImages: true,
        garagePhotos: updatedOrderedPhotos,
      }));
    }
    if(this.props.editorOnly) {
      this.props.needsPhotoUpdate();
    }
  };

  setLeadPhoto = (image) => {
    // lead photo is determined by it's order, if order is 0 then it is lead.

    var garagePhotosList = [...this.state.garagePhotos];
    var updatedOrderedPhotos = [];
    var imageOrder = image.order;

    // set new order list
    garagePhotosList.forEach((photo) => {

      if(photo.order < imageOrder && photo.id != image.id) {
        // add to order to preserve order since there is a gap now
        photo.order = photo.order + 1;
      } else if(photo.id === image.id) {
        // new lead image
        photo.order = 0;
        this.props.handleUpdateLeadPhoto(photo.url);
      }
      updatedOrderedPhotos.push(photo);
    });

    // send update to firebase
    updatedOrderedPhotos.forEach(photo => {
      const timestamp = firebase.database.ServerValue.TIMESTAMP;
      this.state.garagePhotosRef.child(photo.id).set({'timestamp': timestamp, 'order': photo.order});
    });

    //update state
    this.setState({
      garagePhotos: updatedOrderedPhotos,
    });

  };


  render() {
    return (
      <div>
        <div className="state-of-upload-title">
        { this.state.uploading?
          <Typography>Uploading! Just a moment...</Typography>:
          <Typography>{this.props.editorOnly?
            "Add and edit your listings photos below": "Let's add at least one photo for your space"
          }</Typography>
        }
        </div>


        <div className="ordered-garage-thumb-container">
        {this.state.garageHasImages?
          <React.Fragment>
            {this.state.garagePhotos.map((image, i) => (
              <div className={"garage-image-uploaded-list-item " + `order_garage_${image.order}`} style={{"backgroundImage": `url(${image.url})`}} >
                <div className="thumb-actions">
                  <Button className={image.order == 0? "set-lead-photo-btn lead":"set-lead-photo-btn"} onClick={image.order != 0? () => this.setLeadPhoto(image): ''} >
                    {image.order == 0? <FavoriteIcon/>:<FavoriteOutlineIcon/>}
                  </Button>
                  <Button className="delete-photo-btn" onClick={() => this.deletePhoto(image)}>
                    <DeleteIcon />
                  </Button>
                </div>
                {image.order == 0?
                  <div className="lead-photo-label">
                  <FavoriteIcon/>
                  <Typography>Lead Photo</Typography>
                  </div>:<div></div>}
              </div>
            ))}</React.Fragment>:<div></div>
        }
        {this.state.acceptedFiles?
          <React.Fragment>
            {this.state.acceptedFiles.map((image, i) => (
            
                <div className={this.state.garageHasImages? `garage-uploading-button-icon order_garage_${this.state.garagePhotos.length + (i + 1)}`: 'garage-uploading-button-icon'} index={i}>
                  <div className='uploading-started' >
                    <div className="photo-upload-started">
                      <Loader type="TailSpin" color="#90ffc8"/>
                    </div>
                  </div>
                </div>

            ))}</React.Fragment>:
          <div></div>
        }
        </div>

        <div>
        {this.state.garagePhotos.length == 6?
          <div>
            <Typography variant="caption" className="upload-photo-info">
            <CameraIcon className="camera-icon"/>
              You have reached the maximum photo limit for your garage.
              <br />Remove photos to upload new ones.
            </Typography>
          </div>:
          <React.Fragment>
          {this.state.uploading?
          <div></div>:
          <Dropzone
            onDrop={this.onDrop}
            accept="image/jpeg, image/png"
            minSize={10}
            maxSize={20971520}
            maxFiles={this.state.garagePhotos.length > 0? 6 - this.state.garagePhotos.length: 6}
            multiple
          >
          {({getRootProps, getInputProps, isDragActive}) => {
            return (
              <div {...getRootProps()} className={classNames('dropzone', {'dropzone--isActive': isDragActive})}>
                <input {...getInputProps()} />
                { isDragActive ?
                  <div>
                  <Button className='upload-start-button-icon uploading-drop-garage'>
                    <div className='uploading-drop'>
                      <CloudUploadIcon className="drop-upload-icon" />
                      start upload
                    </div>
                  </Button>
                  <Typography variant="caption" className="upload-photo-info">
                  <ArrowIcon className="rotate-arrow"/>
                  Drop your photos to start uploading! (maximum 6)
                  </Typography>
                  </div>:
                  <div>
                  <Button className='upload-start-button-icon'>
                    <CloudUploadIcon className="drop-upload-icon" />
                    <Stripe className="stripe-bg" />
                  </Button>
                  <Typography variant="caption" className="upload-photo-info">
                  <ArrowIcon className="rotate-arrow"/>
                  {this.state.garagePhotos.length > 0? 
                    <div className="inline-upload-message">
                    You can upload {6 - this.state.garagePhotos.length} more {this.state.garagePhotos.length == 5? 'photo': 'photos'}.
                    </div>:
                    <div className="inline-upload-message">
                    Drop your image in the circle above, or click to select a photo. (maximum 6)
                    </div>
                  }
                  </Typography>
                  </div>
                }
              </div>
            )
          }}
          </Dropzone>}
          </React.Fragment>
        }
        </div>
      </div>
    );
  }
}

export default GaragePhotoUploader;


