import React from "react";
import Typography from "@material-ui/core/Typography";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import PropTypes from 'prop-types';
import InputLabel from '@material-ui/core/InputLabel';
import CheckIcon from '@material-ui/icons/Check';
import CircularProgress from "@material-ui/core/CircularProgress";

import PhoneIcon from '@material-ui/icons/Phone';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';

import MaskedInput from 'react-text-mask';
import Input from '@material-ui/core/Input';
import firebase from 'firebase/app';
import dbFireStore from '../firebase';
const axios = require('axios');

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      placeholder={"(777)888-9999"}
      guide={false}
    />
  );
}

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};


function TextMaskCustomVerify(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/[0-9]/,' ', '-', ' ', /[0-9]/, ' ', '-', ' ',/[0-9]/, ' ', '-', ' ', /[0-9]/, ' ', '-', ' ', /[0-9]/, ' ', '-', ' ', /[0-9]/]}
      placeholderChar={'\u2000'}
      placeholder={"1 - 1 - 1 - 1 - 1 - 1 - 1"}
      guide={false}
    />
  );
}

TextMaskCustomVerify.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

const isDevMode = process.env.NODE_ENV === "development" && process.env.NODE_ENV !== "production"? true: false;

const VERIFY_API_KEY = isDevMode? "pqEpJcJAbl4HOqC6NG2381Cw3rXOBtKZ5LozmSGR": "YRvBaoex5u1bV6Wskz08R6i3yb9FEOXn2h5IwScf";
const VERIFY_CREATE_ENDPOINT = isDevMode? "https://sa6fjstz1g.execute-api.us-east-1.amazonaws.com/default/createVerification_DEV": "https://8w5jn554gl.execute-api.us-east-1.amazonaws.com/default/createVerification";

const VERIFY_CHECK_API_KEY = isDevMode? "yDhblESQT4XypD0W73Cw26Ey1cDX4vs84hDfKdpf": "2FFeH6N4Uv8aOVEnlYJsDDmApV3qY099iY0enHkg";
const VERIFY_CHECK_ENDPOINT = isDevMode? "https://2ouvgmn7c7.execute-api.us-east-1.amazonaws.com/default/checkVerification_DEV": "https://8w5jn554gl.execute-api.us-east-1.amazonaws.com/default/checkVerification";

const VerifyPhone = props => {
  const [value, setValue] = React.useState('sms');
  const [verified, setVerified] = React.useState('false');
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [verifyPhoneStatus, setVerifyPhoneStatus] = React.useState(props.isVerified);
  const [awaitingVerification, setAwaitingVerification] = React.useState(false);

  const [numb, setNumb] = React.useState({
    textmask: '',
    numberformat: '',
  });

  const [verify, setVerify] = React.useState({
    textmask: '',
    numberformat: '',
  });

  function handleChange(event) {
    setValue(event.target.value);
  }

  function toggleForm(event) {
    setOpen(!open);
  }

  function sendVerification(event) {
    setLoading(true);
    var phone_number = "+1" + numb.textmask.replace(/\D/g,'');
    var type = value;
    var verifyPhone = {to: phone_number, channel: type};
    var setPhoneRef = firebase.database().ref('users/' + firebase.auth().currentUser.uid + "/profile");
    setPhoneRef.child("phone").set(phone_number);
    setPhoneRef.off();
    axios({
      method: 'POST',
      url: VERIFY_CREATE_ENDPOINT,
      headers: {
        "x-api-key": VERIFY_API_KEY
      },
      data: verifyPhone
      }).then(response => {
        setPhoneRef.child("verify_phone_id").set(response.data.verification);
        setLoading(false);
        setAwaitingVerification(true);
      }).catch(error => {
        // handle error when sending
        setLoading(false);
        props.handleError({
          message: "There was a problem verifying this number. Please try again."
        });
      });
  }

  function verifyCode(event) {
    setLoading(true);
    var setPhoneRef = firebase.database().ref('users/' + firebase.auth().currentUser.uid + "/profile/phone");
    setPhoneRef.once('value', snapshot => {
      if (snapshot.val() != null) {
        //User has phone, now check if their key is correct
        var code_check = verify.textmask.replace(/\D/g,'');
        var verifyPhone = {to: snapshot.val(), code: code_check};
        axios({
          method: 'POST',
          url: VERIFY_CHECK_ENDPOINT,
          headers: {
            "x-api-key": VERIFY_CHECK_API_KEY
          },
          data: verifyPhone
          }).then(response => {
            if(response.data.verification == "approved") {
              var setPhoneRef = firebase.database().ref('users/' + firebase.auth().currentUser.uid + "/profile/verified_status");
              setPhoneRef.child("phone").set(true);
              setVerifyPhoneStatus(true);

              props.handleUpdateVerification();
              
              var userPrivatePublicRef = firebase.database().ref('users/' + firebase.auth().currentUser.uid + '/profile/public_profile_key');
              userPrivatePublicRef.once('value', snapshot => {
                if (snapshot.val() != null) {
                  //User has public profile
                  var userPublicRef = dbFireStore.firestore().collection("users").doc(snapshot.val());
                  userPublicRef.update({
                    verified_phone: true,
                    lastUpdated: firebase.firestore.FieldValue.serverTimestamp()
                  });
                } else {
                  // no public profile, set profile
                  // createPublicProfile();
                }
              });

            }
            setLoading(false);
          }).catch(error => {
            // handle error when sending
            setLoading(false);
            props.handleError({
              message: "We could not verify your code. Please try again or request a new verification code."
            });
          });

      } else {
        // User doesn't have phone number reset form, throw error?
      }
    });
  }

  function inputVerifyCode(event) {
    setAwaitingVerification(true);
  }

  function reSendVerification(event) {
    setAwaitingVerification(false);
  }

  const handleNumbChange = name => event => {
    setNumb({
      ...numb,
      [name]: event.target.value,
    });
  };

  const handleVerifyChange = name => event => {
    setVerify({
      ...verify,
      [name]: event.target.value,
    });
  };

  return (
    <div>
    {verifyPhoneStatus?
      <Button size="small" className="verify-phone-btn verified-phone" onClick={() => toggleForm()}>
        <CheckIcon />
        <span>
        Your Phone is Verified
        </span>
      </Button>:

      <div className="verify-dash-block">
        <div className="verify-title-block phone">
        </div>
        <Button size="small" className="verify-dash-btn" onClick={() => toggleForm()}>
          <span>
            Verify Phone Number
          </span>
        </Button>
      </div>
    }
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => toggleForm()}
      className="verify-phone-block"
      >
      {verifyPhoneStatus?
        <div className="success-phone-verify">
          <img
            src={require("../images/phone-check-motorroof-verified.jpg")}
            alt="verify phone number"
            className="verify-phone-number-hero number-is-verified"
          />
          <Typography variant="h4">Your Phone is Verified</Typography>
          <Typography variant="body2">
            All good to go! You can now post your space on MotorRoof.
          </Typography>
          <Button className="verify-success-btn" onClick={() => toggleForm()}>
            <CheckIcon />
            Back to Listing
          </Button>
        </div>:
        <span>
        <img
          src={require("../images/phone-check-motorroof.png")}
          alt="verify phone number"
          className="verify-phone-number-hero"
        />
        {awaitingVerification?
          <div className="enter-verification-code-phone">
            <Typography variant="h4">Enter Verification Code</Typography>
            <Typography variant="body2">
              You will receive a {value == 'call'? 'phone call': 'text message'} containing a 6 digit code. Enter that code below to verify your phone number.
            </Typography>
            <Input
              label="Your verification number"
              className="code-verify-input"
              value={verify.textmask}
              onChange={handleVerifyChange('textmask')}
              id="formatted-verify-mask-input"
              inputComponent={TextMaskCustomVerify}
            />
            <Button
              className={loading? "send-verification-phone-btn loading-verify-btn": "send-verification-phone-btn" }
              disabled={loading}
              onClick={verifyCode}>
              {loading?
              <CircularProgress
                size={20}
                thickness={6}
                className="loader-login-spinner"
              />:
              <React.Fragment>
              <CheckIcon />
              Verify Code
              </React.Fragment>}
            </Button>
            <Button className="resend-phone-verification" onClick={reSendVerification}>
              Resend Code
            </Button>
          </div>:
          <FormControl component="fieldset">
          <FormLabel component="legend">Phone verification by</FormLabel>
          <RadioGroup
              aria-label="verification type"
              name="typeVerification"
              value={value}
              onChange={handleChange}
            >
              <FormControlLabel value="sms" control={<Radio />} label="Text Message (SMS)*" />
              <FormControlLabel value="call" control={<Radio />} label="Phone Call" />
           </RadioGroup>
          <div className="phone-number-verify-wrap">
          <Typography variant="caption">Your phone number</Typography>
          <PhoneIcon className="verify-phone-placeholder-icon"/>
          <Input
            label="Your phone number"
            className="phone-number-verify-input"
            value={numb.textmask}
            onChange={handleNumbChange('textmask')}
            id="formatted-text-mask-input"
            inputComponent={TextMaskCustom}
            autoFocus
          />
          </div>
          <Button
            className={loading? "send-verification-phone-btn loading-verify-btn": "send-verification-phone-btn" }
            disabled={loading}
            onClick={() => sendVerification()}>
            {loading?
              <CircularProgress
                size={20}
                thickness={6}
                className="loader-login-spinner"
              />:"Send verification"}
          </Button>
          <Button className="resend-phone-verification" onClick={inputVerifyCode}>
            Have your verify code?
          </Button>
        </FormControl>
      }
      </span>}
      </Dialog>
    </div>
  );
};


export default VerifyPhone;
