import React from "react";
import { Link } from "react-router-dom";

import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Avatar from "@material-ui/core/Avatar";
import CircularProgress from "@material-ui/core/CircularProgress";

import UserAvatarImg from "../images/missing-avatar.svg";

const DropdownMenu = props => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="user-menu-button-container">
      <Button
        className="user-menu-button"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        {props.loading ? (
          <div className="user-nav-avatar-loading">
            <CircularProgress size={35} className="loading-avatar" />
          </div>
        ) : (
          <Avatar
            className={
              props.avatarPhotoURL
                ? "user-nav-avatar has-avatar"
                : "user-nav-avatar no-user-avatar"
            }
            src={props.avatarPhotoURL ? props.avatarPhotoURL : UserAvatarImg}
          />
        )}
        <span className="username-navbar">{props.displayName}</span>
      </Button>
      <Menu
        id="menu"
        anchorEl={anchorEl}
        keepMounted
        style={{ zIndex: 9999 }}
        className={Boolean(anchorEl) ? "menu-is-open" : "menu-is-closed"}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem 
          component={Link} 
          to="/reservations" 
          onClick={handleClose}
        >
          Reservations
        </MenuItem>
        <MenuItem 
          component={Link} 
          to="/edit-profile" 
          onClick={handleClose}
        >
          Edit Profile
        </MenuItem>
        <MenuItem
          component={Link}
          to="/settings"
          onClick={handleClose}
          className="hide-element"
        >
          Settings
        </MenuItem>
        {/*
        <MenuItem
          component={Link}
          to={`/user/${props.publicProfileKey}`}
          onClick={handleClose}
        >
          View Your Profile
        </MenuItem>
        */}
        <MenuItem
          onClick={() => {
            props.logout();
          }}
        >
          Logout
        </MenuItem>
      </Menu>
    </div>
  );
};

export default DropdownMenu;
