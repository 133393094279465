import React, { useState, useEffect } from 'react';
import GarageGridItem from "./GarageGridItem";
import { Link } from "react-router-dom";

import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import Typography from '@material-ui/core/Typography';


import { ReactComponent as AcIcon } from '../images/ac.svg';
import { ReactComponent as HeatingIcon } from '../images/heat.svg';
import { ReactComponent as PaintIcon } from '../images/paint-gun.svg';
import { ReactComponent as LiftIcon } from '../images/lift.svg';
import { ReactComponent as WeldIcon } from '../images/weld.svg';
import { ReactComponent as WifiIcon } from '../images/wifi.svg';

import firebase from 'firebase/app';
import dbFireStore from '../firebase';

const toTitleCase = (str) => {
    return str.replace(/\w\S*/g, function(txt){
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
}

const FeaturedSingleGarage = props => {
  const [garage, setGarage] = React.useState('');
  const [loadingGarage, setLoadingGarage] = React.useState(false);
  const [garagePhotoLead, setGaragePhotoLead] = React.useState('');
  const [ownerRating, setOwnerRating] = React.useState(5);
  const [ownerPhoto, setOwnerPhoto] = React.useState('');
  const [amenitiesList, setAmenitiesList] = React.useState(null);
  const [securityList, setSecurityList] = React.useState(null);


  const getGarage = garageURI => {
    if(!loadingGarage) {
      setLoadingGarage(true);
      dbFireStore.firestore().collection("garages")
        .where("url", "==", garageURI)
        .get()
        .then(querySnapshot => {
          if(querySnapshot.docs.length == 1) {
            var garageData = querySnapshot.docs[0].data();
            setGarage(garageData);
            if(garageData.photos && garageData.garage_key) {
              getGaragePhotos(garageData.photos, garageData.garage_key, garageData);
            }
            getGarageOwnerInfo(garageData.owner_photo_id);
            getOwnerRating(garageData.owner_id);
            if(garageData.amenities) {
              var amenitiesInfo = Object.keys(garageData.amenities).filter((item) => item !== 'cctv' && item !== 'locked' && item !== 'security');
              setAmenitiesList(amenitiesInfo);
              var securityInfo = Object.keys(garageData.amenities).filter((item) => item == 'cctv' || item == 'locked' || item == 'security');
              setSecurityList(securityInfo);
            }

          } else {
            setGarage('');
          }
        })
        .catch(error => {
          // error set garage to nothing
          setGarage('');
        });
    }
  };

  const getGaragePhotos = (photos, garage_key, garageData) => {
    var photoListLength = Object.keys(photos).length;
    var garagePhotos = Array(photoListLength).fill('');
    var photoIndex = 0;
    if(photos && garage_key) {
      Object.keys(photos).forEach((photo) => {
        if(garageData.photos[photo].order === 0) {
          var leadId = photo;
          var leadImgLocationRef = firebase.storage().ref(`/garages/${garage_key}/${leadId}/photo.jpg`);
          leadImgLocationRef.getDownloadURL().then(url => {
            setGaragePhotoLead(url);
          }).catch(error => {
            setGaragePhotoLead('');
          })
        } else {
          setGaragePhotoLead('');
        }
      });
    } else {
      setGaragePhotoLead('');
    }
  };

  const getGarageOwnerInfo = (photoId) => {
    if(photoId) {
      var userPhotoRef = firebase.storage().ref(`/${photoId}/photo.jpg`);
      userPhotoRef.getDownloadURL().then(url => {
        setOwnerPhoto(url);
      }).catch(error => {
        setOwnerPhoto('');
      });
    }
  };

  const getOwnerRating = (owner_id) => {
    dbFireStore.firestore().collection("reviews").where("review_of_user_id", "==", owner_id).get().then(querySnapshot => {
      var reviewsList = [];
      var queryLength = querySnapshot.docs.length;
      var queryLooped = 0;
      if(queryLength === 0) {
        // user has no reviews yet
        setOwnerRating(5);
      } else {
        querySnapshot.forEach((review) => {
          var reviewInfo = review.data();
          // parse review data here
          queryLooped++;
          reviewsList.push(reviewInfo.rating);
          if(queryLength == queryLooped) {
            // end of the list, push to state
            var sum = reviewsList.reduce((a, b) => a + b, 0);
            var avg = (sum/queryLength).toFixed(1);
            setOwnerRating(avg);
          };
        });
      }
    }).catch((error) => {
      setOwnerRating(5);
    });
  };

  const renderAmenitie = (item) => {
    switch(item) {
      case 'ac':
        return (<AcIcon />);
        break;
      case 'heating':
        return (<HeatingIcon />);
        break;
      case 'lift':
        return (<LiftIcon />);
        break;
      case 'paint':
        return (<PaintIcon />);
        break;
      case 'welding':
        return (<WeldIcon />);
        break;
      case 'wifi':
        return (<WifiIcon />);
        break;
      default:
        return('');
        break;
    }
  };


  return (
    <React.Fragment>
      {getGarage(props.garageURI)}
      {garage?
        <React.Fragment>
        <div className="featured-garage-single-wrap">
          <h2 className="featured-single-title mobile">Featured Space</h2>
          <div className="featured-garage-container">
          <Link to={props.garageURI? props.garageURI: ''} className="link-garage">
            <div className="garage-featured-single-item">
              <GarageGridItem 
                garageTitle={garage.title}
                isStorage={garage.isStorage}
                isWorkshop={garage.isWorkshop}
                avatarPhotoURL={ownerPhoto !== ''? ownerPhoto: null}
                displayName={garage.owner_name}
                starRating={ownerRating}
                price={garage.price}
                city={garage.city}
                state={garage.state}
                photo={garagePhotoLead !== ''? garagePhotoLead: null}
              />
              {props.garageId}
            </div>
          </Link>
          <div className="featured-garage-single-content">
            <h2 className="featured-single-title">Featured Space</h2>
            <h1 className="featured-garage-owner-name">
              Wrenchers LLC
            </h1>
            <h4 className="location-featured">Novi, MI</h4>
            <p className="featured-info">Dedicated in-door heated building with camera security and locked gated access.</p>
            {securityList && securityList.length > 0?
              <div className="garage-security-wrap">
                {securityList.map((item, index) => (
                  <div className={`security-item  has-security-${garage.amenities[item]}`}>
                    <div className={`${item}-security-tag`}>
                      <Typography variant="body2">
                      {item == 'cctv'? 'Security Cameras': item == 'locked'? 'Locked Access':item == 'security'? 'Alarm System': ''}
                      </Typography>
                    </div>
                  </div>
                ))}
              </div>:<div></div>
              }
            <div className="garage-amenities">
              {amenitiesList && amenitiesList.map((item,index) => (
                <div className={`amenitie-label ${item}-label has-amenitie-${garage.amenities[item]}`} key={`${item}-${index}`}>
                  {garage.amenities[item]?
                    <div className="check-amenitie">
                    <CheckCircleIcon />
                    </div>:<div className="check-amenitie-false">
                      <RadioButtonUncheckedIcon />
                    </div>}
                  <div className={`amenitie-icon icon-${item}`}>
                    {renderAmenitie(item)}
                  </div>
                  <Typography variant="body2">{`${item == 'ac'? "A/C": toTitleCase(item)}`}</Typography>
                </div>
              ))}
            </div>
            

          </div>
          </div>
          </div>
        </React.Fragment>:null
      }
    </React.Fragment>
  );
}

export default FeaturedSingleGarage;
