import React from "react";
import { withRouter, Link } from "react-router-dom";
import firebase from "firebase/app";

import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import TermsText from "../../components/TermsAndConditions";
import PrivacyPolicy from "../../components/PrivacyPolicy";

import { ReactComponent as MotorRoofLogo } from "../../images/motorroof-logo-g.svg";

import "../../styles/signup.scss";

class SignUp extends React.Component {
  state = {
    loading: false,
    email: null,
    password: null,
    isChecked: false,
    isSubscribedChecked: true,
    openTerms: false,
    openPolicy: false,
    firstName: null,
    lastName: null,
  };

  handleSubmit = e => {
    e.preventDefault();
    if (this.state.isChecked) {
      if(this.state.firstName && this.state.firstName.length > 1 && this.state.lastName && this.state.lastName.length > 1) {
        this.createUser();
      } else {
        this.props.handleError({
          message: "First Name and Last Name are required to use our platform."
        });
      }
    } else {
      this.props.handleError({
        message: "In order to use our services, please accept our terms and conditions."
      });
    }
  };

  createUser = () => {
    this.setState({ loading: true });
    firebase
      .auth()
      .createUserWithEmailAndPassword(this.state.email, this.state.password)
      .then(user => {
        this.props.userRequiresUpdate(this.state.firstName, this.state.lastName);
        this.props.subscribeToNews(this.state.isSubscribedChecked);
      })
      .catch(error => {
        this.setState({ loading: false });
        this.props.handleError(error);
      });
  };

  handleClick = () => this.props.history.push(`/login`);

  handleChange = e => this.setState({ [e.target.id]: e.target.value });

  handleCheckbox = () => this.setState({ isChecked: !this.state.isChecked });

  handleSubscribedCheckbox = () => this.setState({ isSubscribedChecked: !this.state.isSubscribedChecked });

  handleOpenTerms = () => this.setState({ openTerms: true });

  handleOpenPolicy = () => this.setState({ openPolicy: true });

  handleClose = () =>
    this.setState({ openTerms: false, openPolicy: false });

  handleAgreement = () => {
    this.setState({
      isChecked: true,
      open: false,
      openTerms: false,
      openPolicy: false
    });
  };

  handleDisagreement = () => {
    this.setState({
      isChecked: false,
      open: false,
      openTerms: false,
      openPolicy: false
    });
  };

  componentDidMount() {
    this.props.firstSignup(true);
  }

  render() {
    var year_current = new Date().getFullYear();
    return (
      <div className="signup-page-wrap">
        <div className="signup-container-wrap">
          <Paper className="signup-form-container">
            <div className="left-col">
              <MotorRoofLogo fill="#ddd" className="motorroof-signup-logo" />
              <div className="motorroof-register" />
              <div className="signup-motorroof" />
              <div className="motorroof-register-background" />
            </div>
            <div className="right-col">
              <h2>Sign up</h2>
              <form onSubmit={this.handleSubmit} className="signup-form">
                <FormControl margin="normal" className="form-control-row name-row" required fullWidth>
                  <TextField
                    required
                    id="firstName"
                    label="First Name"
                    name="firstname"
                    type="firstname"
                    autoComplete="firstname"
                    autoFocus={true}
                    onChange={this.handleChange}
                    margin="normal"
                  />
                  <TextField
                    required
                    id="lastName"
                    label="Last Name"
                    name="lastname"
                    type="lastname"
                    autoComplete="lastname"
                    onChange={this.handleChange}
                    margin="normal"
                  />
                </FormControl>
                <FormControl margin="normal" className="form-control-row" required fullWidth>
                  <TextField
                    required
                    id="email"
                    label="Email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    onChange={this.handleChange}
                    margin="normal"
                  />
                </FormControl>
                <FormControl margin="normal" className="form-control-row" required fullWidth>
                  <TextField
                    required
                    id="password"
                    label="Password"
                    name="password"
                    type="password"
                    autoComplete="password"
                    onChange={this.handleChange}
                    margin="normal"
                  />
                </FormControl>
                <FormControl>
                  <div>
                    <Checkbox
                      checked={this.state.isChecked}
                      onChange={this.handleCheckbox}
                      value="checked"
                      color="primary"
                    />

                    <span className="checkbox-terms with-link">
                      I agree with the
                      <a
                        href="#"
                        className="terms-policy-link"
                        onClick={this.handleOpenTerms}
                      >
                        terms
                      </a>
                      and
                      <a
                        href="#"
                        className="terms-policy-link"
                        onClick={this.handleOpenPolicy}
                      >
                        privacy policy.
                      </a>
                    </span>
                    <Dialog
                      open={this.state.openTerms}
                      onClose={this.handleClose}
                      scroll="paper"
                      className="terms-and-conditions-dialog"
                    >
                   
                      <DialogTitle>TERMS OF USE</DialogTitle>
                      <DialogTitle
                        disableTypography={true}
                        className="fix-padding-terms"
                      >
                        Last updated November 15, 2019
                      </DialogTitle>
                      <DialogContent>
                        <TermsText />
                      </DialogContent>
                      <DialogActions>
                        <Button
                          onClick={() => this.handleDisagreement()}
                          color="primary"
                        >
                          Disagree
                        </Button>
                        <Button
                          onClick={() => this.handleAgreement()}
                          color="primary"
                        >
                          Agree
                        </Button>
                      </DialogActions>
                    </Dialog>
                    <Dialog
                      open={this.state.openPolicy}
                      onClose={this.handleClose}
                      scroll="paper"
                      className="privacy-policy-dialog"
                    >
                      <DialogTitle>PRIVACY POLICY</DialogTitle>
                      <DialogTitle
                        disableTypography={true}
                        className="fix-padding-terms"
                      >
                        Last Updated: November 15, 2019
                      </DialogTitle>
                      <DialogContent>
                        <PrivacyPolicy />
                      </DialogContent>
                      <DialogActions>
                        <Button
                          onClick={this.handleDisagreement}
                          color="primary"
                        >
                          Disagree
                        </Button>
                        <Button onClick={this.handleAgreement} color="primary">
                          Agree
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </div>
                  <div>
                    <Checkbox
                      checked={this.state.isSubscribedChecked}
                      onChange={this.handleSubscribedCheckbox}
                      value="checked"
                      color="primary"
                    />

                    <span className="checkbox-terms">
                      Subscribe to our newsletter
                    </span>
                  </div>                  
                </FormControl>
                <Button
                  type="submit"
                  disabled={this.state.loading}
                  fullWidth
                  variant="contained"
                  size="large"
                  color="primary"
                  className="login-btn"
                >
                  {this.state.loading ? (
                    <CircularProgress
                      size={20}
                      thickness={6}
                      className="loader-login-spinner"
                    />
                  ) : (
                    <div>
                      <span>Create Account</span>
                    </div>
                  )}
                </Button>
              </form>
              <div className="signup-cta-links">
                <span>Already have an MotorRoof account?</span>
                <Link to="/login">Log in</Link>
              </div>
            </div>
          </Paper>
        </div>
        <div className="signup-footer-bar" />
        <div className="signup-footer">
          <p>©{year_current} Motor Roof, LLC. All rights reserved.</p>
          <div className="footer-link-holder">
            <a href="https://motorroof.com/terms-of-use" target="_blank">Terms</a>
            <a href="https://motorroof.com/privacy-policy" target="_blank">Privacy</a>
            <a href="https://motorroof.com/disclaimer" target="_blank">
              Disclaimer
            </a>
            {/* <a href="https://help.motorroof.com/">Help</a> */}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(SignUp);
