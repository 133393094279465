import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import FormControl from '@material-ui/core/FormControl';

import { emphasize, makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import NoSsr from '@material-ui/core/NoSsr';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';

const state_suggestions = [
  {label:"AK", value: "AK - Alaska"}, 
  {label:"AL", value: "AL - Alabama"}, 
  {label:"AR", value: "AR - Arkansas"}, 
  {label:"AS", value: "AS - American Samoa"}, 
  {label:"AZ", value: "AZ - Arizona"}, 
  {label:"CA", value: "CA - California"}, 
  {label:"CO", value: "CO - Colorado"}, 
  {label:"CT", value: "CT - Connecticut"}, 
  {label:"DC", value: "DC - District of Columbia"}, 
  {label:"DE", value: "DE - Delaware"}, 
  {label:"FL", value: "FL - Florida"}, 
  {label:"GA", value: "GA - Georgia"}, 
  {label:"GU", value: "GU - Guam"}, 
  {label:"HI", value: "HI - Hawaii"}, 
  {label:"IA", value: "IA - Iowa"}, 
  {label:"ID", value: "ID - Idaho"}, 
  {label:"IL", value: "IL - Illinois"}, 
  {label:"IN", value: "IN - Indiana"}, 
  {label:"KS", value: "KS - Kansas"}, 
  {label:"KY", value: "KY - Kentucky"}, 
  {label:"LA", value: "LA - Louisiana"}, 
  {label:"MA", value: "MA - Massachusetts"}, 
  {label:"MD", value: "MD - Maryland"}, 
  {label:"ME", value: "ME - Maine"}, 
  {label:"MI", value: "MI - Michigan"}, 
  {label:"MN", value: "MN - Minnesota"}, 
  {label:"MO", value: "MO - Missouri"}, 
  {label:"MS", value: "MS - Mississippi"}, 
  {label:"MT", value: "MT - Montana"}, 
  {label:"NC", value: "NC - North Carolina"}, 
  {label:"ND", value: "ND - North Dakota"}, 
  {label:"NE", value: "NE - Nebraska"}, 
  {label:"NH", value: "NH - New Hampshire"}, 
  {label:"NJ", value: "NJ - New Jersey"}, 
  {label:"NM", value: "NM - New Mexico"}, 
  {label:"NV", value: "NV - Nevada"}, 
  {label:"NY", value: "NY - New York"}, 
  {label:"OH", value: "OH - Ohio"}, 
  {label:"OK", value: "OK - Oklahoma"}, 
  {label:"OR", value: "OR - Oregon"}, 
  {label:"PA", value: "PA - Pennsylvania"}, 
  {label:"PR", value: "PR - Puerto Rico"}, 
  {label:"RI", value: "RI - Rhode Island"}, 
  {label:"SC", value: "SC - South Carolina"}, 
  {label:"SD", value: "SD - South Dakota"}, 
  {label:"TN", value: "TN - Tennessee"}, 
  {label:"TX", value: "TX - Texas"}, 
  {label:"UT", value: "UT - Utah"}, 
  {label:"VA", value: "VA - Virginia"}, 
  {label:"VI", value: "VI - Virgin Islands"}, 
  {label:"VT", value: "VT - Vermont"}, 
  {label:"WA", value: "WA - Washington"}, 
  {label:"WI", value: "WI - Wisconsin"}, 
  {label:"WV", value: "WV - West Virginia"},
  {label:"WY", value: "WY - Wyoming"}
];

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  input: {
    display: 'flex',
    padding: 0,
    height: 'auto',
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
  },
  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08,
    ),
  },
  noOptionsMessage: {
    padding: theme.spacing(1, 2),
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    bottom: 6,
    fontSize: 16,
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  }
}));

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

NoOptionsMessage.propTypes = {
  /**
   * The children to be rendered.
   */
  children: PropTypes.node,
  /**
   * Props to be passed on to the wrapper.
   */
  innerProps: PropTypes.object.isRequired,
  selectProps: PropTypes.object.isRequired,
};

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

inputComponent.propTypes = {
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.any.isRequired,
    }),
  ]),
};

function Control(props) {
  const {
    children,
    innerProps,
    innerRef,
    selectProps: { classes, TextFieldProps },
  } = props;
  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: classes.input,
          ref: innerRef,
          children,
          ...innerProps,
        },
      }}
      {...TextFieldProps}
    />
  );
}

Control.propTypes = {
  /**
   * Children to render.
   */
  children: PropTypes.node,
  /**
   * The mouse down event and the innerRef to pass down to the controller element.
   */
  innerProps: PropTypes.shape({
    onMouseDown: PropTypes.func.isRequired,
  }).isRequired,
  innerRef: PropTypes.oneOfType([
    PropTypes.oneOf([null]),
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.any.isRequired,
    }),
  ]).isRequired,
  selectProps: PropTypes.object.isRequired,
};

function Option(props) {
  return (
    <MenuItem
      ref={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

Option.propTypes = {
  /**
   * The children to be rendered.
   */
  children: PropTypes.node,
  /**
   * props passed to the wrapping element for the group.
   */
  innerProps: PropTypes.shape({
    id: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    onMouseMove: PropTypes.func.isRequired,
    onMouseOver: PropTypes.func.isRequired,
    tabIndex: PropTypes.number.isRequired,
  }).isRequired,
  /**
   * Inner ref to DOM Node
   */
  innerRef: PropTypes.oneOfType([
    PropTypes.oneOf([null]),
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.any.isRequired,
    }),
  ]).isRequired,
  /**
   * Whether the option is focused.
   */
  isFocused: PropTypes.bool.isRequired,
  /**
   * Whether the option is selected.
   */
  isSelected: PropTypes.bool.isRequired,
};

function Placeholder(props) {
  const { selectProps, innerProps = {}, children } = props;
  return (
    <Typography color="textSecondary" className={selectProps.classes.placeholder} {...innerProps}>
      {children}
    </Typography>
  );
}

Placeholder.propTypes = {
  /**
   * The children to be rendered.
   */
  children: PropTypes.node,
  /**
   * props passed to the wrapping element for the group.
   */
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired,
};

function SingleValue(props) {
  return (
    <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

SingleValue.propTypes = {
  /**
   * The children to be rendered.
   */
  children: PropTypes.node,
  /**
   * Props passed to the wrapping element for the group.
   */
  innerProps: PropTypes.any.isRequired,
  selectProps: PropTypes.object.isRequired,
};

function ValueContainer(props) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

ValueContainer.propTypes = {
  /**
   * The children to be rendered.
   */
  children: PropTypes.node,
  selectProps: PropTypes.object.isRequired,
};


function Menu(props) {
  return (
    <React.Fragment>
    {props.selectProps.disabled?
      <div></div>:
      <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
        {props.children}
      </Paper>
    }
    </React.Fragment>
  );
}

Menu.propTypes = {
  /**
   * The children to be rendered.
   */
  children: PropTypes.element.isRequired,
  /**
   * Props to be passed to the menu wrapper.
   */
  innerProps: PropTypes.object.isRequired,
  selectProps: PropTypes.object.isRequired,
};


export default function IntegrationReactSelect(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [single, setSingle] = React.useState(null);

  function handleChangeSingle(value) {
    setSingle(value);
    props.setSelectedState(value);
  }

  const selectStyles = {
    input: base => ({
      ...base,
      color: theme.palette.text.primary,
      '& input': {
        font: 'inherit',
      },
    }),
  };

  return (
    <div className="form-container-select">
    <div className={classes.root}>
      <NoSsr>
        {props.disabled?
          <div className="disable-dropdown"></div>:null
        }
        <Select
          classes={classes}
          styles={selectStyles}
          inputId="react-select-single"
          disabled={props.disabled}
          inputProps={
            {
              autoComplete: 'new-password'
            }
          }
          TextFieldProps={{
            label: 'State',
            disabled: props.disabled,
            InputLabelProps: {
              htmlFor: 'react-select-single',
              shrink: true,
              autoComplete: 'new-password'
            },
          }}
          placeholder="State"
          options={state_suggestions}
          components={{
            Control,
            Menu,
            NoOptionsMessage,
            Option,
            Placeholder,
            SingleValue,
            ValueContainer
          }}
          value={props.currentSelect? {label: props.currentSelect, value: props.currentSelect}: single}
          onChange={props.disabled? null:handleChangeSingle}
        />
      </NoSsr>
    </div>
    </div>
  );
}