import React from "react";
import Typography from "@material-ui/core/Typography";
import Rater from 'react-rater';
import firebase from 'firebase/app';

const Star = ({ willBeActive, isActive, style }) => {
  const fill = isActive ? '#fc6' : willBeActive ? '#ffdd99' : '#e3e3e3'
  return (
    <svg viewBox="0 0 19.481 19.481" width="15" height="15" style={style}>
      <path
        fill={fill}
        d="m10.201,.758l2.478,5.865 6.344,.545c0.44,0.038 0.619,0.587 0.285,0.876l-4.812,4.169 1.442,6.202c0.1,0.431-0.367,0.77-0.745,0.541l-5.452-3.288-5.452,3.288c-0.379,0.228-0.845-0.111-0.745-0.541l1.442-6.202-4.813-4.17c-0.334-0.289-0.156-0.838 0.285-0.876l6.344-.545 2.478-5.864c0.172-0.408 0.749-0.408 0.921,0z"
      />
    </svg>
  )
}

const ReviewList = props => {
  return (
    <div>
      {props.type == "reservation"?
        <div className="reservation-reviews">
          {props.haveReview === false?
            <div className="has-no-review-of-self">
              ✏️ The {props.userType === "guest"? "host": "guest"} hasn't reviewed you yet!
            </div>:null
          }
          {props.reviews.map((review) => (
            <div className={`review-block ${review.written_by_user_id === firebase.auth().currentUser.uid? 'viewers-review': ''}`}>
            {review.written_by_user_id === firebase.auth().currentUser.uid?
              <div className="reviwers-title">
                <Typography>Your Review</Typography>
              </div>:null
            }
            {review.written_by_user_id != firebase.auth().currentUser.uid?
              <div className="reviewedby-title">
                <Typography>{props.userType === "host"? "Your Guest's Review": "Your Host's Review"}</Typography>
              </div>:null
            }
            <Typography>
              {review.content}
            </Typography>
            <Rater
              total={5}
              rating={review.rating}
              interactive={false}
            >
              <Star />
            </Rater>
            <div className="reviewer">
              <Typography>
                {review.written_by_name}
              </Typography>
            </div>
            </div>
          ))}
        </div>:null
      }
      {props.type == "user"?
        <div className="user-reviews">
          {props.reviews.map((review) => (
            <div className="review-block for-user">
              <Typography>
              {review.content}
              </Typography>
              <Rater
                total={5}
                rating={review.rating}
                interactive={false}
              >
                <Star />
              </Rater>
              <div className="reviewer">
                <Typography>
                  {review.written_by_name}
                </Typography>
              </div>
            </div>
          ))}
        </div>:null
      }
    </div>
  )
};

export default ReviewList;
