import React ,{ useState } from 'react';
import Typography from "@material-ui/core/Typography";

import Button from "@material-ui/core/Button";
import {Link} from "react-router-dom";

import VideoMakeReservation from "../images/make-reservation.mp4";
import VideoUploadImage from "../images/upload-image.mp4";
import VideoSendChat from "../images/send-chat.mp4";
import VideoSearch from "../images/search.mp4";

const WhatIsMotorRoof = props => {
  return (
    <div className="what-is-motorroof-wrap">
      <div className="what-is-container">
        <div className="renter-or-host">
          <h2>
            Are you a renter or host?
          </h2>
          <Typography>
            MotorRoof is a marketplace community for sharing extra spaces. Each place is unique, and listing is free. MotorRoof provides 24-hour support and tools to help verify users, including public reviews.
          </Typography>
        </div>
        <div className="renter-col">
          <h2>
            Renting
          </h2>
          <div className="step-row-home">
            <span><Typography>1</Typography></span>
            <Typography>
              Search for where you want to rent a garage, storage, parking, or hobby spot.
            </Typography>
            <div className="how-to-search">
              <video autostart={"true"} autoPlay muted loop src={VideoSearch} type="video/mp4" />
            </div>
          </div>
          <div className="step-row-home step-2-renter">
            <span><Typography>2</Typography></span>
            <Typography>
            Reserve the dates you need the space.
            </Typography>
            <div className="how-to-monitor reservation">
              <div className="monitor-navbar">
                <div className="close-monitor"></div>
                <div className="min-monitor"></div>
                <div className="expand-monitor"></div>
              </div>
              <video autostart={"true"} autoPlay muted loop src={VideoMakeReservation} type="video/mp4" />
            </div>
          </div>
          <div className="step-row-home step-3-renter">
          <span><Typography>3</Typography></span>
            <Typography>
            Your host has 48 hours to approve your reservation unless you book the same day. 
            </Typography>
          </div>
        </div>
        <div className="host-col">
          <h2>
            Hosting
          </h2>
          <div className="step-row-home">
          <span><Typography>1</Typography></span>
          <Typography>
          Sign up and add your space. Don't forget to add pictures!
          </Typography>
          <div className="how-to-monitor create-garage">
            <div className="monitor-navbar">
              <div className="close-monitor"></div>
              <div className="min-monitor"></div>
              <div className="expand-monitor"></div>
            </div>
            <video autostart={"true"} autoPlay muted loop src={VideoUploadImage} type="video/mp4" />
          </div>
          </div>
          <div className="step-row-home">
          <span><Typography>2</Typography></span>
          <Typography>
          When someone books your space or sends you a message, you will receive an email and notification.
          </Typography>
           <div className="how-to-monitor sending-chats">
            <div className="monitor-navbar">
              <div className="close-monitor"></div>
              <div className="min-monitor"></div>
              <div className="expand-monitor"></div>
            </div>
            <video autostart={"true"} autoPlay muted loop src={VideoSendChat} type="video/mp4" />
          </div>
          </div>
        </div>
        <div className="left-box-home">
        </div>
        <div className="right-box-home">
        </div>
        <div className="review-each-other">
          <div className="review-each-content">
          <h2>Review each other!</h2>
          <p>Let the community know about your experience by writing a public review after your reservation.</p>
          </div>
          <div className="review-preview">
          </div>
        </div>
      </div>
      <div className="join-the-community">
        <h2>Join the community</h2>
        {props.signedIn?
          <Link to={'/search'}>
          <Button className="home-std-btn">Browse Spaces</Button>
          </Link>:
          <Link to={'/signup'}>
          <Button className="home-std-btn">Sign up</Button>
          </Link>
        }
      </div>
    </div>
  )
}

export default WhatIsMotorRoof;