import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import moment from 'moment';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const formatCurrencyUSD = (amount) => {
  const locale = 'en-US';
  const options = { style: 'currency', currency: 'USD'};
  return Intl.NumberFormat(locale, options).format(amount);
}


export default function PrintableTable(props) {
  const classes = useStyles();

  return (
    <TableContainer>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Payment Type</TableCell>
            <TableCell align="left">Payout Status</TableCell>
            <TableCell align="left">Reservation Id</TableCell>
            <TableCell align="left">Created At</TableCell>
            <TableCell align="left">Paid On</TableCell>
            <TableCell align="left">Guest Payment</TableCell>
            <TableCell align="right">Payment Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.printRecords.map((row) => (
            <TableRow key={row.uid}>
              <TableCell component="th"scope="row" padding="none">
                {row.request_type}
              </TableCell>
              <TableCell align="left"><div className={`payment-status-label ${row.payout_status.replace(/ /g,"_").toLowerCase()}`}>{row.payout_status}</div></TableCell>
              <TableCell align="left">{`#${row.reservation_id.slice(0,8).toUpperCase()}`}</TableCell>
              <TableCell align="left">{moment(row.created_at).format("MM/DD/YYYY")}</TableCell>
              <TableCell align="left">{row.paid_on === "0"? '—': moment(row.paid_on).format("MM/DD/YYYY")}</TableCell>
              <TableCell align="left" size="small"><div className={`paymentstatus ${row.paid.replace(/ /g,"_").toLowerCase()}`}>{row.paid}</div></TableCell>
              <TableCell align="right" size="small">{formatCurrencyUSD(row.payment_amount)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
