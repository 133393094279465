import React, { useState } from 'react';
import { NavLink, Link, Redirect } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import firebase from 'firebase/app';
import PhoneIcon from '@material-ui/icons/Phone';
import { ReactComponent as Logo } from '../images/motorroof-logo.svg';
import { ReactComponent as FacebookIcon } from '../images/facebook.svg';
import { ReactComponent as InstagramIcon } from '../images/instagram.svg';
import { ReactComponent as TwitterIcon } from '../images/twitter.svg';
import { ReactComponent as YoutubeIcon } from '../images/youtube.svg';

import "../styles/footer.scss";

const Footer = (props) => {
  var userAuth = firebase.auth().currentUser? true: false;
  return (
    <div className="footer-wrap">
      <div className="footer-content-container">
        <div className="footer-logo-col">
          <Logo />
        </div>
        <div className="footer-link-col-container">
          <div className="footer-link-col">
            <a href="https://www.notion.so/Job-Board-cbfcd5c67c2449baa6b70be3184cca6d" target="_blank" >
              Careers
            </a>
            {/* <a href="https://help.motorroof.com/" >
              Help Desk
            </a> */}
          </div>
          <div className="footer-link-col">
            <NavLink to="/how-to-host" >
              Hosting 101
            </NavLink>
            {userAuth?
              <React.Fragment>
                <NavLink to="/dashboard" >
                  Dashboard
                </NavLink>
                <NavLink to="/reservations" >
                  Reservations
                </NavLink>
                <NavLink to="/hosting-hub" >
                  Hosting Hub
                </NavLink>
              </React.Fragment>:
              <React.Fragment>
                <NavLink to="/login" >
                  Log in
                </NavLink>
                <NavLink to="/signup" >
                  Sign up
                </NavLink>
              </React.Fragment>
            }
          </div>
          <div className="footer-link-col">
            <a href="https://motorroof.com/terms-of-use" target="_blank">
              Terms of Use
            </a>
            <a href="https://motorroof.com/privacy-policy" target="_blank" >
              Privacy Policy
            </a>
            <a href="https://motorroof.com/disclaimer" target="_blank">
              Disclaimer
            </a>
          </div>
        </div>
      </div>
      <div className="footer-sub-nav-container">
        <div className="footer-sub-left-col social-links-row">
          <Typography variant={"caption"}>
            Follow Us
          </Typography>
          <a href="https://www.facebook.com/motorroof" className="facebook-btn" target="_blank">
            <FacebookIcon />
          </a>
          <a href="https://www.instagram.com/motorroof/" className="instagram-btn" target="_blank">
            <InstagramIcon />
          </a>
          <a href="https://twitter.com/MotorRoofApp" className="twitter-btn" target="_blank">
            <TwitterIcon />
          </a>
          <a href="https://www.youtube.com/channel/UCJyCoFTJkMRe_9JVwJ6uZ3g" className="youtube-btn" target="_blank">
            <YoutubeIcon />
          </a>
        </div>
        <div className="footer-sub-right-col support-number">
          <Typography variant={"caption"}>
          Need Help?
          </Typography>
          <Typography><PhoneIcon />(855)212-3262</Typography>
        </div>
      </div>
      <div className="copy-and-claim-row">
        <Typography variant="caption">
          Copyright {new Date().getFullYear()}. Motor Roof, LLC, All rights reserved. Made with &hearts; in Michigan.
        </Typography>
      </div>
    </div>
  );
}
export default Footer;
