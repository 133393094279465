import React from "react";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import CheckIcon from '@material-ui/icons/Check';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});



const VerifyID = props => {
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [verifyIDStatus, setVerifyIDStatus] = React.useState(props.isVerified);

  function toggleForm(event) {
    setOpen(!open);
  };

  function sendVerification(event) {
    setLoading(true);
  };

  function checkLater(event) {
    toggleForm();
  };

  return (
    <div>
      <div className="verify-dash-block hide-element">
        <div className="verify-title-block passport">
        </div>
        <Button size="small" className="verify-dash-btn" onClick={() => toggleForm()}>
          <span>
            ID Verification
          </span>
        </Button>
      </div>
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => toggleForm()}
      className="verify-id-block"
      >
      {verifyIDStatus?
        <div className="verify-complete">
          <Typography variant="h4">Your Email is Verified</Typography>
          <Typography variant="body2">
            Thank you for verifying your government issued identification.
          </Typography>
          <Button className="verify-success-btn" onClick={() => toggleForm()}>
            <CheckIcon />
            Continue
          </Button>
        </div>:
        <div>
          <img
            src={require("../images/phone-check-motorroof.png")}
            alt="verify identification"
            className="verify-id-hero"
          />
          <div className="verify-sent">
            <Typography variant="h4">ID Verification</Typography>
            <Typography variant="body2">
              In order to protect against fraud we request that users verifiy their government issued identification.
            </Typography>
            <Button
              className={"begin-verify-id"}
              disabled={loading}
              onClick={checkLater}>
              {loading?
              <CircularProgress
                size={20}
                thickness={6}
                className="loader-login-spinner"
              />:
              <React.Fragment>
              Begin
              </React.Fragment>}
            </Button>
            <Button
              className={"check-id-later"}
              disabled={loading}
            >
            Check Later
            </Button>
        </div>
      </div>
      }
      </Dialog>
    </div>
  );
};


export default VerifyID;
