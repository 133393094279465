import React from "react";
import Typography from "@material-ui/core/Typography";
import HelpIcon from '@material-ui/icons/Help';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import firebase from 'firebase/app';

import { ReactComponent as StripeLogo } from '../images/stripe-logo.svg';

const ConnectStripe = props => {
  const [aboutStripeOpen, setAboutStripeOpen] = React.useState(false);
  const [stripeUrl, setStripeUrl] = React.useState(null);

  const toggleAboutStripe = () => {
    setAboutStripeOpen(!aboutStripeOpen);
  };

  //state passed to url to help prevent CRSF we check this on callback
  let state_store = localStorage.getItem("state_store");
  if(state_store) {
    // user has state_store
  } else {
    // they need state_stroe
    localStorage.setItem("state_store", Math.floor(Math.random() * 9999999999).toString());
    state_store = localStorage.getItem("state_store");
  }

  //check for prefill info to pass to connect_url to help!
  var userInfoRef = firebase.database().ref('users/' + firebase.auth().currentUser.uid + '/profile');
  userInfoRef.once("value").then((snapshot) => {
    var data = snapshot.val();
    var phone = data.phone? data.phone: null;
    var email = firebase.auth().currentUser.email;

    const isDevMode = process.env.NODE_ENV === "development" && process.env.NODE_ENV !== "production"? true: false;

    const callback_uri = isDevMode? 'http://localhost:3000/dashboard/stripe/token': 'https://motorroof.com/dashboard/stripe/token';
    const client_id = isDevMode? 'ca_JeIjPPUmfXv0Q6aqJ6A8Yrk4vGbi64og': 'ca_Fo95s2vEErHu91dEj0wRRFoXRkVj6drG';
    setStripeUrl(`https://connect.stripe.com/express/oauth/authorize?redirect_uri=${callback_uri}&stripe_user[phone_number]=${phone? phone.split("+")[1]: ''}&stripe_user[country]=US&client_id=${client_id}&stripe_user[email]=${email}&state=${state_store}`);
  });

  return (
    <React.Fragment>
    {props.isButton?
      <a href={stripeUrl}>
        <Button>
          <Typography variant="body2">Setup Direct Deposit</Typography>
        </Button>
      </a>    
      :<React.Fragment>
          <div className="no-seller-account-found">
            {stripeUrl?
              <React.Fragment>
              <Dialog
                open={aboutStripeOpen}
                onClose={toggleAboutStripe}
              >
              <div className="about-stripe-dialog">
                <Typography variant="h4">Why do we use Stripe?</Typography>
                <Typography variant="body2">Stripe handle billions of dollars every year for forward-thinking businesses around the world. MotorRoof is one of the millions of companies in over 120 countries that use Stripe to start, run, and scale their businesses.</Typography>
                <Typography style={{"marginTop": 20, "marginBottom": 20}}>See how popular companies such as Lyft uses Stripe to securely pay drivers around the world!</Typography>
                <iframe width="100%" height="315" src="https://www.youtube.com/embed/hLQjGzK_GHk" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <div className="stripe-info-actions-wrap">
                  <a href={stripeUrl}>
                    <Button className="connect-payout-btn">Connect Payout with Stripe</Button>
                  </a>
                  <Button className="close-connect-btn" onClick={toggleAboutStripe}>Close</Button>
                </div>
              </div>
              </Dialog>
              <div className={`info-col ${props.type && props.type == "sidebar"? "sidebar-size": ""}`}>
                <Typography variant="h2">
                  {props.type && props.type == "sidebar"?
                  "📣 Get direct deposits":
                  "📣 Add hosting payment information."}
                </Typography>
                <Typography variant="body2" style={{"marginTop": 10}}>
                  {props.type && props.type == "sidebar"?
                  "Setup direct deposits from MotorRoof to your bank account.":
                  "Every 1st of the month you will receive a deposit of your earnings for the month. We've partnered with Stripe™ to help securely connect your bank account for receiving your payments for hosting on MotorRoof."
                  }
                  {props.type && props.type == "sidebar"?
                    null:<div><Typography variant="body2" style={{"marginTop": 10}}> You can still receive a check payout for reservations {/*<a href="https://help.motorroof.com/article/48-receiving-a-check-payout-instead-of-direct-deposit" target="_blank">learn more</a>*/}</Typography></div>}
                </Typography>
                <a href={stripeUrl}>
                  <Button className="connect-payout-btn">Connect Payout</Button>
                </a>
              </div>
              <div className={`stripe-col ${props.type && props.type == "sidebar"? "sidebar-size": ""}`}>
                <div className="about-stripe">
                  <HelpIcon className="stripe-about-btn" onClick={toggleAboutStripe}/>
                  <StripeLogo />
                  <Typography variant="caption">powered by</Typography>
                </div>
              </div>
              </React.Fragment>:null
            }
          </div>
        </React.Fragment>
      }
    </React.Fragment>
  );
};

export default ConnectStripe;
