import React from 'react';
import Typography from '@material-ui/core/Typography';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import Tooltip from '@material-ui/core/Tooltip';
import HelpIcon from '@material-ui/icons/HelpOutline';

import { withStyles } from '@material-ui/core/styles';

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

const weekdayList = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];

const GarageReservationAccessHours = (props) => {
  return(
    <div>
      {props.garage?
       <div className="garage-access-hours">
        {props.garage.hours?
          <div className="hours-wrap">
            <div className={props.garage.hours && props.garage.hours.is24hours? "access-hours-title is-open-24hours": "access-hours-title"}>
              <AccessTimeIcon className="time-icon" />
              <Typography variant="h5">Access Hours</Typography>
              <HtmlTooltip
                placement="top"
                title={
                  <React.Fragment>
                    <Typography color="inherit" variant="body2">These are set times by the owner of the garage for when you can visit or use the space.</Typography>
                  </React.Fragment>
                }
              >
                <HelpIcon className="info-hover" />
              </HtmlTooltip>
            </div>
            { props.garage.hours && props.garage.hours.is24hours?
            <React.Fragment></React.Fragment>:
            <React.Fragment>
            {props.garage.hours.monday && weekdayList.map((weekdayName, index) => (
              <div key={`weekday-list-${index}`}>
                {props.garage.hours[weekdayName].map((item, index) => (
                  <div key={`open-day-${index}`}>
                    {props.garage.hours.isOpen[item.name]?
                      <div className="hours-information is-open">
                        <div className="day-of-week-title">
                          <Typography variant="body2">
                            {`${item.name.charAt(0).toUpperCase() + item.name.slice(1)}`}
                          </Typography>
                        </div>

                        {item.startHours && item.endHours?
                        <React.Fragment>
                        <div className="hour-info-col">
                          <Typography variant="h4">
                            {item.startHours? item.startHours: ''}
                          </Typography>
                        </div>
                        <div className='dash-hours'>
                        ―
                        </div>
                        <div className="closed-at-col">
                          <Typography variant="h4">
                            {item.endHours? item.endHours: ''}
                          </Typography>
                        </div>
                        </React.Fragment>:<div className="no-hours-but-open">
                        <Typography variant="h4">
                          Open
                        </Typography>
                        </div>
                        }
                      </div>:
                      <div className="hours-information closed">
                         <Typography variant="h4">
                          {`${item.name.charAt(0).toUpperCase() + item.name.slice(1)} closed`}
                        </Typography>
                      </div>
                    }
                  </div>
                ))}
              </div>
            ))}
            </React.Fragment>
          }
          </div>:
          <React.Fragment>
          <div className="access-hours-title no-hours">
              <AccessTimeIcon className="time-icon" />
              <Typography variant="h5">Access Hours</Typography>
              <HtmlTooltip
                placement="top"
                title={
                  <React.Fragment>
                    <Typography color="inherit" variant="body2">These are set times by the owner of the garage for when you can visit or use the space.</Typography>
                  </React.Fragment>
                }
              >
                <HelpIcon className="info-hover" />
              </HtmlTooltip>
            </div>
          <div className="no-access-hours-provided">
            <Typography variant="body2">No Hours Specified</Typography>
            <HtmlTooltip
                placement="top"
                title={
                  <React.Fragment>
                    <Typography color="inherit" variant="body2">This owner does not require specific access times.</Typography>
                  </React.Fragment>
                }
              >
                <HelpIcon className="info-hover" />
              </HtmlTooltip>
          </div>
          </React.Fragment>
        }
        <div className={props.garage.hours && !props.garage.hours.monday || !props.garage.hours? "garage-access-extra-info no-hours-box": "garage-access-extra-info"}>
          {props.garage.hours && props.garage.hours.appointmentOnly?
            <div className="garage-is-appointment-only">
              <Typography variant="body2">
              By Appointment Only
              <HtmlTooltip
                placement="top"
                title={
                  <React.Fragment>
                    <Typography color="inherit" variant="body2">Schedule appointments with one click using MotorRoof's private and secure messaging.</Typography>
                  </React.Fragment>
                }
              >
                <HelpIcon className="info-hover" />
              </HtmlTooltip>
              </Typography>

            </div>:
            <div className="garage-is-not-appointment-only">
              <Typography variant="body2">
              No Appointment Needed
              <HtmlTooltip
                placement="top"
                title={
                  <React.Fragment>
                    <Typography color="inherit" variant="body2">This owner specifies that you don't need to make an appointment to use the property.</Typography>
                  </React.Fragment>
                }
              >
                <HelpIcon className="info-hover" />
              </HtmlTooltip>
              </Typography>
            </div>
          }
          {props.garage.hours && props.garage.hours.is24hours?
            <div className="garage-is-24hours">
            <Typography variant="body2">24 Hour Access Available
            <HtmlTooltip
                placement="top"
                title={
                  <React.Fragment>
                    <Typography color="inherit" variant="body2">This space has 24-hour access!</Typography>
                  </React.Fragment>
                }
              >
                <HelpIcon className="info-hover" />
              </HtmlTooltip>
            </Typography></div>:<div></div>
          }
        </div>
      </div>:null
    }
    </div>
  )
}

export default GarageReservationAccessHours;
