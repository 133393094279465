import React ,{ useState } from 'react';
import GarageGridItem from "./GarageGridItem";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";

const formatCurrencyUSD = (amount) => {
  const locale = 'en-US';
  const options = { style: 'currency', currency: 'USD'};
  return Intl.NumberFormat(locale, options).format(amount);
}

const GaragePin = props => {
  var isWorkshop = props.garage.isWorkshop;
  var isStorage = props.garage.isStorage;
  var isOpen = props.activePinInfoBox == props.garage.index && props.garage.markerDisplay;
  var priceWidth = props.garage.price.length;
  var typeWidth = props.garage.isStorage && props.garage.isWorkshop? 2:1;
  return (
    <div className={`garage-map-pin-wrap type-width-${typeWidth} tag-width-${priceWidth}`}>
      <div className={isOpen? "garage-map-pin opened": "garage-map-pin"}>
        <div className={isOpen? "pin-info-container opened": "pin-info-container"}>
          <Link to={props.garage.url? props.garage.url: ''} target="_blank" className="link-garage">
            <GarageGridItem
              garageTitle={props.garage.title}
              isStorage={props.garage.isStorage}
              isWorkshop={props.garage.isWorkshop}
              avatarPhotoURL={props.garage.userPhoto? props.garage.userPhoto: null}
              displayName={props.garage.owner_name}
              needsStar={props.garage.owner_id}
              price={props.garage.price}
              city={props.garage.city}
              state={props.garage.state}
              photo={props.garage.leadPhoto? props.garage.leadPhoto: null}
            />
          </Link>
        </div>
        <span className="toggle-info-pin" onClick={() => props.handleOpenMapPin(props.garage.index)}>
        {isWorkshop && isStorage?
          <div className="storage-and-workshop-pin">
            <div className="workshop-pin"></div>
            <div className="storage-pin"></div>
          </div>:
          <React.Fragment>
            {isWorkshop?
              <div className="workshop-pin"></div>:
              <React.Fragment>
                {isStorage?
                  <div className="storage-pin"></div>:<div></div>
                }
              </React.Fragment>
            }
          </React.Fragment>
        }
        <Typography variant="body2">{formatCurrencyUSD(props.garage.price).split(".")[0]}</Typography>
        </span>
      </div>
    </div>
  )
}

export default GaragePin;