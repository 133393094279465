import React from "react";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import CheckIcon from '@material-ui/icons/Check';

import firebase from 'firebase/app';

const axios = require('axios');

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});



const VerifyEmail = props => {
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [verifyEmailStatus, setVerifyEmailStatus] = React.useState(props.isVerified);
  function toggleForm(event) {
    const user = firebase.auth().currentUser;

    const settings = {
      // In production, change the url to the domain name
      url: 'https://motorroof.com/dashboard/',
      handleCodeInApp: true,
    };

    user.sendEmailVerification(settings).then(() => {
      // Email sent.
    }).catch(error => {
      // An error happened.
    });

    setOpen(!open);
  };

  function sendVerification(event) {
    setLoading(true);
  };

  function resendCode(event) {

  };

  return (
    <div>
      <div className="verify-dash-block">
      <div className="verify-title-block email">
      </div>
      <Button size="small" className="verify-dash-btn" onClick={() => toggleForm()}>
        <span>
          Send Email Verification
        </span>
      </Button>
      </div>
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => toggleForm()}
      className="verify-email-block"
      >
      {verifyEmailStatus?
        <div className="verify-complete email-verify">
          <Typography variant="h4">Your Email is Verified</Typography>
          <img
            src={require("../images/email-check-motorroof-verified.jpg")}
            alt="verified email"
            className="verify-email-hero-complete"
          />
          <Typography variant="body2">
            Your email has been verified!
          </Typography>
          <Button className="verify-success-btn" onClick={() => toggleForm()}>
            <CheckIcon />
            Continue
          </Button>
        </div>:
        <div>
          <img
            src={require("../images/email-check-motorroof.jpg")}
            alt="verify email address"
            className="verify-email-hero"
          />
          <div className="verify-sent">
            <Typography variant="h4">Email Verification Code Sent</Typography>
            <Typography variant="body2">
              We have sent you a verification email. Click the link within the email to verify ownership.
            </Typography>
            <Button
              className={"resend-verification-email-btn"}
              disabled={loading}>
              {loading?
              <CircularProgress
                size={20}
                thickness={6}
                className="loader-login-spinner"
              />:
              <React.Fragment>
              </React.Fragment>}
            </Button>
        </div>
      </div>
      }
      </Dialog>
    </div>
  );
};


export default VerifyEmail;
