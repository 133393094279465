import React from "react";
import {Link} from "react-router-dom";

import SearchHome from "./SearchHome";
import HomePageSlider from './HomePageSlider';
import FeaturedSingleGarage from '../../components/FeaturedSingleGarage';
import WordSlider from '../../components/WordSlider';
import Typography from "@material-ui/core/Typography";
import WhatIsMotorRoof from "../../components/WhatIsMotorRoof";
//import RecentNewsBlock from '../../components/RecentNewsBlock';


import Button from "@material-ui/core/Button";
import Footer from "../../components/Footer";

import { ReactComponent as TwentyFour } from '../../images/24hr.svg';
import { ReactComponent as Safe } from '../../images/safe.svg';
import { ReactComponent as Hands } from '../../images/handshake.svg';


import "../../styles/home.scss";

const Home = (props) => {
  return (
    <div className="with-navbar-wrap">
      <div className="home-slider">
        <div className="slide-1">
          <div className="rent-garages-title">
            <h1>
            Find a place to rent&nbsp;
            <WordSlider />
            </h1>
            <SearchHome redirectToReferrerSearch={props.redirectToReferrerSearch} />
            
          </div>
          <HomePageSlider />
        </div>
      </div>

      <FeaturedSingleGarage garageURI={"/garage/novi/mi/398681437669"} />

      <div className="what-can-i-rent-block">
        <div className="what-can-i-container">
          <div className="col-left">
            <div className="info-block-float">
              <div className="what-garage-mobile"></div>
              <h2>The marketplace to share your space!</h2>
              <Typography>
                MotorRoof is a platform for users to connect and share their extra spaces. You can find workshops, garages, storage, parking, and more on MotorRoof. Need a spot that has a lift, welder, or just some extra space?</Typography>
              <Typography className='extra-info'>
              You've come to the right place.
              </Typography>
              {props.signedIn?
                <Link to={"/search"}>
                  <Button className="home-std-btn">Rent some space</Button>
                </Link>:
                 <Link to={'/signup'}>
                  <Button className="home-std-btn">Rent some space</Button>
                  </Link>}
            </div>
          </div>
          <div className="what-garage"></div>
        </div>
      </div>

      <div className="become-a-host-block">
        <div className="become-a-host-container">
          <div className='col-left'>
          </div>
          <div className='col-right'>
            <h2>List your space for free</h2>
            <Typography>
              Start your entrepreneurial journey right now. Get access to advance reporting and property management tools to empower your profitability. Manage your business from anywhere at any time using your phone, tablet, or computer. No strings attached, get started right now.
            </Typography>
            <Link to={'/how-to-host'}>
              <Button className="home-std-btn">Learn More</Button>
            </Link>
          </div>
        </div>

      </div>

      {/* <RecentNewsBlock /> */}


      <div className="saftey-highlights-block">
        <div className='safety-container'>
        <div className="col-three">
          <div className="saftey-icon">
            <TwentyFour className="twenty-four-icon" />
          </div>
          <Typography>24-Hour Support</Typography>
          <span>
          <Typography>If you have any issues or need to report unsafe behavior, you can call, email, or live chat our support team anytime.</Typography>
          </span>
        </div>
        <div className="col-three">
          <div className="saftey-icon">
            <Hands className="hands-home-icon" />
          </div>
          <Typography>Safety is Priority</Typography>
          <span>
          <Typography>Scammers begone! We track unsafe behavior using Artifical Intelligence and deploy Deep Learning mechanisms for detecting bad actors.</Typography>
          </span>
        </div>
        <div className="col-three">
          <div className="saftey-icon">
            <Safe className="safe-home-icon" />
          </div>
          <Typography>Private & Secure</Typography>
          <span>
          <Typography>MotorRoof utilizes advanced data encryption technology to keep your information and transactions private and secure.</Typography>
          </span>
        </div>
        </div>
      </div>
      <WhatIsMotorRoof signedIn={props.signedIn} />
      <div className="no-padding-footer">
      <Footer />
      </div>
    </div>
  );
};

export default Home;
