import React from "react";

import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";

class ConfirmPasswordDialog extends React.Component {
  state = {
    value: "",
    loading: false
  };

  handleChange = e => this.setState({ value: e.target.value });

  startLoader = () => this.setState({ loading: true });

  stopLoader = () => this.setState({ loading: false });

  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={() => this.props.handleClose()}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle
          className="form-dialog-title"
          style={{ marginBottom: "10px" }}
        >
          Please Enter Your MotorRoof Password
        </DialogTitle>
        <DialogContent>
          <form>
            <TextField
              autoFocus
              margin="dense"
              id="password"
              label="password"
              type="password"
              value={this.state.value}
              onChange={this.handleChange}
              fullWidth
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.props.handleClose()} color="primary">
            Cancel
          </Button>
          <Button
            color="primary"
            disabled={this.state.loading}
            onClick={() => { this.startLoader(); this.props.handlePasswordSubmit(this.state.value, this.stopLoader)}}
          >
            {this.state.loading ? (
              <CircularProgress
                size={20}
                thickness={6}
                className="loader-login-spinner"
              />
            ) : (
              <div>
                <span>Confirm</span>
              </div>
            )}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default ConfirmPasswordDialog;
