import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import CheckIcon from '@material-ui/icons/Check';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import Confetti from 'react-dom-confetti';
import "../styles/welcome.scss";
import wIntroImg from "../images/stripe-connected-slide-1.jpg";
import wReserveImg from "../images/w-when-paid.jpg";
import wApproveImg from "../images/approved-guest.png";
import wTrustImg from "../images/wTrust.png";
import firebase from 'firebase/app';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const config_conf = {
  angle: "90",
  spread: "119",
  startVelocity: 45,
  elementCount: 50,
  dragFriction: 0.1,
  duration: 3000,
  stagger: 0,
  width: "10px",
  height: "10px",
  colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"]
};

const welcomeSteps = [
  {
    title: <p>Stripe <span>Connected!</span></p>,
    info: "Congratulations, you are now ready to accept payments and receive deposits for your MotorRoof space!",
    imgPath: wIntroImg
  },
  {
    title: 'When do you get paid?',
    info: "Every first of the month, you will receive an automatic deposit of your earnings!",
    imgPath: wReserveImg
  },
  {
    title: 'Host Support',
    info: "We are always here to help if you have any questions or concerns. Feel free to call, chat, or email us 24/7! Good luck with your new venture!",
    imgPath: wApproveImg
  },
];

function StripeConfirm() {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const [open, setOpen] = React.useState(true);
  const [confettiOn, setConfettiOn] = React.useState(false)
  const maxSteps = welcomeSteps.length;

  function handleNext() {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  }

  function handleBack() {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  }

  function handleStepChange(step) {
    setActiveStep(step);
  }

  function handleClose() {
    var userProfileRef = firebase.database().ref('users/' + firebase.auth().currentUser.uid + '/profile');
    userProfileRef.child("beenStripeHostWelcomed").set(true).then(() => {
      userProfileRef.off();
      setOpen(false);
    });
  }

  function triggerYeyFactor() {
    var timer = setTimeout(() => {
      setConfettiOn(true);
      clearTimeout(timer);
    }, 200);
  }

  return (
    <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        className={"welcome-screen-view"}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
    {triggerYeyFactor()}
    <div className="confetti-wrapper">
      <Confetti active={ confettiOn } config={ config_conf }/>
    </div>
    <div className="welcome-slide-container">
      <div className="welcome-slide-title">
        <Typography >{welcomeSteps[activeStep].title}</Typography>
      </div>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {welcomeSteps.map((step, index) => (
          <div key={step.label}>
            {Math.abs(activeStep - index) <= 2 ? (
              <img className={"welcome-hero " + "step-" + index} src={step.imgPath} alt={step.label} />
            ) : null}
          </div>
        ))}
      </SwipeableViews>
      <div className="welcome-slide-info">
        <Typography >{welcomeSteps[activeStep].info}</Typography>
      </div>
      <MobileStepper
        steps={maxSteps}
        position="static"
        variant="dots"
        className="stepper-dots"
        activeStep={activeStep}
      />
      <div className="button-holder-welcome">
      {activeStep > 0?
        <Button size="small" className="back-btn-welcome" onClick={handleBack} disabled={activeStep === 0}>
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </Button>:<div></div>
      }
      {activeStep === maxSteps - 1?
        <Button size="small" className="welcome-next-btn thanks" onClick={() => handleClose()}>
          Got it thanks!
          <CheckIcon />
        </Button>:
        <Button size="small" className="welcome-next-btn" onClick={handleNext}>
          Next
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </Button>
      }
      </div>
    </div>
    </Dialog>
  );
}

export default StripeConfirm;