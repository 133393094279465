import React from "react";
import MapGL, {Marker, NavigationControl} from 'react-map-gl';


const MAPBOX_TOKEN = 'pk.eyJ1IjoibW90b3Jyb29mIiwiYSI6ImNqeWJhdXJlbTA3ZXgzZHA1aTY1MHp1OXYifQ.gzAtdjOpRs5jdFvWgf93vw';

const GarageAreaMap = (props) => {
  const viewport = {
    latitude: props.location.latitude,
    longitude: props.location.longitude,
    zoom: 12,
    bearing: 0,
    pitch: 0,
    height: "300px",
    width: "100%",
  }
  return (  
    <MapGL
      {...viewport}
      mapboxApiAccessToken={MAPBOX_TOKEN}
      scrollZoom={false}
      mapStyle="mapbox://styles/mapbox/streets-v11"
    > 
      <Marker
        longitude={props.location.longitude}
        latitude={props.location.latitude}
        offsetTop={-20}
        offsetLeft={-10}
      >
      <div className="garage-map-area-general-location" ></div>
      </Marker>
    </MapGL>
  )
}

export default GarageAreaMap;
