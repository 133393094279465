import React from "react";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import StarRate from './StarRate';
import ReviewList from './ReviewList';
import Typography from "@material-ui/core/Typography";

import firebase from 'firebase/app';
import dbFireStore from '../firebase';

class WriteReview extends React.Component {
  state = {
    value: "",
    reviewWritten: false,
    loadingReviews: true,
    haveReview: false,
    reviews: [],
  };

  componentDidMount() {
    this.getReviews(this.props.reservationId);
  }

  getReviews = async (reservation_id) => {
    this.setState({reviews: []});
    await dbFireStore.firestore()
      .collection("reviews")
      .where("reservation", "==", reservation_id)
      .get()
      .then((querySnapshot) => {
        var loopedAmount = 0;
        if(querySnapshot.empty) {
          // no reviews yet
          this.setState({loadingReviews: false, reviewWritten: false});
        } else {
          querySnapshot.forEach(doc => {
            loopedAmount++;
            if(doc.data().written_by_user_id === firebase.auth().currentUser.uid) {
              // this person viewing has written their review, so they can see all reviews now.
              this.setState(prevState => ({
                reviews: [...prevState.reviews, doc.data()],
                reviewWritten: true,
              }));
            } else {
              // not viewers, but adding it to the state for viewing after writing the viewers review
              this.setState(prevState => ({
                reviews: [...prevState.reviews, doc.data()],
                haveReview: true,
              }));
            }
            if(querySnapshot.docs.length === loopedAmount) {
              this.setState({loadingReviews: false});
            }
          });
        }
      });
  };

  handleChange = e => this.setState({ value: e.target.value });

  postReview = (e) => {
    if(this.props.rating === null) {
      this.props.handleError({
        message: "Please enter a rating."
      });
    } else if (this.state.value.length === 0) {
      this.props.handleError({
        message: "Please write a review of the user."
      });
    } else {
      var hasSwearWords = this.state.value.toLowerCase().includes("fuck") || this.state.value.toLowerCase().includes("shit");
      
      if(hasSwearWords) {
        this.props.handleError({
          message: "Please no profanity!"
        });
      } else {
        // post review
        var reviewRef = dbFireStore.firestore().collection("reviews").doc();
        var public_name = '';
        var displayName = firebase.auth().currentUser.displayName;
        if(displayName.split().length > 1) {
          public_name = `${displayName.split(' ')[0]} ${displayName.split(' ')[1].charAt(0)}.`;
        } else {
          public_name = displayName.split(' ')[0];
        }

        var reviewInfo = {
          content: this.state.value,
          rating: this.props.rating,
          written_by_user_id: firebase.auth().currentUser.uid,
          written_by_name: public_name,
          review_of_user_id: this.props.of,
          reservation: this.props.reservationId,
          created_at: firebase.firestore.FieldValue.serverTimestamp(),
        }

        reviewRef.set(reviewInfo).then(() => {
          this.setState({
            reviewWritten: true,
          });
          this.getReviews(this.props.reservationId);
        });
      }
    }
  };

  render() {
    return (
        <React.Fragment>
        {this.state.loadingReviews?
          <div>
            <CircularProgress className="review-loader" />
          </div>
        :
        <div>
          {this.state.reviewWritten?
            <React.Fragment>
             <Typography variant="h5" className="review-list-title">
                Thank you for your review!
              </Typography>
              <div className="review-list">
                <ReviewList haveReview={this.state.haveReview} type={"reservation"} reviews={this.state.reviews} userType={this.props.userType} />
              </div>
            </React.Fragment>:
            <div>
              <Typography variant="h5">
                Write Your Review
              </Typography>
              <Typography>
                Remember to be polite, respectful, and considerate. Your review will be publicly listed to help other users, so be descriptive.
                You may also be reviewed by the {this.props.userType === 'host'? "guest.": "host."}
              </Typography>
              <div className="rate-your-stay">
                <Typography variant="h5">
                {this.props.userType === "host"? "How was your guest?": "How was your stay?"}
                </Typography>
                <StarRate passRating={this.props.passRating}/>
              </div>
              <div className="write-review-of-user">
                <Typography variant="h5">
                  Leave your public review of this {this.props.userType === "host"? "guest.": "host."}
                </Typography>
              </div>
              <form>
                <TextField
                  label="Write Your Review"
                  value={this.state.value}
                  onChange={this.handleChange}
                  fullWidth
                  multiline={true}
                  variant="filled"
                  rows="4"
                />
              </form>
              <Button
                className="write-review-btn"
                onClick={(e) => this.postReview(e)}
              >
              Post Review
              </Button>
            </div>
          }
      </div>}
      </React.Fragment>
    );
  }
}

export default WriteReview;
