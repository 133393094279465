import React from 'react';

import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import InputBase from "@material-ui/core/InputBase"; 
import Button from "@material-ui/core/Button";
import LocationIcon from "@material-ui/icons/LocationOn";
import SearchIcon from '@material-ui/icons/Search';
import Typography from "@material-ui/core/Typography";

import {Link} from "react-router-dom";

import CircularProgress from "@material-ui/core/CircularProgress";
import LinearProgress from '@material-ui/core/LinearProgress';
import { ReactComponent as LocationSearchPin } from '../../images/location-search-pin.svg';

const axios = require('axios');

const MAPBOX_TOKEN = 'pk.eyJ1IjoibW90b3Jyb29mIiwiYSI6ImNqeWJhdXJlbTA3ZXgzZHA1aTY1MHp1OXYifQ.gzAtdjOpRs5jdFvWgf93vw';

class SearchHome extends React.Component {
  state = {
    search: '',
    autocompleteSearch: '',
    autocomplete: null,
    searching: false,
    keySelectIndex: null,
  };

  componentDidMount() {
    this.searchingPlace = null;
  };

  setAutocompleteSearch = e => {
    this.setState({
      autocompleteSearch: e,
    });
  };

  setSearching = b => {
    this.setState({
      searching: b,
    });
  };

  setSearchLink = s => {
    this.setState(prevState => ({
      search: s,
    }));
  };

  setAutocompleteSearchResults = results => {
    if(results) {
      this.setState({
        autocomplete: results,
      });
    } else {
      // being cleared need to reset our dropdown index selected
      this.setState({
        autocomplete: null,
        keySelectIndex: null,
      });
    }
  };

  handleChange = e => {
    // we set the time so we don't run a bunch of geoqueries if the user is in the middle of typing!
    // so this should run at the max rate of every 2s
    if(e.target.value && e.target.value.length > 0) {
      clearTimeout(this.searchingPlace);
      this.setAutocompleteSearch(e.target.value);
      this.setSearching(true);
      this.searchingPlace = setTimeout(() => {
        this.performGeoSearch();
      }, 1000);
      this.setSearchLink(encodeURIComponent(e.target.value));
    } else {
      this.setSearching(false);
      clearTimeout(this.searchingPlace);
      this.setSearchLink('');
      this.setAutocompleteSearch('');
      this.setAutocompleteSearchResults('');
      this.setState({keySelectIndex: null});
    }
  };

  performGeoSearch = () => {
    let searchQuery = encodeURI(`${this.state.autocompleteSearch}`);
    axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${searchQuery}.json?country=US&autocomplete=true&fuzzyMatch=true&types=place&access_token=${MAPBOX_TOKEN}`)
      .then((response) => {
        console.log(response.data.features);
        if(response.data.features.length > 0) {
          var locationRecords = [];
          response.data.features.forEach((place, index) => {
            var name = place.place_name.replace(', United States','');
            var long = place.geometry.coordinates[0];
            var lat = place.geometry.coordinates[1];
            locationRecords.push({index: index, name: name, longitude: long, latitude: lat});
            if(response.data.features.length == index + 1) {
              this.setAutocompleteSearchResults(locationRecords);
              this.setSearching(false);
            }
          });
        } else {
          // no results
          this.setAutocompleteSearchResults(locationRecords);
          this.setSearching(false);
        }
      })
      .catch((error) => {
        console.log('Could not find location');
        this.setSearching(false);
        this.setAutocompleteSearchResults(null);
      });
  }

  sendSearch = (event,type, i) => {
    if(type == 'auto') {
      event.preventDefault();
      if(this.state.keySelectIndex !== null) {
        // selected from dropdown
        this.setAutocompleteSearch(this.state.autocomplete[this.state.keySelectIndex].name);
        var searchLinkParams = `${encodeURIComponent(this.state.autocomplete[this.state.keySelectIndex].name)}?lat=${this.state.autocomplete[this.state.keySelectIndex].latitude}&long=${this.state.autocomplete[this.state.keySelectIndex].longitude}`;
      } else {
        // selected from topform
        var searchLinkParams = `${this.state.search}`;
      }
    } else {
      this.setAutocompleteSearch(this.state.autocomplete[i].name);
      var searchLinkParams = `${encodeURIComponent(this.state.autocomplete[i].name)}?lat=${this.state.autocomplete[i].latitude}&long=${this.state.autocomplete[i].longitude}`;
    }
    this.setAutocompleteSearchResults(null);
    this.setSearchLink(searchLinkParams);
    // then perform search direct
    clearTimeout(this.searchingPlace);
    this.setSearching(false);
    this.setAutocompleteSearchResults(null);
    this.props.redirectToReferrerSearch(`/search/${searchLinkParams}`);
  }

  onKeyPress = event => {
    if(this.state.autocomplete && this.state.autocomplete.length > 0 && !this.state.searching) {
      if(event.key == "ArrowDown") {
        event.preventDefault();
        if(this.state.keySelectIndex === null || this.state.keySelectIndex + 1 === this.state.autocomplete.length) {
          //first key down pressed OR at the end of list so go back to top
          this.setState({
            keySelectIndex: 0,
          });
        } else {
          // go down a record
          this.setState(prevState => ({
            keySelectIndex: prevState.keySelectIndex + 1,
          }));
        }
      } else if (event.key == "ArrowUp") {
        event.preventDefault();
        if(this.state.keySelectIndex === 0) {
          //first key down pressed OR at the end of list so go back to top
          this.setState(prevState => ({
            keySelectIndex: prevState.autocomplete.length - 1,
          }));
        } else if (this.state.keySelectIndex === null) {
          // don't do anything, the list displays down
        } else {
          // go up a record
          this.setState(prevState => ({
            keySelectIndex: prevState.keySelectIndex - 1,
          }));
        }
      }
    }
  };

  closeAutoSearch = () => {
    this.setSearching(false);
    clearTimeout(this.searchingPlace);
    this.setAutocompleteSearchResults(null);
    this.setState({keySelectIndex: null});
  };

  render() {
    return (
      <div className="search-form-home">
        <div className="search-location-home">
          {this.state.searching || this.state.autocomplete && this.state.autocomplete.length > 0?
              <div className="outside-search" onClick={this.closeAutoSearch}></div>:<React.Fragment></React.Fragment>
            }
            <div className={this.state.searching || this.state.autocomplete && this.state.autocomplete.length > 0? "is-searching-input": ""}>
            <div className="nav-search-container-form homepage-search">
              <div className="nav-search-icon">
                {this.state.autocomplete && this.state.autocomplete.length > 0 && this.state.searching?
                  <CircularProgress
                    size={20}
                    thickness={6}
                    color={"#b9b9b9"}
                  />:
                  <LocationIcon className="search-icon-home" />
                }
              </div>
              <form onSubmit={(e) => this.sendSearch(e,'auto')}>
                <InputBase
                  placeholder="Where do you want to rent?"
                  classes={{
                    root: "nav-search-input-root",
                    input: "nav-search-input-text"
                  }}
                  value={this.state.autocompleteSearch}
                  inputProps={{ "aria-label": "Search garages" }}
                  onChange={(e) => this.handleChange(e)}
                  onKeyDown={this.onKeyPress}
                />
                { this.state.searching?
                    <div className="searching-info-bar">
                    <LinearProgress variant="query" className="searching-navbar-progress" /></div>:
                    this.state.autocomplete && this.state.autocomplete.length > 0?
                      <div className="has-results-bar"></div>:<div></div>
                }
                {this.state.autocomplete && this.state.autocomplete.length > 0?
                  <div className="autocomplete-navbar-results">
                  { this.state.autocomplete.map((place, i) => (
                    <React.Fragment key={`search-parent-${i}`}>
                    {this.state.keySelectIndex == i?
                      <div className="search-result-row selected-by-key" key={`search-result-${i}`} onClick={(e) => this.sendSearch(e,'result',i)}>
                        <div className="location-search-pin">
                          <LocationSearchPin />
                        </div>
                        <Typography
                          component="span"
                          variant="body2"
                        >
                        {place.name}
                        </Typography>
                      </div>:
                      <div className="search-result-row" key={`search-result-${i}`} onClick={(e) => this.sendSearch(e,'result',i)}>
                        <div className="location-search-pin">
                          <LocationSearchPin />
                        </div>
                        <Typography
                          component="span"
                          variant="body2"
                        >
                        {place.name}
                        </Typography>
                      </div>
                    }
                    </React.Fragment>
                  ))}
                  </div>:<div>
                    {this.state.searching?
                      <div className="searching-locations">
                        <CircularProgress
                          size={20}
                          thickness={6}
                          color={"#b9b9b9"}
                        />
                        <Typography
                        component="span"
                        variant="body2"
                        >
                        Searching...
                        </Typography>
                      </div>:<div></div>
                    }
                  </div>
                }
              </form>
            </div>
            </div>
        </div>
        <Link to="/search">
          <Button className='homepage-search-btn' startIcon={<SearchIcon />}>
            Search
          </Button>
        </Link>
      </div>
    );
  }
}

export default SearchHome;
