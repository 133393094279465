import React, { PureComponent } from 'react';
import firebase from 'firebase/app';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import AlertSnackbar from './AlertSnackbar';

class ForgotPasswordDialog extends PureComponent {
  state = {
    open: false,
    value: '',
    isDisabled: false,
    hasError: false,
    emailSent: false,
    errorMessage: '',
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const emailAddress = this.state.value;
    var actionCodeSettings = {
      url: 'https://motorroof.com/',
      handleCodeInApp: false
    };
    this.setState({isDisabled: true});
    firebase.auth().sendPasswordResetEmail(emailAddress, actionCodeSettings).then((success) => {
      // Email sent.
      this.setState({emailSent: true, isDisabled: false, hasError: false});
    }).catch((error) => {
      // An error happened.
      this.setState({
        isDisabled: false,
        hasError: true,
        errorMessage: error.message
      });
    });
  };

  handleError = () => {
    this.setState({
      hasError: false
    });
  };

  handleClose = () => {
    this.setState({ open: false, hasError: false, emailSent: false });
  };

  handleClickOpen = (e) => {
    e.preventDefault();
    this.setState({ open: true, hasError: false, emailSent: false });
  };

  handleValueChange = (e) => {
    this.setState({ value: e.target.value })
  };

  resetForm = () => {
    this.setState({ hasError: false, emailSent: false, isDisabled: false });
  };

  handleErrorClick = () => {
    this.setState({ hasError: false });
  };


  render() {
    return (
        <div className="forgot-password-container">
          <a href="#" onClick={(e) => this.handleClickOpen(e)} color="primary" aria-label="forgot-password" className="forgot-password-link">
            Forgot Password?
          </a>
           <Dialog
              open={this.state.open}
              onClose={this.handleClose}
              aria-labelledby="form-dialog-title"
              className="forgot-password-dialog"
            >
              <DialogContent>
                { this.state.emailSent?
                <div className='forgot-password-email-sent'>
                 <Typography variant="h3">Reset Password Email Sent</Typography>
                 <Typography variant="body1">
                  Check your email for instructions on resetting your password.
                  </Typography>
                  <div className="resend-password-reset">
                    <Typography variant="body1">
                    Didn't get the email?
                    </Typography>
                    <Button onClick={this.resetForm} color="primary" variant="contained">
                      Send Email Again
                  </Button>
                  </div>
                </div>:
                <div className='forgot-password-content'>
                  <Typography variant="h3">Forgot Your Password?</Typography>
                  <Typography variant="body1">
                  Enter your email below and we will send you a email to reset your password.
                  </Typography>
                  <TextField
                    autoFocus
                    margin="normal"
                    id="email"
                    label="Your Email"
                    type="text"
                    value={this.state.value}
                    onChange={this.handleValueChange}
                    fullWidth
                  />
                </div>
                }
                </DialogContent>
              { this.state.emailSent? '':
                <DialogActions>
                  <Button onClick={this.handleClose} color="primary">
                    Cancel
                  </Button>
                  <Button variant="contained" onClick={this.handleSubmit} color="primary" disabled={this.state.isDisabled} className={`btn-disabled-${this.state.isDisabled}`}>
                    {this.state.isDisabled ? <CircularProgress size={20} thickness={6} className='loader-login-spinner' /> : <div>Send Reset Password Email</div> }
                  </Button>
                </DialogActions>}
              <AlertSnackbar isType="error" isOpen={this.state.hasError} handleClick={this.handleErrorClick} message={this.state.errorMessage} />
            </Dialog>
        </div>
    );
  }
}

export default ForgotPasswordDialog;

