import React from "react";
import Typography from "@material-ui/core/Typography";
import {Link} from "react-router-dom";
import LocationOnIcon from '@material-ui/icons/LocationOn';

import moment from 'moment';

const ReservationListItem = props => {
  const {garageList,
        reservation,
        i,
        isHost,
        ownerInfo} = props;
  return (
    <Link to={`/reservations/trip/${reservation.id}`} >
      <div className='reservation-row' key={`${i}-res`}>
        <div className="reservation-photo" style={garageList && garageList[reservation.garage_id] && garageList[reservation.garage_id].url? {"backgroundImage": `url(${garageList[reservation.garage_id].url})`}: null}></div>
        <div className="reservation-content">
          <div className="col-left-content">
          <div className='float-row'>
            {reservation.is_workshop?
              <div className={'Workshop type-label'} ><Typography>Workshop</Typography></div>:null
            }
            {reservation.is_storage?
              <div className={'Storage type-label'} ><Typography>Storage</Typography></div>:null
            }
          </div>
          <div className="status">
            {reservation.host_canceled || reservation.guest_canceled?
              <div className="reservation-status canceled">
                <Typography>
                  {`${reservation.host_canceled? 'Host Canceled': "Guest Canceled"}`}
                </Typography>
              </div>:

            <React.Fragment>
              {reservation.completed_reservation?
                <div className="reservation-status completed">
                  <Typography>
                  Completed
                  </Typography>
                </div>
                :<React.Fragment>
                {reservation.host_approved?
                  <div className="reservation-status approved">
                    <Typography>
                    Approved
                    </Typography>
                  </div>:
                  <React.Fragment>
                  {reservation.host_denied?
                  <div className="reservation-status denied">
                    <Typography>
                    Host Denied Request
                    </Typography>
                  </div>:
                  <div className="reservation-status pending">
                    <Typography>
                    Pending Host Approval
                    </Typography>
                  </div>}
                  </React.Fragment>
                }
                </React.Fragment>
              }

            </React.Fragment>
          }

          </div>
          <div className="location">
            <LocationOnIcon />
            <Typography>
            {reservation.location?
              <React.Fragment>
                {`${reservation.location.street_one},${reservation.location.street_two? ` ${reservation.location.street_two},`: ''} ${reservation.location.city}, ${reservation.location.state}, ${reservation.location.zip}`}
              </React.Fragment>
              :
              <React.Fragment>
                {garageList[reservation.garage_id]? garageList[reservation.garage_id].temp_address: ''}
              </React.Fragment>
            }
            </Typography>
          </div>
          <div className="checkin-checkout-row">
            <div className="start-date">
              <span><Typography>Check In</Typography></span>
              <Typography>
              {`${moment(reservation.start_date.toDate()).format('MMMM Do YYYY')}`}
              </Typography>
            </div>
            <div className="end-date">
              <span><Typography>Check Out</Typography></span>
              <Typography>
              {`${moment(reservation.end_date.toDate()).format('MMMM Do YYYY')}`}
              </Typography>
            </div>
          </div>
          <div className="reservation-detail-row">
            <Typography>Reservation: #{reservation.id.slice(0,7).toUpperCase()}</Typography>
            <Typography>{`Created On: ${moment(reservation.created_at.toDate()).format('MMMM Do YYYY')}`}</Typography>
          </div>
          </div>
          <div className="col-right-content">
            <strong>{isHost? 'Your Guest': 'Your Host'}</strong>
            <div className="host-content">
            <div className="about-host-user-icon" style={ownerInfo && ownerInfo.photo? {"backgroundImage": `url(${ownerInfo.photo})`}: null}>
            </div>
            <Typography>
              {ownerInfo && ownerInfo.name? ownerInfo.name: ''}
            </Typography>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default ReservationListItem;
