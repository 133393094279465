import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';
import 'firebase/firestore';

const isDevMode = process.env.NODE_ENV === "development" && process.env.NODE_ENV !== "production"? true: false;

const config = {
  apiKey: isDevMode? "AIzaSyDT9cbrhBYgHHSKQ17w6S02ybWr2U2e-5U":"AIzaSyCkmwCX3GI5BI7Rm4ig0u7wD7kBLCKSXvs",
  authDomain: isDevMode? "motorroof-prod.firebaseapp.com": "motorroof-dev.firebaseapp.com",
  databaseURL: isDevMode? "https://motorroof-prod.firebaseio.com": "https://motorroof-dev.firebaseio.com",
  projectId: isDevMode? "motorroof-prod": "motorroof-dev",
  storageBucket: isDevMode? "motorroof-prod.appspot.com": "motorroof-dev.appspot.com",
  messagingSenderId: isDevMode? "718709881181": "404049451237",
  appId: isDevMode? "1:718709881181:web:acfd06d9ccb6ebd74dd0b4": "1:404049451237:web:6d5e8c951d77029f",
};

if (isDevMode) {
  console.log("🔥🔥🔥DEV MODE🔥🔥🔥");
}


firebase.initializeApp(config);

export const dbFireStore = firebase.firestore();

export default firebase;
