import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import GarageGridItem from "./GarageGridItem";
import Confetti from 'react-dom-confetti';
import CircularProgress from "@material-ui/core/CircularProgress";

import { withStyles } from '@material-ui/core/styles';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';

// required for forms
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import GarageAvailabilityScheduler from './GarageAvailabilityScheduler';
import GarageAccessHours from './GarageAccessHours';
import GaragePhotoUploader from './GaragePhotoUploader';
import InputAdornment from '@material-ui/core/InputAdornment';

//Map and address
import g from 'ngeohash';
import MapGL, {Marker, NavigationControl} from 'react-map-gl';
import StateSelect from './StateSelect';

//icons
import CheckIcon from '@material-ui/icons/Check';
import LocationIcon from "@material-ui/icons/LocationOn";
import { ReactComponent as AcIcon } from '../images/ac.svg';
import { ReactComponent as HeatingIcon } from '../images/heat.svg';
import { ReactComponent as PaintIcon } from '../images/paint-gun.svg';
import { ReactComponent as LiftIcon } from '../images/lift.svg';
import { ReactComponent as WeldIcon } from '../images/weld.svg';
import { ReactComponent as WifiIcon } from '../images/wifi.svg';
import { ReactComponent as AlarmIcon } from '../images/alarm.svg';
import { ReactComponent as LockIcon } from '../images/locked.svg';
import { ReactComponent as CameraIcon } from '../images/camera.svg';

import firebase from 'firebase/app';
import dbFireStore from '../firebase';

import "../styles/edit_garage.scss";

const axios = require('axios');

const navStyle = {
  position: 'absolute',
  top: 0,
  right: 0,
  padding: '10px'
};

const geolocateStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  margin: 10
};

const packardplant = {
  latitude: 42.379520,
  longitude: -83.028648
}

const config_conf = {
  angle: "180",
  spread: "119",
  startVelocity: 45,
  elementCount: 50,
  dragFriction: 0.1,
  duration: 3000,
  stagger: 0,
  width: "10px",
  height: "10px",
  colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"]
};

const MAPBOX_TOKEN = 'pk.eyJ1IjoibW90b3Jyb29mIiwiYSI6ImNqeWJhdXJlbTA3ZXgzZHA1aTY1MHp1OXYifQ.gzAtdjOpRs5jdFvWgf93vw';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const EditTabs = withStyles({
  root: {
    borderColor: '#e8e8e8',
  },
  indicator: {
    backgroundColor: '#1890ff',
  },
})(Tabs);

const EditTab = withStyles(theme => ({
  root: {
    textTransform: 'none',
    '&:hover': {
      color: '#40a9ff',
      opacity: 1,
    },
    '&$selected': {
      color: '#1890ff',
    },
    '&:focus': {
      color: '#40a9ff',
    },
  },
  selected: {
    color: "40a9ff",
  }
}))(props => <Tab {...props} />);

const a11yProps = index => {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const TabPanel = props => {
  const { children, value, index, ...other } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}


class GarageEditForm extends React.PureComponent {
  state = {
    garage: null,
    tabValue: 0,
    checkingAddress: false,
    needsSave: false,
    saving: false,
    wantsDelete: false,
    viewport: {
      latitude: packardplant.latitude,
      longitude: packardplant.longitude,
      zoom: 5,
      bearing: 0,
      pitch: 0,
      height: 200,
      width: "100%"
    },
    marker: {
      latitude: packardplant.latitude,
      longitude: packardplant.longitude
    },
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.garage !== prevProps.garage) {
      this.getEditGarage(this.props.garage, this.props.garage.id);
    }
  };

  componentWillUnmount() {
    clearTimeout(this.titleTimer);
    clearTimeout(this.descTimer);
  }

  isNumeric(num){
    return !isNaN(num)
  };

  getGarageLeadPhoto = (photos, garageKey) => {
    if(photos) {
      var photosList = Object.entries(photos);
      photosList.forEach((photo) => {
        if(photo[1].order === 0) {
          var leadId = photo[0];
          var leadImgLocationRef = firebase.storage().ref(`/garages/${garageKey}/${leadId}/photo.jpg`);
          leadImgLocationRef.getDownloadURL().then(url => {
            this.setState(prevState => ({
              leadPhoto: prevState.leadPhoto != url? url: null,
            }));
          });
        }
      });
    } else {
      this.setState({
        leadPhoto: null,
      });
    }
  };

  handleNoLeadPhoto = () => {
    this.props.updateCardPhoto(this.props.garage.id, null);
    this.setState(prevState => ({
      leadPhoto: null,
    }));
  };

  handleUpdateLeadPhoto = (photo) => {
    this.props.updateCardPhoto(this.props.garage.id, photo);
    this.setState({
      leadPhoto: photo
    });
  };

  getEditGarage = (garage, garageKey) => {
    this.getGarageLeadPhoto(garage.photos, garageKey);
    this.setState({
      editingGarageRef: firebase.database().ref('users/' + firebase.auth().currentUser.uid + '/garages/' + garageKey),
      hostGuidelinesAgreed: garage.hostAgreementSigned? garage.hostAgreementSigned.agreement_status: false,
      activeStep: garage.activeStep,
      tabValue: 0,
      garage: {
        key: garageKey,
        title: garage.title? garage.title: null,
        description: garage.description? garage.description: null,
        types: [{name: 'Workshop', isType: garage.isWorkshop? garage.isWorkshop: false},
                {name: 'Storage', isType: garage.isStorage? garage.isStorage: false}
                ],
        price: garage.price? garage.price: null,
        photos: garage.photos? garage.photos: null,
        address: {
          street_one: garage.address && garage.address.street_one?garage.address.street_one: null,
          street_two: garage.address && garage.address.street_two? garage.address.street_two: null,
          city: garage.address && garage.address.city? garage.address.city: null,
          state: garage.address && garage.address.state? garage.address.state: null,
          zip: garage.address && garage.address.zip? garage.address.zip: null
        },
        hours: garage.hours? garage.hours: null,
        schedule: garage.schedule? garage.schedule: null,
        amenities: {
          heating: garage.amenities && garage.amenities.heating? garage.amenities.heating: false,
          ac:  garage.amenities && garage.amenities.ac? garage.amenities.ac: false,
          wifi: garage.amenities && garage.amenities.wifi? garage.amenities.wifi: false,
          lift: garage.amenities && garage.amenities.lift? garage.amenities.lift: false,
          welding: garage.amenities && garage.amenities.welding? garage.amenities.welding: false,
          paint: garage.amenities && garage.amenities.paint? garage.amenities.paint: false,
          cctv: garage.amenities && garage.amenities.cctv? garage.amenities.cctv: false,
          security: garage.amenities && garage.amenities.security? garage.amenities.security: false,
          locked: garage.amenities && garage.amenities.locked? garage.amenities.locked: false,
        }
      },
      marker: {
        latitude: garage.location && garage.location.latitude? garage.location.latitude: packardplant.latitude,
        longitude: garage.location && garage.location.longitude? garage.location.longitude: packardplant.longitude,
      },
      viewport: {
        latitude: garage.location && garage.location.latitude? garage.location.latitude: packardplant.latitude,
        longitude: garage.location && garage.location.longitude? garage.location.longitude: packardplant.longitude,
        zoom: 15,
        bearing: 0,
        pitch: 0,
        height: 200,
        width: "100%"
      },
    });
  };

  handleChange = (event, newValue) => {
    this.setState({tabValue: newValue});
  };

  //tab 1 - Basic Info
  handleTypeCheckboxChange = (name) => {
    if(!this.state.needsSave) {
      this.setState({needsSave: true});
    }
    switch (name) {
      case 'workshop':
        return (
          this.setState(prevState => ({
            garage: {
              ...prevState.garage,
              types: [
                {name: 'Workshop', isType: !prevState.garage.types[0].isType },
                prevState.garage.types[1]
                ]
            }
          }))
        )
      case 'storage':
        return (
          this.setState(prevState => ({
            garage: {
              ...prevState.garage,
              types: [
                prevState.garage.types[0],
                {name: 'Storage', isType: !prevState.garage.types[1].isType}
                ]
            }
          }))
        )
      default:
        return ''
    };
  };

  needsSave = () => {
    if(!this.state.needsSave) {
      this.setState({needsSave: true});
    }
  };

  handleChangeTitle = name => event => {
    let newTitle = event.target.value;
    clearTimeout(this.titleTimer);
    this.titleTimer = setTimeout(() => {
      this.setState(prevState => ({
        needsSave: true,
        garage: {
          ...prevState.garage,
          title: newTitle,
        }
      }));
    }, 450);
  }

  handleChangePrice = price => event => {
    if(!this.state.needsSave) {
      this.setState({needsSave: true});
    }
    let newPrice = event.target.value.replace(/\s/g, '').replace(/\D/g,'');
    if (/^\d+$/.test(newPrice) && newPrice !== "0" && newPrice !== "") {
      this.setState( prevState => ({
        garage: {
          ...prevState.garage,
          price: newPrice.slice(0,5),
        }
      }))
    } else {
      this.setState( prevState => ({
        garage: {
          ...prevState.garage,
          price: newPrice,
        }
      }))
    }
  };

  handleChangeDescription = desc => event => {
    let newDesc = event.target.value;
    clearTimeout(this.descTimer);
    this.descTimer = setTimeout(() => {
      this.setState( prevState => ({
        needsSave: true,
        garage: {
          ...prevState.garage,
          description: newDesc,
        }
      }))
    }, 450);
  };

  addAmenitie = (amenitie, e) => {
    e.preventDefault();
    if(!this.state.needsSave) {
      this.setState({needsSave: true});
    }
    this.setState( prevState => ({
      garage: {
        ...prevState.garage,
        amenities: {...prevState.garage.amenities, [amenitie]: !prevState.garage.amenities[amenitie]},
      }
    }));
  };

  //tab 2 - Photos
  // Update happens to private, needs save to go public with photos

  //tab 3 - availability 
  handleUpdateSchedule = (schedule) => {
    this.setState(prevState => ({
      needsSave: true,
      garage: {
        ...prevState.garage,
        schedule: schedule,
      }
    }));
  };

  //tab 4 - Access Hours
  handleUpdateHours = (hours) => {
    this.setState(prevState => ({
      needsSave: true,
      garage: {
        ...prevState.garage,
        hours: hours,
      }
    }));
  };

  //tab 5 - Location
  reverseGeoSearch = () => {

    var hasAddress = this.state.garage.address.street_one && this.state.garage.address.street_one.length > 0;
    var hasCity = this.state.garage.address.city && this.state.garage.address.city.length > 0;
    var hasState = this.state.garage.address.state && this.state.garage.address.state.length > 0;
    var hasZip = this.state.garage.address.zip && this.state.garage.address.zip.length >= 5;

    if(hasAddress && hasCity && hasState && hasZip) {
      var formatFirstStreet = this.state.garage.address.street_one.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
      var formatSecondStreet = this.state.garage.address.street_two? this.state.garage.address.street_two.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ''): "";
      var formatCity = this.state.garage.address.city.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');

      let searchQuery = encodeURI(`${formatFirstStreet} ${formatSecondStreet}, ${formatCity}, ${this.state.garage.address.state}, ${this.state.garage.address.zip}`);
      axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${searchQuery}.json?country=US&limit=1&access_token=${MAPBOX_TOKEN}`)
        .then((response) => {
          if(response.data.features.length > 0) {
            var long = response.data.features[0].geometry.coordinates[0];
            var lat = response.data.features[0].geometry.coordinates[1];
            this.setState(prevState => ({
              marker: {
                latitude: lat,
                longitude: long
              },
              viewport: {
                ...prevState.viewport,
                latitude: lat,
                longitude: long,
                zoom: 14,
              },
              checkingAddress: true
            }));

          }
        })
        .catch((error) => {
          this.props.handleError({
            message: "There was an error fetching your address, please try again."
          });
        });
    } else if(!hasAddress) {
      this.props.handleError({
        message: "Please enter a valid street address for your listing."
      });
    } else if(!hasCity) {
      this.props.handleError({
        message: "Please enter a valid city for your listing."
      });
    } else if(!hasState) {
      this.props.handleError({
        message: "Please enter a valid State for your listing."
      });
    } else if(!hasZip) {
      this.props.handleError({
        message: "Please enter a valid zip for your listing."
      });
    } else {
      this.props.handleError({
        message: "Please enter a valid address for your listing."
      });
    }
  }

  prepSearchAddressText = name => event => {
    let newAddressText = event.target.value;
    if(!this.state.needsSave) {
      this.setState({needsSave: true});
    }
    if(name === 'zip') {
      if (/^\d+$/.test(newAddressText)) {
        this.setState( prevState => ({
          garage: {
            ...prevState.garage,
            address: {
              ...prevState.garage.address,
              [name]: newAddressText
            },
          }
        }))
      } else {
        this.setState( prevState => ({
          garage: {
            ...prevState.garage,
            address: {
              ...prevState.garage.address,
              [name]: ''
            },
          }
        }))
      }
    } else {
      this.setState( prevState => ({
        garage: {
          ...prevState.garage,
          address: {
            ...prevState.garage.address,
            [name]: newAddressText
          },
        }
      }))
    }
  };

  updateViewport = viewport => {
    this.setState({
      viewport: {
        ...this.state.viewport,
        ...viewport,
      }
    });
  };

  onMarkerDragEnd = event => {
    this.setState({
      marker: {
        longitude: event.lngLat[0],
        latitude: event.lngLat[1]
      }
    });
  };

  editAddressDialogChange = () => {
    this.setState({
      checkingAddress: false,
      saving: false,
    });
  };

  setSelectedState = abbr => {
    if(!this.state.needsSave) {
      this.setState({needsSave: true});
    }
    this.setState(prevState => ({
      garage: {
        ...prevState.garage,
        address: {
          ...prevState.garage.address,
          state: abbr.label,
        }
      }
    }));
  };

  //tab 5 - Delete
  handleDeleteProperty = async (garageKey) => {
    this.props.handleDestoryProperty(garageKey);
    this.askForDelete();
  };
  
  hasReservationMatch = garageKey => {
    const hostGarageRef = firebase.database().ref('users/' + firebase.auth().currentUser.uid + '/garages/' + garageKey);
    hostGarageRef.once('value').then(snapshot => {
      if (snapshot.val() !== null) {
        const garageData = snapshot.val();
        const garagePublicKey = garageData.garage_key;
        
        dbFireStore.firestore()
          .collection("reservations")
          .where("host_id", "==", firebase.auth().currentUser.uid)
          .where("garage_id", "==", garagePublicKey)
          .get()
          .then(querySnapshot => {
            var queryLength = querySnapshot.docs.length;
            if(queryLength === 0) {
              // There are NOT reservations under this garage, proceed to deletion
              this.handleDeleteProperty(garageKey);
            } else {
              // There ARE reservations under this garage, DO NOT allow deletion
              this.props.handleError({
                message: "You cannot delete a property while it has a reservation."
              });
            }
          }).catch(error => {
            // Error
          });
      }
    });
  };

  // Save and update public garage
  saveGarageInfo = (tab) => {
    this.setState({
      saving: true,
    });
    // we need to know if they change forms, because we should save seprately,
    // if they change and don't confirm we need to reload the default data
    // we do this because if there is a validation error on a form they can't see when saving, it becomes difficult to edit
    if (tab === 0) {
      // basic info tab
      var checkTitleContent = this.state.garage.title && this.state.garage.title.length < 80 && this.state.garage.title.length > 4? true:false;
      var checkDescContent = this.state.garage.description && this.state.garage.description.length < 2500 && this.state.garage.description.length > 1? true:false;
      var checkPrice = this.state.garage.price && this.isNumeric(this.state.garage.price);
      if(!this.state.garage.types[0].isType && !this.state.garage.types[1].isType) {
        this.setState({saving: false});
        this.props.handleError({
          message: "Is your space a Workshop or Storage spot?"
        });
      } else if(checkTitleContent && checkDescContent && checkPrice) {
        // check for bad language stuff
        var hasSwearWordTitle = this.state.garage.title.toLowerCase().includes("fuck") || this.state.garage.title.toLowerCase().includes("shit");
        var hasSwearWordDesc = this.state.garage.description.toLowerCase().includes("fuck") || this.state.garage.description.toLowerCase().includes("shit");

        if(hasSwearWordTitle) {
          this.setState({saving: false});
          this.props.handleError({
            message: "Please no bad language in your title!"
          });

        } else if(hasSwearWordDesc) {
          this.setState({saving: false});
          this.props.handleError({
            message: "Please no bad language in your description!"
          });

        } else {
          // good to go, update private first then public
          this.state.editingGarageRef.child("title").set(this.state.garage.title);
          this.state.editingGarageRef.child("description").set(this.state.garage.description);
          this.state.editingGarageRef.child("price").set(this.state.garage.price);
          this.state.editingGarageRef.child("isWorkshop").set(this.state.garage.types[0].isType);
          this.state.editingGarageRef.child("isStorage").set(this.state.garage.types[1].isType);
          this.state.editingGarageRef.child("amenities").set({
            heating: this.state.garage.amenities.heating,
            ac: this.state.garage.amenities.ac,
            wifi: this.state.garage.amenities.wifi,
            lift: this.state.garage.amenities.lift,
            welding: this.state.garage.amenities.welding,
            paint: this.state.garage.amenities.paint,
            cctv: this.state.garage.amenities.cctv,
            security: this.state.garage.amenities.security,
            locked: this.state.garage.amenities.locked,
          });

          // take a snap of what should of been created
          this.state.editingGarageRef.once('value').then((snapshot) => {
            if (snapshot.val() !== null) {
              var garageData = snapshot.val();
              var garagePublicLocationKey = garageData.garage_key;
              var publicGarageRef = dbFireStore.firestore().collection("garages").doc(garagePublicLocationKey);
              publicGarageRef.update({
                title: garageData.title,
                price: garageData.price,
                description: garageData.description,
                isStorage: garageData.isStorage,
                isWorkshop: garageData.isWorkshop,
                amenities: garageData.amenities,
                timestamp: firebase.firestore.FieldValue.serverTimestamp()
              }).then(() => {
                // then update public garage
                this.setState({
                  needsSave: false,
                  saving: false,
                });

                this.props.updateCardData({
                  id: snapshot.key,
                  title: garageData.title,
                  price: garageData.price,
                  description: garageData.description,
                  types: [...this.state.garage.types],
                  isStorage: garageData.isStorage,
                  isWorkshop: garageData.isWorkshop,
                });

              }).catch((err) => {
                this.setState({saving: false});
                this.props.handleError({
                  message: "There was a problem saving your garage, please try again."
                });
              });
            }
          });
        }
      } else if(!checkPrice) {
        this.setState({saving: false});
        this.props.handleError({
          message: "Please enter your daily price."
        });
      } else if(!checkTitleContent) {
        this.setState({saving: false});
        this.props.handleError({
          message: "Please enter a title for your listing. (Max 80 Characters)"
        });
      } else if(!checkDescContent) {
        this.setState({saving: false});
        this.props.handleError({
          message: "Please enter a description for your listing. (Max 1500 Characters)"
        });
      } else {
        this.setState({saving: false});
        this.props.handleError({
          message: "To post a listing we require your listing title, and a description."
        });
      }
    } else if (tab == 1) {
      // Photos
      this.state.editingGarageRef.once('value').then((snapshot) => {
        if (snapshot.val() !== null) {
          var garageData = snapshot.val();
          var garagePublicLocationKey = garageData.garage_key;
          var publicGarageRef = dbFireStore.firestore().collection("garages").doc(garagePublicLocationKey);
          publicGarageRef.update({
            photos: garageData.photos? garageData.photos: null,
            timestamp: firebase.firestore.FieldValue.serverTimestamp()
          }).then(() => {
            // don't need to update card so just reset save state
            this.setState({
              needsSave: false,
              saving: false,
            });
          }).catch((err) => {
            this.setState({saving: false});
            this.props.handleError({
              message: "There was a problem saving your garage, please try again."
            });
          });
        }
      });
    } else if (tab == 2) {
      // availability
      this.state.editingGarageRef.child("schedule").set(this.state.garage.schedule);
       // take a snap of what should of been created
      this.state.editingGarageRef.once('value').then((snapshot) => {
        if (snapshot.val() !== null) {
          var garageData = snapshot.val();
          var garagePublicLocationKey = garageData.garage_key;
          var publicGarageRef = dbFireStore.firestore().collection("garages").doc(garagePublicLocationKey);
          var garageSchedule = null;
          if(garageData.schedule) {
            var scheduleList = [];
            Object.keys(garageData.schedule).forEach((year) => {
              var yearRecord = garageData.schedule[year];
              var months = [];
              Object.keys(yearRecord).forEach((month) => {
                months.push([`${month}`, yearRecord[month]]);
              });
              scheduleList.push([`${year}`, months]);
            });
            // apprently all of a sudden I can't store nested arrays,
            // so I had to do this to convert our object into a array
            garageSchedule = JSON.stringify(scheduleList);
          } else {
            garageSchedule = "";
          }
          publicGarageRef.update({
            schedule: garageSchedule,
            timestamp: firebase.firestore.FieldValue.serverTimestamp()
          }).then(() => {
            // don't need to update card so just reset save state
            this.setState({
              needsSave: false,
              saving: false,
            });

            this.props.updateCardData({
              id: snapshot.key,
              schedule: garageData.schedule,
            });

          }).catch((err) => {
            this.setState({saving: false});
            this.props.handleError({
              message: "There was a problem saving your garage, please try again."
            });
          });
        }
      });
    } else if (tab == 3) {
      // access hours
      this.state.editingGarageRef.child("hours").set(this.state.garage.hours);
      this.state.editingGarageRef.once('value').then((snapshot) => {
        if (snapshot.val() !== null) {
          var garageData = snapshot.val();
          var garagePublicLocationKey = garageData.garage_key;
          var publicGarageRef = dbFireStore.firestore().collection("garages").doc(garagePublicLocationKey);
          publicGarageRef.update({
            hours: garageData.hours? garageData.hours: null,
            timestamp: firebase.firestore.FieldValue.serverTimestamp()
          }).then(() => {
            this.setState({
              needsSave: false,
              saving: false,
            });
            this.props.updateCardData({
              id: snapshot.key,
              hours: garageData.hours,
            });
          }).catch((err) => {
            this.setState({saving: false});
            this.props.handleError({
              message: "There was a problem saving your garage, please try again."
            });
          });
        }
      });
    } else if (tab == 4) {
      // Location
      this.reverseGeoSearch();
    }
  };

  // only one we check the address before completing the save
  confirmAndSubmitAddress = () => {
    this.state.editingGarageRef.child("location").set({
      latitude: this.state.marker.latitude,
      longitude: this.state.marker.longitude,
    });
    if(this.state.garage.address.street_two) {
      var addressBlock = {
        street_one: this.state.garage.address.street_one,
        street_two: this.state.garage.address.street_two,
        city: this.state.garage.address.city,
        state: this.state.garage.address.state,
        zip: this.state.garage.address.zip,
      };
    } else {
      var addressBlock = {
        street_one: this.state.garage.address.street_one,
        city: this.state.garage.address.city,
        state: this.state.garage.address.state,
        zip: this.state.garage.address.zip,
      };
    }
    this.state.editingGarageRef.child("address").set({
      ...addressBlock
    });
    this.state.editingGarageRef.once('value').then((snapshot) => {
      if (snapshot.val() !== null) {
        var garageData = snapshot.val();
        var garagePublicLocationKey = garageData.garage_key;
        var publicGarageRef = dbFireStore.firestore().collection("garages").doc(garagePublicLocationKey);
        var garageGeoHash = g.encode(garageData.location.latitude, garageData.location.longitude, 8);
        var garageURL = `/garage/${encodeURI(garageData.address.city.replace(/\s+/g, '-').toLowerCase())}/${encodeURI(garageData.address.state.replace(/\s+/g, '-').toLowerCase())}/${Math.floor(new Date().valueOf() * Math.random())}`;

        publicGarageRef.update({
          location: garageGeoHash,
          city: garageData.address.city,
          state: garageData.address.state,
          url: garageURL,
          timestamp: firebase.firestore.FieldValue.serverTimestamp()
        }).then(() => {
          this.setState({
            needsSave: false,
            saving: false,
            checkingAddress: false,
          });
          this.props.updateCardData({
            id: snapshot.key,
            location: garageData.location,
            url: garageURL,
          });
        }).catch((err) => {
          this.setState({saving: false});
          this.props.handleError({
            message: "There was a problem saving your garage, please try again."
          });
        });
      }
    });
  };

  needsPhotoUpdate = () => {
    this.setState({
      needsSave: true,
    });
  };

  askForDelete = () => {
    this.setState(prevState => ({
      wantsDelete: !prevState.wantsDelete,
    }));
  };


  render() {
    return (
      <div className="edit-garage-slide-out">
        <Dialog fullScreen open={this.props.open} className="edit-garage-from-fixed-wrap" onClose={this.props.handleClose} TransitionComponent={Transition}>
          {this.state.garage?
            <React.Fragment>
            <AppBar className="edit-garage-app-bar">
              <Toolbar>
                <IconButton edge="start" color="inherit" className="close-edit-btn" onClick={this.props.handleClose} aria-label="close">
                  <CloseIcon />
                </IconButton>
                <Typography variant="h6" className="editing-garage-title">
                  <span>
                  Editing:</span> {this.state.garage.title}
                </Typography>
                <Button className={this.state.needsSave && !this.state.saving?"save-edit-btn": this.state.saving? "save-edit-btn saving": "save-edit-btn disabled"} onClick={() => this.saveGarageInfo(this.state.tabValue)} disabled={!this.state.needsSave || this.state.saving}>
                {this.state.needsSave && !this.state.saving?
                  "Save Property": this.state.saving?
                  <React.Fragment>
                  <div className="saving-loader" >
                    <CircularProgress size={20} thickness={6}/>
                  </div>
                  Saving...
                  </React.Fragment>:
                  "Saved"
                }
                <Confetti active={ !this.state.needsSave } config={ config_conf }/>
                </Button>
              </Toolbar>
            </AppBar>
            <div className="edit-garage-preview-col">
              <GarageGridItem
                garageTitle={this.state.garage.title}
                isStorage={this.state.garage.types[1].isType}
                isWorkshop={this.state.garage.types[0].isType}
                avatarPhotoURL={this.props.avatarPhotoURL}
                displayName={this.props.displayName}
                starRating={this.props.userStars? this.props.userStars: 5}
                price={this.state.garage.price}
                city={this.state.garage.address? this.state.garage.address.city: null}
                state={this.state.garage.address? this.state.garage.address.state: null}
                photo={this.state.leadPhoto? this.state.leadPhoto: null}
              />
            </div>
            <div className="edit-garage-form-col">
              <AppBar position="static" color="default" className="edit-tabs-app-bar">
                <EditTabs
                  value={this.state.tabValue}
                  onChange={this.handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs example"
                >
                  <EditTab label="Basic Info" {...a11yProps(0)} />
                  <EditTab label="Photos" {...a11yProps(1)} />
                  <EditTab label="Availability" {...a11yProps(2)} />
                  <EditTab label="Access Hours" {...a11yProps(3)} />
                  <EditTab label="Location" {...a11yProps(4)} />
                  <EditTab label="Delete Property" {...a11yProps(5)} />
                </EditTabs>
              </AppBar>
              <TabPanel value={this.state.tabValue} index={0}>
                <Typography variant="h5" className="tab-title">Basic Info</Typography>
                 <div className="edit-garage-basic-info-wrapper">
                  <Typography className="edit-garage-form-subtitle" variant="caption">
                    Type of space available
                  </Typography>
                  <FormControlLabel
                    control={
                      <Checkbox checked={this.state.garage.types[0].isType} onChange={() => this.handleTypeCheckboxChange('workshop')} value="Workshop" />
                    }
                    label="Workshop"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox checked={this.state.garage.types[1].isType} onChange={() => this.handleTypeCheckboxChange('storage')} value="Storage" />
                    }
                    label="Storage"
                  />
                  <div className="edit-garage-price-and-description">
                    <Typography className="edit-garage-form-subtitle" variant="caption">
                      Price and description
                    </Typography>
                    <div className="info-about-rate-container">
                    <TextField
                      id="listing-price"
                      className="listing-price"
                      label="Daily Price"
                      value={this.state.garage.price}
                      onChange={this.handleChangePrice()}
                      margin="normal"
                      variant="outlined"
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                    />
                    <div className="rate-info">
                      <Typography>Please enter a whole number with no special characters or letters, for example, enter 15 instead of 15.00.</Typography>
                    </div>
                    </div>
                    <TextField
                      id="listing-title"
                      className="listing-title edit-form-listing-title"
                      label="Listing Title"
                      defaultValue={this.state.garage.title}
                      onChange={this.handleChangeTitle()}
                      margin="normal"
                      variant="outlined"
                    />
                    <TextField
                      id="listing-description"
                      className="listing-description"
                      label="Describe your space"
                      multiline
                      rows="4"
                      defaultValue={this.state.garage.description}
                      onChange={this.handleChangeDescription()}
                      margin="normal"
                      variant="outlined"
                    />
                  </div>

                  <div className="build-garage-amenities">
                    <Typography className="edit-garage-form-subtitle" variant="caption">
                      Security and amenities
                    </Typography>
                    <div className={this.state.garage.amenities.cctv? 'check-amenities selected-amenitie cctv': 'check-amenities cctv' } onClick={(e) => this.addAmenitie('cctv', e)}>
                      {this.state.garage.amenities.cctv?
                        <CheckIcon />:
                        <CameraIcon />}
                      <Typography>
                      Security Cameras 
                      </Typography>
                    </div>

                    <div className={this.state.garage.amenities.security? 'check-amenities selected-amenitie security': 'check-amenities security' } onClick={(e) => this.addAmenitie('security', e)}>
                      {this.state.garage.amenities.security?
                        <CheckIcon />:
                        <AlarmIcon />}
                      <Typography>
                      Alarm System
                      </Typography>
                    </div>

                    <div className={this.state.garage.amenities.locked? 'check-amenities selected-amenitie locked': 'check-amenities locked' } onClick={(e) => this.addAmenitie('locked', e)}>
                      {this.state.garage.amenities.locked?
                        <CheckIcon />:
                        <LockIcon />}
                      <Typography>
                      Locked Access
                      </Typography>
                    </div>

                    <div className={this.state.garage.amenities.heating? 'check-amenities selected-amenitie heating': 'check-amenities heating' } onClick={(e) => this.addAmenitie('heating', e)}>
                      {this.state.garage.amenities.heating?
                        <CheckIcon />:
                        <HeatingIcon />
                      }
                      <Typography>
                      Heating
                      </Typography>
                    </div>

                    <div className={this.state.garage.amenities.ac? 'check-amenities selected-amenitie ac': 'check-amenities ac' } onClick={(e) => this.addAmenitie('ac', e)}>
                      {this.state.garage.amenities.ac? 
                        <CheckIcon />:
                        <AcIcon />}
                      <Typography>
                      A/C
                      </Typography>
                    </div>

                    <div className={this.state.garage.amenities.lift? 'check-amenities selected-amenitie lift': 'check-amenities lift' } onClick={(e) => this.addAmenitie('lift', e)}>
                      {this.state.garage.amenities.lift?
                        <CheckIcon />:
                        <LiftIcon />}
                      <Typography>
                      Vehicle Lift
                      </Typography>
                    </div>

                    <div className={this.state.garage.amenities.welding? 'check-amenities selected-amenitie welding': 'check-amenities welding' } onClick={(e) => this.addAmenitie('welding', e)}>
                      {this.state.garage.amenities.welding?
                        <CheckIcon />:
                        <WeldIcon />}
                      <Typography>
                      Welding Equipment
                      </Typography>
                    </div>

                    <div className={this.state.garage.amenities.paint? 'check-amenities selected-amenitie paint': 'check-amenities paint' } onClick={(e) => this.addAmenitie('paint', e)}>
                      {this.state.garage.amenities.paint?
                        <CheckIcon />:
                        <PaintIcon />}
                      <Typography>
                      Paint Booth
                      </Typography>
                    </div>

                    <div className={this.state.garage.amenities.wifi? 'check-amenities selected-amenitie wifi': 'check-amenities wifi' } onClick={(e) => this.addAmenitie('wifi', e)}>
                      {this.state.garage.amenities.wifi?
                        <CheckIcon />:
                        <WifiIcon />}
                      <Typography>
                      Wifi
                      </Typography>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel value={this.state.tabValue} index={1}>
                <Typography variant="h5">Photos</Typography>
                  <Typography variant="body2" className="edit-panel-info-header">
                  Photo's uploaded here will be private until you publish your changes by clicking save.
                  </Typography>
                  <div>
                    <GaragePhotoUploader
                      editorOnly={true}
                      needsPhotoUpdate={this.needsPhotoUpdate}
                      handleUpdateLeadPhoto={this.handleUpdateLeadPhoto}
                      handleNoLeadPhoto={this.handleNoLeadPhoto}
                      editingGarageRef={this.state.editingGarageRef? this.state.editingGarageRef: null} />
                  </div>
              </TabPanel>
              <TabPanel value={this.state.tabValue} index={2}>
                <Typography variant="h5">Availability</Typography>
                <Typography variant="body2" className="edit-panel-info-header">
                  Select days your property won't be available for rent.
                  New guests will be unable to book days you mark below as not available. Don't forget to save changes to your calendar by hitting the save button above.
                </Typography>
                <div className="hours-and-access-container">
                  <GarageAvailabilityScheduler
                    editorOnly={true}
                    schedule={this.state.garage.schedule? this.state.garage.schedule: null}
                    handleUpdateSchedule={this.handleUpdateSchedule}/>
                </div>
              </TabPanel>
              <TabPanel value={this.state.tabValue} index={3}>
                <Typography variant="h5">Access Hours</Typography>
                <Typography variant="body2" className="edit-panel-info-header">What kind of access hours will you provide for your guests?</Typography>
                <div className="hours-and-access-container">
                <GarageAccessHours
                  editorOnly={true}
                  hours={this.state.garage.hours? this.state.garage.hours: null}
                  handleUpdateHours={this.handleUpdateHours} />
                </div>
              </TabPanel>
              <TabPanel value={this.state.tabValue} index={4}>
                <Typography variant="h5">Location</Typography>
               <Typography variant="body2" className="edit-panel-info-header">
               We only give this address to users you permit to be guests at your property.
                </Typography>
                <Dialog className="checking-address-dialog" open={this.state.checkingAddress} aria-labelledby="form-dialog-check-address">
                  <div className="check-address-notification">
                    <Typography variant="h2" className="location-found-title">
                      Is this the correct address?
                    </Typography>
                    <Typography className="location-found-content">
                      You can drag and drop the pin to adjust your location or go back and change your address information.
                      We share this address only to visiting guests who you confirm and never display publicly.
                    </Typography>
                  </div>
                  <div className="mapbox-container">
                    <MapGL
                      {...this.state.viewport}
                      className="mapbox-gl-wrap"
                      onViewportChange={this.updateViewport}
                      mapboxApiAccessToken={MAPBOX_TOKEN}
                      scrollZoom={false}
                      mapStyle="mapbox://styles/mapbox/streets-v11"
                    >
                      <Marker
                        longitude={this.state.marker.longitude}
                        latitude={this.state.marker.latitude}
                        offsetTop={-20}
                        offsetLeft={-10}
                        draggable
                        onDragEnd={this.onMarkerDragEnd}
                      >
                        <LocationIcon className="marker-pin-red" size={20} />
                      </Marker>
                      <div className="nav" style={navStyle}>
                        <NavigationControl
                          onViewportChange={this.updateViewport}
                          showCompass={false}
                        />
                      </div>
                    </MapGL>
                    <div className="confirm-location-actions">
                      <Button size="small" className="address-back-btn" onClick={() => this.editAddressDialogChange()} >Change Address Info</Button>
                      <Button size="small" className="address-next-btn" onClick={() => this.confirmAndSubmitAddress()} >
                        <CheckIcon />
                        Confirm Address
                      </Button>
                    </div>
                  </div>
                </Dialog>
                <div className="map-edit-wrap">
                <MapGL
                  {...this.state.viewport}
                  width={"100%"}
                  height={200}
                  zoom={14}
                  className="mapbox-gl-wrap garage-edit-map-wrap"
                  mapboxApiAccessToken={MAPBOX_TOKEN}
                  scrollZoom={false}
                  mapStyle="mapbox://styles/mapbox/streets-v11"
                >
                  <Marker
                    longitude={this.state.marker.longitude}
                    latitude={this.state.marker.latitude}
                    offsetTop={-20}
                    offsetLeft={-10}
                  >
                    <LocationIcon className="marker-pin-red" size={20} />
                  </Marker>
                </MapGL>
                </div>
                <div className="garage-location-editor">
                  <div className="full-width-input">
                    <TextField
                      id="search_address"
                      className="search-address-newgarage"
                      label="Address"
                      value={this.state.garage.address.street_one}
                      onChange={this.prepSearchAddressText('street_one')}
                    />
                  </div>
                  <div className="full-width-input">
                    <TextField
                      id="search_address_two"
                      className="search-address-newgarage"
                      label="Address Line 2"
                      value={this.state.garage.address.street_two}
                      onChange={this.prepSearchAddressText('street_two')}
                    />
                  </div>
                  <div className="full-width-input city-garage-build">
                    <div className="col-left">
                    <TextField
                      id="search_address_city"
                      className="search-address-newgarage"
                      label="City"
                      value={this.state.garage.address.city}
                      onChange={this.prepSearchAddressText('city')}
                    />
                    </div>
                    <div className="col-center">
                    <StateSelect
                      setSelectedState={this.setSelectedState}
                      currentSelect={this.state.garage.address.state}/>
                    </div>
                    <div className="col-right">
                    <TextField
                      id="search_zip"
                      className="search-address-newgarage"
                      label="Zip/Postal Code"
                      value={this.state.garage.address.zip}
                      onChange={this.prepSearchAddressText('zip')}
                    />
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel value={this.state.tabValue} index={5}>
                <Typography variant="h5">Delete Property</Typography>
                <div className="delete-property-container">
                <Typography variant="body2" className="edit-panel-info-header">
                  Warning! Once you delete this property you will be unable to recover any data regarding this place.
                </Typography>
                <Typography variant="body2" className="edit-panel-info-header">
                  If you have any active reservations you will be unable to remove this listing until completing your reservations.
                </Typography>
                <Button onClick={this.askForDelete}>Delete Property</Button>
                </div>
              </TabPanel>
              <Dialog
                className="wants-to-delete-info"
                open={this.state.wantsDelete}
                keepMounted
                onClose={this.askForDelete}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
              >
                <DialogTitle>
                Delete this property?
                </DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Are you sure you want to delete this property forever? This cannot be undone.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={this.askForDelete} className="cancel-delete">
                    Cancel
                  </Button>
                  <Button onClick={() => this.hasReservationMatch(this.state.garage.key)} className="delete-forever-btn">
                    Delete Property
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
            </React.Fragment>:<React.Fragment></React.Fragment>
        }
        </Dialog>
      </div>
    )
  }
}
export default GarageEditForm;