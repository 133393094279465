import React from "react";
import { withStyles } from "@material-ui/core/styles";
import BuildGarageForm from  './BuildGarageForm';

import "../../styles/newgarage.scss";

export default function NewGarage(props) {
  return (
    <div className="with-navbar-wrap">
      <div className="create-garage-accent-left">
        <div className="accent-crop"></div>
      </div>
      <div className="garage-form-wrap">
        <div className="garage-form-container">
          <div className="left-form-col">
            <div className="create-new-garage-title">
              <h2>List your space for rent</h2>
              <p>Add the information below about your space.</p>
              <p style={{"marginTop": '-15px'}}>After completing your property will be listed on our marketplace!</p>
            </div>
            <BuildGarageForm
              handleError={props.handleError}
              avatarPhotoURL={props.avatarPhotoURL}
              verificationRef={props.verificationRef}
              userStars={props.userStars}
              displayName={`${props.displayName.split(' ')[0]} ${props.displayName.split(' ')[1].charAt(0)}.`}
            />
          </div>
        </div>
      </div>
    </div>
  )
};

