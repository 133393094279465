import React from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';

import IconButton from '@material-ui/core/IconButton';
import CheckIcon from '@material-ui/icons/Check';
import DialogTitle from '@material-ui/core/DialogTitle';
import DateRangeIcon from '@material-ui/icons/DateRange';

import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, Calendar } from "@material-ui/pickers";

import moment from 'moment';
import firebase from 'firebase/app';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class GarageAvailabilityScheduler extends React.Component {
  state = {
    open: false,
    todaysDate:  moment(new Date()),
    editDate: moment(new Date()),
    schedule: this.props.schedule,
  };

  componentDidUpdate(prevProps) {
    if (this.props.schedule !== prevProps.schedule) {
      this.setState({
        schedule: this.props.schedule,
      });
    }
  };

  handleSubmitSchedule = () => {
    //Set to firebase updated schedule
    this.setState(prevState => ({open: !prevState.open}));
    this.props.handleUpdateSchedule(this.state.schedule);
  };

  handleCloseSchedule = () => {
    this.setState(prevState => ({open: !prevState.open}));
  }

  toggleEditForm = () => {
    this.setState(prevState => ({open: !prevState.open}));
  };

  toggleDateAvailability = (date) => {
    // returns the date the you selected
    const yearNum = date.year();
    const monthNum = date.month();
    const monthact = this.state.schedule;
    const selectedDay = date.date();

    if(monthact) {
      // check if any month has even been set
      var monthHasKeys = monthact.hasOwnProperty(yearNum) && monthact[yearNum].hasOwnProperty(monthNum);

      if(monthHasKeys) {
        // this month has been intialized before
        // change the record to the opposite of what was set before
        this.changeMonthArray(yearNum, monthNum, selectedDay);
      } else {
        //This is a new month that needs to get added
        //Also there is another month that is in state so don't overwrite
        this.addNewMonthArray(date, yearNum, monthNum, selectedDay, true);
      }
    } else {
      // no month has been set!, create a now month record
      this.addNewMonthArray(date, yearNum, monthNum, selectedDay, false);
    }
  };

  addNewMonthArray = (date, yearNum, monthNum, selectedDay, updatePrev) => {
    const dayAmount = moment(date).endOf('month').date();
    let monthBlock = [];

    for(var i = 0; i < dayAmount; i++){
      if(i == selectedDay - 1) {
        monthBlock.push(0);
      } else {
        monthBlock.push(1);
      }
    }

    if(updatePrev) {
      this.setState(prevState => ({
        schedule: {
          ...prevState.schedule,
          [yearNum]: {
            ...prevState.schedule[yearNum],
            [monthNum]: monthBlock
          }
        }
      }), () => {
        if(this.props.editorOnly) {
          this.props.handleUpdateSchedule(this.state.schedule);
        };
      });

    } else {

      const newMonthArray = {
        [yearNum]: {
          [monthNum]: monthBlock
        }
      }

      this.setState(prevState => ({
        schedule: newMonthArray,
      }), () => {
        if(this.props.editorOnly) {
          this.props.handleUpdateSchedule(this.state.schedule);
        };
      });
    }
  };


  changeMonthArray = (yearNum, monthNum, selectedDay) => {
    var year = yearNum;
    var month = monthNum;
    var day = selectedDay;

    var newScheduleRecord = this.state.schedule[year][month][day - 1] == 1? 0: 1;

    this.setState(prevState => ({
      schedule: {
        ...prevState.schedule,
        [year]: {
          ...prevState.schedule[year],
          [month]: [
            ...prevState.schedule[year][month].slice(0, day - 1),
            newScheduleRecord,
            ...prevState.schedule[year][month].slice(day)
          ]
        }
      }
    }), () => {
      if(this.props.editorOnly) {
        this.props.handleUpdateSchedule(this.state.schedule);
      };
    });
  };

  updateEditMonth = (date) => {
    this.setState({
      editDate: date,
    });
  }

  handleClearMonth = () => {
    var month = this.state.editDate.month();
    var year = this.state.editDate.year();

    const dayAmount = moment(this.state.editDate).endOf('month').date();
    let monthBlock = [];

    for(var i = 0; i < dayAmount; i++){
      monthBlock.push(1);
    }

    if(this.state.schedule) {
       this.setState(prevState => ({
        schedule: {
          ...prevState.schedule,
          [year]: {
            ...prevState.schedule[year],
            [month]: monthBlock
          }
        }
      }), () => {
        if(this.props.editorOnly) {
          this.props.handleUpdateSchedule(this.state.schedule);
        }
      });
    }

  };

  renderDay = (date, selectedDate, dayInCurrentMonth) => {
    const monthact = this.state.schedule;
    const dateKey = `${date.date()}-${date.month()}`;
    var dateHasActivity = false;
    var dateBooked = false;
    var inPast = date.isBefore(this.state.todaysDate) && !date.isSame(this.state.todaysDate, 'day');


    if (monthact !== null && dayInCurrentMonth && !inPast) {
      var monthHasKeys = monthact.hasOwnProperty(date.year()) && monthact[date.year()].hasOwnProperty(date.month());
      if(monthHasKeys) {
        var dateRecord = monthact[date.year()][date.month()][date.date() - 1];
        if(dateRecord === 1) {
        // Date is available
        var dateHasActivity = false;
        } else if(dateRecord === 0) {
          // Date has activity saved
          var dateHasActivity = true;
        } else if(dateRecord === 2) {
          // Date has been booked
          var dateBooked = true;
        }
      }
    }

    var dateClassName = dayInCurrentMonth && !inPast? 'schedule-day-block':
                        inPast && !dayInCurrentMonth? 'schedule-day-block hidden-day':
                        inPast? 'schedule-day-block inpast-day':
                        'schedule-day-block hidden-day';

    return (
      <div>
      <div className={dateClassName} key={dateKey}>
        <IconButton className="day-object" disabled={!dayInCurrentMonth || inPast} >
          <span>{moment(date).format("D")}</span>
          {dateHasActivity? <div className="not-available"></div>: ''}
        </IconButton>
      </div>
      </div>
    )
  };


  render() {
    return(
      <MuiPickersUtilsProvider utils={MomentUtils} >
      <div>
      {this.props.editorOnly?
        <div className="hosting-hub-availability-calendar">
          <div className="scheduler-screen-container">
            <div className="block-out-date-col">
              <Calendar
                disablePast
                date={this.state.todaysDate}
                renderDay={this.renderDay}
                onChange={this.toggleDateAvailability}
                onMonthChange={this.updateEditMonth}
              />
              <Button className="clear-month-btn" onClick={() => this.handleClearMonth()}>Clear Month</Button>
            </div>
          </div>
        </div>:
        <React.Fragment>
          <Button size="small" className="change-scheduler-btn" onClick={() => this.toggleEditForm()}>
            <DateRangeIcon />
            <span>
            Availability
            </span>
          </Button>
          <Dialog
            open={this.state.open}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => this.handleCloseSchedule()}
            className={"scheduler-screen-view"}
            >
            <DialogTitle className="scheduler-form-dialog-title">Availability</DialogTitle>
            <div className="scheduler-screen-container">
              <Typography className="dialog-info">Select days your property won't be available for rent.</Typography>
              <div className="block-out-date-col">
                <Calendar
                  disablePast
                  date={this.state.todaysDate}
                  renderDay={this.renderDay}
                  onChange={this.toggleDateAvailability}
                  onMonthChange={this.updateEditMonth}
                />
                <Button className="clear-month-btn" onClick={() => this.handleClearMonth()}>Clear Month</Button>
              </div>
              <div className="confirm-available-actions-wrap">
                <div className="confirm-available-actions">
                  <Button size="small" className="address-back-btn" onClick={() => this.handleCloseSchedule()} >Cancel Edit</Button>
                  <Button size="small" className="address-next-btn" onClick={() => this.handleSubmitSchedule()} >
                    <CheckIcon />
                    Save Availability Dates
                  </Button>
                </div>
              </div>
            </div>
          </Dialog>       
        </React.Fragment>
      }
    </div>
    </MuiPickersUtilsProvider>
    )
  }
}

export default GarageAvailabilityScheduler;