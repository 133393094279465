import React from 'react';
import Image1 from '../../images/service.png';
import Image3 from '../../images/review-first.png';
import Image2 from '../../images/paint-booth.png';

export default class HomePageSlider extends React.Component {

  state = {
    images: [
      Image1,
      Image2,
      Image3,
    ],
    sliderIndex: 0,
  };

  componentDidMount() {
    this.timer = setTimeout(() => {
      this.transitionSlide();
    }, 7000);
  };

  componentWillUnmount() {
    this.stopSlider();
  };

  transitionSlide = () => {

    if(this.state.sliderIndex === this.state.images.length - 1) {
      this.setState( prevState => ({
        sliderIndex: 0
      }));
    } else {
      this.setState(prevState => ({
        sliderIndex: prevState.sliderIndex + 1,
      }));
    }
    this.timer = setTimeout(() => {
      this.transitionSlide();
    }, 8000);

  };

  stopSlider = () => {
    clearTimeout(this.timer);
  };

  createSlides = () => {
    return (
      <div>
        {this.state.images.map((image, i) => (
          <div className={"mini-slider mini-slide-" + i + " currentSlide-" + this.state.sliderIndex} key={i} style={{ backgroundImage: "url(" + image +")"}}></div>
        ))}
      </div>
    )
  };

  render() {
    return (
      <div>
        {this.createSlides()}
      </div>
    )
  }
};
