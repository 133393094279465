import React from 'react';
import SearchMap from "./SearchMap";
import GarageGridItem from "../../components/GarageGridItem";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import NoPlaceFoundInfoBox from '../../components/NoPlaceFoundInfoBox';
import { Redirect, Link } from "react-router-dom";

import firebase from 'firebase/app';
import dbFireStore from '../../firebase';
import g from 'ngeohash';

import "../../styles/search.scss";
const axios = require('axios');
const queryString = require('query-string');

const MAPBOX_TOKEN = 'pk.eyJ1IjoibW90b3Jyb29mIiwiYSI6ImNqeWJhdXJlbTA3ZXgzZHA1aTY1MHp1OXYifQ.gzAtdjOpRs5jdFvWgf93vw';

class Search extends React.PureComponent {
  state = {
    searching: true,
    startDate: null,
    endDate: null,
    searchParams: {
      name: null,
      longitude: null,
      latitude: null,
      error: null,
    },
    amenities: {
      heating: null,
      ac: null,
      wifi: null,
      lift: null,
      welding: null,
      paint: null,
    },
    location: null,
    priceStart: null,
    priceEnd: null,
    isStorage: null,
    isWorkshop: null,
    propertiesList: null,
    activePinInfoBox: null,
    noPlacesFound: null,
    hideNoPlacesAlert: false,
  }

  componentDidMount() {
    // In render, if client is not authed, show special noPlacesFoundInfoBox 
    // fix render auth issue
    this.startSearch();
  };

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.location !== this.props.location) {
      // the location search url is different we need to search for the new query
      this.startSearch();
    }
  };

  locationSubscriptionCheck = () => {
    const user = firebase.auth().currentUser;
    const searchLocation = decodeURIComponent(this.props.match.params.location);

    if (searchLocation && user) {
      const requestsCollectionRef = dbFireStore.firestore().collection("requests");
      const requestsRef = requestsCollectionRef.where("user_id", "==", user.uid);
      requestsRef.get()
        .then(querySnapshot => {
          if(querySnapshot.docs.length > 0) {
            // Check if user has subscribed to this location
            const docID = querySnapshot.docs[0].id;
            const userRequestsRef = requestsCollectionRef.doc(docID);
            userRequestsRef.get()
            .then(doc => {
              if (doc.exists) {
                const data = doc.data();
                const locations = data.locations;

                let hasSubscribedLocation = false;
                locations.forEach(location => {
                  if(location === searchLocation) {
                    // User has subscribed to new garages in this area, hide the no locations alert
                    hasSubscribedLocation = true;
                  } 
                });
                this.setState({ hideNoPlacesAlert: hasSubscribedLocation });
              } else {
                console.log('No such document!');
              }
            })
            .catch(err => {
              console.log('Error getting document', err);
            });
          } else {
            // user hasn't subscribed to this location
          }
      }).catch((error) => {
        console.log(error);
      });
    } 
  };

  startSearch = () => {
    if (this.props.match.params && this.props.match.params.location) {
      var location = this.props.match.params.location;
      if(this.props.history.location.search) {
        //check the long and lat if there is any
        let searchParams = queryString.parse(this.props.history.location.search);
        if(searchParams.lat && searchParams.long) {
          this.setState(prevState => ({
            searchParams: {
              name: decodeURIComponent(location),
              longitude: searchParams.long,
              latitude: searchParams.lat,
              error: false,
            }
          }),() => {
            this.searchProperties(this.state.searchParams);
          });
        } else {
          // failed to parse the search params provided but we have a location to geolocate before search
          this.geolocateSearch(location);
        }
      } else {
        // no long and lat we need to geolocate before search
         this.geolocateSearch(location);
      }
    } else {
      // we got nothing, so default search for featured/new
      this.searchProperties();
    }
  };

  geolocateSearch = (location) => {
    axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURI(location)}.json?country=US&types=place&limit=1&access_token=${MAPBOX_TOKEN}`)
      .then((response) => {
        if(response.data.features.length > 0) {
          var name = response.data.features[0].place_name.replace(', United States','');
          var long = response.data.features[0].geometry.coordinates[0];
          var lat = response.data.features[0].geometry.coordinates[1];
          this.setState(prevState => ({
            searchParams: {
              name: name,
              longitude: long,
              latitude: lat,
              error: false,
            }
          }), () => {
            this.searchProperties(this.state.searchParams);
          });
        } else {
          // no results run default search, inform user that we couldn't find their search
          this.searchProperties();
          this.setState({
            searching: false,
            searchParams: {
              name: location,
              error: true,
            }
          });
        }
      })
      .catch((error) => {
        console.log('Could not find location');
        this.searchProperties();
        this.setState({
          searching: false,
          searchParams: {
            name: location,
            error: true,
          }
        });
      });
  }

  searchProperties = async (options) => {

    var lastPropertyList = this.state.propertiesList;
    this.setState({
      searching: true,
      activePinInfoBox: null
    });

    var searchGeoHash = null;

    // get location to search
    if(options && options.latitude && options.longitude) {
      // http://www.elastic.co/guide/en/elasticsearch/guide/current/geohashes.html
      if(options.distance) {
        searchGeoHash = g.encode(options.latitude,options.longitude).substring(0, options.distance);
      } else {
        searchGeoHash = g.encode(options.latitude,options.longitude).substring(0, 3);
      }
      await dbFireStore.firestore()
        .collection("garages")
        .orderBy('location')
        .startAt(searchGeoHash)
        .endAt(`${searchGeoHash}\uf8ff`).get()
        .then((querySnapshot) => {
          var propertiesList = [];
          var queryLength = querySnapshot.docs.length;
          var queryLooped = 0;
          var garageKey = querySnapshot.docs.garage_key;
          if(queryLength === 0) {
            this.setState({
              noPlacesFound: true,
            });
            this.queryFeaturedPlaces();
          } else {
            querySnapshot.forEach((garage) => {
              var garageData = garage.data();
              // get long and lat from geohash
              var latlon = g.decode(garageData.location);
              garageData.location = {
                longitude: latlon.longitude,
                latitude: latlon.latitude,
              }
              // Set garage id, marker display status, and index for fast storting
              garageData.id = garage.id;
              garageData.markerDisplay = true;
              garageData.markerInfoWindowDisplay = false;
              garageData.index = queryLooped;
              queryLooped++;
              propertiesList.push(garageData);
              if(queryLength == queryLooped) {
                // we should only perform this if the list is different,
                // or we are rendering a bunch for no reason
                
                var currentPropertyList = propertiesList.map(place => place.id);
                var prevPropertyList = lastPropertyList? lastPropertyList.map(place => place.id): null;

                if (prevPropertyList && currentPropertyList.join() == prevPropertyList.join()) {
                  // List hasn't changed don't update properties list
                  // should be the same order, if we join then we are just compairing two long strings
                  // instead of looping again over an array to see if they are equal
                  this.setState({
                    noPlacesFound: false,
                    searching: false,
                  });
                } else {
                  this.setState({
                    propertiesList: propertiesList,
                    noPlacesFound: false,
                  });
                  this.getPropertyImages();
                }
              }
            });
          }
        });
    } else {
      // No geosearch info, fall back search here
      this.queryFeaturedPlaces();
    }
  };

  getPropertyImages = () => {
    var propertyListLength = this.state.propertiesList.length;
    this.state.propertiesList.forEach((garage, index) => {
      var newPropertyImageRecord = this.state.propertiesList[index];
      if(garage.photos && garage.garage_key) {
        Object.keys(garage.photos).forEach((photo) => {
          if(garage.photos[photo].order === 0) {
            var leadId = photo;
            var leadImgLocationRef = firebase.storage().ref(`/garages/${garage.garage_key}/${leadId}/photo.jpg`);
            leadImgLocationRef.getDownloadURL().then(url => {    
              newPropertyImageRecord.leadPhoto = url;
              this.setState(prevState => ({
                propertiesList: [
                  ...prevState.propertiesList.splice(0,index),
                  newPropertyImageRecord,
                  ...prevState.propertiesList.splice(index + 1)
                ]
              }));
            }).catch((error) => {
              console.log('There was an error retreiving the garages lead photo');
            });
          }
        });
      }
      if(propertyListLength == index + 1) {
        this.getPropertyUserInfo();
      }
    });
  };

  queryFeaturedPlaces = async () => {
    this.setState({
      searching: false,
      noPlacesFound: true,
    });
    /*
    // should happen when we didn't get a location from the user OR there was no places found with their search
    await dbFireStore.firestore().collection("garages").get().then((querySnapshot) => {
      var propertiesList = [];
      var queryLength = querySnapshot.docs.length;
      var queryLooped = 0;
      var garageKey = querySnapshot.docs.garage_key;
      querySnapshot.forEach((garage) => {
        var garageData = garage.data();
        // get long and lat from geohash
        var latlon = g.decode(garageData.location);
        garageData.location = {
          longitude: latlon.longitude,
          latitude: latlon.latitude,
        }
        // Set garage id, marker display status, and index for fast storting
        garageData.id = garage.id;
        garageData.markerDisplay = true;
        garageData.markerInfoWindowDisplay = false;
        garageData.index = queryLooped;
        queryLooped++;
        propertiesList.push(garageData);
        if(queryLength == queryLooped) {
          this.setState(prevState => ({
            propertiesList: propertiesList,
            noPlacesFound: true,
          }), () => {
            this.getPropertyImages();
          });
          // Display No Places Alert Notification
          this.locationSubscriptionCheck();
        }
      });
    });
    */
  }

  getPropertyUserInfo = () => {
    var propertyListLength = this.state.propertiesList.length;
    this.state.propertiesList.forEach((garage, index) => {
      var newPropertyUserRecord = this.state.propertiesList[index];
      if(garage.owner_photo_id) {
        var userPhotoRef = firebase.storage().ref(`/${garage.owner_photo_id}/photo.jpg`);
        userPhotoRef.getDownloadURL().then(url => {
          newPropertyUserRecord.userPhoto = url;
          this.setState(prevState => ({
            propertiesList: [
              ...prevState.propertiesList.splice(0,index),
              newPropertyUserRecord,
              ...prevState.propertiesList.splice(index + 1)
            ]
          }));
        }).catch((error) => {
          console.log('There was an error retreiving the users photo');
        });
      }
      if(propertyListLength == index + 1) {
        this.searchingTime = setTimeout(() => {
          this.setState(prevState => ({
            searching: false,
          }), () => {
            clearTimeout(this.searchingTime);
          });
        }, 1000);
      }
    });
  };

  handleOpenMapPin = (garageIndex) => {
    if(this.state.activePinInfoBox == null && garageIndex == null) {
      // do nothing, no reason to set state
    } else if(this.state.activePinInfoBox == garageIndex) {
      // same pin was clicked, close that infobox
      this.setState({
        activePinInfoBox: null,
      });
    } else {
      // open new info box
      this.setState({
        activePinInfoBox: garageIndex,
      });
    }
  };

  getSearchDistanceByViewportZoom = (zoom) => {
    //0-24
    switch(true) {
      case zoom < 5:
        return 1;
        break;
      case zoom < 10:
        return 2;
        break;
      case zoom < 14:
        return 3;
        break;
      case zoom < 18:
        return 4;
        break;
      default:
        return 5;
        break;
    }
  };

  handleSearchUpdateOnMapMove = viewport => {
    var distance = this.getSearchDistanceByViewportZoom(viewport.zoom);
    this.searchProperties({latitude: viewport.latitude, longitude: viewport.longitude, distance: distance });
  };

  handleGetNotified = e => {
    e.preventDefault();
    const user = firebase.auth().currentUser;
    const locationName = this.state.searchParams.name;
    if (user) {
      this.props.handleError({
        message: `We will notify you when spaces open up in ${decodeURIComponent(locationName)}`,
      });
      this.setState({ 
        hideNoPlacesAlert: true, 
      });
      this.props.subscribeUser(locationName);
    } else {
      this.props.history.push(`/signup`);
    }
  };

  render() {
    const propertiesFound = this.state.propertiesList? this.state.propertiesList.length: 0;
    // if not signed in and there was no properties found, ask them to signup
    // if they are not signed in but properties returned, show them the properties!
    // don't redirect if its still searching!
    var redirectNonSignIn = !firebase.auth().currentUser && this.state.noPlacesFound && !this.state.searching? <Redirect to="/signup" />: null;
    return (
      <div className="search-page-wrap">
        {redirectNonSignIn}
        <div className="search-content-col-overflow-wrap">
        <div className="search-content-col">
          <div className="filters-wrap">
          </div>
          {this.state.searchParams.name && this.state.searchParams.name.length > 0 ?
            <div className="searching-for-location-title">
            {this.state.searching?
              <React.Fragment>
                <CircularProgress
                  size={20}
                  thickness={6}
                  className="searching-properties-spinner"
                />
              </React.Fragment>:<div></div>
            }
            <Typography variant="h2">
              {this.state.searching?
                <React.Fragment>
                  {this.state.searchParams && this.state.searchParams.name?
                    `Searching in ${decodeURIComponent(this.state.searchParams.name)}...`:
                    "Searching..."
                  }
                </React.Fragment>:
                <div>
                  {this.state.noPlacesFound?
                    <NoPlaceFoundInfoBox disabled={this.state.hideNoPlacesAlert} searchParams={this.state.searchParams} handleGetNotified={this.handleGetNotified} />:
                  <React.Fragment>
                  {this.state.searchParams && this.state.searchParams.name?
                    `We found ${propertiesFound} ${propertiesFound > 1? 'properties': 'property'} in ${decodeURIComponent(this.state.searchParams.name)}.`:
                    `We found ${propertiesFound} ${propertiesFound > 1? 'properties': 'property'} within your search.`
                  }
                  </React.Fragment>
                }</div>
              }
            </Typography></div>:<div></div>
          }
          <div className="sub-info-bar">
            
          </div>
          <div className="search-results-wrap">
            <div className="search-list-container">
              <React.Fragment>
                {propertiesFound > 0 && !this.state.noPlacesFound && !this.state.searching?
                  <React.Fragment>
                    {this.state.propertiesList.map((garage, i) => (
                      <div className="grid-list-block" key={`garageitem-${i}`}>
                        <Link to={garage.url? garage.url: ''} target="_blank" className="link-garage">
                          <GarageGridItem
                            garageTitle={garage.title}
                            isStorage={garage.isStorage}
                            isWorkshop={garage.isWorkshop}
                            avatarPhotoURL={garage.userPhoto? garage.userPhoto: null}
                            displayName={garage.owner_name}
                            needsStar={garage.owner_id}
                            price={garage.price}
                            city={garage.city}
                            state={garage.state}
                            photo={garage.leadPhoto? garage.leadPhoto: null}
                          />
                        </Link>
                      </div>
                    ))}
                  </React.Fragment>:
                  <div>
                  {this.state.propertiesList && this.state.propertiesList.length == 0 && !this.state.searching?
                    <Typography variant="h4">
                    {this.state.searchParams && this.state.searchParams.name?
                      `Sorry! We couldn't find any properties in ${this.state.searchParams.name}..`:
                      "Sorry! We couldn't find any properties within your search."
                    }
                    </Typography>:
                    <div></div>
                  }
                  </div>
                }
              </React.Fragment>
            </div>
          </div>
        </div>
        </div>
        <div className="search-garage-map">
          <SearchMap
            garages={propertiesFound? this.state.propertiesList: []}
            searchParams={this.state.searchParams}
            handleOpenMapPin={this.handleOpenMapPin}
            activePinInfoBox={this.state.activePinInfoBox}
            isSearching={this.state.searching}
            handleSearchUpdateOnMapMove={this.handleSearchUpdateOnMapMove}
          />
        </div>
      </div>
    );
  }
}
 
export default Search;