import React from "react";
import firebase from "firebase/app";
import dbFireStore from '../firebase';
import {StripeProvider} from 'react-stripe-elements';
import { Switch, Route, BrowserRouter, Redirect } from "react-router-dom";
import { Offline } from "react-detect-offline";
import BillingInfo from "./BillingInfo";
import EditProfile from "./EditProfile";
import Home from "./Home";
import HostingHub from "./HostingHub";
import Login from "./Login";
import NewGarage from "./HostingHub/NewGarage";
import GaragePage from "./GaragePage";
import ProfilePage from "./Dashboard";
import PublicProfile from "./PublicProfile";
import Reservations from "./Reservations";
import PaymentCenter from "./PaymentCenter";
import ReservationCreate from "./Reservations/create";
import ReservationShow from "./Reservations/show";
import HowToHost from "./HowToHost";
import Search from "./Search";
import SignUp from "./SignUp";
import Page from "./Page";
import Typography from "@material-ui/core/Typography";
import ScrollToTop from "../components/ScrollToTop";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

import Navbar from "../components/Navbar";

import AsyncStripeProvider from '../components/AsyncStripeProvider';

import AlertSnackbar from "../components/AlertSnackbar";
import "../styles/common.scss";

import axios from 'axios';

require("../firebase");

const SUBSCRIBE_MAILING_KEY = "EYAqBkTrYS73nwMwexe2534FSoe95h1k6PsxMzT9";
const SUBSCRIBE_MAILING_ENDPOINT = "https://c3xacovzsg.execute-api.us-east-1.amazonaws.com/default/addMemberMailChimp";

class App extends React.Component {
  state = {
    auth: false,
    loading: true,
    loadingAvatar: true,
    loadingForm: false,
    hasError: false,
    displayName: null,
    user: null,
    avatarPhotoURL: null,
    userStars: null,
    userHasGarages: false,
    missingFirstName: "",
    missingLastName: "",
    isMissingFullName: false,
    loadingUpdate: false,
    redirectToReferrer: null,
    redirectLink: null,
    stripeLoaded: false,
    error: {
      message: ""
    },
    publicProfileKey: null,
    hasConnection: null,
    wantsToReport: false,
    valueReport: "",
    reported: false,
    reported_id: null,
    loadingReport: false,
  };

  handleAuthUser = (email, password, callback) => {
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(creds => {
        callback();
        this.setState({ auth: true, loading: false })
      })
      .catch(error => {
        callback();
        this.handleError(error);
        this.setState({auth: false, user: null, loading: false})
      });
  };

  logout = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        this.setState({
          auth: false,
          user: null,
          loading: false
        });
      });
  };

  handleErrorClick = () => this.setState({ hasError: false });

  handleError = error => {
    this.setState({
      error: {
        message: error.message,
        type: error.type? error.type: 'error',
      },
      hasError: true
    });
  };

  componentDidMount() {
    this.authUserStateRef = firebase.auth().onAuthStateChanged(user => {
      if (user) {
        const userName = firebase.auth().currentUser.displayName;
        const email = user.email;
        if (userName != null && userName.split(' ')[1]) {
          this.setState({
              displayName: userName,
              user: user,
              auth: true,
              loading: false,
              firstSignup: false,
              verificationRef: firebase.database().ref("users/" + firebase.auth().currentUser.uid + "/profile/verified_status"),
              userAvatarRef: firebase
                .database()
                .ref("users/" + firebase.auth().currentUser.uid + "/profile/photos/avatar"),
              userPublicProfileRef: firebase
                .database()
                .ref("users/" + firebase.auth().currentUser.uid + "/profile/public_profile_key")
            },
            () => this.setUpUserAccount()
          );
        } else {
          // User is required for first and last, we must prompt them
          this.setState({
              displayName: email,
              user: user,
              auth: true,
              loading: false,
              isMissingFullName: true,
              verificationRef: firebase.database().ref("users/" + firebase.auth().currentUser.uid + "/profile/verified_status"),
              userAvatarRef: firebase
                .database()
                .ref("users/" + firebase.auth().currentUser.uid + "/profile/photos/avatar")
            },
            () => this.setUpUserAccount()
          );
        }
      } else {
        this.setState({ authed: false, user: null, loading: false });
      }
    });
  };

  getPublicProfileKey = () => {
    if(this.state.userPublicProfileRef) {
      this.state.userPublicProfileRef.once("value", snapshot => {
        if (snapshot.val() != null) {
          //User has public profile
          this.setState({ publicProfileKey: snapshot.val() })
        } 
      });
    }
  };

  setUpUserAccount = () => {
    this.getPublicProfileKey();
    this.getAvatar();
    this.checkUserGarages();
  };

  checkUserGarages = () => {
    this.userGaragesRef = firebase.database().ref('users/' + firebase.auth().currentUser.uid + '/garages');
    this.userGaragesRef.once('value', snapshot => {
      if (snapshot.val() != null) {
        //User has garages
       this.setState({
          userHasGarages: true
        });
      } else {
        // User doesn't have garages
       this.setState({
          userHasGarages: false
        });
      }
    });
  };

  handleChange = e => this.setState({ [e.target.id]: e.target.value });

  getAvatar = () => {
    this.state.userAvatarRef.on("value", snapshot => {
      if (snapshot.val() != null) {
        snapshot.forEach(childSnapshot => {
          var childData = childSnapshot.val();
          var childUrl = childData.url;
          this.setState({ avatarPhotoURL: childUrl, loadingAvatar: false });
        });
      } else {
        this.setState({ avatarPhotoURL: null, loadingAvatar: false });
      }
    });
  };

  updateUserName = (displayName) => {
    this.setState({ displayName: displayName, loadingUpdate: true });
    const newUser = firebase.auth().currentUser;
    const newUserId = newUser.uid;
    // add profile ref
    this.userProfileRef = firebase
      .database()
      .ref("users/" + newUserId + "/profile");

    var public_name = '';
    if(displayName.split().length > 1) {
      public_name = `${displayName.split(' ')[0]} ${displayName.split(' ')[1].charAt(0)}.`;
    } else {
      public_name = displayName.split(' ')[0];
    }

    dbFireStore.firestore().collection("users").add({
      display_name: public_name,
      user_id: newUserId,
      joined: firebase.firestore.FieldValue.serverTimestamp(),
      lastUpdated: firebase.firestore.FieldValue.serverTimestamp(),
      verified_phone: false,
      verified_email: false,
      verified_id: false,
    }).then((docRef) => {
      // post was succssful, we can get the post id from here!
      this.userProfileRef.child("public_profile_key").set(docRef.id);
      this.userProfileRef.child("verified_status").set({
        phone: false,
        email: false,
        id: false
      });
      this.userProfileRef.child("termsAndConditions").set({
        agreed: true,
        timestamp: firebase.database.ServerValue.TIMESTAMP
      });
    }).catch((error) => {
      // throw error alert
      console.log(error);
    });

    // Add user full name
    newUser
      .updateProfile({
        displayName: displayName
      }).then(() => {
        this.setState({
          displayName: displayName,
          firstSignup: false,
          loadingUpdate: false,
          isMissingFullName: false,
        });
      })
      .catch(error => {
        // Ask the user to add their Full Name again here
        this.setState({ error, loadingUpdate: false });
      });
  };

  subscribeToNews = hasSubscribed => {
    if (hasSubscribed) {
      this.subscribeUser();
    }
    const newUser = firebase.auth().currentUser;
    const newUserId = newUser.uid;

    this.userProfileRef = firebase
      .database()
      .ref("users/" + newUserId + "/profile");

    this.userProfileRef.child("subscribed").set(hasSubscribed);
  };

  subscribeUser = locationName => {
    const user = firebase.auth().currentUser;
    const requestsRef = dbFireStore.firestore().collection("requests");
    if (locationName) {
      const userRequestsRef = requestsRef.where("user_id", "==", user.uid);
      userRequestsRef.get()
        .then(querySnapshot => {
          if(querySnapshot.docs.length > 0) {
            const docID = querySnapshot.docs[0].id;
            requestsRef.doc(docID).update({
              locations: firebase.firestore.FieldValue.arrayUnion(locationName)
            });
          } else {
            // user hasn't subscribed to new garages 
            requestsRef.add({
              user_id: user.uid,
              locations: [locationName],
              lastUpdated: firebase.firestore.FieldValue.serverTimestamp(),
            })
            .then((docRef) => {
              console.log(docRef);
            })
            .catch((error) => {
              console.log(error);
            });
          }
      }).catch((error) => {
        console.log(error);
      });
    } else {
      axios({
        method: 'POST',
        url: SUBSCRIBE_MAILING_ENDPOINT,
        headers: {
          "x-api-key": SUBSCRIBE_MAILING_KEY
        },
        data: {
          userEmail: user.email,
        }
      })
      .then(response => {
        console.log(response);
      })
      .catch(error => {
        console.log(error);
      });
    }
  };

  userRequiresUpdate = (firstname, lastname) => {
    if(this.state.isMissingFullName) {
      if(this.state.missingFirstName.length > 1 && this.state.missingLastName.length > 1) {
        const userFirstName = this.state.missingFirstName.charAt(0).toUpperCase() + this.state.missingFirstName.slice(1);
        const userLastName = this.state.missingLastName.charAt(0).toUpperCase() + this.state.missingLastName.slice(1);

        const fullName = userFirstName + ' ' + userLastName;

        this.updateUserName(fullName);
      } else {
        this.handleError({
          message: "First Name and Last Name are required to use our platform.",
          type: "error"
        });
      }
    } else if (firstname && lastname) {
      const userFirstName = firstname.charAt(0).toUpperCase() + firstname.slice(1);
      const userLastName = lastname.charAt(0).toUpperCase() + lastname.slice(1);

      const fullName = userFirstName + ' ' + userLastName;

      this.updateUserName(fullName);
    } else {
      this.setState({ isMissingFullName: true });
    }
  };

  handleFirstSignup = (status) => {
    this.setState({firstSignup: status});
  }

  redirectToReferrerSearch = link => {
    if(this.state.redirectToReferrer) {
      this.setState(prevState => ({
        redirectToReferrer: false,
        redirectLink: link,
      }), () => {
        this.setState({redirectToReferrer: true});
      });
    } else {
      this.setState({
        redirectToReferrer: true,
        redirectLink: link,
      });
    }
  };

  setStripeLoaded = () => {
    this.setState({
      stripeLoaded: true,
    });
  };
    
  handleConnectionError = status => {
    if (!status) {
      this.handleError({
        message: "Your internet connection quality is slow or unstable. Some actions performed may not work.",
        type: "connection-error"
      });
    }
    this.setState({ hasConnection: status });
  };

  reportUser = (offendingUserId, offendingUserPublicId, typeOfReport) => {
    this.setState({loadingReport: true});
    var user = firebase.auth().currentUser;
    var firstName = '';
    var lastName = '';
    if(user.displayName && user.displayName.split().length > 1) {
      firstName = `${user.displayName.split(' ')[0]}`;
      lastName = `${user.displayName.split(' ')[1]}`;
    } else {
      firstName = `${user.displayName.split(' ')[0]}`;
      lastName = '';
    }

    if(typeOfReport === "reported_by_host") {
      // We need reservation info from host
      var reservation_id = window.location.pathname.split("/reservations/trip/")[1];
      this.userProfileRef = firebase.database().ref('users/' + firebase.auth().currentUser.uid + '/profile/');
      this.userProfileRef.once("value").then(profile => {
        axios({
          method: 'post',
          url: "https://a8787vqyfa.execute-api.us-east-1.amazonaws.com/default/reportUser",
          headers: {
            "x-api-key": "IYeYbo41BC1drILBiAaG18x6yYizHpCkSVvUW3ra"
          },
          data: {
            createdBy: user.uid,
            createdByEmail: user.email,
            createdByFirstName: firstName,
            createdByLastName: lastName,
            customerStripeId: 'N/A',
            hostStripeId: profile.val().host_stripe_user_id? profile.val().host_stripe_user_id: "No HostStripeID Found",
            reservationId: reservation_id,
            reportedId: offendingUserId? offendingUserId: "N/A",
            reportedPublicId: offendingUserPublicId? offendingUserPublicId: "N/A",
            reportMessage: this.state.valueReport,
            typeOfReport: "Host",
            createdAt: new Date().toISOString().split('.')[0]+"Z"
          }
        })
        .then(response => {
          this.setState({ reported: true, reported_id: response.data.msg !== "Failed to report user"? response.data.msg: null, loadingReport: false});
        })
        .catch(error => {
          console.log(error);
          this.setState({ reported: false, reported_id: null, loadingReport: false});
        });
      }).catch(error => {
          console.log("Reporting error for user info");
      });
    } else if(typeOfReport === "reported_by_guest") {
      // We need reservation info from guest
      var reservation_id = window.location.pathname.split("/reservations/trip/")[1];
      
      this.userProfileRef = firebase.database().ref('users/' + firebase.auth().currentUser.uid + '/profile/');
      this.userProfileRef.once("value").then(profile => {
        axios({
          method: 'post',
          url: "https://a8787vqyfa.execute-api.us-east-1.amazonaws.com/default/reportUser",
          headers: {
            "x-api-key": "IYeYbo41BC1drILBiAaG18x6yYizHpCkSVvUW3ra"
          },
          data: {
            createdBy: user.uid,
            createdByEmail: user.email,
            createdByFirstName: firstName,
            createdByLastName: lastName,
            customerStripeId: profile.val().customerId? profile.val().customerId: "Missing CustomerStripeID",
            hostStripeId: "N/A",
            reservationId: reservation_id,
            reportedId: offendingUserId? offendingUserId: "N/A",
            reportedPublicId: offendingUserPublicId? offendingUserPublicId: "N/A",
            reportMessage: this.state.valueReport,
            typeOfReport: "Guest",
            createdAt: new Date().toISOString().split('.')[0]+"Z"
          }
        })
        .then(response => {
          this.setState({ reported: true, reported_id: response.data.msg !== "Failed to report user"? response.data.msg: null, loadingReport: false});
        })
        .catch(error => {
          console.log(error);
          this.setState({ reported: false, reported_id: null, loadingReport: false});
        });
      });

    } else {
      // normal report
      axios({
        method: 'post',
        url: "https://a8787vqyfa.execute-api.us-east-1.amazonaws.com/default/reportUser",
        headers: {
          "x-api-key": "IYeYbo41BC1drILBiAaG18x6yYizHpCkSVvUW3ra"
        },
        data: {
          createdBy: user.uid,
          createdByEmail: user.email,
          createdByFirstName: firstName,
          createdByLastName: lastName,
          customerStripeId: 'N/A',
          hostStripeId: 'N/A',
          reservationId: 'N/A',
          reportedId: offendingUserId? offendingUserId: "N/A",
          reportedPublicId: offendingUserPublicId? offendingUserPublicId: "N/A",
          reportMessage: this.state.valueReport,
          typeOfReport: "User",
          createdAt: new Date().toISOString().split('.')[0]+"Z"
        }
      })
      .then(response => {
        this.setState({ reported: true, reported_id: response.data.msg !== "Failed to report user"? response.data.msg: null, loadingReport: false});
      })
      .catch(error => {
        console.log(error);
        this.setState({ reported: false, reported_id: null, loadingReport: false});
      });
    }

  };

  askToReport = () => {
    this.setState(prevState => ({
      wantsToReport: !prevState.wantsToReport,
      reported: false,
      loadingReport: false,
      reported_id: null,
      valueReport: "",
    }));
  };

  typeMessageReport = e => this.setState({ valueReport: e.target.value });

  render() {
    const noNavPath =
      window.location.pathname === "/login" ||
      window.location.pathname === "/signup";
    const redirectToReferrer = this.state.redirectToReferrer? <Redirect to={this.state.redirectLink} />:<React.Fragment></React.Fragment>;
    const hostName = this.state.displayName && this.state.displayName.includes("@")? this.state.displayName: this.state.displayName && this.state.displayName.split(' ')[1]? `${this.state.displayName.split(' ')[0].charAt(0).toUpperCase() + this.state.displayName.split(' ')[0].slice(1) + ' ' + this.state.displayName.split(' ')[1].charAt(0).toUpperCase()}.`: null;
    const isDevMode = process.env.NODE_ENV === "development" && process.env.NODE_ENV !== "production"? true: false;

    return (
      <AsyncStripeProvider apiKey={isDevMode? 'pk_test_51J106UAG2liEQzaOTqfGKhfWnuvZhPwVOqnOJfBKiTMeBTt6NjSTCLAdWPuw4Q9o3RY8cWFHvwSof6elYKjYRA4200P5ydUc3j':'pk_live_OSpnmWcAZUOOd0uoB4wL64IS0077tUzLBs'} setStripeLoaded={this.setStripeLoaded}>
      <React.Fragment>
        <AlertSnackbar
          isType={this.state.error.type}
          isOpen={this.state.hasError}
          handleClick={this.handleErrorClick}
          message={this.state.error.message}
        />
        {this.state.loading?
          <div className="app-loader-wrap">
            <div className="loading-app">
              <CircularProgress className="main-loader"/>
            </div>
          </div>:<React.Fragment></React.Fragment>
        }
        <BrowserRouter>
          <ScrollToTop>
          <React.Fragment>
            {redirectToReferrer}
              <div>
                 <Dialog
                  open={this.state.isMissingFullName}
                  scroll="paper"
                  className="fullname-missing-form"
                >
                <DialogTitle><span className='whoops-title'>Whoops!</span>
                We didn't catch your name</DialogTitle>
                <DialogContent>
                  <Typography variant="body2">
                  In order to use MotorRoof we require every user to submit their full name.
                  This helps keep our platform safe for everyone to use.
                  </Typography>
                  <FormControl margin="normal" required fullWidth>
                    <TextField
                      required
                      id="missingFirstName"
                      label="First Name"
                      name="firstname"
                      type="firstname"
                      autoComplete="firstname"
                      autoFocus={true}
                      onChange={this.handleChange}
                      margin="normal"
                    />
                    <TextField
                      required
                      id="missingLastName"
                      label="Last Name"
                      name="lastname"
                      type="lastname"
                      autoComplete="lastname"
                      onChange={this.handleChange}
                      margin="normal"
                    />
                  </FormControl>
                  <DialogActions>
                    <Button
                      fullWidth
                      variant="contained"
                      size="large"
                      color="primary"
                      disabled={this.state.loadingUpdate}
                      className={this.state.loadingUpdate? "update-btn is-loading": "update-btn"}
                      onClick={() => this.userRequiresUpdate(this.state.missingFirstName, this.state.missingLastName)}
                    >
                    {this.state.loadingUpdate ? (
                      <CircularProgress
                        size={20}
                        thickness={6}
                        className="loader-login-spinner"
                      />
                    ) : (
                      <div>
                        <span>Save your name</span>
                      </div>
                    )}
                    </Button>
                  </DialogActions>
                </DialogContent>
                </Dialog>
              </div>:<div></div>
          </React.Fragment>
          <React.Fragment>
            {this.state.auth ? (
              <Navbar
                signedIn={true}
                displayName={this.state.displayName}
                avatarPhotoURL={this.state.avatarPhotoURL}
                noNavPath={noNavPath}
                loadingAvatar={this.state.loadingAvatar}
                loading={this.state.loading}
                redirectToReferrerSearch={this.redirectToReferrerSearch}
                userHasGarages={this.state.userHasGarages}
                logout={this.logout}
                publicProfileKey={this.state.publicProfileKey}
              />
            ) : (
              <Navbar
                signedIn={false}
                noNavPath={noNavPath}
                loading={this.state.loading}
                redirectToReferrerSearch={this.redirectToReferrerSearch}
              />
            )}
          </React.Fragment>
          {this.state.auth ? (
            <Switch>
              <Route
                exact path="/billing"
                render={props => (
                  <BillingInfo
                    {...props}
                    stripeLoaded={this.state.stripeLoaded}
                    handleError={this.handleError}
                  />
                )}
              />
              <Route
                exact
                path="/garages/new"
                render={props => (
                  <NewGarage {...props}
                    avatarPhotoURL={this.state.avatarPhotoURL}
                    verificationRef={this.state.verificationRef}
                    displayName={this.state.displayName}
                    handleError={this.handleError}
                    userStars={this.state.userStars} />
                )}
              />
              <Route exact path={"/how-to-host"} component={HowToHost} />
              <Route
                exact path="/hosting-hub"
                render={props => (
                  <HostingHub {...props}
                    avatarPhotoURL={this.state.avatarPhotoURL}
                    handleError={this.handleError}
                    hostName={hostName}
                  />
                )}
              />
              <Route
                exact path="/payment-center"
                render={props => (
                  <PaymentCenter {...props}
                    avatarPhotoURL={this.state.avatarPhotoURL}
                    handleError={this.handleError}
                    hostName={hostName}
                  />
                )}
              />
              <Route 
                exact 
                path="/edit-profile" 
                render={props => (
                  <EditProfile {...props} 
                    handleError={this.handleError}
                  />
                )}
              />
              <Route exact path="/reservations" component={Reservations} />
              <Route exact path="/reservations/trip/:reservation_id/"
                render={props => (
                  <ReservationShow
                    {...props}
                    handleError={this.handleError}
                    stripeLoaded={this.state.stripeLoaded}
                    displayName={this.state.displayName}
                    avatarPhotoURL={this.state.avatarPhotoURL}
                    askToReport={this.askToReport}
                    typeMessageReport={this.typeMessageReport}
                    wantsToReport={this.state.wantsToReport}
                    valueReport={this.state.valueReport}
                    reportUser={this.reportUser}
                    reported={this.state.reported}
                    reportedId={this.state.reported_id}
                    loadingReport={this.state.loadingReport}
                  />
                )}
              />
              <Route exact path="/reservations/create/:garage_id/:start_date/:end_date"
                render={props => (
                  <ReservationCreate
                    {...props}
                    handleError={this.handleError}
                    stripeLoaded={this.state.stripeLoaded}
                  />
                )}
              />
              <Route
                path="/search/:location"
                render={props => (
                  <Search {...props} 
                    handleError={this.handleError}
                    subscribeUser={this.subscribeUser}
                  />
                )}
              />
              <Route
                path="/search/"
                render={props => (
                  <Search {...props} 
                    handleError={this.handleError}
                  />
                )}
              />
              <Route
                path="/user/:public_profile_key"
                render={props => (
                  <PublicProfile {...props}
                    publicProfileKey={this.state.publicProfileKey}
                    askToReport={this.askToReport}
                    typeMessageReport={this.typeMessageReport}
                    wantsToReport={this.state.wantsToReport}
                    valueReport={this.state.valueReport}
                    reportUser={this.reportUser}
                    reported={this.state.reported}
                    reportedId={this.state.reported_id}
                    loadingReport={this.state.loadingReport}
                  />
                )}
              />
              <Route exact path="/home" 
                render={props => (
                  <Home {...props} signedIn={true} redirectToReferrerSearch={this.redirectToReferrerSearch} />
                )}
              />
              <Route 
                exact path={"/dashboard/stripe/token"} 
                render={props => (
                  <ProfilePage {...props}
                    handleError={this.handleError}
                  />
                )}
              />
              <Route exact path={"/dashboard"} component={ProfilePage} />
              <Route
                path="/garage/:city/:state/:garageid"
                render={props => (
                  <GaragePage {...props}
                    handleError={this.handleError}
                    askToReport={this.askToReport}
                    typeMessageReport={this.typeMessageReport}
                    wantsToReport={this.state.wantsToReport}
                    valueReport={this.state.valueReport}
                    reportUser={this.reportUser}
                    reported={this.state.reported}
                    reportedId={this.state.reported_id}
                    loadingReport={this.state.loadingReport}
                  />
                )}
              />
              <Route
                exact path={"/privacy-policy"}
                render={props => (
                  <Page
                    pageName="privacy_policy"
                  />
                )}
              />
              <Route
                exact path={"/terms-of-use"}
                render={props => (
                  <Page
                    pageName="terms_of_use"
                  />
                )}
              />
              <Route
                exact path={"/disclaimer"}
                render={props => (
                  <Page
                    pageName="disclaimer"
                  />
                )}
              />
              <Route exact path={"/"}
                render={props => (
                  <ProfilePage {...props}
                    handleError={this.handleError}
                  />
                  )}
              />
               <Route
                render={props => (
                  <ProfilePage {...props}
                    handleError={this.handleError}
                  />
                  )}
              />
            </Switch>
          ) : (
            <Switch>

              <Route exact path={["/", "/home"]}
                render={props => (
                  <Home {...props} redirectToReferrerSearch={this.redirectToReferrerSearch} />
                )}
              />

              <Route
                exact
                path="/signup"
                render={props => (
                  <SignUp 
                    {...props} 
                    handleError={this.handleError} 
                    userRequiresUpdate={this.userRequiresUpdate} 
                    firstSignup={this.handleFirstSignup}
                    subscribeToNews={this.subscribeToNews} 
                  />
                )}
              />
              <Route
                path="/search/:location"
                render={props => (
                  <Search {...props}
                    handleError={this.handleError}
                  />
                )}
              />
              <Route
                path="/search/"
                render={props => (
                  <Search {...props} 
                    handleError={this.handleError}
                  />
                )}
              />
              <Route
                path="/garage/:city/:state/:garageid"
                render={props => (
                  <GaragePage {...props}
                    handleError={this.handleError}
                  />
                )}
              />
              <Route
                path="/user/:public_profile_key"
                render={props => (
                  <PublicProfile {...props}
                    publicProfileKey={this.state.publicProfileKey}
                  />
                )}
              />
              <Route
                exact path={"/privacy-policy"}
                render={props => (
                  <Page
                    pageName="privacy_policy"
                  />
                )}
              />
              <Route
                exact path={"/terms-of-use"}
                render={props => (
                  <Page
                    pageName="terms_of_use"
                  />
                )}
              />
              <Route exact path={"/how-to-host"} component={HowToHost} />
              <Route
                exact path={"/disclaimer"}
                render={props => (
                  <Page
                    pageName="disclaimer"
                  />
                )}
              />
              <Route
                exact
                path="/login"
                render={props => (
                  <Login
                    {...props}
                    loading={this.state.loadingForm}
                    handleAuthUser={this.handleAuthUser}
                    userRequiresUpdate={this.userRequiresUpdate}
                    handleError={this.handleError}
                  />
                )}
              />
              <Route
                render={props => (
                  <Home {...props} redirectToReferrerSearch={this.redirectToReferrerSearch} />
                )}
              />
            </Switch>
          )}
        </ScrollToTop>
        </BrowserRouter>
        <Offline onChange={(status) => this.handleConnectionError(status)}>
        </Offline>
      </React.Fragment>
      </AsyncStripeProvider>
    );
  }
}

export default App;
