import React from 'react';
import firebase from 'firebase/app';
import DashboardNav from "../../components/DashboardNav";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import {Elements} from 'react-stripe-elements';
import AddPaymentForm from '../../components/AddPaymentForm';

import CircularProgress from "@material-ui/core/CircularProgress";
import CheckIcon from '@material-ui/icons/Check';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import BillingTable from '../../components/BillingTable';
import dbFireStore from '../../firebase';
import { Link } from "react-router-dom";

import axios from 'axios';

import "../../styles/billing.scss";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const toTitleCase = (str) => {
    return str.replace(/\w\S*/g, function(txt){
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
}
const isDevMode = process.env.NODE_ENV === "development" && process.env.NODE_ENV !== "production"? true: false;

const AWS_STRIPEDASHBOARDLINK_LAMBDA = isDevMode? 'JrLc8XGMwQjxarec47FN11ZuYEikd9y42I3kVF7a': 'r9CmepiXyX5XOqxCzRYIJ4Lulm0qytoX1ZrHAOKS';
const STRIPEDASHBOARDLINK_ENDPOINT = isDevMode? 'https://gwooweja9a.execute-api.us-east-1.amazonaws.com/default/DEV_hostGenerateDashboardLink': 'https://raature570.execute-api.us-east-1.amazonaws.com/default/hostGenerateDashboardLink';

class BillingInfo extends React.Component {
  state = {
    loading: true,
    savingCard: false,
    createModeToggle: false,
    confirmDelete: false,
    deletingCard: false,
    hostStripeConnected: false,
    loadingStripeHost: false,
    pastInvoiceList: [],
    errorRecord: false,
    reviewCount: null,
    usersRating: null,
  };

  componentDidMount() {
    this.checkCustomerInfo();
    this.getOwnerReviews();
  };

  getOwnerReviews = () => {
    dbFireStore.firestore().collection("reviews").where("review_of_user_id", "==", firebase.auth().currentUser.uid).get().then(querySnapshot => {
      var reviewsList = [];
      var queryLength = querySnapshot.docs.length;
      var queryLooped = 0;
      if(queryLength === 0) {
        // user has no reviews yet
      } else {
        querySnapshot.forEach((review) => {
          var reviewInfo = review.data();
          // parse review data here
          queryLooped++;
          reviewsList.push(reviewInfo.rating);
          if(queryLength == queryLooped) {
            // end of the list, push to state
            var sum = reviewsList.reduce((a, b) => a + b, 0);
            var avg = (sum/queryLength).toFixed(1);
            this.setState({
              reviewCount: queryLength,
              usersRating: avg,
            });
          };
        });
      }
    }).catch((error) => {
      console.log(error);
    });
  };

  checkCustomerInfo = () => {
    this.setState({loading: true});
    this.userProfileRef = firebase.database().ref('users/' + firebase.auth().currentUser.uid + '/profile/');
    this.userProfileRef.once("value").then(profile => {
      //check if host connected billing
      if(profile.val().host_stripe_user_id) {
        this.setState({
          hostStripeConnected: true,
          stripeLink: profile.val().host_stripe_user_id
        });
      }
      // check if guest connected customer
      if(profile.val().customerId) {
        // user has a stripe customer id -> get their info
        this.getCustomerInfo(profile.val().customerId);
      } else {
        // user needs to create a customer id in stripe and then base customer off of that created data
        this.createCustomer();
      }
    });
  };

  createCustomer = () => {
    const email = firebase.auth().currentUser.email;
    const fullname = firebase.auth().currentUser.displayName? firebase.auth().currentUser.displayName: '';
    const isDevMode = process.env.NODE_ENV === "development" && process.env.NODE_ENV !== "production"? true: false;

    axios({
      method: 'post',
      url: isDevMode? 'https://gl0rddpxh8.execute-api.us-east-1.amazonaws.com/default/DEV_createCustomer': 'https://3zej6teneb.execute-api.us-east-1.amazonaws.com/default/createCustomer',
      headers: {
        'x-api-key': isDevMode? 'imWuWDwIOg71r5I64SXli77aH4KSBoec5Nr9XzHI': 'nnJfyb4vgL9nWfHdDhRRt5qW2y7QJO5P699lIt2N'
      },
      data: {
        name: fullname,
        email: email
      }
    }).then(response => {
      const success = response.data.success;
      if(success) {
        this.userProfileRef.child("customerId").set(response.data.customer.id);
        this.setState({
          customerData: response.data.customer,
          loading: false,
        });
      } else {
        console.log("Stripe create customer account error");
        this.setState({
          customerData: null,
          loading: false,
        });
      }
    }).catch(error => {
      console.log('Stripe Payment API Error');
      this.setState({
        customerData: null,
        loading: false,
      });
    });
  };

  createStripeDashLink = (e) => {
    e.preventDefault();

    this.setState({
      loadingStripeHost: true,
    });

    var userInfo = {
      stripe_id: this.state.stripeLink,
    };

    axios({
      method: 'POST',
      url: STRIPEDASHBOARDLINK_ENDPOINT,
      headers: {
        "x-api-key": AWS_STRIPEDASHBOARDLINK_LAMBDA
      },
      data: userInfo,
      validateStatus: (status) => { // this is so we don't confuse the catch for error when error was returned from the server
        return true;
      }
    }).then(response => {
      if(response.status === 200) {
        var temp_link = response.data.link.url;
        window.location.href = temp_link;
      }
    }).catch(error => {
      console.log(error);
      this.setState({
        loadingStripeHost: false,
      });
    });

  }

  getCustomerInfo = customerId => {
    const isDevMode = process.env.NODE_ENV === "development" && process.env.NODE_ENV !== "production"? true: false;

    axios({
      method: 'post',
      url: isDevMode? 'https://vbjfkaqew8.execute-api.us-east-1.amazonaws.com/default/DEV_retrieveCustomer': 'https://hr36gm45mf.execute-api.us-east-1.amazonaws.com/default/retrieveCustomer',
      headers: {
        'x-api-key': isDevMode? 'gzbz5RTxuY1d3OElN5qff5URoEA8a0Zg75MVNlSZ': 'lRvcJr7SUyEe3JcGaK4B3FUTa3DOi5c1dzSz3190'
      },
      data: {
        customerId: customerId
      }
    }).then(response => {
      const customerData = response.data.customer;
      const success = response.data.success;
      if(success) {
        // set to state
        // need to get their past invoice list here and pass it to the table, format using the method below
        // createData(new Date('Sat Oct 26 2019 15:13:47 GMT+0900 (Japan Standard Time)'), 'ASDFASDFWEF2E', 'Reservation', "ASDFW234FD", 1234),
        this.setState({
          customerData: customerData,
        });
        this.getCustomerChargeList(customerId);
      } else {
        // there was a error
        this.setState({
          customerData: null,
          loading: false,
        });
        console.log('Error getting customer info');
      }
    }).catch(error => {
      this.setState({
        customerData: null,
        loading: false,
      });
      console.log('Error getting customer info');
    });
  };

  getCustomerChargeList = (customerId) => {
    const isDevMode = process.env.NODE_ENV === "development" && process.env.NODE_ENV !== "production"? true: false;

    axios({
      method: 'post',
      url: isDevMode? 'https://ep6stg2izc.execute-api.us-east-1.amazonaws.com/default/DEV_listChargesGuest': 'https://7dvtnei6u2.execute-api.us-east-1.amazonaws.com/default/listChargesGuest',
      headers: {
        'x-api-key': isDevMode? 'k8rfowtw7217snO0UUcNM9Xa0ttxRCsG23nuTNgr': 'FchnPvGqTQ7fCdSbjyEmoagt16tQglVS61BfGhYv'
      },
      data: {
        customerId: customerId
      }
    }).then(response => {
      const success = response.data.success;
      if(success) {
        var responseLength = response.data.charges.data.length;
        if(responseLength === 0) {
          this.setState({
            pastInvoiceList: [],
            loading: false,
          });
        } else {
          this.setState({
            loading: true,
          });
          var iterations = 0;
          var newInvoiceList = [];
          var reservationDataList = [];
          response.data.charges.data.forEach(item => {
            iterations++;
            // prob need to add card data to the charge row
            // also need status
            var typeOfCharge = item.metadata? item.metadata.type: 'Unspecified';
            var reservationId = item.metadata? item.metadata.reservation_id: null;
            var cardInfo = {
              brand: item.payment_method_details.card.brand,
              last4: item.payment_method_details.card.last4,
              expires: `${item.payment_method_details.card.exp_month}/${item.payment_method_details.card.exp_year.toString().slice(-2)}`
            }

            var newRow = this.createData(new Date(item.created * 1000),reservationId, typeOfCharge ,toTitleCase(item.status), cardInfo, item.amount);
            newInvoiceList.push(newRow);

            if(reservationId) {
              var docRef = firebase.firestore().collection("reservations").doc(reservationId);
              docRef.get().then((doc) => {
                if(doc.exists) {
                  var reservationData = {
                    [reservationId]: doc.data()
                  };
                  reservationDataList.push(reservationData);
                  if(iterations == responseLength) {
                    this.setState({
                      pastInvoiceList: newInvoiceList,
                      reservationDataList: reservationDataList,
                      loading: false,
                    });
                  }
                } else {
                  console.log("not found");
                  if(iterations == responseLength) {
                    this.setState({
                      pastInvoiceList: newInvoiceList,
                      reservationDataList: reservationDataList,
                      loading: false,
                    });
                  }
                }
              }).catch((error) => {
                console.log('Error Getting Document');
                this.setState({
                  errorRecord: true,
                });
              });
            } else {
              if(iterations == responseLength) {
                this.setState({
                  pastInvoiceList: newInvoiceList,
                  reservationDataList: reservationDataList,
                  loading: false,
                });
              }
            }
          });
        }
      } else {
        this.setState({
          pastInvoiceList: [],
          reservationDataList: [],
          loading: false,
        });
      }
    }).catch(error => {
      this.setState({
        pastInvoiceList: [],
        reservationDataList: [],
        loading: false,
      });
      console.log(error);
    });
  }

  updateCustomerInfo = (newCustomerData) => {
    this.setState({
      customerData: newCustomerData,
    });
  }

  toggleUpdateCardInfo = () => {
    this.setState(prevState => ({
      createModeToggle: !prevState.createModeToggle,
    }));
  };

  createModeToggleOff = () => {
    this.setState({
      createModeToggle: false,
    });
  };

  deleteCardConfirmToggle = () => {
    this.setState(prevState => ({
      confirmDelete: !prevState.confirmDelete,
    }));
  };

  deletePaymentInfo = () => {
    this.setState({deletingCard: true, confirmDelete: false, createModeToggle: false});
    const customerId = this.state.customerData.id;
    const cardToDelete = this.state.customerData.default_source;

    const isDevMode = process.env.NODE_ENV === "development" && process.env.NODE_ENV !== "production"? true: false;

    axios({
      method: 'post',
      url: isDevMode? 'https://hdsbxx8h6d.execute-api.us-east-1.amazonaws.com/default/DEV_deleteCard':'https://c4zr2auzgf.execute-api.us-east-1.amazonaws.com/default/deleteCard',
      headers: {
        'x-api-key': isDevMode? 'PS5XvnPOD61uFCZw6L7sZ93D6BXz6PMI6vsJmPtK': 'eiSuOGPrv86GKFFC16tjD4ENIqHUML3A8E06DAQG'
      },
      data: {
        customerId: customerId,
        cardToken: cardToDelete,
      }
    }).then(response => {
      const customerData = response.data.customer;
      const success = response.data.success;
      if(success) {
        // set to state
        var updateCustomerData = this.state.customerData;
        updateCustomerData.default_source = null;
        this.setState({
          deletingCard: false,
          customerData: updateCustomerData,
        });
      } else {
        // there was a error
        this.setState({
          deletingCard: false,
        });
        this.props.handleError({
          message: "There was an error deleting your payment information. Please try again."
        });
        console.log('Error deleting customer card info');
      }
    }).catch(error => {
      this.setState({
        deletingCard: false,
      });
      this.props.handleError({
        message: "There was an error deleting your payment information. Please try again."
      });
      console.log('Error deleting customer card');
    });
  };

  createData = (date, reservation, type, status, cardInfo, total) => {
    return { date, reservation, type, status, cardInfo, total };
  }

  render() {
    var defaultSourceId = this.state.customerData? this.state.customerData.default_source: null;
    var savedCardInfo = defaultSourceId? this.state.customerData.sources.data.filter((card) => card.id === defaultSourceId)[0]: null;
    var createModeStatus = this.state.createModeToggle || !savedCardInfo;
    return (
      <div className="edit-profile-wrap">
        <React.Fragment>
          {this.state.loadingStripeHost?
            <div className="app-loader-wrap dashboard-loader">
              <div className="loading-app">
                <CircularProgress className="main-loader"/>
              </div>
            </div>:null}
        </React.Fragment>
        <div className="dashboard-container billing-dashboard">
            <Dialog
              open={this.state.confirmDelete}
              onClose={this.deleteCardConfirmToggle}
              className={"confirm-delete-dialog"}
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogTitle id="alert-dialog-title">Confirm Delete</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Are you sure you want to delete your payment information?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.deleteCardConfirmToggle} color="primary">
                  Cancel
                </Button>
                <Button className="delete-card-btn" onClick={this.deletePaymentInfo} color="primary" autoFocus>
                  Delete Card
                </Button>
              </DialogActions>
            </Dialog>
          <div className="col-left">
            <DashboardNav
              trustLevel={1}
              reviewCount={this.state.reviewCount? this.state.reviewCount: 0}
              starRating={this.state.usersRating? this.state.usersRating: 5} 
            />
          </div>
          <div className="col-right">
            {this.state.hostStripeConnected?
              <div className="manage-host-payment-container">
              <Typography variant="h5">Hosting Payment Center</Typography>
              <div className="garage-payments-icon"></div>
              <Link to="/payment-center">
              <Button>
                <Typography variant="body2">Payment Center</Typography>
              </Button></Link></div>:null
            }
            <div className="dashboard-notifications billing-info">
              <Typography variant="h5" component="h3">Manage Payment Information</Typography>
              <Typography variant="caption" className="subtitle">
                Edit payment info and view payment history
              </Typography>
            </div>
            <div className="user-cards-data">
              {this.state.customerData && !this.state.customerData.default_source?
                <div className="no-garages-yet">
                <Typography>💳 No payment info found. Add your payment info below to start renting!</Typography></div>: null
              }
              {savedCardInfo && !this.state.loading?
                <div className="default-card-source">
                  {this.state.deletingCard?
                    <div className="deleting-card-loader">
                      <div className="loading-stripe-card-from">
                      <div className="loader-container-delete">
                      <CircularProgress className="stripe-form-loader"/>
                       <Typography variant="caption">Deleting Payment Info</Typography>
                      </div>
                      </div>
                    </div>:null
                  }
                  <Typography variant="h3" className="payment-info-title">Your Default Payment Source</Typography>
                  <div className="saved-card-info">
                    <div className={`card-brand-logo ${savedCardInfo.brand}`}>
                    </div>
                    <div className="card-four">
                    <span>Last 4</span>
                    {savedCardInfo.last4}
                    </div>
                    <div className="card-four">
                      <span>exp</span>
                      {`${savedCardInfo.exp_month}/${savedCardInfo.exp_year.toString().slice(-2)}`}
                    </div>
                    <div className="saved-card-actions">
                      {!this.state.createModeToggle?
                        <Button className="update-card-btn" onClick={this.toggleUpdateCardInfo}>
                          Update
                        </Button>:<Button className="update-card-btn with-cancel" onClick={this.toggleUpdateCardInfo}>
                          Cancel Update
                        </Button>
                      }
                      <Button className="delete-card-btn" onClick={this.deleteCardConfirmToggle}>
                        Delete
                      </Button>
                    </div>
                  </div>
                </div>: null
              }
            </div>
            <div className="add-payment-info-form">
              {this.props.stripeLoaded && !this.state.loading?
                <Elements>
                  <AddPaymentForm
                    loading={this.state.loading}
                    handleError={this.props.handleError}
                    customerId={this.state.customerData? this.state.customerData.id: null}
                    updateCustomerInfo={this.updateCustomerInfo}
                    createMode={createModeStatus}
                    createModeToggleOff={this.createModeToggleOff}
                  />
                </Elements>:
                <div className="loading-stripe-card-from">
                <CircularProgress className="stripe-form-loader"/>
                 <Typography variant="caption">Loading Payment Information</Typography>
                </div>
              }
            </div>
            <div className="billing-history-container">
              <div className="dashboard-notifications billing-info">
              <Typography variant="h5" component="h3">Payment History</Typography>
              <Typography variant="caption" className="subtitle">
                Track your recent payments & view receipts
              </Typography>
              </div>
              <div className="billing-table-wrap">
                <BillingTable rows={this.state.pastInvoiceList} loading={this.state.loading} errorRecord={this.state.errorRecord}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
};
 
export default BillingInfo;
