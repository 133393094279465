import React from "react";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from '@material-ui/core/Button';
import DashboardNav from "../../components/DashboardNav";
import ReservationListItem from "../../components/ReservationListItem";
import {Link} from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';

import moment from 'moment';

import firebase from 'firebase/app';
import dbFireStore from '../../firebase';
import Footer from "../../components/Footer";

import "../../styles/reservation_list.scss";

const toTitleCase = (str) => {
    return str.replace(/\w\S*/g, function(txt){
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
}

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      className="reservations-tab-panel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

const ReservationTabs = withStyles({
  root: {
    borderColor: '#e8e8e8',
  },
  indicator: {
    backgroundColor: '#1890ff',
  },
})(Tabs);

const ReservationTab = withStyles(theme => ({
  root: {
    textTransform: 'none',
    '&:hover': {
      color: '#40a9ff',
      opacity: 1,
    },
    '&$selected': {
      color: '#1890ff',
    },
    '&:focus': {
      color: '#40a9ff',
    },
  },
  selected: {
    color: "40a9ff",
  }
}))(props => <Tab {...props} />);

class Reservations extends React.Component {
  state = {
    loading: true,
    reservations: [],
    garages: {},
    reviewCount: null,
    usersRating: null,
    value: 0,
    upcomingList: [],
    pastList: [],
    garageOwners: null,
    hasHostReservations: false
  }

  componentDidMount() {
    this.checkForReservations();
    this.getOwnerReviews(firebase.auth().currentUser.uid);
    this.checkForHostReservations();
  }

  handleChange = (event, newValue) => {
    this.setState({
      value: newValue
    });
  };


  getOwnerReviews = (owner) => {
    dbFireStore.firestore().collection("reviews").where("review_of_user_id", "==", owner).get().then(querySnapshot => {
      var reviewsList = [];
      var queryLength = querySnapshot.docs.length;
      var queryLooped = 0;
      if(queryLength === 0) {
        // user has no reviews yet
      } else {
        querySnapshot.forEach((review) => {
          var reviewInfo = review.data();
          // parse review data here
          queryLooped++;
          reviewsList.push(reviewInfo.rating);
          if(queryLength == queryLooped) {
            // end of the list, push to state
            var sum = reviewsList.reduce((a, b) => a + b, 0);
            var avg = (sum/queryLength).toFixed(1);
            this.setState({
              reviewCount: queryLength,
              usersRating: avg,
            });
          };
        });
      }
    }).catch((error) => {
      console.log(error);
    });
  };

  checkForReservations = async () => {
    await dbFireStore.firestore()
      .collection("reservations")
      .where("guest_id", "==", firebase.auth().currentUser.uid)
      .orderBy('created_at', "desc")
      .get()
      .then((querySnapshot) => {
        var reservationsList = [];
        var queryLength = querySnapshot.docs.length;
        var queryLooped = 0;
        if(queryLength === 0) {
          // no reservations
          this.setState({
            loading: false,
            upcomingList: [],
            pastList: []
          });
        } else {
          // user has reservations
          querySnapshot.forEach((reservation) => {
            queryLooped++;
            var reservationData = reservation.data();
            reservationData.id = reservation.id;
            this.getGarageInfo(reservation.data().garage_id);
            reservationsList.push(reservationData);
            if(queryLength === queryLooped) {
              // end of loop!
              // UPCOMING reservations
              const upcomingList = reservationsList.filter(element => moment(new Date()).diff(moment(element.start_date.toDate()), "days") <= 0 || moment(new Date()).diff(moment(element.end_date.toDate()), "days") <= 0 && !element.host_canceled && !element.guest_canceled && !element.completed_reservation);

              // PAST reservations
              const pastList = reservationsList.filter(element => moment(new Date()).diff(moment(element.start_date.toDate()), "days") > 0 && moment(new Date()).diff(moment(element.end_date.toDate()), "days") > 0);

              this.setState({
                upcomingList: upcomingList,
                pastList: pastList,
                loading: false,
              });
            }
          });
        }
      }).catch(error => {
        // error duh!
        this.setState({
          loading: false,
          upcomingList: [],
          pastList: []
        });
      });
  }

  getGarageInfo = (garage_id) => {
    dbFireStore.firestore().collection("garages").doc(garage_id).get().then((garage) => {
      // check if garage has photos, grab one if it does
      if(garage.data()) {
        if(garage.data().owner_id) {
          this.getGarageOwnerInfo(garage_id, garage.data().owner_id);
        }
        if(garage.data().photos && garage.data().garage_key) {
          Object.keys(garage.data().photos).forEach((photo) => {
            if(garage.data().photos[photo].order === 0) {
              var leadId = photo;
              var leadImgLocationRef = firebase.storage().ref(`/garages/${garage.data().garage_key}/${leadId}/photo.jpg`);
              leadImgLocationRef.getDownloadURL().then(url => {
                this.setState(prevState => ({
                  garages: {
                    ...prevState.garages,
                    [garage_id]: {url: url, temp_address: `${toTitleCase(garage.data().city)}, ${garage.data().state}`}
                  }
                }));
              });
            }
          });

        } else {
          // has no photos
          this.setState(prevState => ({
            garages: {
              ...prevState.garages,
              [garage_id]: {url: '', temp_address: `${toTitleCase(garage.data().city)}, ${garage.data().state}`}
            }
          }));
        }
      } else {
        console.log(`Garage not found ${garage_id}`);
      }
    });
  };

  getGarageOwnerInfo = (garage_id, owner_id) => {
    if(this.state.garageOwners && this.state.garageOwners[garage_id]) {
      // already have their info
    } else {
      // don't have this garge owners info for this garage id
      dbFireStore.firestore().collection("users")
        .where("user_id", "==", owner_id)
        .get().then((owner) => {
          var ownerData = owner.docs[0].data();
          if(ownerData.user_photo_key) {
            this.getOwnerPhoto(garage_id, ownerData.display_name, ownerData.user_photo_key);
          } else {
            this.setState(prevState => ({
            garageOwners: {
              ...prevState.garageOwners,
              [garage_id]: {name: ownerData.display_name, photo: ''}
            }}));
          }
        }).catch(error => {
          console.log("error getting owner info");
        });
      }
  };

  getOwnerPhoto = (garage_id, name, owner_photo_key) => {
    const storageRef = firebase.storage().ref();
    const storageUserPhotoRef = storageRef.child(`${owner_photo_key}`);
    storageUserPhotoRef.getDownloadURL().then(url => {
      this.setState(prevState => ({
        garageOwners: {
          ...prevState.garageOwners,
          [garage_id]: {name: name, photo: url}
        }}));
    }).catch(error => {
      this.setState(prevState => ({
        garageOwners: {
          ...prevState.garageOwners,
          [garage_id]: {name: name, photo: ''}
        }}));
    });
  };

  checkForHostReservations = async () => {
    await dbFireStore.firestore()
      .collection("reservations")
      .where("host_id", "==", firebase.auth().currentUser.uid)
      .orderBy('created_at', "desc")
      .get()
      .then((querySnapshot) => {
        var queryLength = querySnapshot.docs.length;
        if(queryLength === 0) {
          // no reservations
          this.setState({hasHostReservations: false});
        } else {
          // create link
          this.setState({hasHostReservations: true});
        }
      }).catch(error => {
        // error duh!
      });
  }


  render() {
    return (
      <div className="with-navbar-wrap">
        <div className="dashboard-container">
          <div className="col-left">
          <DashboardNav 
            trustLevel={1} 
            reviewCount={this.state.reviewCount? this.state.reviewCount: 0}
            starRating={this.state.usersRating? this.state.usersRating: 5} 
          />
          </div>
          <div className="col-right dashboard-notifications">
            <Typography variant="h5" component="h3">
              Your Reservations
            </Typography>
            <Typography variant="caption" className="subtitle with-reservations">
              View upcoming stays you reserved here
            </Typography>
            <div className="reservation-guest-list-info">
            <Typography>
            Your Host has up to 48 hours to accept your request, and you will see your reservation automatically confirmed when they do.
            </Typography>
            {/* <Typography>
              Learn more about <a href="https://help.motorroof.com/article/54-reservation-statuses" target="blank">reservation statuses.</a>
            </Typography> */}
            </div>
            {this.state.hasHostReservations?
              <div className="host-reservations-link-bar">
              <Typography variant="caption" className="subtitle with-reservations and-has-hostings">
                Looking for your hosted properties?
              </Typography>
              <Link to="/hosting-hub" className="has-host-reservations-tab">
                <Button>Host Reservations</Button>
              </Link></div>:null
            }

            <AppBar position="static" color="default" className="flat-bar">
            <ReservationTabs
              value={this.state.value}
              onChange={this.handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            >
              <ReservationTab label="Current Reservations" {...a11yProps(0)} />
              <ReservationTab label="Past" {...a11yProps(1)} />
            </ReservationTabs>
          </AppBar>
          <TabPanel value={this.state.value} index={0}>
            <div className="reservations-list-container">
              {this.state.upcomingList.length < 1?
                <div className="no-garages-yet">
                  <Typography variant="h4">🐣 You have no current reservations</Typography>
                  <Typography>Find a space to reserve and view your reservations here.</Typography>
                </div>:
                <React.Fragment>
                  {this.state.upcomingList.map((reservation, i) => (
                    <ReservationListItem
                      key={`${i}-upcomingList-${reservation.id}`}
                      i={i}
                      reservation={reservation}
                      ownerInfo={this.state.garageOwners && this.state.garageOwners[reservation.garage_id]? this.state.garageOwners[reservation.garage_id]: null}
                      garageList={this.state.garages}
                      />
                  ))}
                </React.Fragment>
              }
            </div>
          </TabPanel>
          <TabPanel value={this.state.value} index={1}>
            <div className="reservations-list-container">
            {this.state.pastList.length < 1?
                <div className="no-garages-yet">
                  <Typography variant="h4">🧾 You have no past reservations</Typography>
                  <Typography>Here you will see all your past bookings.</Typography>
                </div>:
                <React.Fragment>
                  {this.state.pastList.map((reservation, i) => (
                    <ReservationListItem
                      reservation={reservation}
                      i={i}
                      key={`${i}-pastList-${reservation.id}`}
                      ownerInfo={this.state.garageOwners && this.state.garageOwners[reservation.garage_id]? this.state.garageOwners[reservation.garage_id]: null}
                      garageList={this.state.garages}/>
                  ))}
                </React.Fragment>
              }
              </div>
          </TabPanel>
          </div>
          <Footer />
        </div>
        {this.state.loading?
          <div className="app-loader-wrap dashboard-loader">
            <div className="loading-app">
              <CircularProgress className="main-loader"/>
            </div>
          </div>:null}
      </div>
    );
  }
};

export default Reservations;
