import React from "react";
import { Link } from "react-router-dom";

import firebase from 'firebase/app';
import dbFireStore from '../../firebase';

import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from '@material-ui/core/Button';

import ConnectStripe from '../../components/ConnectStripe';
import WelcomeToMotorRoof from '../../components/WelcomeToMotorRoof';
import VerifiedEmailNotice from '../../components/VerifiedEmailNotice';
import StripeConfirm from '../../components/StripeConfirm';
import VerifyEmail from '../../components/VerifyEmail';
import VerifyID from '../../components/VerifyID';
import VerifyPhone from '../../components/VerifyPhone';
import AddAboutInfo from '../../components/AddAboutInfo';
import DashboardNav from "../../components/DashboardNav";
import Footer from "../../components/Footer";

import axios from 'axios';
import queryString from 'query-string';

import "../../styles/dashboard.scss";


const isDevMode = process.env.NODE_ENV === "development" && process.env.NODE_ENV !== "production"? true: false;

const AWS_CHECK_STRIPE_TOKEN_LAMBDA = isDevMode? 'pbd11jsg5UaxBabmX8nLbaPPpeHPtVBJaeH005Av' :'NgBnMRhRfT89UTaWA7sDs8iLNcryMnFR8Y4Z5e7L';
const VERIFY_STRIPEHOSTKEY_ENDPOINT = isDevMode? 'https://9gluayugh3.execute-api.us-east-1.amazonaws.com/default/DEV_hostTokenCheckStripe':'https://t1exzob2j3.execute-api.us-east-1.amazonaws.com/default/hostTokenCheckStripe';


class Dashboard extends React.Component {
  state = {
    loading: true,
    userGaragesRef: firebase.database().ref('users/' + firebase.auth().currentUser.uid + '/garages'),
    needsWelcome: false,
    needsEmailNotice: false,
    needsStripeHostConfirm: false,
    powerLevel: null,
    hostStripeConnected: true,
    hostStripeError: false,
    user_description: null,
    loadingStripe: false,
    reviewCount: null,
    usersRating: null,
    verified_status: {
      email: false,
      id: false,
      phone: false
    },
    hasActiveGarages: null,
    hasDraftGarage: null,
  };

  checkStripeToken = () => {
    this.setState({loadingStripe: true});
    // check state store to help prevent CSRF
    const state_store = localStorage.getItem("state_store");
    let stripe_params = queryString.parse(this.props.location.search);
    var verifyToken = {token: stripe_params.code};
    if(state_store == stripe_params.state) {
      // the state keys match send to lambda to check stripe
      axios({
      method: 'POST',
      url: VERIFY_STRIPEHOSTKEY_ENDPOINT,
      headers: {
        "x-api-key": AWS_CHECK_STRIPE_TOKEN_LAMBDA
      },
      data: verifyToken,
      validateStatus: (status) => { // this is so we don't confuse the catch for error when error was returned from the server
        return true;
      },
      }).then(response => {
        if(response.status !== 200) {
          // we posted to the api sucessfully but stripe returned a error
          var error_info = JSON.parse(response.data.stripe_error);
          if(error_info.error === "invalid_grant") {
            this.props.handleError({
              message: "This authorization code has already been used. All tokens issued with this code have been revoked. Please connect with Stripe again!",
            });
            // need user to try again, as their account may be whiped
            this.userUpdateStripeRef = firebase.database().ref('users/' + firebase.auth().currentUser.uid + '/profile');
            this.userUpdateStripeRef.child("host_stripe_connect_error").set(true).then(() => {
              // updated stripe_user_id (which is for hosts so for now we save it to hosts?);
              // this should replace the current history, thus distorying the record of the token was sent to prevent resend on refreash, etc.
              this.props.history.replace('/dashboard');
              this.setState({loadingStripe: false});
            });
          } else {
            this.props.handleError({
              message: error_info.error_description,
            });
            this.userUpdateStripeRef = firebase.database().ref('users/' + firebase.auth().currentUser.uid + '/profile');
            this.userUpdateStripeRef.child("host_stripe_connect_error").set(true).then(() => {
              // updated stripe_user_id (which is for hosts so for now we save it to hosts?);
              // this should replace the current history, thus distorying the record of the token was sent to prevent resend on refreash, etc.
              this.props.history.replace('/dashboard');
              this.setState({loadingStripe: false});
            });
          }
        } else {
          // stripe was successfull, follow this plan
          // 1. need to save the stripe_user_id to their firebase account
          // 2. if successful we need to redirect the user or it will revoke their account due to security (and disable back button!)
          // 3. after redirect we need to show a congrats message for connecting their account, we can set stripeHostConfirmation: true
          // then when they land on the other page we check if they need a stripe confirmation popup and then set that value to false
          this.userUpdateStripeRef = firebase.database().ref('users/' + firebase.auth().currentUser.uid + '/profile');
          this.userUpdateStripeRef.child("host_stripe_connect_error").set(false);
          var stripe_user_id = response.data.stripe_user_id;

          this.userUpdateStripeRef.child("host_stripe_user_id").set(stripe_user_id).then(() => {
            // updated stripe_user_id (which is for hosts so for now we save it to hosts?);
            // this should replace the current history, thus distorying the record of the token was sent to prevent resend on refreash, etc.
            //  Just in case 

            // we also need to update their garages if they have any with their stripe id!!
            // should be under the garage record destination_charge
            this.userGaragesRef = firebase.database().ref('users/' + firebase.auth().currentUser.uid + '/garages');
            this.userGaragesRef.once('value', snapshot => {
              if (snapshot.val() != null) {
                var lengthGarage = Object.keys(snapshot.val()).length;
                var iterations = 0;
                snapshot.forEach((garage) => {
                  iterations++;
                  const garageData = garage.val();
                  const isFinished = garageData.isFinished;
                  const garageKey = garageData.garage_key;
                  if (isFinished && garageKey) {
                    // user has public garage and needs their stripe key added to it to process payments
                    const publicGarageRef = dbFireStore
                      .firestore()
                      .collection("garages")
                      .doc(garageKey);
                    publicGarageRef.update({
                      destination_charge: stripe_user_id,
                      lastUpdated: firebase.firestore.FieldValue.serverTimestamp()
                    });
                  }
                  if(iterations == lengthGarage) {
                    // last loop ended
                    this.props.history.replace('/dashboard');
                    this.setState({
                      loadingStripe: false,
                      needsStripeHostConfirm: true,
                      hostStripeConnected: true,
                      hostStripeError: false,
                      loading: false,
                    });
                  }
                });
              }
            });
          });
        }
      }).catch(error => {
        // handle error when sending
        this.props.handleError({
          message: "There was a problem verifying your Stripe Account. Please try again."
        });
        this.props.history.replace('/dashboard');
        this.setState({loadingStripe: false});
      });
    } else {
      // show error, clear state string and ask to try to connect again
      this.props.handleError({
        message: "There was a problem verifying your Stripe Account. Please try again."
      });
      this.props.history.replace('/dashboard');
      this.setState({loadingStripe: false});
    }
  };

  checkStripeCustomer = () => {
    const userProfileRef = firebase.database().ref('users/' + firebase.auth().currentUser.uid + '/profile/');
    userProfileRef.once("value").then(profile => {
      if(profile.val() != null && !profile.val().customerId) {
        const email = firebase.auth().currentUser.email;
        const fullname = firebase.auth().currentUser.displayName? firebase.auth().currentUser.displayName: '';
        const isDevMode = process.env.NODE_ENV === "development" && process.env.NODE_ENV !== "production"? true: false;
        axios({
          method: 'post',
          url: isDevMode? 'https://gl0rddpxh8.execute-api.us-east-1.amazonaws.com/default/DEV_createCustomer': 'https://3zej6teneb.execute-api.us-east-1.amazonaws.com/default/createCustomer',
          headers: {
            'x-api-key': isDevMode? 'imWuWDwIOg71r5I64SXli77aH4KSBoec5Nr9XzHI': 'nnJfyb4vgL9nWfHdDhRRt5qW2y7QJO5P699lIt2N'
          },
          data: {
            name: fullname,
            email: email
          }
        }).then(response => {
          const success = response.data.success;
          if(success) {
            userProfileRef.child("customerId").set(response.data.customer.id);
          } else {
            console.log("Stripe customer account error");
          }
        }).catch(error => {
          console.log('Stripe Payment API Error');
        });
      }
    });
  }

  componentDidMount() {
    if(this.props.location.pathname === '/dashboard/stripe/token') {
      this.checkStripeToken();
    }
    if(this.props.location.pathname === '/signup') {
      this.props.history.replace('/dashboard');
    }
    this.checkStripeCustomer();
    this.checkVerifyPowerLevel();
    if(this.state.userGaragesRef) {
      this.state.userGaragesRef.on('value', snapshot => {
        if (snapshot.val() != null) {
          if(Object.keys(snapshot.val()).length > 0) {
            var lengthGarage = Object.keys(snapshot.val()).length;
            var activeGarages = 0;
            var drafts = false;
            var iterations = 0;
            snapshot.forEach(garage => {
              iterations++;
              var garageData = garage.val();
              if(garageData.isFinished) {
                activeGarages++;
              } else {
                drafts = true;
              }
              if(iterations == lengthGarage) {
                this.setState({
                  hasActiveGarages: activeGarages,
                  hasDraftGarage: drafts,
                });
              }
            });
          }
        }
      });
    }
  }

 getOwnerReviews = (owner) => {
    dbFireStore.firestore().collection("reviews").where("review_of_user_id", "==", owner).get().then(querySnapshot => {
      var reviewsList = [];
      var queryLength = querySnapshot.docs.length;
      var queryLooped = 0;
      if(queryLength === 0) {
        // user has no reviews yet
      } else {
        querySnapshot.forEach((review) => {
          var reviewInfo = review.data();
          // parse review data here
          queryLooped++;
          reviewsList.push(reviewInfo.rating);
          if(queryLength == queryLooped) {
            // end of the list, push to state
            var sum = reviewsList.reduce((a, b) => a + b, 0);
            var avg = (sum/queryLength).toFixed(1);
            this.setState({
              reviewCount: queryLength,
              usersRating: avg,
            });
          };
        });
      }
    }).catch((error) => {
      console.log(error);
    });
  };

  checkVerifyPowerLevel() {
    const user = firebase.auth().currentUser;
    const userInfoId = user.uid;
    this.getOwnerReviews(user.uid);
    firebase.database().ref('/users/' + userInfoId + '/profile').once('value').then((snapshot) => {
      const needsStripeHostConfirm = snapshot.val() && !snapshot.val().beenStripeHostWelcomed && snapshot.val().host_stripe_user_id? true: false;
      const beenWelcomed = (snapshot.val() && snapshot.val().beenWelcomed) || false;
      const hasVerifiedUpdate = (snapshot.val() && snapshot.val().verified_status) && snapshot.val().verified_status.email;
      const hasVerifiedEmail = (snapshot.val() && snapshot.val().verified_status) && user.emailVerified;
      const hasVerifiedID = (snapshot.val() && snapshot.val().verified_status) && snapshot.val().verified_status.id;
      const hasVerifiedPhone = (snapshot.val() && snapshot.val().verified_status) && snapshot.val().verified_status.phone;
      const userInfo = (snapshot.val() && snapshot.val().user_description) || null;
      const hasStripeConnectError = snapshot.val() && snapshot.val().host_stripe_connect_error? true: false;
      const hasStripeUserID = snapshot.val() && snapshot.val().host_stripe_user_id? true: false;

      if(hasStripeUserID === false) {
        this.setState({
          hostStripeConnected: false,
        });
      }

      if(hasStripeConnectError) {
        this.setState({
          hostStripeError: true,
        });
      }

      if(hasVerifiedEmail) {
        // user's email has been verified through firebase
        if(!hasVerifiedUpdate) {
          // need to update database
          this.setState({ 
            needsEmailNotice: true 
          });

          this.userProfileRef = firebase.database().ref('users/' + userInfoId + '/profile');
          this.userProfileRef.child("verified_status").child("email").set(true);
          this.userProfileRef.once('value', snapshot => {
            if (snapshot.val() != null) {
              //User has public profile
              const public_profile_key = snapshot.val().public_profile_key;
              const verifiedEmailRef = dbFireStore.firestore().collection("users").doc(public_profile_key);
              verifiedEmailRef.update({
                verified_email: true,
                lastUpdated: firebase.firestore.FieldValue.serverTimestamp()
              });
            }
          });
        }
      }

      if(!beenWelcomed) {
        // This is the first time they used the app, set state to true and then show the welcome screens
        this.setState({
          needsWelcome: true,
          powerLevel: 1,
          verified_status: {
            email: hasVerifiedEmail,
            id: hasVerifiedID,
            phone: hasVerifiedPhone
          },
          loading: false,
        });
      } else if(needsStripeHostConfirm) {
        this.userProfileRef = firebase.database().ref('users/' + userInfoId + '/profile');
        this.userProfileRef.child("beenStripeHostWelcomed").set(true);
        this.setState({
          needsStripeHostConfirm: true,
          powerLevel: 1,
          verified_status: {
            email: hasVerifiedEmail,
            id: hasVerifiedID,
            phone: hasVerifiedPhone
          },
          loading: false,
        });
      } else {
        // Then we just check their power level
        this.setState({
          needsWelcome: false,
          user_description: userInfo,
          verified_status: {
            email: hasVerifiedEmail,
            id: hasVerifiedID,
            phone: hasVerifiedPhone
          },
          loading: false,
        });
      }
    });
  }

  componentWillUnmount() {
    if(this.userProfileRef) {
      this.userProfileRef.off();
    }
  }

  needsVerifiedEmailNotice = () => {
    this.setState({ needsEmailNotice: false });
  };

  hasUserDescription = () => {
    this.setState({ user_description: true });
  };

  render () {
    return (
      <div className="with-navbar-wrap">
        {this.state.loadingStripe?
          <div className="app-loader-wrap dashboard-loader loading-stripe">
            <div className="loading-app stripe-loader">
              <CircularProgress className="main-loader"/>
              <div className="motor-roof-connect-stripe"></div>
              <Typography variant="caption">Please wait as we verify your account.</Typography>
            </div>
          </div>:
          <React.Fragment>
            {this.state.loading?
              <div className="app-loader-wrap dashboard-loader">
                <div className="loading-app">
                  <CircularProgress className="main-loader"/>
                </div>
              </div>:null}
          </React.Fragment>
        }
        <React.Fragment>
        {this.state.needsWelcome?
          <WelcomeToMotorRoof />:
          <div></div>
        }
        {this.state.needsEmailNotice?
          <VerifiedEmailNotice needsVerifiedEmailNotice={this.needsVerifiedEmailNotice} />: 
          <div></div>
        }
        {this.state.needsStripeHostConfirm && !this.state.hostStripeError?
          <StripeConfirm />:
          <div></div>
        }
        <div className="dashboard-container">
          <div className="col-left">
          <DashboardNav 
            trustLevel={1} 
            reviewCount={this.state.reviewCount? this.state.reviewCount: 0}
            starRating={this.state.usersRating? this.state.usersRating: 5} 
          />
          </div>
          <div className="col-right dashboard-notifications">
            <Typography variant="h5" component="h3">
              Dashboard
            </Typography>
            <Typography variant="caption" className="subtitle">
              Notifications
            </Typography>
            {!this.state.hostStripeConnected && this.state.hasActiveGarages > 0|| this.state.hostStripeError && this.state.hasActiveGarages > 0?
              <ConnectStripe />:
              <div className="no-garages-yet">
              <Typography>You have no new dashboard notifications.</Typography>
              </div>
            }

            <React.Fragment>
            {this.state.hasActiveGarages && this.state.hasActiveGarages > 0?
              <div className="user-has-garages-infobox">
                <Typography variant="h4">Your properties</Typography>
                <div className="host-quick-link no-spaces">
                  <Typography>{`You have ${this.state.hasActiveGarages > 1? `${this.state.hasActiveGarages} properties`: `${this.state.hasActiveGarages} property`} available for rent`}</Typography>
                  <Link to="/hosting-hub">
                    <Button className="add-space-btn">Manage Properties</Button>
                  </Link>
                </div>
              </div>:
              this.state.hasDraftGarage?
                <div className="user-has-garages-infobox">
                <Typography variant="h4">Your Hosted Spaces</Typography>
                <div className="host-quick-link has-draft">
                <Typography>{`You have an unpublished listing as a draft.`}</Typography>
                <Link to="/garages/new">
                  <Button className="edit-draft-btn">Edit</Button>
                </Link>
                <Link to="/hosting-hub">
                  <Button className="delete-draft-btn">Delete</Button>
                </Link>
                </div>
                </div>:
              <div className="user-has-garages-infobox">
                <Typography variant="h4">List your space</Typography>
                <div className="host-quick-link no-spaces">
                  <Typography>Host your own space for free!</Typography>
                  <Link to="/garages/new">
                    <Button className="add-space-btn">Add Your Space</Button>
                  </Link>
                </div>
              </div>
            }
            </React.Fragment>

            {this.state.verified_status.email && this.state.verified_status.phone && this.state.user_description?
              null:
              <div className="user-verification-blocks">
                <div className="about-verification-roadmap">
                  <Typography variant="h4">Improve your trust level</Typography>
                  <Typography>No one likes dealing with strangers. Improve your verification to help gain others trust and keep our community safe.</Typography>
                </div>
                <React.Fragment>
                  {this.state.verified_status.email?
                    <div className="verified-block-complete">email verified!</div>:<VerifyEmail />
                  }
                  {this.state.verified_status.phone?
                    <div className="verified-block-complete">phone verified!</div>:<VerifyPhone />
                  } 
                  {this.state.verified_status.id?
                    <div className="verified-block-complete">id verified!</div>:<VerifyID />
                  }
                </React.Fragment>
                {this.state.user_description?
                  <div className="verified-block-complete">User has info about them</div>:<AddAboutInfo hasUserDescription={this.hasUserDescription} />
                }
              </div>
            }
          </div>
        </div>
        <Footer />
        </React.Fragment>
      </div>
    );
  }
};

export default Dashboard;
