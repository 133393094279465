import React, { Component } from "react";

import firebase from 'firebase/app';
import dbFireStore from "../../firebase";

import DashboardNav from "../../components/DashboardNav";

import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Switch from "@material-ui/core/Switch";
import CircularProgress from "@material-ui/core/CircularProgress";

import Loader from "react-loader-spinner";

import "../../styles/edit_profile.scss";

import axios from 'axios';
const SUBSCRIBE_MAILING_TOGGLE_KEY = "aeNxjYwao52VKTfwbk5535BUC2yAfOeV5Uvxzoga";
const SUBSCRIBE_MAILING_TOGGLE_ENDPOINT = " https://fm6ibujtji.execute-api.us-east-1.amazonaws.com/default/toggleSubscriptionMailChimp";
const SUBSCRIBE_MAILING_KEY = "EYAqBkTrYS73nwMwexe2534FSoe95h1k6PsxMzT9";
const SUBSCRIBE_MAILING_ENDPOINT = "https://c3xacovzsg.execute-api.us-east-1.amazonaws.com/default/addMemberMailChimp";

export default class EditProfile extends Component {

  state = {
    aboutInfoText: "",
    loading: false,
    subscribed: false,
    reviewCount: null,
    usersRating: null,
  };

  componentDidMount() {
    this.getAboutInfo();
    this.getOwnerReviews();
  }

  getOwnerReviews = () => {
    dbFireStore.firestore().collection("reviews").where("review_of_user_id", "==", firebase.auth().currentUser.uid).get().then(querySnapshot => {
      var reviewsList = [];
      var queryLength = querySnapshot.docs.length;
      var queryLooped = 0;
      if(queryLength === 0) {
        // user has no reviews yet
      } else {
        querySnapshot.forEach((review) => {
          var reviewInfo = review.data();
          // parse review data here
          queryLooped++;
          reviewsList.push(reviewInfo.rating);
          if(queryLength == queryLooped) {
            // end of the list, push to state
            var sum = reviewsList.reduce((a, b) => a + b, 0);
            var avg = (sum/queryLength).toFixed(1);
            this.setState({
              reviewCount: queryLength,
              usersRating: avg,
            });
          };
        });
      }
    }).catch((error) => {
      console.log(error);
    });
  };

  getAboutInfo = () => {
    const userProfileRef = firebase.database().ref('users/' + firebase.auth().currentUser.uid + '/profile/');
    userProfileRef.once('value', snapshot => {
      if (snapshot.val() != null) {

        //Check if user public profile key exists
        const publicProfileKey = snapshot.val().public_profile_key;

        //Check if user is subscribed to mailing list
        // + if value wasn't set fallback to false or it will fail the call to lambda
        const subscribed = snapshot.val().subscribed? snapshot.val().subscribed: false;

        if(publicProfileKey) {
          //User has public profile
          const userPublicRef = dbFireStore.firestore().collection("users").doc(publicProfileKey);
          userPublicRef.get()
          .then(doc => {
            if (!doc.exists) {
              console.log('No such document!');
            } else {
              const data = doc.data();

              if (data.about_info) {
                console.log("about info", data.about_info);
                this.setState({ 
                  aboutInfoText: data.about_info,
                  subscribed
                });
              } else {
                this.setState({ subscribed });
              }
            }
          })
          .catch(err => {
            console.log('Error getting document', err);
          });
        } else {
          this.setState({ 
            subscribed
          });
        }
      }
    });
  };

  updateAboutInfo = () => {
    this.setState({ loading: true });
    const userPrivatePublicRef = firebase
      .database()
      .ref("users/" + firebase.auth().currentUser.uid + "/profile/public_profile_key");

    userPrivatePublicRef.once("value", snapshot => {
      if (snapshot.val() != null) {
        //User has public profile
        const userPublicRef = dbFireStore
          .firestore()
          .collection("users")
          .doc(snapshot.val());

        userPublicRef.update({
          about_info: this.state.aboutInfoText,
          lastUpdated: firebase.firestore.FieldValue.serverTimestamp()
        }).then(() => {
          const userProfileRef = firebase
            .database()
            .ref("users/" + firebase.auth().currentUser.uid + "/profile/");

          userProfileRef.update({
            user_description: true
          });
          this.setState({ loading: false });
        });
      }
    });
  };

  handleChange = e => this.setState({ aboutInfoText: e.target.value });

  handleToggle = () => {
    const user = firebase.auth().currentUser;
    const { subscribed } = this.state;

    //Start Loader 
    this.setState({ toggleLoader: true });

    axios({
      method: 'POST',
      url: SUBSCRIBE_MAILING_TOGGLE_ENDPOINT,
      headers: {
        "x-api-key": SUBSCRIBE_MAILING_TOGGLE_KEY
      },
      data: {
        userEmail: user.email,
        subscribed: !subscribed
      }
    })
    .then(response => {
      const subscriptionStatus = response.data.subscriptionStatus;

      if (subscriptionStatus === "subscribed") {
        // Update firebase that user IS subscribed
        this.updateSubscriptionFirebase(true, user);
        this.props.handleError({
          message: "Awesome, you are subscribed! You can unsubscribe at any time.",
          type: "subscribed"
        });
        this.setState({ 
          subscribed: true,  
          toggleLoader: false
        });
      } else if (subscriptionStatus === "unsubscribed") {
        // Update firebase that user is NOT subscribed
        this.updateSubscriptionFirebase(false, user);
        this.props.handleError({
          message: "You unsubscribed from our mailing list. You can resubscribe at any time.",
          type: "error"
        });
        this.setState({ 
          subscribed: false,
          toggleLoader: false
        });
      } else {
        // Not a member of MailChimp Audience
        this.firstTimeSubscription(user);
      }
    })
    .catch(error => {
      console.log(error);
    });
  };

  firstTimeSubscription = user => {
    axios({
      method: 'POST',
      url: SUBSCRIBE_MAILING_ENDPOINT,
      headers: {
        "x-api-key": SUBSCRIBE_MAILING_KEY
      },
      data: {
        userEmail: user.email,
      }
    })
    .then(response => {
      console.log(response);
      this.updateSubscriptionFirebase(true, user);
      this.props.handleError({
        message: "Thank you for subscring to our mailing list. You can unsubscribe at any time.",
        type: "subscribed"
      });
      this.setState({ 
        subscribed: true,  
        toggleLoader: false
      });
    })
    .catch(error => {
      console.log(error);
    });
  };

  updateSubscriptionFirebase = (isSubscribed, user) => {
    const userSubscribedRef = firebase.database().ref('users/' + user.uid + '/profile/subscribed');
    userSubscribedRef.set(isSubscribed);
  };

  render() {
    const { loading, toggleLoader, subscribed, aboutInfoText } = this.state;
    return (
      <div className="edit-profile-wrap">
        <div className="dashboard-container">
          <div className="col-left">
            <DashboardNav
              trustLevel={1}
              reviewCount={this.state.reviewCount? this.state.reviewCount: 0}
              starRating={this.state.usersRating? this.state.usersRating: 5} 
            />
          </div>
          <div className="col-right">
            <div className="edit-about-info-form">
              <div className="edit-about-info-title edit-profile-title">
                <Typography variant="h5" component="h3">
                  Edit Profile
                </Typography>
                <Typography variant="caption" className="subtitle">
                  Let hosts and guests know more about you.
                </Typography>
              </div>
              <div className="subscribe-mailing-list">
              <Typography className="subscribe-text">Receive MotorRoof promotions, news, and coupon emails</Typography>
              <div className="subscribe-toggle">
                <Switch
                  checked={subscribed}
                  disabled={toggleLoader}
                  onChange={this.handleToggle}
                  value="subscribe-toggle"
                  color="primary"
                  inputProps={{ 'aria-label': 'subscribe checkbox' }}
                />
                {toggleLoader?
                  <Loader 
                    type="TailSpin" 
                    color="#1ea6f1" 
                    height={20}
                    width={20}
                  />
                : null
                }
              </div>
            </div>
              <Typography className="profile-form-break-title">
                Public info
              </Typography>
              <TextField
                id="outlined-multiline-static"
                label="About You"
                multiline
                fullWidth
                rows="4"
                value={aboutInfoText}
                margin="normal"
                variant="outlined"
                onChange={this.handleChange}
              />
            </div>
            <div className="button-holder-about-info">
              <Button
                size="small"
                className={loading? 'about-info-save-btn loading': 'about-info-save-btn'}
                onClick={() => this.updateAboutInfo()}
                disabled={loading}
              >
              {loading?
                <div className="saving-btn-dash">
                <CircularProgress size={13} thickness={6} className="progress-saving"/>
                  <span>
                    Saving...
                  </span>
                </div>:
                <div>Save</div>
              }
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
};