import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';

import { autoPlay } from 'react-swipeable-views-utils';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);


const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    height: 50,
    paddingLeft: theme.spacing(4),
    backgroundColor: theme.palette.background.default,
  },
  img: {
    height: "auto",
    display: 'block',
    maxWidth: "100%",
    overflow: 'hidden',
    width: '100%',
  },
}));

const GarageGallery = props => {
  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = props.garagePhotosList? props.garagePhotosList.length : 0;

  function handleNext() {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  }

  function handleBack() {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  }

  function handleStepChange(step) {
    setActiveStep(step);
  }

  return (
    <div className="garage-gallery-wrap">
      {maxSteps > 0?
        <div className="gallery-actions-wrap">
        {maxSteps > 1?
          <React.Fragment>
          <div className={activeStep === 0? "left-arrow-container disabled-btn": "left-arrow-container"}>
            <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </Button>
          </div>
          <div className={activeStep === maxSteps - 1? "right-arrow-container disabled-btn":"right-arrow-container"}>
            <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
              {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </Button>
          </div></React.Fragment>:<div></div>
        }
        <div className="garage-gallery-container">
        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents
        >
          {props.garagePhotosList.map((step, index) => (
            <div key={`slide-${index}`} className="slider-container">
              {Math.abs(activeStep - index) <= 2 ? (
                <img className={classes.img} src={step.imgPath} alt={step.label} />
              ) : null}
            </div>
          ))}
        </SwipeableViews>
        <div className="gallery-stepper-index-container-wrap">
        <MobileStepper
          steps={maxSteps}
          position="static"
          className="gallery-stepper-index-container"
          variant="text"
          activeStep={activeStep}
        /></div></div></div>:
        <div className='garage-gallery-has-no-photos'></div>
      }
    </div>
  );
}

export default GarageGallery;
