import React from 'react';
import firebase from 'firebase/app';
import dbFireStore from '../../firebase';
import Confetti from 'react-dom-confetti';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Button from "@material-ui/core/Button";
import g from 'ngeohash';
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from 'moment';
import GarageGridItem from "../../components/GarageGridItem";
import axios from 'axios';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Redirect } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import HelpIcon from '@material-ui/icons/HelpOutline';

import {Elements} from 'react-stripe-elements';
import AddPaymentForm from '../../components/AddPaymentForm';

import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Checkbox from '@material-ui/core/Checkbox';
import Payment from '@material-ui/icons/Payment';

import { ReactComponent as MotorRoofLogo } from "../../images/motorroof-logo.svg";

import EventAvailableIcon from '@material-ui/icons/EventAvailable';

import "../../styles/reservation.scss";

const formatCurrencyUSD = (amount) => {
  const locale = 'en-US';
  const options = { style: 'currency', currency: 'USD'};
  return Intl.NumberFormat(locale, options).format(amount);
}

const toTitleCase = (str) => {
    return str.replace(/\w\S*/g, function(txt){
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
}

const config_conf = {
  angle: "90",
  spread: "59",
  startVelocity: 35,
  elementCount: 50,
  dragFriction: 0.1,
  duration: 3000,
  stagger: 0,
  width: "10px",
  height: "10px",
  colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"]
};

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

class ReservationCreate extends React.PureComponent {

  state = {
    loading: true,
    loadingPayment: true,
    garage: null,
    garagePhotosList: null,
    garageOwnerPhotoURL: null,
    startDate: null,
    endDate: null,
    rentalDays: null,
    rentalSubTotal: null,
    rentalServiceFee: null,
    rentalTotal: null,
    savingCard: false,
    createModeToggle: false,
    confirmDelete: false,
    deletingCard: false,
    guestGuidelinesAgreed: false,
    creatingChargeAndReservation: false,
    paymentComplete: false,
  }

  componentDidMount() {
    this.setBeacon();
    if(this.props.match.params && this.props.match.params.garage_id && this.props.match.params.start_date && this.props.match.params.end_date) {
      //unix time conversion from url
      var garageId = this.props.match.params.garage_id;
      var startDate = new Date(this.props.match.params.start_date * 1000);
      var endDate = new Date(this.props.match.params.end_date * 1000);
      this.setReservationInfo(startDate, endDate, garageId);
      this.getGarageInfo(garageId);
      this.checkCustomerInfo();
    } else {
      // redirect to dashbaord?
    }
  };

  componentWillUnmount() {
    if(window.Beacon) {
      window.Beacon('destroy');
    }
  }

  setBeacon = () => {
    window.Beacon('init', 'b6fb3931-6d73-4a27-a596-87191307622c');
    window.Beacon('config', {
      display: {
        position: 'left'
      }
    });
  };

  setReservationInfo = (start_date, end_date, garageId) => {
    this.setState({
      startDate: start_date,
      endDate: end_date,
    })
    this.userProfileRef = firebase.database().ref('users/' + firebase.auth().currentUser.uid + '/profile/');
    this.userProfileRef.once("value").then(profile => {
      if(profile.val().guestAgreementSigned && profile.val().guestAgreementSigned.agreement_status) {
        // user has already agreed   
        this.setState({
          garagePublicId: garageId,
          guestGuidelinesAgreed: true,
        });
      } else {      
        this.setState({
          garagePublicId: garageId,
          guestGuidelinesAgreed: false,
        });
      }
    });
  };

  calculateCost = () => {
    //calculate days staying, plus fees
    const rentalDays = this.state.startDate && this.state.endDate? moment(this.state.endDate).diff(this.state.startDate, 'days') + 1: null;
    const rentalSubTotal = rentalDays? formatCurrencyUSD(rentalDays * this.state.garage.price): "Error!";
    const rentalServiceFee = rentalDays? formatCurrencyUSD((rentalDays * this.state.garage.price) * .145): "Error!";
    const rentalTotal = rentalDays? formatCurrencyUSD(
        (rentalDays * this.state.garage.price) +
        ((rentalDays * this.state.garage.price) * .145)
    ): "Error!";
    this.setState({
      rentalDays: rentalDays,
      rentalSubTotal: rentalSubTotal,
      rentalServiceFee: rentalServiceFee,
      rentalTotal: rentalTotal,
    });
  }

  getGarageInfo = garageId => {
    dbFireStore.firestore().collection("garages")
    .doc(garageId)
    .get()
    .then(querySnapshot => {
      var scheduleObj = {};
      if(querySnapshot.data()) {
        // found unique record set state.
        var garageData = querySnapshot.data();
        if(garageData.schedule.length > 1) {
          // translate schedule json to object
          var garageDataSchedule = JSON.parse(garageData.schedule);          
          garageDataSchedule.forEach((year) => {
            // grab year record
            var yearNum = year[0];
            var months = {};
            year[1].forEach((month) => {
              var monthNum = month[0];
              months[monthNum] = month[1];
            });
            scheduleObj[yearNum] = months;
          });
          garageData.schedule = scheduleObj;
        }

        var latlon = g.decode(garageData.location);
        this.setState({
          garage: garageData,
          loading: false,
          location: {
            longitude: latlon.longitude,
            latitude: latlon.latitude
          }
        });
        if(garageData.photos && garageData.garage_key) {
          var imageLabel = `${garageData.city}, ${garageData.state} - Garage and Workshop for rent on MotorRoof.com`;
          this.getGaragePhotos(garageData.photos, garageData.garage_key, imageLabel);
        }
        this.calculateCost();
        this.getGarageOwnerInfo(garageData.owner_photo_id);
      } else if (querySnapshot.docs.length == 0) {
        // couldn't find it
      } else {
        // throw error and redirect, there was more then one! url is not unique.
      }
    })
    .catch(error => {
      // redirect user.
      console.log("Error getting documents: ", error);
    });

  };

  getGarageOwnerInfo = photoId => {
    if(photoId) {
      var userPhotoRef = firebase.storage().ref(`/${photoId}/photo.jpg`);
      userPhotoRef.getDownloadURL().then(url => {
        this.setState({
          garageOwnerPhotoURL: url
        });
      }).catch(error => {
        console.log('There was an error retreiving the users photo');
      });
    }
  };

  getGaragePhotos = (photos, garage_key, label) => {
    var photoListLength = Object.keys(photos).length;
    var garagePhotos = Array(photoListLength).fill('');
    var photoIndex = 0;
    if(photos && garage_key) {
      Object.keys(photos).forEach((photo) => {
        var leadImgLocationRef = firebase.storage().ref(`/garages/${garage_key}/${photo}/photo.jpg`);
        leadImgLocationRef.getDownloadURL().then(url => {
          photoIndex++;
          if(url) {
            garagePhotos[photos[photo].order] = {imgPath: url, label: label, order: photos[photo].order};
          }
          if(photoListLength == photoIndex) {
            this.setState({garagePhotosList: garagePhotos});
          }
        }).catch((error) => {
          console.log('There was an error retreiving the garages lead photo');
        });
      });
    }
  };

  checkCustomerInfo = () => {
    this.setState({loadingPayment: true});
    this.userProfileRef = firebase.database().ref('users/' + firebase.auth().currentUser.uid + '/profile/');
    this.userProfileRef.once("value").then(profile => {
      if(profile.val().customerId) {
        // user has a stripe customer id -> get their info
        this.getCustomerInfo(profile.val().customerId);
      } else {
        // user needs to create a customer id in stripe and then base customer off of that created data
        this.createCustomer();
      }
    });
  };

  createCustomer = () => {
    const email = firebase.auth().currentUser.email;
    const fullname = firebase.auth().currentUser.displayName? firebase.auth().currentUser.displayName: '';
    const isDevMode = process.env.NODE_ENV === "development" && process.env.NODE_ENV !== "production"? true: false;
    axios({
      method: 'post',
      url: isDevMode? 'https://gl0rddpxh8.execute-api.us-east-1.amazonaws.com/default/DEV_createCustomer': 'https://3zej6teneb.execute-api.us-east-1.amazonaws.com/default/createCustomer',
      headers: {
        'x-api-key': isDevMode? 'imWuWDwIOg71r5I64SXli77aH4KSBoec5Nr9XzHI': 'nnJfyb4vgL9nWfHdDhRRt5qW2y7QJO5P699lIt2N'
      },
      data: {
        name: fullname,
        email: email
      }
    }).then(response => {
      const success = response.data.success;
      if(success) {
        this.userProfileRef.child("customerId").set(response.data.customer.id);
        this.setState({
          customerData: response.data.customer,
          loadingPayment: false,
        });
      } else {
        console.log("Stripe create customer account error");
        this.setState({
          customerData: null,
          loadingPayment: false,
        });
      }
    }).catch(error => {
      console.log('Stripe Payment API Error');
      this.setState({
        customerData: null,
        loadingPayment: false,
      });
    });
  };

  getCustomerInfo = customerId => {
    const isDevMode = process.env.NODE_ENV === "development" && process.env.NODE_ENV !== "production"? true: false;

    axios({
      method: 'post',
      url: isDevMode? 'https://vbjfkaqew8.execute-api.us-east-1.amazonaws.com/default/DEV_retrieveCustomer': 'https://hr36gm45mf.execute-api.us-east-1.amazonaws.com/default/retrieveCustomer',
      headers: {
        'x-api-key': isDevMode? 'gzbz5RTxuY1d3OElN5qff5URoEA8a0Zg75MVNlSZ': 'lRvcJr7SUyEe3JcGaK4B3FUTa3DOi5c1dzSz3190'
      },
      data: {
        customerId: customerId
      }
    }).then(response => {
      const customerData = response.data.customer;
      const success = response.data.success;
      if(success) {
        // set to state
        // need to get their past invoice list here and pass it to the table, format using the method below
        // createData(new Date('Sat Oct 26 2019 15:13:47 GMT+0900 (Japan Standard Time)'), 'ASDFASDFWEF2E', 'Reservation', "ASDFW234FD", 1234),
        this.setState({
          customerData: customerData,
          loadingPayment: false,
          pastInvoiceList: [],
        });
      } else {
        // there was a error
        this.setState({
          customerData: null,
          loadingPayment: false,
        });
        console.log('Error getting customer info');
      }
    }).catch(error => {
      this.setState({
        customerData: null,
        loadingPayment: false,
      });
      console.log('Error getting customer info');
    });
  };

  updateCustomerInfo = (newCustomerData) => {
    this.setState({
      customerData: newCustomerData,
    });
  }

  toggleUpdateCardInfo = () => {
    this.setState(prevState => ({
      createModeToggle: !prevState.createModeToggle,
    }));
  };

  createModeToggleOff = () => {
    this.setState({
      createModeToggle: false,
    });
  };

  deleteCardConfirmToggle = () => {
    this.setState(prevState => ({
      confirmDelete: !prevState.confirmDelete,
    }));
  };

  deletePaymentInfo = () => {
    this.setState({deletingCard: true, confirmDelete: false, createModeToggle: false});
    const customerId = this.state.customerData.id;
    const cardToDelete = this.state.customerData.default_source;
    const isDevMode = process.env.NODE_ENV === "development" && process.env.NODE_ENV !== "production"? true: false;

    axios({
      method: 'post',
      url: isDevMode? 'https://hdsbxx8h6d.execute-api.us-east-1.amazonaws.com/default/DEV_deleteCard': 'https://c4zr2auzgf.execute-api.us-east-1.amazonaws.com/default/deleteCard',
      headers: {
        'x-api-key': isDevMode? 'PS5XvnPOD61uFCZw6L7sZ93D6BXz6PMI6vsJmPtK': 'eiSuOGPrv86GKFFC16tjD4ENIqHUML3A8E06DAQG'
      },
      data: {
        customerId: customerId,
        cardToken: cardToDelete,
      }
    }).then(response => {
      const customerData = response.data.customer;
      const success = response.data.success;
      if(success) {
        // set to state
        var updateCustomerData = this.state.customerData;
        updateCustomerData.default_source = null;
        this.setState({
          deletingCard: false,
          customerData: updateCustomerData,
        });
      } else {
        // there was a error
        this.setState({
          deletingCard: false,
        });
        this.props.handleError({
          message: "There was an error deleting your payment information. Please try again."
        });
        console.log('Error deleting customer card info');
      }
    }).catch(error => {
      this.setState({
        deletingCard: false,
      });
      this.props.handleError({
        message: "There was an error deleting your payment information. Please try again."
      });
      console.log('Error deleting customer card');
    });
  };

  handleGuestGuidelinesAgreed = () => {
    this.userProfileRef = firebase.database().ref('users/' + firebase.auth().currentUser.uid + '/profile/');
    this.userProfileRef.child("guestAgreementSigned").set({
      agreement_status: !this.state.guestGuidelinesAgreed,
      agreed_on: firebase.database.ServerValue.TIMESTAMP,
    }).then(() => {
      this.setState({
        guestGuidelinesAgreed: !this.state.guestGuidelinesAgreed,
      });
    });
  };

  createAndChargeReservation = () => {
    this.setState({
      creatingChargeAndReservation: true,
    });

    var default_payment = this.state.customerData.default_source;
    var daily_rate = this.state.garage.price;
    var rental_days_count = this.state.rentalDays;
    var rental_subtotal = rental_days_count * daily_rate;
    var rental_servicefee =  Math.round(100 * parseFloat((rental_days_count * daily_rate) * .145));
    var rental_total = (rental_days_count * daily_rate) + ((rental_days_count * daily_rate) * .145);
    
    var total_in_pennies = Math.round(100 * parseFloat(rental_total));
    var garage_id = this.state.garagePublicId;
    var guest_id = firebase.auth().currentUser.uid;
    var host_id = this.state.garage.owner_id;
    var start_date = this.state.startDate;
    var end_date = this.state.endDate;

    const isDevMode = process.env.NODE_ENV === "development" && process.env.NODE_ENV !== "production"? true: false;

    var dataInfo = {
      garage_id: garage_id,
      guest_id: guest_id,
      host_id: host_id,
      start_date: start_date.getTime(),
      end_date: end_date.getTime(),
      daily_rate: daily_rate,
      rental_days_count: rental_days_count,
      rental_subtotal: rental_subtotal, 
      rental_servicefee: rental_servicefee,
      is_workshop: this.state.garage.isWorkshop,
      is_storage: this.state.garage.isStorage,
      rental_total: rental_total,
      customer_id: this.state.customerData.id,
      default_payment: default_payment,
      total_in_pennies: total_in_pennies,
      destination_charge: this.state.garage.destination_charge? this.state.garage.destination_charge: "",
      is_check_payout: this.state.garage.destination_charge? "false": "true"
    };

    axios({
      method: 'POST',
      url: isDevMode? 'https://n6ac93wcd0.execute-api.us-east-1.amazonaws.com/default/DEV_createAndChargeReservation': 'https://jopilqfs6a.execute-api.us-east-1.amazonaws.com/default/createAndChargeReservation',
      headers: {
        "x-api-key": isDevMode? 'Hgk3ic8NrM23ygpRvHegAkHpOJmtOdp4OCxkvsj0': "jsFpSfjN4p1KqZ5oZoAT5443x3VoHPdT7yhibxbm"
      },
      data: dataInfo,
    }).then(response => {
      const success = response.data.success;
      if(success) {
        this.userReservationRef = firebase.database().ref('users/' + firebase.auth().currentUser.uid + '/profile/reservations');
        this.userReservationRef.push({
          reservation_id: response.data.reservation_id,
          timestamp: firebase.database.ServerValue.TIMESTAMP,
        });
        this.setState(prevState => ({
          final_reservation_id: response.data.reservation_id,
        }), () => {
          this.setState({
            paymentComplete: true,
            creatingChargeAndReservation: false,
          });
        });
      } else {
        console.log("Stripe charge customer account error");
        this.setState({
          paymentComplete: false,
          creatingChargeAndReservation: false,
        });
        this.props.handleError({
          message: "We were unable to complete the payment. Please try again."
        });
      }
    }).catch(error => {
      console.log(error);
      this.props.handleError({
        message: "We were unable to complete the payment. Please try again."
      });
      this.setState({
        paymentComplete: false,
        creatingChargeAndReservation: false,
      });
    });

  };

  render() {
    var garagePrice = this.state.garage? formatCurrencyUSD(this.state.garage.price).split(".")[0]: '';
    var garageOwnerView = this.state.garage && firebase.auth().currentUser && firebase.auth().currentUser.uid === this.state.garage.owner_id;
    var userSignedIn = firebase.auth().currentUser;
    var defaultSourceId = this.state.customerData? this.state.customerData.default_source: null;
    var savedCardInfo = defaultSourceId? this.state.customerData.sources.data.filter((card) => card.id === defaultSourceId)[0]: null;
    var createModeStatus = this.state.createModeToggle || !savedCardInfo;
    const supportUser = {
      user_id: firebase.auth().currentUser.uid,
      email: firebase.auth().currentUser.email,
      name: firebase.auth().currentUser.displayName
    };
    return (
      <div className="with-navbar-wrap">
      {garageOwnerView?
        <Redirect to='/dashboard' {...supportUser}/>:null
      }
      {this.state.garage?
        <div className="create-reservation-container">
          <Dialog
            open={this.state.confirmDelete}
            onClose={this.deleteCardConfirmToggle}
            className={"confirm-delete-dialog"}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle id="alert-dialog-title">Confirm Delete</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to delete your payment information?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.deleteCardConfirmToggle} color="primary">
                Cancel
              </Button>
              <Button className="delete-card-btn" onClick={this.deletePaymentInfo} color="primary" autoFocus>
                Delete Card
              </Button>
            </DialogActions>
          </Dialog>
          <div className="col-left">
            <GarageGridItem
              garageTitle={this.state.garage.title}
              isStorage={this.state.garage.isStorage}
              isWorkshop={this.state.garage.isWorkshop}
              avatarPhotoURL={this.state.garageOwnerPhotoURL}
              displayName={this.state.garage.owner_name}
              starRating={this.state.garage.userStars? this.state.garage.userStars: 5}
              price={this.state.garage.price}
              city={this.state.garage.city}
              state={this.state.garage.state}
              photo={this.state.garagePhotosList ? this.state.garagePhotosList[0].imgPath: null}
            />
          </div>
          <div className="col-right">
            <div className="dashboard-notifications billing-info">
              <Typography variant="h5" component="h3">Your Reservation</Typography>
              <Typography variant="caption" className="subtitle">
                Review your reservation and payment information below
              </Typography>
              <div className="reservation-info-content">
                <EventAvailableIcon />
                <Typography>
                  You will receive confirmation of your reservation from the host within 48 hours. If your host does not respond or cancels the booking, you receive a refund in full!
                </Typography>
              </div>
            </div>

            <div className="reservation-info-container">
              <div className="reservation-top-info-row">
                <div className="where-col">
                  <span className="title-res"><Typography>Where</Typography></span>
                  <Typography>
                  {toTitleCase(this.state.garage.city)}, {this.state.garage.state}
                  <span className="weekday">
                  Full Address After Confirm
                  </span>
                  </Typography>
                </div>
                <div className="start-date-col">
                  <span className="title-res"><Typography>Check-in</Typography></span>
                  <Typography>
                  {`${moment(this.state.startDate).format('MMMM Do YYYY')}`}
                  <span className="weekday">{`${moment(this.state.startDate).format('dddd')}`}</span>
                  </Typography>
                </div>
                <div className="end-date-col">
                  <span className="title-res"><Typography>Check-out</Typography></span>
                  <Typography>
                  {`${moment(this.state.endDate).format('MMMM Do YYYY')}`}
                    <span className="weekday">{`${moment(this.state.endDate).format('dddd')}`}</span>
                  </Typography>
                </div>
              </div>
            </div>

            <div className="user-cards-data">
              {this.state.customerData && !this.state.customerData.default_source?
                <div className="no-garages-yet">
                <Typography>💳 Add your payment info below to reserve this space!</Typography></div>: null
              }
              {savedCardInfo?
                <div className="default-card-source">
                  {this.state.deletingCard?
                    <div className="deleting-card-loader">
                      <div className="loading-stripe-card-from">
                      <div className="loader-container-delete">
                      <CircularProgress className="stripe-form-loader"/>
                       <Typography variant="caption">Deleting Payment Info</Typography>
                      </div>
                      </div>
                    </div>:null
                  }
                  <Typography variant="h3" className="payment-info-title">
                  Pay using this payment information
                  </Typography>
                  <div className="saved-card-info">
                    <div className={`card-brand-logo ${savedCardInfo.brand}`}>
                    </div>
                    <div className="card-four">
                    <span>Last 4</span>
                    {savedCardInfo.last4}
                    </div>
                    <div className="card-four">
                      <span>exp</span>
                      {`${savedCardInfo.exp_month}/${savedCardInfo.exp_year.toString().slice(-2)}`}
                    </div>
                    <div className="saved-card-actions">
                      {!this.state.createModeToggle?
                        <Button className="update-card-btn" onClick={this.toggleUpdateCardInfo}>
                          Update
                        </Button>:<Button className="update-card-btn with-cancel" onClick={this.toggleUpdateCardInfo}>
                          Cancel Update
                        </Button>
                      }
                      <Button className="delete-card-btn" onClick={this.deleteCardConfirmToggle}>
                        Delete
                      </Button>
                    </div>
                  </div>
                </div>: null
              }
            </div>
            <div className="add-payment-info-form">
              {this.props.stripeLoaded && !this.state.loadingPayment?
                <Elements>
                  <AddPaymentForm
                    loading={this.state.loadingPayment}
                    handleError={this.props.handleError}
                    customerId={this.state.customerData? this.state.customerData.id: null}
                    updateCustomerInfo={this.updateCustomerInfo}
                    createMode={createModeStatus}
                    createModeToggleOff={this.createModeToggleOff}
                    paymentTitle={"Pay using this payment method"}
                  />
                </Elements>:
                <div className="loading-stripe-card-from">
                <CircularProgress className="stripe-form-loader"/>
                 <Typography variant="caption">Loading Payment Information</Typography>
                </div>
              }
            </div>
            <div className="payment-info-breakdown">
              <div className="total-table">

                <div className="total-row">
                  <div className="info-col">
                    <Typography variant="caption">Rental Days:</Typography>
                    <div className="tooltip-help-icon">
                      <HtmlTooltip
                        placement="left"
                        title={
                          <React.Fragment>
                            <Typography color="inherit" variant="body2">Rental day info</Typography>
                          </React.Fragment>
                        }
                      >
                        <HelpIcon />
                      </HtmlTooltip>
                    </div>
                    </div>
                  <div className="amount-col">
                    <Typography variant="caption">{this.state.rentalDays}x</Typography>
                  </div>
                </div>

                <div className="total-row">
                  <div className="info-col">
                    <Typography variant="caption">Subtotal:</Typography>
                    <div className="tooltip-help-icon">
                      <HtmlTooltip
                        placement="left"
                        title={
                          <React.Fragment>
                            <Typography color="inherit" variant="body2">Rental days times the daily rate.</Typography>
                          </React.Fragment>
                        }
                      >
                        <HelpIcon />
                      </HtmlTooltip>
                    </div>

                  </div>
                  <div className="amount-col">
                    <Typography variant="caption">{this.state.rentalSubTotal}</Typography>
                  </div>
                </div>

                <div className="total-row">
                  <div className="info-col">
                    <Typography variant="caption">Service Fee:</Typography>
                    <div className="tooltip-help-icon">
                      <HtmlTooltip
                        placement="left"
                        title={
                          <React.Fragment>
                            <Typography color="inherit" variant="body2">This helps our platform provide you 24/7 support and a safe renting environment.</Typography>
                          </React.Fragment>
                        }
                      >
                        <HelpIcon />
                      </HtmlTooltip>
                    </div>
                  </div>
                  <div className="amount-col">
                    <Typography variant="caption">{this.state.rentalServiceFee}</Typography>
                  </div>
                </div>

                <div className="total-row final-amount">
                  <div className="info-col">
                    <Typography variant="caption">Total:</Typography>
                    <div className="tooltip-help-icon">
                      <HtmlTooltip
                        placement="left"
                        title={
                          <React.Fragment>
                            <Typography color="inherit" variant="body2">This is your subtotal plus our service fee.</Typography>
                          </React.Fragment>
                        }
                      >
                        <HelpIcon />
                      </HtmlTooltip>
                    </div>
                  </div>
                  <div className="amount-col">
                    <Typography variant="caption">{this.state.rentalTotal}</Typography>
                  </div>
                </div>
              </div>
            </div>
            <div className="submit-payment-form">
              <div className="final-reservation-requirements">
                <a href="https://motorroof.com/terms-of-use" target="_blank">
                <Typography className="garage-host-guidelines">
                  MotorRoof Terms of Use.
                </Typography>
                </a>
                <FormControlLabel
                  control={
                    <Checkbox checked={this.state.guestGuidelinesAgreed} onChange={() => this.handleGuestGuidelinesAgreed()} value="guest_agreement" />
                  }
                  label="I agree to and have read the"
                />
                {this.state.guestGuidelinesAgreed && savedCardInfo && this.state.customerData?
                  <Button
                    onClick={this.createAndChargeReservation}
                    className="create-reservation-btn"
                    startIcon={<Payment />}
                  >
                    Pay and Reserve Space
                  </Button>:
                  <HtmlTooltip
                  placement="left"
                  title={
                    <React.Fragment>
                      <Typography color="inherit" variant="body2">Please agree to our Guest Guidelines</Typography>
                    </React.Fragment>
                  }
                >
                  <Button
                    className={'disable-sub-btn create-reservation-btn'}
                    startIcon={<Payment />}
                  >
                    Pay and Reserve Space
                  </Button>
                </HtmlTooltip>
                }
              </div>
            </div>
          </div>
        </div>:
        <div className="app-loader-wrap">
          <div className="loading-app">
            <CircularProgress className="main-loader"/>
          </div>
        </div>
      }
      {this.state.creatingChargeAndReservation?
        <div className={`app-loader-wrap creating-reservation-wrap loading-charge`}>
          <MotorRoofLogo className="motorroof-create-logo" />
          <div className="reservation-create-buffer">
          <div className="reservation-for">
            {this.state.garage?
              <GarageGridItem
                garageTitle={this.state.garage.title}
                isStorage={this.state.garage.isStorage}
                isWorkshop={this.state.garage.isWorkshop}
                avatarPhotoURL={this.state.garageOwnerPhotoURL}
                displayName={this.state.garage.owner_name}
                starRating={this.state.garage.userStars? this.state.garage.userStars: 5}
                price={this.state.garage.price}
                city={this.state.garage.city}
                state={this.state.garage.state}
                photo={this.state.garagePhotosList ? this.state.garagePhotosList[0].imgPath: null}
              />:null
            }
          </div>
          <div className="loading-app making-reservation">
            <CircularProgress className="main-loader"/>
            <Typography className="creating-loader-text" variant="caption">Creating Your Reservation!</Typography>
          </div>
          </div>
        </div>:null
      }
      {this.state.paymentComplete?
        <Redirect to={`/reservations/trip/${this.state.final_reservation_id}`} />:null
      }
      </div>
    );
  }
}
 
export default ReservationCreate;

