import React from "react";
import firebase from "firebase/app";
import ForgotPasswordDialog from '../../components/ForgotPasswordDialog';

import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Paper from "@material-ui/core/Paper";
import { Link } from "react-router-dom";

import LockIcon from "@material-ui/icons/LockOutlined";

import { ReactComponent as MotorRoofLogo } from "../../images/motorroof-logo.svg";
import FacebookLogo from "../../images/facebook-logo.svg";
import GoogleLogo from "../../images/google-logo.svg";

import "../../styles/login.scss";

import ConfirmPasswordDialog from "../../components/ConfirmPasswordDialog";


export default class Login extends React.Component {
  state = {
    loading: this.props.loading,
    open: false,
    fbEmail: null,
    fbCred: null,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.loading !== this.props.loading) {
      this.setState({ loading: this.props.loading });
    }
  }

  handleSubmit = e => {
    e.preventDefault();
    this.setState({ loading: true });
    this.props.handleAuthUser(
      this.state.email,
      this.state.password,
      this.stopLoader
    );
  };

  stopLoader = () => this.setState({ loading: false });

  handlePasswordSubmit = (password, callback) => {
    var pendingCred = this.state.fbCred;
    var email = this.state.fbEmail;

    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .catch(error => {
        this.props.handleError(error);
      })
      .then(user => {
        if (user) {
          firebase
            .auth()
            .currentUser.linkAndRetrieveDataWithCredential(pendingCred)
            .then(() => {
              callback();
            });
        }
      });
  };

  handleChange = e => this.setState({ [e.target.id]: e.target.value });

  facebookLogin = () => {
    this.setState({ loading: true });
    firebase
      .auth()
      .signInWithPopup(new firebase.auth.FacebookAuthProvider())
      .then(facebookInfo => {
        if (facebookInfo.additionalUserInfo.isNewUser) {
          this.facebookUserUpdate(facebookInfo);
        }
        this.setState({ loading: false });
      })
      .catch(error => {
        //Merge accounts if they exist
        if (error.code === "auth/account-exists-with-different-credential") {
          this.setState({ fbCred: error.credential, fbEmail: error.email });
          firebase
            .auth()
            .fetchSignInMethodsForEmail(error.email)
            .then(methods => {
              if (methods[0] === "password") {
                //Prompt user for MotorRoof Password
                this.setState({
                  open: true,
                  loading: false
                });
              } else if (methods[0] === "google.com") {
                //Only allow users to have one provider
                this.props.handleError({
                  message: "Please sign in using a provider associated with this email address."
                });
                this.setState({
                  loading: false
                });
              }
            });
        } else {
          this.props.handleError(error);
          this.setState({
            loading: false
          });
        }
      });
  };

  googleLogin = () => {
    this.setState({ loading: true });

    const provider = new firebase.auth.GoogleAuthProvider();

    firebase
      .auth()
      .signInWithPopup(provider)
      .then(googleInfo => {
        if (googleInfo.additionalUserInfo.isNewUser) {
          this.googleUserUpdate(googleInfo);
        }
        this.setState({ loading: false });
      })
      .catch(error => {
        this.props.handleError(error);
        this.setState({
          loading: false
        });
      });
  };

  googleUserUpdate = googleInfo => {
    const givenName = googleInfo.additionalUserInfo.profile.given_name;
    const familyName = googleInfo.additionalUserInfo.profile.family_name;
    this.props.userRequiresUpdate(givenName, familyName);
  };

  facebookUserUpdate = facebookInfo => {
    const firstName = facebookInfo.additionalUserInfo.profile.first_name;
    const lastName = facebookInfo.additionalUserInfo.profile.last_name;
    this.props.userRequiresUpdate(firstName, lastName);
  };

  handleClose = () => this.setState({ open: false });

  render() {
    var year_current = new Date().getFullYear();
    return (
      <div className="login-page-container">
        <Paper className="login-signup-form">
          <div className="login-form-logo-container">
            <Link to="/">
            <MotorRoofLogo className="motorroof-login-logo" />
            </Link>
          </div>
          <form onSubmit={this.handleSubmit}>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="email">Email</InputLabel>
              <Input
                required
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                onChange={this.handleChange}
                autoFocus
              />
            </FormControl>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="password">Password</InputLabel>
              <Input
                required
                name="password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={this.handleChange}
              />
            </FormControl>
            <div className="login-btn-wrap">
              <Button
                type="submit"
                fullWidth
                variant="contained"
                size="large"
                className="btn-primary login-btn"
                disabled={this.state.loading}
                style={{ marginTop: "5px" }}
              >
                {this.state.loading ? (
                  <CircularProgress
                    size={20}
                    thickness={6}
                    className="loader-login-spinner"
                  />
                ) : (
                  <div>
                    <LockIcon className="icon-lock" />
                    <span>Sign In</span>
                  </div>
                )}
              </Button>
            </div>

            <div>
              <Button
                fullWidth
                variant="contained"
                size="large"
                color="primary"
                className="facebook-login-btn"
                disabled={this.state.loading}
                onClick={() => this.facebookLogin()}
              >
                <img src={FacebookLogo} alt="Facebook Logo" />
                Sign in with Facebook
              </Button>
            </div>


            <div>
              <Button
                fullWidth
                variant="contained"
                size="large"
                color="primary"
                className="google-login-btn"
                disabled={this.state.loading}
                onClick={() => this.googleLogin()}
              >
                <img src={GoogleLogo} alt="Google Logo" />
                Sign in with Google
              </Button>
            </div>
            <div className="signup-cta-links">
              <ForgotPasswordDialog />
              <Link to="/signup">Create Account</Link>
            </div>
          </form>
        </Paper>
        <ConfirmPasswordDialog
          open={this.state.open}
          handleClose={this.handleClose}
          handlePasswordSubmit={this.handlePasswordSubmit}
        />
        <div className="signup-footer-bar" />
        <div className="signup-footer">
          <p>©{year_current} Motor Roof, LLC. All rights reserved.</p>
          <div className="footer-link-holder">
            <a href="https://motorroof.com/terms-of-use" target="_blank">Terms</a>
            <a href="https://motorroof.com/privacy-policy" target="_blank">Privacy</a>
            <a href="https://motorroof.com/disclaimer" target="_blank">Disclaimer</a>
            {/* <a href="https://help.motorroof.com/">Help</a> */}
          </div>
        </div>
      </div>
    );
  }
}
