import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import CheckIcon from '@material-ui/icons/Check';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import Confetti from 'react-dom-confetti';
import "../styles/welcome.scss";
import wIntroImg from "../images/welcome-intro.png";
import wReserveImg from "../images/w-reserve.jpg";
import wApproveImg from "../images/approved-guest.png";
import wTrustImg from "../images/wTrust.png";
import firebase from 'firebase/app';

const axios = require('axios');
const WELCOME_API_KEY = "JgYlIkMXgd2Z0bqH2nYba3Ga0GmMshsa6aOuoKHg";
const WELCOME_EMAIL_ENDPOINT = "https://luxd0qs1ze.execute-api.us-east-1.amazonaws.com/default/sendWelcomeEmailSendGrid";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const config_conf = {
  angle: "90",
  spread: "119",
  startVelocity: 45,
  elementCount: 50,
  dragFriction: 0.1,
  duration: 3000,
  stagger: 0,
  width: "10px",
  height: "10px",
  colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"]
};

const welcomeSteps = [
  {
    title: <p>Welcome to <span>MotorRoof!</span></p>,
    info: "Start searching where you want to rent a space in the toolbar above.",
    imgPath: wIntroImg
  },
  {
    title: 'Making a reservation',
    info: "Book a garage, workshop, or storage spot the same way you would book a hotel room.",
    imgPath: wReserveImg
  },
  {
    title: 'Get host approval',
    info: "Your host has 48 hours to respond to your booking request. If they cancel, deny, or fail to respond, you will be refunded for the reservation.",
    imgPath: wApproveImg
  },
  {
    title: 'Build your trust',
    info: "Everyone has a trust meter, so follow the host's rules and have fun! Review your host at the end of your rental and remember to report any unsafe behavior.",
    imgPath: wTrustImg
  },
];

function WelcomeToMotorRoof() {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const [open, setOpen] = React.useState(true);
  const [confettiOn, setConfettiOn] = React.useState(false)
  const maxSteps = welcomeSteps.length;

  function handleNext() {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  }

  function handleBack() {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  }

  function handleStepChange(step) {
    setActiveStep(step);
  }

  function handleClose() {
    var userProfileRef = firebase.database().ref('users/' + firebase.auth().currentUser.uid + '/profile');
    userProfileRef.child("beenWelcomed").set(true).then(() => {
      sendWelcomeEmail();
      userProfileRef.off();
      setOpen(false);
    });
  }

  function triggerYeyFactor() {
    var timer = setTimeout(() => {
      setConfettiOn(true);
      clearTimeout(timer);
    }, 200);
  }

  function sendWelcomeEmail() {
    axios({
      method: 'POST',
      url: WELCOME_EMAIL_ENDPOINT,
      headers: {
        "x-api-key": WELCOME_API_KEY
      },
      data: {
        userEmail: firebase.auth().currentUser.email,
        userDisplayName: firebase.auth().currentUser.displayName
      }
    })
    .then(response => {
      console.log(response);
    })
    .catch(error => {
      console.log(error);
    });
  }

  return (
    <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        className={"welcome-screen-view"}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
    {triggerYeyFactor()}
    <div className="confetti-wrapper">
      <Confetti active={ confettiOn } config={ config_conf }/>
    </div>
    <div className="welcome-slide-container">
      <div className="welcome-slide-title">
        <Typography >{welcomeSteps[activeStep].title}</Typography>
      </div>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {welcomeSteps.map((step, index) => (
          <div key={step.label}>
            {Math.abs(activeStep - index) <= 2 ? (
              <img className={"welcome-hero " + "step-" + index} src={step.imgPath} alt={step.label} />
            ) : null}
          </div>
        ))}
      </SwipeableViews>
      <div className="welcome-slide-info">
        <Typography >{welcomeSteps[activeStep].info}</Typography>
      </div>
      <MobileStepper
        steps={maxSteps}
        position="static"
        variant="dots"
        className="stepper-dots"
        activeStep={activeStep}
      />
      <div className="button-holder-welcome">
      {activeStep > 0?
        <Button size="small" className="back-btn-welcome" onClick={handleBack} disabled={activeStep === 0}>
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </Button>:<div></div>
      }
      {activeStep === maxSteps - 1?
        <Button size="small" className="welcome-next-btn thanks" onClick={() => handleClose()}>
          Got it thanks!
          <CheckIcon />
        </Button>:
        <Button size="small" className="welcome-next-btn" onClick={handleNext}>
          Next
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </Button>
      }
      </div>
    </div>
    </Dialog>
  );
}

export default WelcomeToMotorRoof;