import React from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import Switch from '@material-ui/core/Switch';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import HourSelect from '../components/HourSelect';
import CheckIcon from '@material-ui/icons/Check';
import ScheduleIcon from '@material-ui/icons/Schedule';
import moment from 'moment';

import DialogTitle from '@material-ui/core/DialogTitle';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class GarageAccessHours extends React.Component {
  state = {
    open: false,
    appointmentOnly: true,
    editAccessHoursRef: null,
    is24hours: false,
    isOpen: {
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      saturday: true,
      sunday: true,
    },
    monday: [
      {
        name: 'monday',
        startHours: null,
        endHours: null
      },
    ],
    tuesday: [
      {
        name: 'tuesday',
        startHours: null,
        endHours: null
      },
    ],
    wednesday: [
      {
        name: 'wednesday',
        startHours: null,
        endHours: null
      },
    ],
    thursday: [
      {
        name: 'thursday',
        startHours: null,
        endHours: null
      },
    ],
    friday: [
      {
        name: 'friday',
        startHours: null,
        endHours: null
      },
    ],
    saturday: [
      {
        name: 'saturday',
        startHours: null,
        endHours: null
      },
    ],
    sunday: [
      {
        name: 'sunday',
        startHours: null,
        endHours: null
      },
    ],
  };

  componentWillMount() {
    if(this.props.hours) {
      this.setState({
        ...this.props.hours
      });
    }
  };

  componentDidUpdate(prevProps) {
    if (this.props.hours !== prevProps.hours) {
      // set hours state.
      this.setState({
        ...this.props.hours
      });
    }
  };



  handleSubmitHours = () => {
    //Set to firebase updated hours
    this.setState(prevState => ({open: !prevState.open}));
    this.props.handleUpdateHours({
      appointmentOnly: this.state.appointmentOnly,
      is24hours: this.state.is24hours,
      isOpen: {
        monday: this.state.isOpen.monday,
        tuesday: this.state.isOpen.tuesday,
        wednesday: this.state.isOpen.wednesday,
        thursday: this.state.isOpen.thursday,
        friday: this.state.isOpen.friday,
        saturday: this.state.isOpen.saturday,
        sunday: this.state.isOpen.sunday,
      },
      monday: this.state.monday,
      tuesday: this.state.tuesday,
      wednesday: this.state.wednesday,
      thursday: this.state.thursday,
      friday: this.state.friday,
      saturday: this.state.saturday,
      sunday: this.state.sunday,
    });
  };

  toggleEditForm = () => {
    this.setState(prevState => ({open: !prevState.open}));
  };

  setSelectedHourState = (value, day, index, type) => {
    if(index > 0) {
      //there is more time slots for this weekday
      this.setState(prevState => ({
      ...prevState,
      [day]: [
        ...prevState[day].slice(0,index),
        {
          ...prevState[day][index],
          [type]: value.value
        },
        ...prevState[day].slice(index),
      ]
    }), () => {
      if(this.props.editorOnly) {
        this.handleSubmitHours();
      }
    });
    } else {
      //there is only one time slot for this weekday
      this.setState(prevState => ({
        ...prevState,
        [day]: [
          {
            ...prevState[day][index],
            [type]: value.value
          },
        ]
      }), () => {
        if(this.props.editorOnly) {
          this.handleSubmitHours();
        }
      });
    }
  };

  handleAppointmentOnly = () => {
    this.setState(prevState => ({
      appointmentOnly: !prevState.appointmentOnly,
    }), () => {
      if(this.props.editorOnly) {
        this.handleSubmitHours();
      }
    });
  };

  handleToggleClosedDate = (weekday, event) => {
    var checked = event.target.checked;
    this.setState(prevState => ({
      isOpen: {
        ...prevState.isOpen,
        [weekday]: checked,
      }
    }), () => {
      if(this.props.editorOnly) {
        this.handleSubmitHours();
      }
    });
  };

  renderHoursList = () => {
    const weekdayList = [
      'monday',
      'tuesday',
      'wednesday',
      'thursday',
      'friday',
      'saturday',
      'sunday',
    ];

    // we only check if one date was created, if not then we don't loop the list
    return (
      <div className="weekday-row">
        {this.state.is24hours? <div className="is-24hours-wrap"></div>:<div></div> }
        {weekdayList.map((weekdayName, index) => (
          <div key={`weekday-list-${index}`}>
            {this.state[weekdayName].map((item, index) => (
              <div className={weekdayName == 'monday' && index == 0? 'hour-row-container': 'hour-row-container no-title'} key={`${item.name}-${index}`}>
              <div className="hour-col-label">
                <Typography className="hour-title">
                  {item.name.charAt(0).toUpperCase() + item.name.slice(1)}
                </Typography>
                <div className="switch-col">
                  {weekdayName == 'monday'?
                    <Typography className="open-switch-label">
                      Open?
                    </Typography>: ''
                  }
                  <Switch
                    checked={this.state.isOpen[item.name]}
                    onChange={(e) => this.handleToggleClosedDate(item.name, e)}
                    value={item.name}
                    color="primary"
                  />
                </div>
              </div>
              <div className="hour-col-select">
                {!this.state.isOpen[item.name]?
                <div className='hours-disabled'></div>:''}
                <div className="start-hour-col">
                  <HourSelect
                    setSelectedState={this.setSelectedHourState}
                    dayOfWeek={item.name}
                    type={'startHours'}
                    label={weekdayName == 'monday' && index == 0? 'Opens at': ''}
                    index={index}
                    currentSelect={this.state[item.name][index].startHours}
                  />
                </div>
                <div className="dash-hours">
                  <div className="dash"></div>
                </div>
                <div className="end-hour-col">
                  <HourSelect
                    setSelectedState={this.setSelectedHourState}
                    dayOfWeek={item.name}
                    type={'endHours'}
                    label={weekdayName == 'monday' && index == 0? 'Closes at': ''}
                    index={index}
                    currentSelect={this.state[item.name][index].endHours}
                  />
                </div>
              </div>
            </div>
            ))}
          </div>
        ))}
      </div>
    )
  }

  handleIs24hours = () => {
    this.setState(prevState => ({
      is24hours: !prevState.is24hours,
    }), () => {
      if(this.props.editorOnly) {
        this.handleSubmitHours();
      }
    });
  };

  render() {
    return(
      <div>
      {this.props.editorOnly?
        <div className="hosting-hub-access-hours">
          <div className="scheduler-screen-container">
            <div className="check-box-left">
              <FormControlLabel
                control={
                  <Checkbox checked={this.state.appointmentOnly} onChange={() => this.handleAppointmentOnly()} value="By Appointment Only" />
                }
                label="By Appointment Only"
              />
              <FormControlLabel
                control={
                  <Checkbox checked={this.state.is24hours} onChange={() => this.handleIs24hours()} value="24 Hour Access" />
                }
                label="24 Hour Access"
              />
            </div>
            {this.renderHoursList()}
          </div>
        </div>:
        <React.Fragment>
          <Button size="small" className="change-access-hours-btn" onClick={() => this.toggleEditForm()}>
            <ScheduleIcon />
            <span>
            Access Hours
            </span>
          </Button>
          <Dialog
            open={this.state.open}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => this.toggleEditForm()}
            className={"scheduler-screen-view selectHours"}
            >
            <DialogTitle className="scheduler-form-dialog-title">Access Hours</DialogTitle>
            <div className="scheduler-screen-container">
              <Typography className="dialog-info">What kind of access hours will you provide for your guests?</Typography>
                {this.renderHoursList()}
                <div className="check-box-center">
                <FormControlLabel
                  control={
                    <Checkbox checked={this.state.appointmentOnly} onChange={() => this.handleAppointmentOnly()} value="By Appointment Only" />
                  }
                  label="By Appointment Only"
                />
                <FormControlLabel
                  control={
                    <Checkbox checked={this.state.is24hours} onChange={() => this.handleIs24hours()} value="24 Hour Access" />
                  }
                  label="24 Hour Access"
                />
                </div>
                <div className="confirm-hours-actions-wrap">
                <div className="confirm-hours-actions">
                  <Button size="small" className="address-back-btn" onClick={() => this.toggleEditForm()} >Cancel Edit</Button>
                  <Button size="small" className="address-next-btn" onClick={() => this.handleSubmitHours()} >
                    <CheckIcon />
                    Save Access Hours
                  </Button>
                </div>
                </div>
            </div>
          </Dialog>
        </React.Fragment>
      }
    </div>
    )
  }
}

export default GarageAccessHours;