import React from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import CircularProgress from "@material-ui/core/CircularProgress";

import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

const ReportUser = ({
  reportUser,
  wantsToReport,
  askToReport,
  valueReport,
  typeMessageReport,
  offendingUserId,
  offendingUserPublicId,
  reported,
  reportedId,
  loadingReport,
  typeOfReport,
}) => {
  return (
    <Dialog
      open={wantsToReport}
      keepMounted
      onClose={askToReport}
      disableEscapeKeyDown={loadingReport? true: false}
      disableBackdropClick={loadingReport? true: false}
      aria-labelledby="wants-to-report"
      aria-describedby="report-user-dialog"
    >
      {reported ? (
        <React.Fragment>
          <DialogTitle>Report received</DialogTitle>
          <DialogContent>
            {reportedId?
              <DialogContentText>
                {`Thank you for keeping our community safe. Your case number is #${reportedId}. Please save this number if you need to contact us about the status of your case. We will review your report as soon as possible.`}
              </DialogContentText>:
              <DialogContentText>
                Thank you for keeping our community safe. We will review this case as soon as possible.
              </DialogContentText>
            }
          </DialogContent>
          <DialogActions>
            <Button onClick={askToReport} className="cancel-delete">
              Close
            </Button>
          </DialogActions>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <DialogTitle>Report User?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              We take user reports very seriously and take action on your
              feedback immediately. Are you sure you want to report this user?
            </DialogContentText>
            <Typography className="deny-label">
              * Please enter your reason for reporting this user.
            </Typography>
            <TextField
              id="report-input"
              className={"report-user-input"}
              placeholder={"Describe your user report here"}
              margin="normal"
              disabled={loadingReport}
              multiline
              variant="outlined"
              value={valueReport}
              onChange={typeMessageReport}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={askToReport}
              disabled={loadingReport}
              className="cancel-delete">
              Nevermind
            </Button>
            <Button
              disabled={loadingReport}
              onClick={() => reportUser(offendingUserId, offendingUserPublicId, typeOfReport)}
              startIcon={loadingReport? <CircularProgress style={{height: 15, width: 15, color: "#ff008b"}} className="loading-payment-request" />:null}
              className={loadingReport? "delete-forever-btn loading-delete-request": "delete-forever-btn"}>
              {loadingReport?
                "Reporting...":"Report User"
              }
            </Button>
          </DialogActions>
        </React.Fragment>
      )}
    </Dialog>
  );
};

export default ReportUser;
