import React from 'react';

export default class WordSlider extends React.Component {

  state = {
    words: [
      "a garage",
      "storage",
      "a workshop",
      "parking",
    ],
    sliderIndex: 0,
  };

  componentDidMount() {
    this.timer = setTimeout(() => {
      this.transitionSlide();
    }, 7000);
  };


  componentWillUnmount() {
    this.stopSlider();
  };

  transitionSlide = () => {

    if(this.state.sliderIndex === this.state.words.length - 1) {
      this.setState( prevState => ({
        sliderIndex: 0
      }));
    } else {
      this.setState(prevState => ({
        sliderIndex: prevState.sliderIndex + 1,
      }));
    }
    this.timer = setTimeout(() => {
      this.transitionSlide();
    }, 8000);

  };

  stopSlider = () => {
    clearTimeout(this.timer);
  };

  createSlides = () => {
    return (
      <React.Fragment>
        {this.state.words.map((word, i) => (
          <b key={`wordName-${i}`}>
          <span className={"word-slider-" + i + " currentWordIndex-" + this.state.sliderIndex} >{word}</span>
          </b>
        ))}
      </React.Fragment>
    )
  };

  render() {
    return (
      <React.Fragment>
        {this.createSlides()}
      </React.Fragment>
    )
  }
};
