import React from 'react';
import Typography from '@material-ui/core/Typography';
import { ReactComponent as SafeIcon } from '../images/safe.svg';
import { ReactComponent as HandIcon } from '../images/handshake.svg';
import { ReactComponent as TwentyFourIcon } from '../images/24hr.svg';

const GarageStandards = () => {
  return(
    <div className="garage-standards-bar">
      <div className="standards-container">
        <div className="col-item">
          <SafeIcon />
          <Typography variant="body1" className="header-info">
          Private & Secure
          </Typography>
          <Typography variant="body2">
            MotorRoof utilizes advanced data encryption technology to keep your information and transactions private and secure.
          </Typography>
        </div>
        <div className="col-item">
          <HandIcon />
          <Typography variant="body1" className="header-info">
          Safety is first
          </Typography>
          <Typography variant="body2">
            Scammers begone! We track unsafe behavior using Artifical Intelligence and deploy Deep Learning mechanisms for detecting bad actors.
          </Typography>
        </div>
        <div className="col-item">
          <TwentyFourIcon />
          <Typography variant="body1" className="header-info">
          24-Hour Support
          </Typography>
          <Typography variant="body2">
            If you have any issues or need to report unsafe behavior, you can call, email, or live chat our support team anytime.
          </Typography>
        </div>
      </div>
    </div>
  )
}

export default GarageStandards;
